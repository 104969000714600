import React from 'react';

import { Form } from '@unform/web';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Input from '~/components/Input';

function FilterVehicle({ onChange }) {
  function handleFilter(data) {
    onChange(data);
  }

  return (
    <Form onSubmit={handleFilter}>
      <Card className="card-list mb-3">
        <Card.Body className="p-5">
          <Row>
            <Col xs={12}>
              <h5 className="card-title">Filtro</h5>
              <hr className="my-3" />
            </Col>
            <Col lg={5} md={5} xs={12}>
              <Input name="plate" label="Placa" maxLength={7} />
            </Col>
            <Col lg={5} md={5} xs={12}>
              <Input name="driver_name" label="Motorista" />
            </Col>
            <Col lg={2} md={2} xs={12} className="text-right py-4">
              <Button type="submit" variant="success">
                Filtrar
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
}

export default FilterVehicle;
