import React, { useEffect, useState } from 'react';
import { Col, Nav, Row, Table } from 'react-bootstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ValidationError } from 'yup';
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Text,
  useSnackbar,
} from '@cargon/ui';

import api from '~/services/api';
import { useTravel } from '~/pages/Travels/Details/provider';
import useErrors from '~/hooks/useErrors';
import CurrencyInput from '@cargon/ui/dist/Input/Currency';
import schema from './schema';

import UploadCteXml from './UploadCteXml';
import UploadMdfeXml from './UploadMdfeXml';
import { ListCtes } from './ListCtes';
import NfseManual from './UploadNfse/NfseManual';

export default function Cte({ showModal, handleClose, status }) {
  const snackbar = useSnackbar();
  const travel = useTravel();

  const [cteList, setCteList] = useState([]);

  const [travelNumber, setTravelNumber] = useState(
    travel.travelInfo.travelNumber
  );
  const [cteNumber, setCteNumber] = useState('');
  const [issuedAt, setIssuedAt] = useState('');
  const [invoices, setInvoices] = useState([]);
  const [destination, setDestination] = useState('');
  const [amountReceivable, setAmountReceivable] = useState();

  const [errors, { getErrorsFromYup, clearErrors }] = useErrors({});

  const [isInFlight, setInFlight] = useState(false);

  const [selectedOptionMenu, setSelectedOptionMenu] = useState('ctemanual');

  useEffect(() => {
    setTravelNumber(travel.travelInfo.travelNumber);
  }, [travel.travelInfo.travelNumber]);

  async function handleAddCte() {
    try {
      const validated = await schema.validate(
        {
          travelNumber,
          cteNumber,
          issuedAt,
          invoices,
          destination,
          amountReceivable,
        },
        {
          abortEarly: false,
          context: { cteList, emitteds: travel.travelInfo.emitteds },
        }
      );

      setCteList(previous => [...previous, validated]);

      setDestination('');
      setCteNumber('');
      setIssuedAt('');
      setInvoices([]);
      setAmountReceivable('');
    } catch (ex) {
      if (ex instanceof ValidationError) {
        getErrorsFromYup(ex.inner);
      }
    }
  }

  async function submitCteList(ctes, outerClearErrors) {
    try {
      setInFlight(true);

      const inTravelStatus = travel.statuses.find(item => item.code === 'ST3');
      const assertedStatus =
        status.order < inTravelStatus.order ? inTravelStatus : status;

      await api.put(
        `travel-data/updateStatusWithCTe/${travel?.travelInfo?.attendedId}`,
        {
          status: assertedStatus,
          travelNumber,
          cteList: ctes,
          carrier: true,
        }
      );

      travel.setTravelInfo(prev => ({ ...prev, status }));

      snackbar.show(<Text>Lista de CT-e adicionada com sucesso.</Text>, {
        type: 'success',
      });

      await travel.getTravel();
    } catch (ex) {
      let message = 'Não foi possível cadastrar a lista de CT-e.';
      if (ex.response) {
        const { error } = ex.response.data;

        if (error && typeof error === 'string') {
          message = error;
        }
      }

      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setInFlight(false);
      setCteList([]);
      clearErrors();
      if (outerClearErrors) {
        outerClearErrors();
      }
    }
  }

  function handleDelete(item) {
    setCteList(cteList.filter(e => e !== item));
  }

  function handleEdit(item) {
    handleDelete(item);

    setDestination(item.destination);
    setCteNumber(item.cteNumber);
    setTravelNumber(item.travelNumber);
    setInvoices(item.invoices);
    setIssuedAt(item.issuedAt);
    setAmountReceivable(item.amountReceivable);
  }

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      handleClose={handleClose}
      heading={
        <Text type="subheader" color="dark" weight="500">
          Adicionar CT-e
        </Text>
      }
      body={
        <>
          <Row className="mb-3">
            <Nav
              variant="tabs"
              activeKey={selectedOptionMenu}
              onSelect={eventKey => setSelectedOptionMenu(eventKey)}
            >
              <Nav.Item>
                <Nav.Link eventKey="ctemanual">CTe manual</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ctexml">CTe</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="nfsexml">NFSe</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="mdfexml">MDFe</Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
          {selectedOptionMenu === 'ctexml' && (
            <UploadCteXml
              setCteList={setCteList}
              submitCteList={submitCteList}
              destinationList={travel.travelInfo.destinations}
            />
          )}
          {selectedOptionMenu === 'nfsexml' && (
            <NfseManual
              submitCteList={submitCteList}
              destinationList={travel.travelInfo.destinations}
              setCteListToSubmit={setCteList}
              travelNumberExists={travel.travelInfo.travelNumber}
              emitteds={travel.travelInfo.emitteds}
              isInFlight={isInFlight}
            />
          )}
          {selectedOptionMenu === 'mdfexml' && (
            <UploadMdfeXml
              originList={[travel.travelInfo.origin]}
              destinationList={travel.travelInfo.destinations}
            />
          )}
          {selectedOptionMenu === 'ctemanual' && (
            <Row>
              <Col xs={12} className="mb-3">
                <Input
                  value={travelNumber}
                  disabled={!!travel.travelInfo.travelNumber}
                  label="Nº da viagem"
                  onChange={event => setTravelNumber(event.target.value)}
                  error={errors.travelNumber}
                />
              </Col>
              <Col xs={6} className="mb-3">
                <Input
                  value={cteNumber}
                  label="Nº do CT-e"
                  onChange={event => setCteNumber(event.target.value)}
                  error={errors.cteNumber}
                />
              </Col>
              <Col className="mb-3">
                <DatePicker
                  value={issuedAt}
                  label="Data de emissão"
                  placeholder="__/__/____ __:__"
                  guide
                  withTime
                  onChange={event => setIssuedAt(event.target.value)}
                  error={errors.issuedAt}
                />
              </Col>
              <Col xs={12}>
                <CurrencyInput
                  label="Valor a receber"
                  value={amountReceivable}
                  onChange={(_event, value) => setAmountReceivable(value)}
                  error={errors.amountReceivable}
                />
              </Col>
              <Col xs={12} className="mb-3">
                <Text type="label">Notas fiscais</Text>
                <AsyncCreatableSelect
                  value={invoices}
                  noOptionsMessage={() => <Text>Digite uma nota fiscal</Text>}
                  formatCreateLabel={value => (
                    <Text>{`Criar "${value}"`} </Text>
                  )}
                  placeholder={null}
                  isMulti
                  createOptionPosition="first"
                  onChange={value => setInvoices(value)}
                />
                {!!errors.invoices && (
                  <Text type="little" color="error">
                    {errors.invoices}
                  </Text>
                )}
              </Col>
              {!!invoices?.length && (
                <Col xs={12}>
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          <Text type="label">Nº da Nota</Text>
                        </th>
                        <th>
                          <Text type="label">Chave</Text>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {invoices.map((invoice, index) => (
                        <tr>
                          <td>
                            <Text type="label">{invoice.value}</Text>
                          </td>
                          <td>
                            <Input
                              value={invoice?.key}
                              onChange={event =>
                                setInvoices(
                                  invoices.map((item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        key: event.target.value,
                                      };
                                    }

                                    return item;
                                  })
                                )
                              }
                              error={errors[`invoices[${index}].key`]}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              )}
              <Col xs={12} className="mb-3">
                <Select
                  placeholder={null}
                  value={destination}
                  label="Destino"
                  options={travel.travelInfo.destinations}
                  getOptionLabel={option => option.formatted}
                  getOptionValue={option => option.id}
                  onChange={item => setDestination(item)}
                  error={errors.destination}
                />
              </Col>
              <Col xs={12} className="text-center mb-3">
                <Button
                  variant="secondary"
                  onClick={() => handleAddCte()}
                  className="py-2"
                >
                  <Text type="regular" weight="500">
                    Adicionar
                  </Text>
                </Button>
              </Col>

              {!!cteList.length && (
                <>
                  <Col xs={12}>
                    <ListCtes
                      cteList={cteList}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  </Col>
                  <Col xs={12} className="pt-4 text-center">
                    <Button
                      variant="primary"
                      onClick={() => submitCteList(cteList)}
                      loading={isInFlight}
                      className="my-2"
                    >
                      <Text type="regular" weight="500">
                        Salvar
                      </Text>
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          )}
        </>
      }
    />
  );
}
