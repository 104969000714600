import React, { useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ValidationError } from 'yup';
import { Button, DatePicker, Input, Select, Text } from '@cargon/ui';
import useErrors from '~/hooks/useErrors';
import CurrencyInput from '@cargon/ui/dist/Input/Currency';
import schema from './schema';

import { ListCtes } from '../ListCtes';

export default function NfseManual({
  submitCteList,
  destinationList,
  travelNumberExists,
  emitteds,
  isInFlight,
}) {
  const [errors, { getErrorsFromYup, clearErrors }] = useErrors({});

  const [cteList, setCteList] = useState([]);
  const [travelNumber, setTravelNumber] = useState(travelNumberExists);
  const [nfseNumber, setNfseNumber] = useState();
  const [issuedAt, setIssuedAt] = useState();
  const [amountReceivable, setAmountReceivable] = useState();
  const [invoices, setInvoices] = useState([]);
  const [destination, setDestination] = useState();

  async function handleAddCte() {
    try {
      const validated = await schema.validate(
        {
          travelNumber,
          cteNumber: nfseNumber,
          issuedAt,
          invoices,
          destination,
          amountReceivable,
        },
        {
          abortEarly: false,
          context: { cteList, emitteds },
        }
      );

      setCteList(previous => [...previous, validated]);

      setDestination('');
      setNfseNumber('');
      setIssuedAt('');
      setInvoices([]);
      setAmountReceivable(0);
      clearErrors();
    } catch (ex) {
      if (ex instanceof ValidationError) {
        getErrorsFromYup(ex.inner);
      }
    }
  }

  function handleDelete(item) {
    setCteList(cteList.filter(e => e !== item));
  }

  function handleEdit(item) {
    handleDelete(item);

    setDestination(item.destination);
    setNfseNumber(item.nfseNumber);
    setTravelNumber(item.travelNumber);
    setInvoices(item.invoices);
    setIssuedAt(item.issuedAt);
    setAmountReceivable(item.amountReceivable);
  }

  function handleSubmit() {
    submitCteList(cteList, clearErrors);
  }

  return (
    <Row>
      <Col xs={12} className="mb-3">
        <Input
          value={travelNumber}
          disabled={!!travelNumberExists}
          label="Nº da viagem"
          onChange={event => setTravelNumber(event.target.value)}
          error={errors.travelNumber}
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Input
          value={nfseNumber}
          label="Nº do CT-e"
          onChange={event => setNfseNumber(event.target.value)}
          error={errors.cteNumber}
        />
      </Col>
      <Col className="mb-3">
        <DatePicker
          value={issuedAt}
          label="Data de emissão"
          placeholder="__/__/____ __:__"
          guide
          withTime
          onChange={event => setIssuedAt(event.target.value)}
          error={errors.issuedAt}
        />
      </Col>
      <Col xs={12}>
        <CurrencyInput
          label="Valor à receber"
          value={amountReceivable}
          onChange={(_event, value) => {
            setAmountReceivable(value);
          }}
          error={errors.amountReceivable}
        />
      </Col>
      <Col xs={12} className="mb-3">
        <Text type="label">Notas fiscais</Text>
        <AsyncCreatableSelect
          value={invoices}
          noOptionsMessage={() => <Text>Digite uma nota fiscal</Text>}
          formatCreateLabel={value => <Text>{`Criar "${value}"`} </Text>}
          placeholder={null}
          isMulti
          createOptionPosition="first"
          onChange={value => setInvoices(value)}
        />
        {!!errors.invoices && (
          <Text type="little" color="error">
            {errors.invoices}
          </Text>
        )}
      </Col>
      {!!invoices.length && (
        <Col xs={12}>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text type="label">Nº da Nota</Text>
                </th>
                <th>
                  <Text type="label">Chave</Text>
                </th>
              </tr>
            </thead>

            <tbody>
              {invoices.map((invoice, index) => (
                <tr>
                  <td>
                    <Text type="label">{invoice.value}</Text>
                  </td>
                  <td>
                    <Input
                      value={invoice?.key}
                      error={errors[`invoices[${index}].key`]}
                      onChange={event =>
                        setInvoices(
                          invoices.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                key: event.target.value,
                              };
                            }

                            return item;
                          })
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      )}
      <Col xs={12} className="mb-3">
        <Select
          placeholder={null}
          value={destination}
          label="Destino"
          options={destinationList}
          getOptionLabel={option => option.formatted}
          getOptionValue={option => option.id}
          onChange={item => setDestination(item)}
          error={errors.destination}
        />
      </Col>
      <Col xs={12} className="text-center mb-3">
        <Button variant="secondary" onClick={handleAddCte} className="py-2">
          <Text type="regular" weight="500">
            Adicionar
          </Text>
        </Button>
      </Col>
      {!!cteList.length && (
        <>
          <Col xs={12}>
            <ListCtes
              cteList={cteList}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </Col>
          <Col xs={12} className="pt-4 text-center">
            <Button
              variant="primary"
              onClick={() => handleSubmit()}
              loading={isInFlight}
              className="my-2"
            >
              <Text type="regular" weight="500">
                Salvar
              </Text>
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
}
