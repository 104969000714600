import React, { useMemo, useState } from 'react';
import { Modal, PDFView, Text, useSnackbar, Radio, Loader } from '@cargon/ui';
import { formatDateTime } from '~/utils/formatter';
import { FaDownload, FaSearch } from 'react-icons/fa';
import { useTravel } from '~/pages/Travels/Details/provider';
import api from '~/services/api';
import * as Styled from './styles';

export default function Attachment({ document, onClickPreview }) {
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);
  const [isInFlight, setInFlight] = useState(false);
  const [status, setStatus] = useState();
  const travel = useTravel();
  const snackbar = useSnackbar();

  const attachment = useMemo(
    () => ({
      ...document.attachments[0],
      obs: document.obs_data,
      nf_number: document.nf_number,
    }),
    [document]
  );

  function handleClick() {
    if (document.isDocument) {
      setShowingModal(true);
    } else {
      onClickPreview();
    }
  }

  async function updateReview(newStatus) {
    try {
      setInFlight(true);
      await api.post(`review-attachments/${attachment.id}`, {
        status: newStatus,
        load_id: travel.travelInfo?.load?.id,
        travel_id: travel.id,
      });

      const messages = {
        accept: 'Anexo aprovado!',
        reject: 'Anexo rejeitado!',
        resend: 'Solicitação de reenvio realizada!',
      };

      snackbar.show(<Text>{messages[newStatus]}</Text>, { type: 'success' });
      setStatus(newStatus);
      await travel.updateEmittedStatus();
    } catch (ex) {
      snackbar.show(
        <Text>
          {ex?.message
            ? `Status alterado, mas houve erro nas seguintes automações: ${ex.message}`
            : 'Não foi possível alterar o status desse anexo'}
        </Text>,
        {
          type: 'warning',
        }
      );
    } finally {
      setInFlight(false);
    }
  }

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      updateReview(status);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [status]);

  React.useEffect(() => {
    if (!travel.isFetching) {
      setStatus(attachment.status);
    }
  }, [attachment]);

  return (
    <div>
      <Modal
        show={isShowingModal}
        onHide={() => setShowingModal(false)}
        size="lg"
        heading={
          <Text color="dark" type="header" weight="500">
            Visualizar PDF
          </Text>
        }
        body={<PDFView file={attachment.path} />}
      />
      {!!attachment.nf_number && (
        <Text type="label" color="dark">
          Nota fiscal {attachment.nf_number}
        </Text>
      )}
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 270,
          height: 160,
          background: `url(${
            document.isDocument ? '/images/default-doc.png' : attachment.path
          }) center center`,
          backgroundSize: '100%',
        }}
      >
        {isHovering && (
          <Styled.Backdrop>
            <button type="button" onClick={handleClick}>
              <FaSearch color="#fff" size={32} />
            </button>
            <a href={attachment.path} download>
              <FaDownload color="#fff" size={32} />
            </a>
          </Styled.Backdrop>
        )}
        {isInFlight && (
          <Styled.Backdrop>
            <Loader size="md" loading={isInFlight} variant="light" />
          </Styled.Backdrop>
        )}
        <Styled.DateTime>
          <Text type="label" color="white">
            {formatDateTime(document.created_at)}
          </Text>
        </Styled.DateTime>
      </div>
      <div className="d-flex justify-content-center mt-1">
        <Radio.Group
          value={status}
          horizontal
          onChange={({ target }) => setStatus(target.value)}
          disabled={isInFlight}
        >
          <Radio value="accept">
            <Text type="regular" color="dark">
              Aceitar
            </Text>
          </Radio>
          <Radio value="resend">
            <Text type="regular" color="dark">
              Reenviar
            </Text>
          </Radio>
          <Radio value="reject">
            <Text type="regular" color="dark">
              Rejeitar
            </Text>
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
}
