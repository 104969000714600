import React from 'react';

import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function DefaultEmptyComponent() {
  return (
    <Container>
      <Row className="pt-5">
        <Col className="pt-5 d-flex justify-content-center align-items-center">
          <Button variant="primary" size="md" disabled>
            Não há itens a serem exibidos
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export function DefaultLoadingComponent() {
  return (
    <Container>
      <Row className="pt-5">
        <Col className="pt-5 d-flex justify-content-center align-items-center">
          <Button variant="secondary" size="md" disabled>
            <Spinner animation="border" role="status" /> Carregando
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

function List({
  data,
  loading,
  renderItem,
  EmptyComponent = DefaultEmptyComponent,
  LoadingComponent = DefaultLoadingComponent,
  isInfinite = false,
}) {
  if (!isInfinite) {
    const display = data.length ? data.map(renderItem) : <EmptyComponent />;
    return <Row>{loading ? <LoadingComponent /> : display}</Row>;
  }
  const notFound = !data.length && !loading && <EmptyComponent />;
  const display = data?.map(renderItem);

  return (
    <Row>
      {display.length ? display : notFound}
      {loading && <LoadingComponent />}
    </Row>
  );
}

export default List;
