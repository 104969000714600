import api from '../api';

export async function fetchReceiverOptions(personId) {
  const response = await api.get(`v3/persons/owner/${personId}`);
  return response.data;
}

export async function fetchPaymentCards(id) {
  const response = await api.get(`payment/info/${id}`);
  return response.data;
}
