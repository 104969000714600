/* eslint-disable import/no-cycle */
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import { Form as Unform } from '@unform/web';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { useSelector, useDispatch } from 'react-redux';

import Header from '~/components/Header';
import Offset from '~/components/Offset';
import Button from '~/components/Button';
import api from '~/services/api';
import { formatCurrency } from '~/utils/formatter';
import Select from '~/components/Select';
import VehicleActions from '~/store/modules/vehicle/actions';
import DealActions from '~/store/modules/deals/actions';

export default function Deal() {
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const vehicles = useSelector(store => store.vehicle.data);
  const dealError = useSelector(store => store.deals.error);
  const dealSuccess = useSelector(store => store.deals.success);
  const user = useSelector(store => store.auth.user.properties);
  const { load_id } = useParams();
  const [load, setLoad] = useState(null);
  const [displayError, setDisplayError] = useState(false);

  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    async function fetchLoad() {
      try {
        const loadRes = await api.get(`loads/${load_id}`);

        if (loadRes.data) {
          setLoad(loadRes.data);
        }
      } finally {
        setLoadingPage(false);
      }
    }
    fetchLoad();
    dispatch(VehicleActions.fetchVehicles());
  }, []);

  useEffect(() => {
    function processLoad(load) {
      if (load.originsList && Array.isArray(load.originsList)) {
        const [origin] = load.originsList;
        setOrigin(`${origin.city} - ${origin.province}`);
      }
      if (load.destinationsList && Array.isArray(load.destinationsList)) {
        const destinations = load.destinationsList
          .map(item => {
            return `${item.city} - ${item.province}`;
          })
          .join(' -> ');
        setDestination(destinations);
      }
    }
    if (load) processLoad(load);
  }, [load]);

  useEffect(() => {
    setDisplayError(!!dealError);
  }, [dealError]);

  function formSubmit(data) {
    const vehicle = vehicles.find(ve => ve.id === data.vehicle_id);

    dispatch(
      DealActions.dealCreateRequest(
        {
          negotiator_id: load.negotiator_id,
          driver_id: user.id,
          load_id,
          vehicle,
        },
        history
      )
    );
  }
  const cardLoad = () => {
    return (
      <Unform ref={formRef} onSubmit={formSubmit}>
        <Row>
          <Col xs={12}>
            <Card classname="card-list">
              <Card.Body className="p-5">
                <Row>
                  <Col xs={12}>
                    <h5 className="card-title">Nº da carga - {load_id}</h5>
                    <hr className="my-3" />
                  </Col>
                  <Col xs={12}>
                    {!load.negotiator_id && load.contact_info && (
                      <Alert variant="info">
                        <b>Carga disponível apenas para negociação via </b>
                        <a
                          target="blank"
                          href={`http://api.whatsapp.com/send?phone=55${load.contact_info}`}
                        >
                          WhatsApp
                        </a>
                      </Alert>
                    )}
                    {displayError && (
                      <Alert
                        variant="danger"
                        onClose={() => setDisplayError(false)}
                        dismissible
                      >
                        {dealError.message}
                      </Alert>
                    )}
                    {dealSuccess && (
                      <Alert variant="success">
                        <b>Negociação criada com sucesso!</b>
                      </Alert>
                    )}
                  </Col>
                  <Col xs={12}>
                    <div className="form-group">
                      <div className="card-label">Origem</div>
                      <div className="card-text">{origin}</div>
                    </div>
                    <div className="form-group">
                      <div className="card-label">Destino</div>
                      <div className="card-text">{destination}</div>
                    </div>
                    <div className="form-group">
                      <div className="card-label">Valor</div>
                      <div className="card-text">
                        {load.fare_company
                          ? formatCurrency(load.fare_company)
                          : 'A combinar'}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    {load.negotiator_id && (
                      <Select
                        label={
                          <strong>Selecione um veículo para negociação</strong>
                        }
                        name="vehicle_id"
                        options={vehicles}
                        getOptionLabel={option => option.plate}
                      />
                    )}
                  </Col>
                  {load.negotiator_id && (
                    <Col xs={12} className="text-right">
                      <Button
                        variant="success"
                        type="submit"
                        className="mt-3 px-3"
                      >
                        Iniciar negociação
                      </Button>
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Unform>
    );
  };
  return (
    <>
      <Header />
      <Offset>
        <Container>
          <Row>
            <Col lg={6} md={6} xs={12}>
              {loadingPage && <Spinner animation="grow" />}

              {!loadingPage && !load && (
                <Card classname="card-list">
                  <Card.Body className="p-5">
                    <Alert variant="info" className="mb-0">
                      <b>Carga não encontrada</b> <br />
                      Caso o problema persista, entre em contato conosco através
                      do email{' '}
                      <a href="mailto:ola@cargon.com.br">
                        ola@cargon.com.br
                      </a>{' '}
                      ou dos nossos métodos de contato disponível no nosso site:{' '}
                      <a href="https://cargon.com.br/contato/">cargon.com.br</a>
                    </Alert>
                  </Card.Body>
                </Card>
              )}

              {!loadingPage && load && cardLoad()}
            </Col>
          </Row>
        </Container>
      </Offset>
    </>
  );
}
