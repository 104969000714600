import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import {
  Button,
  Card,
  BulletList as List,
  Modal,
  Text,
  useSnackbar,
} from '@cargon/ui';

import { ptBR } from 'date-fns/locale';
import { formatDuration, intervalToDuration, isBefore } from 'date-fns';

import TravelBadge from '~/components/TravelBadge';
import { formatCurrency, formatDate } from '~/utils/formatter';
import api from '~/services/api';
import * as Controller from './controller';
import * as Styled from './styles';

export default function TravelCard({ travel, refuse }) {
  const snackbar = useSnackbar();
  const [acceptDuration, setAcceptDuration] = useState('');
  const [confirmationDuration, setConfirmationDuration] = useState('');
  const [acceptedInList, setAcceptedInList] = useState(false);
  const hasBeenConfirmed = useMemo(() => {
    return !!travel?.vehicle_id && !!travel?.driver_id;
  }, [travel?.vehicle_id, travel?.driver_id]);

  const calcConfirmationDeadlineDuration = () => {
    if (travel?.confirmation_deadline) {
      if (isBefore(new Date(), new Date(travel?.confirmation_deadline))) {
        const duration = intervalToDuration({
          start: new Date(),
          end: new Date(travel?.confirmation_deadline),
        });
        const units = ['days', 'hours', 'minutes'];
        const nonzero = Object.entries(duration)
          .filter(([_, value]) => (value || 0) > 0)
          .map(([unit, _]) => unit);
        const temp = formatDuration(duration, {
          format: units.filter(i => new Set(nonzero).has(i)).slice(0, 3),
          delimiter: ', ',
          locale: ptBR,
        });
        setConfirmationDuration(temp);
      }
    }
  };

  useEffect(() => {
    calcConfirmationDeadlineDuration();
  }, [travel?.confirmation_deadline]);

  useEffect(() => {
    setTimeout(() => calcConfirmationDeadlineDuration(), 60000);
  }, [confirmationDuration]);

  const calcDeadlineDuration = () => {
    if (travel?.accept_deadline) {
      if (isBefore(new Date(), new Date(travel?.accept_deadline))) {
        const duration = intervalToDuration({
          start: new Date(),
          end: new Date(travel?.accept_deadline),
        });
        const units = ['months', 'days', 'hours', 'minutes'];
        const nonzero = Object.entries(duration)
          .filter(([_, value]) => (value || 0) > 0)
          .map(([unit, _]) => unit);
        const temp = formatDuration(duration, {
          format: units.filter(i => new Set(nonzero).has(i)).slice(0, 3),
          delimiter: ', ',
          locale: ptBR,
        });
        setAcceptDuration(temp);
      }
    }
  };

  useEffect(() => {
    calcDeadlineDuration();
  }, [travel?.accept_deadline]);

  useEffect(() => {
    setTimeout(() => calcDeadlineDuration(), 60000);
  }, [acceptDuration]);

  const accepted = useMemo(() => {
    return (
      (travel?.logs?.length > 0
        ? travel.logs[0].allocatedTravel?.accepted
        : undefined) || acceptedInList
    );
  }, [travel?.logs, acceptedInList]);

  const auction = useMemo(() => {
    if (travel?.auction?.length > 0) {
      return travel.auction[0];
    }
    return undefined;
  }, [travel?.auction]);

  useEffect(() => {
    calcConfirmationDeadlineDuration();
  }, [travel?.confirmation_deadline]);

  useEffect(() => {
    setTimeout(() => calcConfirmationDeadlineDuration(), 60000);
  }, [confirmationDuration]);

  const [show, setShow] = useState(false);
  const [showCtes, setShowCtes] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleCloseAllDestinations = () => setShow(false);
  const handleShowAllDestinations = () => setShow(true);

  async function acceptTravel() {
    setLoading(true);
    try {
      await api.post(`allocated-travels/accept/${travel.id}`, {
        parameters: {
          load_id: travel.load_id,
          person_id: travel.person_id,
        },
      });

      snackbar.show(<Text>Viagem aceita com sucesso!</Text>, {
        type: 'success',
      });
      setAcceptedInList(true);
    } catch (error) {
      snackbar.show(
        <Text>
          {error.response?.data?.error ||
            'Algo deu errado, tente novamente mais tarde!'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }
  const ModalDestinations = () => (
    <Modal
      scrollable
      show={show}
      onHide={handleCloseAllDestinations}
      heading={
        <Text type="header" className="ml-3">
          Todos os destinos
        </Text>
      }
      body={
        <Row md={12}>
          <Col md={6} className="pl-5">
            <List items={Controller.locationsObject(true, travel)} />
          </Col>
        </Row>
      }
    />
  );

  const Header = () => (
    <div>
      <span>
        <Text color="gray" className="mr-2">
          Viagem {travel.id}{' '}
          {travel.travel_number &&
            travel.travel_number !== travel.id &&
            `- ${travel.travel_number}`}
        </Text>
        {travel?.status?.code === 'ST7' ? (
          <TravelBadge
            className="ml-2 mt-fix"
            variant="danger"
            style={{ fontSize: '100%' }}
          >
            Cancelada
          </TravelBadge>
        ) : (
          <>
            {auction && (
              <TravelBadge
                pill
                variant="primary"
                className="mt-1 ml-2"
                style={{ fontSize: '100%' }}
              >
                Em oferta
              </TravelBadge>
            )}
            {accepted && !auction && (
              <TravelBadge
                pill
                variant="primary"
                className="mt-1 ml-2"
                style={{ fontSize: '100%' }}
              >
                Aceita
              </TravelBadge>
            )}
            {travel?.status?.code === 'ST2' && travel?.is_released === true && (
              <TravelBadge
                pill
                variant="success"
                className="mt-1 ml-2"
                style={{ fontSize: '100%' }}
              >
                Liberada
              </TravelBadge>
            )}
          </>
        )}
      </span>
      <div className="mt-2">
        {acceptDuration && !accepted && !auction && (
          <Text type="regular" className="">
            Tempo restante para aceite: <strong>{acceptDuration}</strong>
          </Text>
        )}
      </div>
      <div>
        {!hasBeenConfirmed && confirmationDuration && !auction && (
          <Text type="regular" className="">
            Tempo restante para confirmação:{' '}
            <strong>{confirmationDuration}</strong>
          </Text>
        )}
      </div>
    </div>
  );
  return (
    <Col xs={12}>
      <Card
        header={<Header />}
        HeaderRightComponent={
          <div style={{ display: 'flex' }}>
            {!auction && (
              <>
                {travel?.status?.code === 'ST2' && (
                  <Button
                    variant="error"
                    onClick={() => refuse()}
                    size="md"
                    className="mr-2 mt-1"
                  >
                    <Text weight={500} type="regular">
                      Recusar viagem
                    </Text>
                  </Button>
                )}
                {!hasBeenConfirmed && (
                  <>
                    {!accepted && acceptDuration && (
                      <Button
                        variant="primary"
                        onClick={() => acceptTravel()}
                        size="md"
                        className="mr-2 mt-1"
                        loading={loading}
                      >
                        <Text weight={500} type="regular">
                          Aceitar viagem
                        </Text>
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
            <Button
              variant="primary"
              as={Link}
              className="mt-1"
              to={auction ? `/oferta/${auction.id}` : `/viagens/${travel.id}`}
              size="md"
            >
              <Text> Ver Detalhes</Text>
            </Button>
          </div>
        }
      >
        <ModalDestinations />
        <Row>
          <Col lg={6} md={6} xs={12}>
            <Row>
              <Col xs={12} className="travel-list-align mb-2">
                <Text className="d-block travel-label-align mb-2">Cliente</Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {travel?.client_name || 'Não informado'}
                </Text>
                {travel?.client_cgccpf && (
                  <Text color="gray" type="regular" className="d-block mb-1">
                    {travel?.client_cgccpf}
                  </Text>
                )}
              </Col>
              <Col xs={12}>
                <List
                  items={Controller.locationsObject(
                    false,
                    travel,
                    handleShowAllDestinations
                  )}
                />
              </Col>
              <Col xs={12}>
                {travel.tags && travel.tags?.length > 0 && (
                  <span style={{ lineHeight: 2 }}>
                    <Text className="d-block travel-label-align mb-2">
                      Tags
                    </Text>
                    {travel.tags.map(tag => (
                      <span className="mr-2 mb-2">
                        <Styled.Badge pill variant="secondary">
                          <Text type="label">{tag.name}</Text>
                        </Styled.Badge>
                      </span>
                    ))}
                  </span>
                )}
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Row>
              <Col xs={12} md={6} className="mb-3">
                <Text className="d-block load-label-align mb-2">
                  Status da viagem:
                </Text>
                <Text color="gray" type="regular" className="d-block mb-3">
                  {travel?.status?.name}
                </Text>
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Text className="d-block load-label-align mb-2">Valor:</Text>
                <Text color="gray" type="regular" className="d-block mb-3">
                  {travel.balance === null
                    ? 'Não possui valor cadastrado'
                    : formatCurrency(travel.balance)}
                </Text>
              </Col>

              <Col xs={12} md={6} className="mb-3">
                <Text className="d-block load-label-align mb-2">
                  Lançamento:
                </Text>
                <Text color="gray" type="regular" className="d-block mb-3">
                  {formatDate(travel.created_at)}
                </Text>
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Text className="d-block load-label-align mb-2">Nº:</Text>
                <Text color="gray" type="regular" className="d-block mb-3">
                  {travel.travel_number || 'Não Informado'}
                </Text>
              </Col>

              {travel.comments && (
                <Col xs={12} md={12} className="mb-3">
                  <Text className="d-block load-label-align mb-2">
                    Observações:
                  </Text>
                  <Text color="gray" type="regular" className="d-block mb-3">
                    {travel.comments}
                  </Text>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}
export const MemoizedTravelCard = React.memo(TravelCard);
