import vehicleTollTagSchema from './validators/tollTags';
import {
  registerTractionVehicleSchema,
  registerWagonVehicleSchema,
} from './validators/registerVehicle';

export const qtyAxis = [
  { value: 1, name: '1 eixo' },
  { value: 2, name: '2 eixos' },
  { value: 3, name: '3 eixos' },
  { value: 4, name: '4 eixos' },
];
export const colors = [
  { label: 'AMARELO', value: 'AMARELO' },
  { label: 'AZUL', value: 'AZUL' },
  { label: 'BEGE', value: 'BEGE' },
  { label: 'BORDO', value: 'BORDO' },
  { label: 'BRANCO', value: 'BRANCO' },
  { label: 'CAQUI', value: 'CAQUI' },
  { label: 'CINZA', value: 'CINZA' },
  { label: 'DOURADO', value: 'DOURADO' },
  { label: 'GRENA', value: 'GRENA' },
  { label: 'GELO', value: 'GELO' },
  { label: 'LARANJA', value: 'LARANJA' },
  { label: 'MADERIA', value: 'MADERIA' },
  { label: 'MARROM', value: 'MARROM' },
  { label: 'METALICO', value: 'METALICO' },
  { label: 'PRATA', value: 'PRATA' },
  { label: 'PRETO', value: 'PRETO' },
  { label: 'ROXO', value: 'ROXO' },
  { label: 'VERDE', value: 'VERDE' },
  { label: 'VERMELHO', value: 'VERMELHO' },
  { label: 'VINHO', value: 'VINHO' },
  { label: 'VIOLETA', value: 'VIOLETA' },
];

export async function validateTollTags(data, setErrors) {
  const errorList = {};
  try {
    await vehicleTollTagSchema.validate(data, {
      abortEarly: false,
    });
    setErrors({ ...errorList });
    return [true, errorList];
  } catch (err) {
    if (err.inner) {
      err.inner.forEach(error => {
        errorList[error.path] = error?.message;
      });
    }
    setErrors({ ...errorList });

    return [false, errorList];
  }
}

export async function validateNewVehicle({ data, setErrors, context }) {
  const errorList = {};
  try {
    if (data.is_wagon)
      await registerWagonVehicleSchema.validate(data, {
        abortEarly: false,
        context,
      });
    else
      await registerTractionVehicleSchema.validate(data, {
        abortEarly: false,
        context,
      });
    setErrors({ ...errorList });
    return [true, errorList];
  } catch (err) {
    if (err.inner) {
      err.inner.forEach(error => {
        errorList[error.path] = error?.message;
      });
    }

    setErrors({ ...errorList });

    return [false, errorList];
  }
}
