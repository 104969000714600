import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';
import { Modal, Radio, Select, Text, Button, useSnackbar } from '@cargon/ui';
import { driverStore } from '~/services/store/Driver';

import {
  fetchVehiclesByUserShippingCompanies,
  attachVehicleToDriver,
} from '~/services/fetch';

function Vehicle({
  currentVehicle,
  setCurrentVehicle,
  isOpen,
  onClose,
  setShow,
}) {
  const [vehicle, setVehicle] = useState();
  const [error, setError] = useState('');
  const [mainVehicle, setMainVehicle] = useState(false);
  const snackbar = useSnackbar();
  const { currentDriver, updateCurrentDriver } = driverStore();

  const params = useParams();

  useEffect(() => {
    setVehicle(currentVehicle);
    if (currentVehicle?.pivot) {
      setMainVehicle(currentVehicle.pivot.current);
    }
  }, [currentVehicle]);

  function handleClose() {
    setCurrentVehicle(null);
    setVehicle(null);
    setError('');
    setMainVehicle(false);
    setShow(false);
    onClose();
  }

  async function fetchVehicles(search) {
    try {
      return fetchVehiclesByUserShippingCompanies({ search });
    } catch {
      snackbar.show(<Text> Erro ao pesquisar veículo! </Text>, {
        type: 'error',
      });
      return false;
    }
  }

  async function AddVehicle(event) {
    event.preventDefault();

    if (
      currentDriver.vehicles?.find(
        element =>
          element.id === vehicle.id || element.pivot?.vehicle_id === vehicle.id
      ) &&
      !currentVehicle
    ) {
      setError('Veículo já adicionado');
      return;
    }

    if (vehicle) {
      try {
        if (params.id) {
          const vehicleId = vehicle.id || vehicle.pivot.vehicle_id;
          const isCurrent = currentDriver?.vehicles?.length
            ? mainVehicle
            : true;

          await attachVehicleToDriver({
            driverId: params.id,
            vehicleId,
            current: isCurrent,
          });
        }

        const attachedVehicles = currentDriver.vehicles || [];
        updateCurrentDriver({
          vehicles: [
            ...attachedVehicles?.map(item =>
              mainVehicle
                ? {
                    ...item,
                    current: false,
                    pivot: { ...item.pivot, current: false },
                  }
                : item
            ),
            {
              ...vehicle,
              current: currentDriver.vehicles?.length ? mainVehicle : true,
            },
          ],
        });

        setCurrentVehicle(null);
        setVehicle(null);
        setError('');
        setMainVehicle(false);
        setShow(false);
      } catch (err) {
        snackbar.show(<Text> Erro ao adicionar veículo! </Text>, {
          type: 'error',
        });
      }
    }
  }

  return (
    <>
      <Modal
        show={isOpen}
        onHide={handleClose}
        heading={
          <Text color="dark" type="header">
            Veículo
          </Text>
        }
        body={
          <form onSubmit={AddVehicle}>
            <Row>
              <Col xs={12}>
                <Select.Async
                  label="Veículo"
                  onSearch={fetchVehicles}
                  value={vehicle}
                  onChange={value => {
                    setVehicle(value);
                  }}
                  getOptionLabel={option => option.plate}
                  getOptionValue={option => option.id}
                  placeholder="Selecione a placa..."
                  error={error}
                  isDisabled={currentVehicle}
                />
              </Col>
              {!!currentDriver.vehicles?.length && (
                <Col xs={12} className="mt-3">
                  <Radio.Group
                    label="Tornar esse motorista atual"
                    onChange={({ target }) => setMainVehicle(target.value)}
                    value={mainVehicle}
                    horizontal
                  >
                    <Radio value>
                      <Text color="dark" type="label">
                        Sim
                      </Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>
              )}
              <Col
                xs={12}
                className="align-items-center d-flex justify-content-center my-4"
              >
                <Button type="submit" disabled={!vehicle}>
                  <Text weigth={500} type="regular">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            </Row>
          </form>
        }
      />
    </>
  );
}

export default Vehicle;
