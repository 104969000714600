import React, { useState } from 'react';
import { Section, Text, useSnackbar } from '@cargon/ui';
import Editable from '~/pages/ShippingCompanies/Single/Details/editable';
import { shippingCompanyStore } from '~/services/store/ShippingCompanies';
import api from '~/services/api';
// eslint-disable-next-line import/no-named-default
import { Col, Row } from 'react-bootstrap';
import { default as Inputs } from '../../../shared/Cards/PaymentMethods';

export default function PaymentMethods() {
  const { person, prevPerson, updatePrevPerson, loadings } =
    shippingCompanyStore();

  const snackbar = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  async function handleSubmit() {
    setLoading(true);

    if (!person?.shippingCompany?.payment?.id) {
      setError('Método de pagamento obrigatório');
      snackbar.show(<Text>Adicione um método de pagamento</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      await api.put(`v3/shipping-company/payment/${person?.id}`, {
        paymentMethod: person?.shippingCompany?.payment?.id,
      });
      updatePrevPerson({
        shippingCompany: {
          ...prevPerson.shippingCompany,
          payment: person?.shippingCompany?.payment,
        },
      });
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setLoading(false);
      setEditing(false);
      setError('');
    } catch (err) {
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Section
      Title="Métodos de pagamento"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={() => handleSubmit()}
          isLoading={loading}
          card="PaymentMethod"
        />
      }
      loading={loadings.paymentMethods}
    >
      {isEditing ? (
        <Inputs error={error} />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="regular" color="#494950" as="p" className="mt-2">
              {person.shippingCompany?.payment?.name || 'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </Section>
  );
}
