import { Text } from 'components';
import React from 'react';

export const NotValueLI = ({ field, message }) => {
  return (
    <li style={{ marginBottom: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ paddingRight: 12 }}>
          <Text weight={500}>{field}</Text>
        </div>
        <div>
          <Text weight={300} fontSize={12} style={{ whiteSpace: 'nowrap' }}>
            {message}
          </Text>
        </div>
      </div>
    </li>
  );
};
