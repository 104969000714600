import React from 'react';

import { ContainerCard, CardBody, CardHeader } from './styles';

export default function Card({ header, HeaderRightComponent, children }) {
  return (
    <ContainerCard className="mb-3">
      {header && (
        <CardHeader>
          {header}
          {HeaderRightComponent}
        </CardHeader>
      )}

      <CardBody>{children}</CardBody>
    </ContainerCard>
  );
}
