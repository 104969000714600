import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Button, Text } from '@cargon/ui';

import Offset from '~/components/Offset';
import Header from '~/components/Header';
import { useSnackbar } from 'components/Snackbar';
import ApplicationLayout from '~/layouts/Application';
import { FaExclamationCircle } from 'react-icons/fa';
import Data from './Data';
import Main from './Main';

import * as Styled from '../../shared/styles';
import AttachDriver from '../shared/AttachDriver';
import { useVehicle } from '../shared/context';
import TollTags from './TollTags';
import useModal from '../../../../hooks/useModal';
import ConvertVehicleModal from './Modals/IsWagon';
import Card from '../../../../components/Card';

export default function Details() {
  const history = useHistory();
  const vehicle = useVehicle();
  const convertModal = useModal();

  if (vehicle.unauthorized) {
    return (
      <>
        <Header />
        <Offset>
          <Container>
            <Card>
              <div className="text-center">
                <FaExclamationCircle
                  size={26}
                  color="#F64E60"
                  className="mr-2 mb-1"
                />
                <Text type="header" color="error">
                  Você não tem permissão para acessar esse veículo
                </Text>
              </div>
            </Card>
          </Container>
        </Offset>
      </>
    );
  }

  return (
    <>
      <Header
        title="Detalhes do Veículo"
        RightComponent={
          <>
            <Styled.ButtonsContainer className="pt-5">
              <Button variant="secondary" onClick={() => history.goBack()}>
                <Text color="dark" weight={500}>
                  Voltar
                </Text>
              </Button>

              <Link to="cadastrar">
                <Button>
                  <Text color="white" weight={500}>
                    Novo veículo
                  </Text>
                </Button>
              </Link>
            </Styled.ButtonsContainer>
          </>
        }
      />
      <Offset>
        <Container>
          <Row>
            {' '}
            <ConvertVehicleModal modal={convertModal} />
            <Col xs={12} md={6}>
              <Main />
            </Col>
            <Col xs={12} md={6}>
              <Data />
              {!vehicle.data.is_wagon && (
                <>
                  <AttachDriver />
                  <TollTags />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Offset>
    </>
  );
}
