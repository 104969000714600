import { array, date, number, object, string } from 'yup';
import moment from 'moment';

import '../../../../../../utils/yup';

export default object({
  travelNumber: string().required('Número da viagem é obrigatório.'),
  cteNumber: string()
    .required('Número do CT-e é obrigatório.')
    .test('unique', 'Número do CT-e deve ser único.', function test(value) {
      const { cteList, emitteds } = this.options.context;
      return (
        !cteList.some(cte => cte.cteNumber === value) &&
        !emitteds.some(cte => cte.cte_number === value)
      );
    }),
  issuedAt: date()
    .transform((_value, originalValue) =>
      moment(originalValue, 'DD/MM/YYYY HH:mm').toDate()
    )
    .required('Data de emissão do CT-e é obrigatória.')
    .typeError('Data de emissão do CT-e inválida.'),
  invoices: array()
    .required('Você deve inserir pelo menos uma nota fiscal.')
    .of(
      object({
        key: string().test(
          'unique',
          'Chave da nota fiscal deve ser única.',
          function test(value) {
            const { cteList } = this.options.context;

            return !cteList.some(cte =>
              cte.invoices.some(invoice => invoice.key === value)
            );
          }
        ),
      }).uniqueProperty('key', 'Chave da nota fiscal deve ser única.')
    ),
  destination: object()
    .required('Destino é obrigatório.')
    .typeError('Destino é obrigatório.'),
  amountReceivable: number()
    .required('Valor à receber obrigatório')
    .typeError('Valor à receber inválido'),
});
