import * as Yup from 'yup';
import moment from 'moment';
import { validateDocument, whiteSpaceOrSize } from '~/utils/validate';

const personalDataFields = {
  is_international: Yup.boolean()
    .nullable()
    .required('Informe se é estrangeiro.'),
  cgccpf: Yup.string()
    .required('Documento obrigatório')
    .when('is_international', {
      is: isInternational => !isInternational,
      then: Yup.string().test('invalid-document', 'Documento inválido', value =>
        validateDocument(value)
      ),
    }),
  social_name: Yup.string()
    .required('Nome obrigatório')
    .test('white-space-field', 'Campo inválido', value =>
      whiteSpaceOrSize({ value, size: 3 })
    ),
  birthdate: Yup.string()
    .test('invalid-date', 'Data inválida', value => {
      return moment(
        value?.split('-').reverse().join('/'),
        'DD/MM/YYYY'
      ).isValid();
    })
    .typeError('Data de nascimento obrigatória')
    .required('Data de nascimento obrigatória'),
  rg: Yup.string()
    .nullable()
    .when('is_international', {
      is: isInternational => !isInternational,
      then: Yup.string().required('RG obrigatório'),
    }),
  rg_emissor: Yup.string()
    .when('is_international', {
      is: isInternational => !isInternational,
      then: Yup.string().required('Orgão emissor obrigatório'),
    })
    .nullable()
    .test('white-space-field', 'Campo inválido', value =>
      whiteSpaceOrSize({ value, size: 2 })
    ),
  rg_dispatch_date: Yup.string()
    .nullable()
    .test('invalid-date', 'Data inválida', value => {
      return moment(
        value?.split('-').reverse().join('/'),
        'DD/MM/YYYY'
      ).isValid();
    })
    .when('is_international', {
      is: isInternational => !isInternational,
      then: Yup.string().required('Data de emissão obrigatório'),
    }),
  mothers_name: Yup.string()
    .test('white-space-field', 'Campo inválido', value =>
      whiteSpaceOrSize({ value, size: 3 })
    )
    .typeError('Nome inválido')
    .required('Nome da mãe obrigatório'),
  father_name: Yup.string()
    .nullable()
    .default(null)
    .test('white-space-field', 'Campo inválido', value =>
      whiteSpaceOrSize({ value, size: 3 })
    ),
  civilState: Yup.object().nullable().required('Estado civil obrigatório'),
};

export const personalDataSchema = Yup.object().shape(personalDataFields);

const cnhFields = {
  cnh_is_foreign: Yup.boolean().required(
    'Insira se o Motorista é estrangeiro.'
  ),
  cnh_number: Yup.string()
    .min(3, 'É necessário ao menos 3 caracteres.')
    .required('Número da CNH é obrigatório.')
    .typeError('Campo obrigatório'),
  cnh_type: Yup.string()
    .required('Tipo de CNH obrigatório.')
    .typeError('Campo obrigatório'),
  cnh_key: Yup.string()
    .required('Chave do CNH obrigatória.')
    .typeError('Campo obrigatório'),
  cnh_form_number: Yup.string()
    .required('Número de formulário da cnh é obrigatório')
    .max(15, 'Máximo 15 caracteres.'),
  cnh_renach: Yup.string()
    .required('Número de renach da cnh é obrigatório')
    .max(15, 'Máximo 15 caracteres.'),
  cnhDispatchCity: Yup.object()
    .nullable()
    .required('Cidade emissão CNH obrigatória'),
  first_cnh_date: Yup.string()
    .required('Insira a data da 1º habilitação.')
    .typeError('Campo obrigatório'),
  cnh_dispatch_date: Yup.string()
    .required('Insira a data de emissão da CNH.')
    .typeError('Campo obrigatório'),
  cnh_exp_date: Yup.string()
    .required('Insira a data de validade da habilitação.')
    .typeError('Campo obrigatório'),
  mopp: Yup.boolean(),
  mopp_description: Yup.string().when('mopp', {
    is: true,
    then: Yup.string()
      .min(3, 'É necessário pelo menos 3 caracteres.')
      .required('Insira uma descrição de mopp.')
      .typeError('Campo obrigatório'),
    otherwise: Yup.string().nullable(),
  }),
  mopp_expiration: Yup.string().when('mopp', {
    is: true,
    then: Yup.string()
      .required('Insira uma data de validade do mopp.')
      .typeError('Campo obrigatório'),
    otherwise: Yup.string().nullable(),
  }),
  is_autonomous: Yup.boolean()
    .required('Insira se o Motorista é autônomo.')
    .typeError('Campo obrigatório'),
  is_owner: Yup.boolean()
    .required('Insira se o Motorista é proprietário.')
    .typeError('Campo obrigatório'),
};

const schemaCNH = Yup.object().shape(cnhFields);

const userFields = {
  verified: Yup.boolean().nullable(),
  password: Yup.string()
    .nullable()
    .min(4, 'Senha deve ter pelo menos 4 caracteres.')
    .when('verified', {
      is: verified => verified !== null && verified !== undefined,
      then: Yup.string().required('Insira uma senha.'),
    }),
  verifyPassword: Yup.string()
    .nullable()
    .when('password', {
      is: Boolean,
      then: Yup.string()
        .required('Confirme a senha.')
        .oneOf([Yup.ref('password')], 'Senhas não conferem.'),
    }),
};

const userDataSchema = Yup.object().shape(userFields);

const driverFields = {
  payment: Yup.object()
    .shape({
      id: Yup.number().required('Insira um método de pagamento'),
    })
    .required('Insira um método de pagamento.')
    .typeError('Campo obrigatório'),
};

const paymentMethodSchema = Yup.object().shape(driverFields);

const contactFields = {
  phone: Yup.string()
    .min(14, 'Número de telefone inválido.')
    .nullable()
    .required('Insira um número de telefone'),
  email: Yup.string().email('Insira um e-mail válido.').nullable(),
  others: Yup.array().of(
    Yup.object({
      name: Yup.string(),
      phone: Yup.string(),
    })
  ),
};

const contactsSchema = Yup.object().shape(contactFields);

const addressFields = {
  address: Yup.string()
    .min(3, 'É necessário pelo menos 3 caracteres.')
    .required('Campo obrigatório.')
    .typeError('Campo obrigatório.'),
  number: Yup.string().when('hasNumber', {
    is: Boolean,
    then: Yup.string()
      .required('Campo obrigatório.')
      .typeError('Campo obrigatório.'),
    otherwise: Yup.string().nullable(),
  }),
  neighborhood: Yup.string()
    .required('Campo obrigatório.')
    .typeError('Campo obrigatório.'),
  zip_code: Yup.string()
    .required('Campo obrigatório.')
    .typeError('Campo obrigatório.'),
  city: Yup.object()
    .shape({
      province: Yup.object()
        .shape({
          country: Yup.object().nullable().required('País obrigatório'),
        })
        .nullable(),
    })
    .nullable(),
};

const addressSchema = Yup.object().shape(addressFields);

const paymentCardFields = {
  paymentCards: Yup.array().of(
    Yup.object().shape({
      corporation: Yup.object().typeError('Campo obrigatório'),
      card_number: Yup.string()
        .required('Campo obrigatório')
        .typeError('Campo obrigatório')
        .test('validate-card', 'Cartão inválido', value => {
          const digits = value.replace(/[^0-9]/g, '');

          if (!digits.match(/[0-9]{16}/)) {
            return false;
          }

          return true;
        }),
      due_date: Yup.string()
        .required('Campo obrigatório')
        .typeError('Campo obrigatório'),
    })
  ),
};

const paymentCardsSchema = Yup.object().shape(paymentCardFields);

const emergencyContactFields = {
  driverEmergencyContact: Yup.object().shape({
    name: Yup.string()
      .required('Insira um nome.')
      .typeError('Campo obrigatório'),
    email: Yup.string()
      .required('Insira um email.')
      .typeError('Campo obrigatório'),
    phone: Yup.string()
      .required('Insira um telefone.')
      .typeError('Campo obrigatório'),
  }),
};

const emergencyContactSchema = Yup.object().shape(emergencyContactFields);

const schemasObject = {
  userDataSchema,
  schemaCNH,
  contactsSchema,
  addressSchema,
  personalDataSchema,
  paymentCardsSchema,
  paymentMethodSchema,
  emergencyContactSchema,
};

export async function validate({ schema, data, setErrors, clearErrors }) {
  clearErrors();
  const errorList = {};
  try {
    await schemasObject[schema].validate(data, {
      abortEarly: false,
    });
    if (errorList) setErrors({ ...errorList });
    return [true, errorList];
  } catch (err) {
    if (err.inner) {
      err.inner.forEach(error => {
        errorList[error.path] = error?.message;
      });
    }
    setErrors({ ...errorList });

    return [false, errorList];
  }
}

export const driverSchema = Yup.object().shape({
  ...personalDataFields,
  ...cnhFields,
  ...userFields,
  ...contactFields,
  ...paymentCardFields,
  ...emergencyContactFields,
  addresses: addressSchema,
  contacts: contactFields.others,
  driver: paymentMethodSchema,
});
