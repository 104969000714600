import React, { useState } from 'react';
import { Section, Button } from '@cargon/ui';

import AttachmentsRegister from '~/pages/Driver/Single/shared/Cards/Attachments/Body';

import { driverStore } from '~/services/store/Driver';
import AttachmentModal from '~/pages/Driver/Single/shared/Cards/Attachments/Modal';

export default function Attachments() {
  const { currentDriver, loadings } = driverStore();

  const [isLoading, setIsLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <AttachmentModal isOpen={showModal} onClose={() => setShowModal(false)} />
      <Section
        Title="Anexos"
        loading={loadings.attachments}
        HeaderRightComponent={
          !!currentDriver?.attachments?.length && (
            <Button onClick={() => setShowModal(true)} loading={isLoading}>
              Adicionar
            </Button>
          )
        }
      >
        <AttachmentsRegister setShowModal={setShowModal} />
      </Section>
    </>
  );
}
