import React, { useEffect } from 'react';
import { Button, Section, Text, useSnackbar } from '@cargon/ui';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Offset from 'components/Offset';
import Container from 'react-bootstrap/Container';
import Header from 'components/Header';
import Data from 'pages/ShippingCompanies/Single/shared/Cards/Data';
import { shippingCompanyRegistrationSchema } from 'pages/ShippingCompanies/Single/shared/validator';
import { shippingCompanyStore } from 'services/store/ShippingCompanies';
import { useValidation } from 'hooks';
import PaymentMethods from 'pages/ShippingCompanies/Single/shared/Cards/PaymentMethods';
import Address from 'pages/ShippingCompanies/Single/shared/Cards/Address';
import AttachShippingCompany from 'pages/ShippingCompanies/Single/shared/Cards/AttachShippingCompany';
import Registered from 'pages/ShippingCompanies/Single/Register/Modal';
import useModal from 'hooks/useModal';
import { reshapeShippingPerson } from 'pages/ShippingCompanies/Single/shared/reshaper';
import api from 'services/api';
import * as Styled from '../styles';
import PaymentCards from '../../../../components/PaymentCards';

export default function Register() {
  const history = useHistory();
  const snackbar = useSnackbar();
  const [loading, setLoading] = React.useState();
  const { person, setErrors, updatePerson } = shippingCompanyStore();
  const modal = useModal();
  const addressModal = useModal();

  const [errors, { validate }] = useValidation(
    shippingCompanyRegistrationSchema
  );

  useEffect(() => {
    setErrors({ ...errors });
  }, [errors]);

  const handleSubmit = async () => {
    setLoading(true);
    if (person.addresses.length === 0) {
      snackbar.show(<Text>Cadastre um endereço</Text>, {
        type: 'error',
      });
      addressModal.open();
      setLoading(false);
      return;
    }

    const shippingCompanyData = {
      isTaxFree: !!person?.shippingCompany?.is_tax_free,
      ie_locale: person.addresses[0]?.uf_name,
    };

    const [isValid] = await validate(
      {
        ...person,
        payment_method: person.shippingCompany?.payment?.name,
      },
      { context: shippingCompanyData }
    );

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }
    const postData = reshapeShippingPerson(person);
    try {
      const response = await api.post('carrier/shipping-company', postData);
      updatePerson({ id: response.data?.id });
      try {
        if (person.personsPayments.length > 0) {
          const cardData = {
            id: response.data.id,
            data: person.personsPayments,
          };

          await api.post('driver/payment/cards', cardData);
        }
      } catch (e) {
        snackbar.show(<Text>Falha ao salvar os cartões</Text>, {
          type: 'error',
        });
      }

      modal.open();
    } catch (e) {
      const text =
        `${e.response.data.error}` ||
        'Não foi possível cadastrar a transportadora.';
      snackbar.show(<Text>{text}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Header
        title="Cadastrar Transportadora"
        RightComponent={
          <Styled.SaveContainer>
            <Button
              variant="secondary"
              onClick={() => history.push('/transportadoras')}
            >
              <Text weight={500} color="black">
                Voltar
              </Text>
            </Button>

            <Button
              variant="success"
              loading={loading}
              onClick={() => {
                handleSubmit();
              }}
            >
              <Text weight={500} color="white">
                Salvar
              </Text>
            </Button>
          </Styled.SaveContainer>
        }
      />
      <Offset>
        <Container>
          <Row>
            <Col md={6}>
              <Section Title="Dados da transportadora">
                <Data />
              </Section>
              <Section Title="Métodos de pagamento">
                <PaymentMethods error={errors?.payment_method} />
              </Section>
            </Col>
            <Col md={6}>
              <Address modal={addressModal} />
              <Section Title="Transportadoras Vinculadas">
                <AttachShippingCompany />
              </Section>
              <PaymentCards
                paymentCards={person?.personsPayments}
                setPaymentCards={cards =>
                  updatePerson({ personsPayments: cards })
                }
              />
            </Col>
          </Row>
          <Registered isOpen={modal.isOpen} onClose={modal.close} />
        </Container>
      </Offset>
    </div>
  );
}
