import React, { useEffect, useMemo, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import FileSaver from 'file-saver';
import {
  Button,
  DatePicker,
  Select,
  Text,
  useSnackbar,
  Input,
} from '@cargon/ui';
import { format, parse, subMonths } from 'date-fns';
import Container from 'react-bootstrap/Container';
import reportApi from '../../../services/api/report';
import Header from '../../../components/Header';
import Offset from '../../../components/Offset';
import entityApi from '../../../services/api/entity';

const allShipping = {
  label: 'Todos os transportadores vinculados',
  id: 'all',
};

export default function CTesReport() {
  const currentUserEmail =
    typeof window !== 'undefined'
      ? JSON.parse(
          JSON.parse(localStorage.getItem('persist:cargon-carriers')).auth
        ).user.properties.email
      : null;

  const snackbar = useSnackbar();
  const [exportLoading, setExportLoading] = useState(false);
  const [email, setEmail] = useState(currentUserEmail);
  const [dates, setDates] = useState({
    from: format(subMonths(new Date(), 1), 'dd/MM/yyyy'), // formatDate(subMonths(new Date(), 1)),
    to: format(new Date(), 'dd/MM/yyyy'), // formatDate(new Date()),
  });

  const [shippingCompanies, setShippingCompanies] = useState([]);
  const [shippingCompaniesOptions, setShippingCompaniesOptions] = useState([]);

  const fetchShippingCompanies = async () => {
    const { data } = await entityApi.get('user/shipping_companies');
    setShippingCompaniesOptions([
      allShipping,
      ...(data.result?.map(shipping => ({
        id: shipping.id,
        label: `${shipping.social_name} - ${shipping.cgccpf}`,
      })) || []),
    ]);
  };

  const availableOptions = useMemo(() => {
    if (shippingCompanies.find(option => option.id === 'all'))
      return [allShipping];
    return shippingCompaniesOptions;
  }, [shippingCompanies, shippingCompaniesOptions]);

  useEffect(() => {
    fetchShippingCompanies();
  }, []);

  function dateFormatter(date, end) {
    // if (end) {
    //   formattedDate.add(1, 'd');
    // }
    const parsed = parse(date, 'dd/MM/yyyy', new Date());
    return format(parsed, 'yyyy-MM-dd');
  }

  function setDate(key, value) {
    setDates(old => {
      return {
        ...old,
        [key]: value,
      };
    });
  }

  async function exportReport() {
    setExportLoading(true);
    try {
      if (!shippingCompanies.length) {
        snackbar.show('Selecione algum transportador', { type: 'error' });
        return;
      }
      const start_date = dateFormatter(dates.from);
      const end_date = dateFormatter(dates.to, true);
      const shipper_id =
        shippingCompanies[0].id === allShipping.id
          ? allShipping.id
          : shippingCompanies.map(shipper => shipper.id);

      await reportApi.get(`ctes`, {
        params: {
          start_date,
          end_date,
          shipper_id,
          email,
        },
      });
      snackbar.show('Relatório será gerado e enviado ao email.', {
        type: 'success',
      });
    } catch (error) {
      if (!!error.response.data?.message) {
        snackbar.show(error.response.data.message, {
          type: error.response.data?.type === 'error' ? 'error' : 'primary',
        });
      } else
        snackbar.show('Erro ao gerar relatório de CTes.', { type: 'error' });
    } finally {
      setExportLoading(false);
    }
  }

  return (
    <>
      <Header title="Relatório de CTes"> </Header>
      <Offset>
        <Container>
          <Row className="filter d-flex justify-content-center space-between">
            <Col md={2} xs={12}>
              <DatePicker
                label="Período de *"
                value={dates.from}
                onChange={({ target }) => setDate('from', target.value)}
                labelColor="#fff"
              />
            </Col>
            <Col md={2} xs={12}>
              <DatePicker
                label="Até *"
                value={dates.to}
                onChange={({ target }) => setDate('to', target.value)}
                labelColor="#fff"
              />
            </Col>
            <Col md={3} xs={12}>
              <Select
                label="Transportadores"
                multiple
                value={shippingCompanies}
                options={availableOptions}
                onChange={value => {
                  if (value && value.find(option => option.id === 'all')) {
                    setShippingCompanies([allShipping]);
                  } else setShippingCompanies(value || []);
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                labelTextColor="#fff"
              />
            </Col>
            <Col md={3} xs={12}>
              <Input
                label="Email para envio *"
                labelColor="#fff"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled
              />
            </Col>
            <Col lg={2} xs={12} className="vertical bottom">
              <Button
                variant="primary"
                loading={exportLoading}
                onClick={exportReport}
                className="w-100"
              >
                <Text type="regular" weight={500}>
                  Gerar Relatório
                </Text>
              </Button>
            </Col>
            <Col md={12} className="mt-3">
              <Text weight={600} color="secondary" type="subtitle">
                Clique em gerar relatório e aguarde a resposta no email
                informado. Isso pode levar algum tempo.
              </Text>
            </Col>
          </Row>
        </Container>
      </Offset>
    </>
  );
}
