import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Text, Button } from '@cargon/ui';
import { FaPenAlt } from 'react-icons/all';
import { FaTrash } from 'react-icons/fa';

export default function PaymentCardDetails({
  paymentCard,
  deleteCard,
  setActiveCard,
}) {
  return (
    <>
      <Row>
        <Col md={8}>
          <Text type="label">Empresa do cartão</Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {paymentCard?.corporation?.name || 'Não informado'}
          </Text>
        </Col>
        <Col md={4} className="d-flex align-items-end justify-content-center">
          <Button size="sm" className="mr-1" onClick={() => setActiveCard()}>
            <FaPenAlt />
          </Button>
          <Button size="sm" variant="error" onClick={() => deleteCard()}>
            <FaTrash />
          </Button>
        </Col>
        <Col md={6}>
          <Text type="label">Número do cartão</Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {paymentCard?.cardNumberFormatted ||
              paymentCard?.card_number ||
              'Não informado'}
          </Text>
        </Col>

        <Col md={6}>
          <Text type="label">Vencimento</Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {paymentCard?.due_date || 'Não informado'}
          </Text>
        </Col>

        <Col md={6}>
          <Text type="label">Ativo</Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {paymentCard?.active ? 'Sim' : 'Não'}
          </Text>
        </Col>

        <Col md={6}>
          <Text type="label">Padrão</Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {paymentCard?.standard_card ? 'Sim' : 'Não'}
          </Text>
        </Col>
      </Row>

      <hr style={{ marginTop: 30, marginBottom: 30 }} />
    </>
  );
}
