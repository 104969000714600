import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import deal from '~/services/api/deal';
import StringBuilder from '~/utils/StringBuilder';

import Types from './types';
import Actions from './actions';

function* dealsRequest({ payload }) {
  const { user_id, only_active, only_closed, paginate } = payload;

  try {
    const response = yield call(deal.get, '/deals', {
      params: { driver_id: user_id, only_active, only_closed, paginate },
    });

    yield put(Actions.dealsSuccess(response));
  } catch (ex) {
    yield put(Actions.dealsFailure());
  }
}
function dealCreateSuccess({ payload }) {
  const { history } = payload;
  setTimeout(() => {
    history.push('/negociacoes');
  }, 2000);
}
function* dealCreateRequest({ payload }) {
  const { negotiator_id, driver_id, load_id, vehicle } = payload.deal;
  const { history } = payload;

  try {
    const response = yield call(deal.post, '/deals', {
      negotiator_id,
      driver_id,
      load_id,
    });

    const builder = new StringBuilder(' ', '', '.');

    builder.append('Tenho interesse de me candidatar nesta carga');

    if (vehicle) {
      builder.append(`com o veículo placa ${vehicle.plate}`);

      if (vehicle.drivers) {
        const [driver] = vehicle.drivers;
        builder.append(
          `e motorista ${driver.social_name} com o CPF ${driver.cgccpf}`
        );
      }
    }

    yield call(deal.post, '/messages', {
      sender_id: driver_id,
      receiver_id: negotiator_id,
      deal_id: response.data.id,
      message: builder.build(),
    });

    yield put(Actions.dealCreateSuccess(response.data, history));
  } catch (ex) {
    const retError = ex.response ? ex.response.data : { message: 'Erro' };
    yield put(Actions.dealCreateFailure(retError));
  }
}

export default all([
  takeLatest(Types.DEALS_REQUEST, dealsRequest),
  takeLatest(Types.DEAL_CREATE_REQUEST, dealCreateRequest),
  takeLatest(Types.DEAL_CREATE_SUCCESS, dealCreateSuccess),
]);
