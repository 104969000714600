import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import theme from '../../../../../styles/theme';

export const TravelCard = styled(Row)`
  padding: 8px 0px;
  margin: 12px 0 !important;
  border: 1px solid ${theme.colors.primary};
  border-radius: 16px;
`;
