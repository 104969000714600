/* eslint-disable import/no-unresolved */
import { Modal, Text } from '@cargon/ui';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaExclamationCircle, FaExternalLinkAlt } from 'react-icons/fa';
import { groupBy } from '~/utils/array';
import { formatDate } from '~/utils/formatter';
import ModalAlert from './Modal';

function SpeedAlert({ alerts, onClick }) {
  return (
    <Col md={12}>
      <FaExclamationCircle color="#FFC107" className="mr-2" />
      <Text color="gray" type="regular">
        Motorista ficou {alerts.length} vezes acima de 80km/h por mais de 3
        minutos.
      </Text>
      <FaExternalLinkAlt
        onClick={onClick}
        color="#3B99FF"
        className="ml-2"
        size={14}
        style={{ cursor: 'pointer' }}
      />
    </Col>
  );
}

function TravelTimeAlert({ alerts }) {
  return (
    <>
      {alerts.map(alert => {
        const metadata = JSON.parse(alert.metadata);
        const start = moment(metadata.startedDrivingAt);
        const end = moment(alert.captured_at);
        const duration = moment.duration(end.diff(start)).asMilliseconds();
        const format =
          duration * 1000 * 60 >= 60 ? 'h [horas e] m [minutos]' : 'h [horas]';

        const travelTime = moment.utc(duration).format(format);

        return (
          <Col md={12}>
            <FaExclamationCircle color="#FFC107" className="mr-2" />
            <Text color="gray" type="regular">
              Motorista dirigiu {travelTime} sem fazer parada para descanso.
            </Text>
          </Col>
        );
      })}
    </>
  );
}

function LocationNotAllowed() {
  return (
    <Col md={12}>
      <FaExclamationCircle color="#FFC107" className="mr-2" />
      <Text color="gray" type="regular">
        Motorista com posicionamento desligado.
      </Text>
    </Col>
  );
}

function Alerts({ alerts }) {
  const [modal, setModal] = useState(false);
  const [key, setKey] = useState();
  const [entries, setEntries] = useState();

  const groupedByDate = groupBy(alerts, item => formatDate(item.captured_at));

  const grouped = Object.fromEntries(
    Object.entries(groupedByDate).map(([x, v]) => {
      const groupedValues = groupBy(v, item => item.slug);

      return [x, groupedValues];
    })
  );

  return (
    <>
      {Object.values(grouped).length > 0 && (
        <Row md={12}>
          <Col md={12}>
            <Text type="subtitle" color="dark">
              Alertas
            </Text>
          </Col>
          <Modal
            size="md"
            heading={key}
            centered
            scrollable
            show={modal}
            body={<ModalAlert alerts={entries} />}
            handleClose={() => setModal(false)}
          />
          {Object.entries(grouped).map(([day, slugs]) => (
            <>
              <Col md={12} className="mt-3">
                <Text color="dark" type="body">
                  {day}
                </Text>
              </Col>
              {Object.entries(slugs).map(([slug, values]) => (
                <>
                  {slug === 'LOCATION_NOT_ALLOWED' && (
                    <LocationNotAllowed alerts={values} />
                  )}
                  {slug === 'SPEED_GAP_EXCEEDED' && (
                    <SpeedAlert
                      alerts={values}
                      onClick={() => {
                        setKey(key);
                        setEntries(values);
                        setModal(true);
                      }}
                    />
                  )}
                  {slug === 'TRAVEL_TIME_EXCEEDED' && (
                    <TravelTimeAlert alerts={values} />
                  )}
                </>
              ))}
            </>
          ))}
        </Row>
      )}
    </>
  );
}

export default Alerts;
