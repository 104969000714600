import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import InfiniteScroll from '~/components/InfiniteScroll';
// eslint-disable-next-line import/no-cycle
import Header from '~/components/Header';
import Offset from '~/components/Offset';
import { DefaultLoadingComponent } from '~/components/List';
import api from '~/services/api';
import CardLoad from './Card';

import Filter from './Filter';

export default function LoadDetail() {
  const [isLoading, setLoading] = useState(false);
  const [loads, setLoads] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    page: 1,
    total: 0,
  });
  const [isFilterVisible, setFilterVisible] = useState(false);
  const [filters, setFilters] = useState();
  const params = useParams();

  async function fetchLoads(fromFilter, page = 1) {
    if (page === 1) setLoading(true);

    const params = {
      page,
    };

    if (fromFilter) {
      const { origin, destination, body } = fromFilter;
      if (origin) {
        params.origin = `${origin.name}-${origin.province.uf}`;
      }

      if (destination) {
        params.destination = `${destination.name}-${destination.province.uf}`;
      }

      if (body) {
        params.body_types = body;
      }
      localStorage.setItem('filter', JSON.stringify({ ...params }));
    } else {
      const filtro = JSON.parse(localStorage.getItem('filter'));
      if (filtro) {
        setFilterVisible(true);

        if (filtro.origin) {
          params.origin = filtro.origin;
        }
        if (filtro.destination) {
          params.destination = filtro.destination;
        }
        if (filtro.body) {
          params.body = filtro.body;
        }
      }
    }

    setFilters(params);

    try {
      const response = await api.get('carrier-loads', { params });
      const { data, ...rest } = response.data;

      setPagination(rest);
      setLoads(oldLoads => {
        if (page === 1) {
          return data;
        }

        return [...oldLoads, ...data];
      });
    } catch (error) {
      // Handle exception
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchLoads();
  }, []);

  return (
    <>
      <Header
        RightComponent={
          <Button
            variant="secondary"
            style={{ minWidth: '86px', height: '38px' }}
            onClick={() => setFilterVisible(!isFilterVisible)}
            className="ml-1"
          >
            {isFilterVisible ? 'Ocultar filtro' : 'Mostrar filtro'}
          </Button>
        }
      />
      <Offset>
        <Container>
          {isFilterVisible && (
            <Filter fetchLoads={fetchLoads} filters={filters} />
          )}
          <InfiniteScroll
            loading={isLoading}
            data={loads}
            renderItem={item => <CardLoad load={item} />}
            onEndReach={() => fetchLoads(filters, pagination.page + 1)}
            hasMore={pagination.page < pagination.lastPage}
            scrollThreshold={0.7}
            loader={pagination.page > 1 ? <DefaultLoadingComponent /> : null}
          />
        </Container>
      </Offset>
    </>
  );
}
