import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import InfiniteScroll from '~/components/InfiniteScroll';
// eslint-disable-next-line import/no-cycle
import Header from '~/components/Header';
import Offset from '~/components/Offset';
import { DefaultLoadingComponent } from '~/components/List';

import api from '~/services/api';
import useCurrentUser from '~/hooks/useCurrentUser';

import useModal from '~/hooks/useModal';
import produce from 'immer';
import useSWRInfinite from 'swr/infinite';
import { MemoizedTravelCard } from './Card/index';
import Filter from './Filter';
import Refuse from './Modal';

async function fetcher([url, config]) {
  const response = await api.get(url, config);
  return response.data;
}

export default function List() {
  const user = useCurrentUser();

  const [travels, setTravels] = useState([]);

  const [pagination, setPagination] = useState({
    lastPage: 0,
    page: 1,
    total: 0,
  });

  const [isFilterVisible, setFilterVisible] = useState(false);
  const [filter, setFilter] = useState();
  const [travelData, setTravelData] = useState();

  const getKey = (pageIndex, previousPageData, params) => {
    if (!params.company_id) return null;
    return [
      `allocated-travels?page=${pageIndex + 1}`,
      {
        params,
      },
    ];
  };

  const { data, size, setSize } = useSWRInfinite(
    (...args) => getKey(...args, { ...filter, company_id: user.company_id }),
    fetcher,
    { refreshInterval: 60000, revalidateAll: true }
  );

  useEffect(() => {
    if (data) {
      setTravels(data.flatMap(dataResponse => dataResponse?.data) || []);
      if (data[data.length - 1] !== undefined) {
        const pageData = { ...data[data.length - 1] };
        delete pageData.data;
        setPagination(pageData);
      }
    }
  }, [data]);

  const refuseModal = useModal();

  function openRefuseModal(travel) {
    setTravelData(travel);
    refuseModal.open();
  }

  function removeTravel(travel) {
    const travelIndex = travels.findIndex(trvl => {
      return trvl.id === travel.id;
    });
    setTravels(
      produce(draft => {
        draft.splice(travelIndex, 1);
      })
    );
  }

  function renderItem(travel) {
    return (
      <MemoizedTravelCard
        travel={travel}
        refuse={() => openRefuseModal(travel)}
      />
    );
  }
  const isLoadingInitialData = !data;

  return (
    <>
      <Header
        RightComponent={
          <Button
            variant="secondary"
            style={{ minWidth: '86px', height: '38px' }}
            onClick={() => setFilterVisible(!isFilterVisible)}
            className="ml-1"
          >
            {isFilterVisible ? 'Ocultar filtro' : 'Mostrar filtro'}
          </Button>
        }
      />

      <Refuse
        isOpen={refuseModal.isOpen}
        onClose={refuseModal.close}
        travel={travelData}
        removeTravel={() => removeTravel(travelData)}
      />
      <Offset>
        <Container>
          {isFilterVisible && (
            <Filter
              getTravels={searchFilter => {
                setFilter(searchFilter);
              }}
            />
          )}
          <InfiniteScroll
            loading={isLoadingInitialData}
            data={travels}
            renderItem={renderItem}
            onEndReach={() => {
              setSize(size + 1);
            }}
            hasMore={pagination.page < pagination.lastPage}
            scrollThreshold={0.6}
            loader={<DefaultLoadingComponent />}
          />
        </Container>
      </Offset>
    </>
  );
}
