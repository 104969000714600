import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ValidationError } from 'yup';
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Text,
  useSnackbar,
} from '@cargon/ui';

import moment from 'moment';

import useErrors from '~/hooks/useErrors';
import api from '~/services/api';
import { formatDateCustom } from '~/utils/formatter';
import { useTravel } from '~/pages/Travels/Details/provider';
import schema from './schema';

export default function CteEdit({ isOpen, onClose, emittedId }) {
  const snackbar = useSnackbar();
  const travel = useTravel();
  const [travelNumber, setTravelNumber] = useState(
    travel.travelInfo.travel_number
  );
  const [cteNumber, setCteNumber] = useState('');
  const [cteNumberOld, setCteNumberOld] = useState('');
  const [issuedAt, setIssuedAt] = useState('');
  const [invoices, setInvoices] = useState([]);
  const [destination, setDestination] = useState('');
  const [loading, setLoading] = useState(false);

  const [errors, { getErrorsFromYup, clearErrors }] = useErrors({});

  async function fetchEmitted() {
    try {
      setLoading(true);

      const response = await api.get(`loads-emitted/${emittedId}`);

      const emitted = response.data?.[0];
      setCteNumberOld(emitted.cte_number);
      setCteNumber(emitted.cte_number);

      setIssuedAt(
        emitted.created_at
          ? formatDateCustom(emitted.created_at, 'DD/MM/YYYY HH:mm')
          : ''
      );
      if (emitted.destinationCity?.name) {
        const dest = emitted.destinationCity;
        dest.formatted = emitted.destinationCity.name;
        setDestination(dest);
      }

      if (emitted.travel_number) {
        setTravelNumber(emitted.travel_number);
      }

      if (emitted.invoicesCte) {
        const invs = emitted.invoicesCte.map(inv => {
          return {
            value: inv.nf_number,
            label: inv.nf_number,
            key: inv.chave_nf,
          };
        });
        setInvoices(invs);
      }
    } catch (ex) {
      //
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchEmitted();
  }, [isOpen]); // emittedId

  async function submitEditCte() {
    // Validate data
    async function validateEditCte() {
      try {
        const validated = await schema.validate(
          {
            travelNumber,
            cteNumber,
            issuedAt,
            invoices,
            destination,
          },
          {
            abortEarly: false,
            context: { emitteds: travel.travelInfo.emitteds, cteNumberOld },
          }
        );

        if (!validated) {
          snackbar.show(<Text>Dados inválidos.</Text>, {
            type: 'error',
          });
          return false;
        }

        return true;
      } catch (ex) {
        if (ex instanceof ValidationError) {
          getErrorsFromYup(ex.inner);
        }
        return false;
      }
    }

    // Update CT-e
    async function updateEditCte() {
      try {
        const formattedIssuedAt = moment(issuedAt, 'DD/MM/YYYY HH:mm');

        const data = {
          cteNumber,
          issuedAt: moment.utc(formattedIssuedAt),
          destination_id: destination.city_id,
          invoices,
        };

        try {
          await api.put(`loads-emitted/updateCte/${emittedId}`, {
            cte: data,
          });

          snackbar.show(<Text>CT-e {cteNumber} atualizado.</Text>, {
            type: 'success',
          });
        } catch (err) {
          snackbar.show(<Text>CT-e {cteNumber} não atualizado.</Text>, {
            type: 'error',
          });
        }
        clearErrors();
        onClose();

        await travel.getTravel();
      } catch (ex) {
        let message = 'Não foi possível alterar o CT-e.';
        if (ex.response) {
          const { error } = ex.response.data;

          if (error && typeof error === 'string') {
            message = error;
          }
        }

        snackbar.show(<Text>{message}</Text>, {
          type: 'error',
        });
      } finally {
        //
      }
    }

    const isValid = await validateEditCte();
    if (isValid) {
      await updateEditCte();
    }
  }

  return (
    <Modal
      show={isOpen}
      handleClose={onClose}
      heading={
        <Text type="subheader" color="dark" weight="500">
          Editar CT-e {cteNumber}
        </Text>
      }
      body={
        <Row>
          <Col xs={12} className="mb-3">
            <Input
              value={travelNumber}
              disabled={!!travel.travelInfo.travel_number || loading}
              label="Nº da viagem"
              onChange={event => setTravelNumber(event.target.value)}
              error={errors.travelNumber}
            />
          </Col>
          <Col xs={6} className="mb-3">
            <Input
              value={cteNumber}
              label="Nº do CT-e"
              onChange={event => setCteNumber(event.target.value)}
              error={errors.cteNumber}
              disabled={loading}
            />
          </Col>
          <Col className="mb-3">
            <DatePicker
              label="Data de emissão"
              value={issuedAt}
              onChange={event => setIssuedAt(event.target.value)}
              withTime
              error={errors.issuedAt}
              disabled={loading}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <Text type="label">Notas fiscais</Text>
            <AsyncCreatableSelect
              value={invoices}
              noOptionsMessage={() => <Text>Digite uma nota fiscal</Text>}
              formatCreateLabel={value => <Text>{`Criar "${value}"`} </Text>}
              placeholder={null}
              isMulti
              createOptionPosition="first"
              onChange={value => setInvoices(value)}
              disabled={loading}
            />
            {!!errors.invoices && (
              <Text type="little" color="error">
                {errors.invoices}
              </Text>
            )}
          </Col>
          {!!invoices?.length && (
            <Col xs={12}>
              <Table>
                <thead>
                  <tr>
                    <th>
                      <Text type="label">Nº da Nota</Text>
                    </th>
                    <th>
                      <Text type="label">Chave</Text>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {invoices.map((invoice, index) => (
                    <tr>
                      <td>
                        <Text type="label">{invoice.value}</Text>
                      </td>
                      <td>
                        <Input
                          value={invoice?.key}
                          onChange={event =>
                            setInvoices(
                              invoices.map((item, i) => {
                                if (i === index) {
                                  return { ...item, key: event.target.value };
                                }

                                return item;
                              })
                            )
                          }
                          error={errors[`invoices[${index}].key`]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
          <Col xs={12} className="mb-3">
            <Select
              placeholder={null}
              value={destination}
              label="Destino"
              options={travel.travelInfo.destinations}
              getOptionLabel={option => option.formatted}
              getOptionValue={option => option.id}
              onChange={item => setDestination(item)}
              error={errors.destination}
              disabled={loading}
            />
          </Col>
          <Col xs={12} className="text-center mb-3">
            <Button
              variant="secondary"
              onClick={() => submitEditCte()}
              disabled={loading}
              className="py-2"
            >
              <Text type="regular" weight="500">
                Salvar
              </Text>
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
