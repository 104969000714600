/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import * as Yup from 'yup';

import api from 'services/api';
import { Button, Input, Modal, Select, Text, Upload } from '@cargon/ui';

const FILE_TYPE_OPTIONS = [
  { label: 'CT-e', value: 'cte' },
  { label: 'Canhoto', value: 'canhoto' },
  { label: 'Outros', value: 'others' },
];

const VALIDATION_SCHEMA = Yup.object().shape({
  fileType: Yup.object().required('Selecione o tipo do documento'),
  file: Yup.mixed().required('Selecione um arquivo'),
  nf: Yup.string().when('fileType', {
    is: fileType => fileType === FILE_TYPE_OPTIONS[1],
    then: Yup.string().required('Informe a nota fiscal referente ao canhoto'),
  }),
});

export default function UploadModal({
  isOpen,
  onClose,
  emittedId,
  fetchDocuments,
}) {
  const [fileType, setFileType] = useState();
  const [file, setFile] = useState();
  const [nf, setNf] = useState('');
  const [errors, setErrors] = useState({ fileType: '', file: '', nf: '' });
  const [isInFlight, setInFlight] = useState(false);

  async function uploadFile() {
    try {
      setInFlight(true);
      await VALIDATION_SCHEMA.validate(
        { fileType, file: file[0], nf },
        { abortEarly: false }
      );

      const endpoint = `travel-attachment/portal-upload/${emittedId}/${fileType.value}`;
      const data = new FormData();
      data.append('document_file', file[0]);

      if (nf) {
        data.append('nf_number', nf);
      }

      data.append('carrier', true);

      await api.post(endpoint, data);

      setFileType(null);
      setFile(null);
      setNf('');

      fetchDocuments(emittedId, { refetch: true, setLoading: setInFlight });
      onClose();
    } catch (ex) {
      if (ex instanceof Yup.ValidationError) {
        setErrors(
          ex.inner.reduce(
            (acc, error) => ({ ...acc, [error.path]: error.message }),
            {}
          )
        );
      }
    } finally {
      setInFlight(false);
    }
  }

  return (
    <Modal
      show={isOpen}
      handleClose={onClose}
      heading={
        <Text color="dark" type="header" weight="500">
          Enviar documentos
        </Text>
      }
      body={
        <>
          <Select
            label="Tipo de arquivo *"
            options={FILE_TYPE_OPTIONS}
            onChange={setFileType}
            value={fileType}
            error={errors.fileType}
          />
          {fileType === FILE_TYPE_OPTIONS[1] && (
            <div className="my-3">
              <Input
                label="Nota fiscal *"
                value={nf}
                onChange={({ target }) => setNf(target.value)}
                error={errors.nf}
              />
            </div>
          )}
          <div className="my-3 text-center">
            <Text color="dark" type="regular">
              As extensões de arquivos aceitas são .jpg .png .pdf
            </Text>
          </div>
          <Upload
            name="file"
            label="Arquivo máximo de 1MB"
            accept=".jpg,.jpeg,.png,.pdf"
            value={file}
            onChange={({ target }) => setFile(target.files)}
          />
          {!!errors.file && (
            <Text color="error" type="little">
              {errors.file}
            </Text>
          )}
          <div className="d-flex flex-column align-items-center mt-4">
            <Button onClick={() => uploadFile()} loading={isInFlight}>
              <Text type="regular" weight="500">
                Enviar
              </Text>
            </Button>
          </div>
        </>
      }
    />
  );
}
