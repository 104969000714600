import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { Button, Modal, Select, Text, useSnackbar } from '@cargon/ui';
import { fetchOwners } from '~/services/fetch';
import { useVehicle } from '~/pages/Vehicle/Single/shared/context';
import api from '~/services/api';
import useCurrentUser from '../../../../hooks/useCurrentUser';

export function VehicleAlreadyRegisteredModal({
  vehicleData,
  isOpen,
  onClose,
}) {
  const vehicle = useVehicle();
  const user = useCurrentUser();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [hasNoLinkedOwner, setHasNoLinkedOwner] = useState(false);
  const [hasLinked, setHasLinked] = useState(false);

  useEffect(() => {
    setHasNoLinkedOwner(!vehicleData?.owner);
  }, [vehicleData]);

  async function handleSubmit() {
    try {
      setIsLoading(true);
      if (vehicle.data.owner) {
        const response = await api.post(
          `carrier/vehicle/${vehicleData.id}/requestChangeOwner/`,
          {
            owner_id: vehicle.data?.owner?.id,
            antt_owner: vehicle.data?.anttOwner?.id,
          }
        );
        if (response?.data) {
          setHasLinked(true);
        }
      } else {
        snackbar.show(
          <Text>Por favor selecione um proprietário para o veículo!</Text>,
          { type: 'error' }
        );
      }
    } catch (err) {
      snackbar.show(
        <Text>
          Não foi possível carregar as transportadoras vinculadas ao motorista.
        </Text>,
        { type: 'error' }
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      closeButton={false}
      backdrop="static"
      show={isOpen}
      onHide={() => {
        onClose();
        setHasLinked(false);
      }}
      size="lg"
      heading={
        <Text type="subheader" color="dark">
          Veículo já cadastrado!
        </Text>
      }
      body={
        <Row className="mx-0 mt-1">
          {!hasNoLinkedOwner && (
            <Text weight="400" color="dark">
              O veículo {vehicleData?.plate} já foi previamente cadastrado na
              plataforma!
            </Text>
          )}

          {hasNoLinkedOwner &&
            (hasLinked ? (
              <Text weight="400" color="dark">
                Solicitação realizada com sucesso! Aguarde uma resposta no seu
                email
              </Text>
            ) : (
              <>
                <Row>
                  <Col xs={12} className="mb-2">
                    {vehicleData?.blocked?.length > 0 ? (
                      <Text weight="400" color="dark">
                        O proprietário do veículo ou do documento do veículo
                        está bloqueado. Deseja solicitar à{' '}
                        <strong>{user?.company?.name}</strong> que o
                        Proprietário do documento deste veículo seja alterado?
                      </Text>
                    ) : (
                      <Text weight="400" color="dark">
                        A placa do veículo indicado já foi cadastrada na
                        plataforma, deseja solicitar à{' '}
                        <strong>{user?.company?.name}</strong> que o
                        Proprietário do documento deste veículo seja alterado?
                      </Text>
                    )}
                  </Col>
                  <Col xs={12} className="mb-2">
                    <Select.Async
                      label="Proprietário do documento *"
                      onSearch={fetchOwners}
                      onChange={value =>
                        vehicle.setCurrentVehicle({ owner: value })
                      }
                      value={vehicle.data.owner}
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.id}
                      error={vehicle.errors?.owner || vehicle.errors?.owner_id}
                    />
                  </Col>

                  <Col xs={12} className="mb-2">
                    <Select.Async
                      label="Proprietário RNTRC*"
                      onSearch={fetchOwners}
                      onChange={value =>
                        vehicle.setCurrentVehicle({
                          anttOwner: {
                            ...value,
                          },
                        })
                      }
                      value={vehicle.data.anttOwner}
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.id}
                      error={
                        vehicle.errors?.anttOwner ||
                        vehicle.errors?.[
                          `anttOwner.cgccpf` || 'anttOwner.anttType'
                        ]
                      }
                    />
                  </Col>
                </Row>
              </>
            ))}
        </Row>
      }
      footer={
        <Row xs={12} className=" mt-1">
          <Button
            variant="success"
            className="mr-2 mt-1"
            size="sm"
            onClick={() => {
              onClose();
              vehicle.clearPrevVehicle();
              vehicle.clearCurrentVehicle();
            }}
          >
            <Text weight="500">Cadastrar outro veículo</Text>
          </Button>
          {!hasNoLinkedOwner || hasLinked ? (
            !hasLinked && (
              <Button
                className="mr-2 mt-1 py-2"
                variant="primary"
                size="sm"
                onClick={() => history.push(`/veiculos/${vehicleData?.id}`)}
              >
                <Text weight="500">Ver veículo</Text>
              </Button>
            )
          ) : (
            <Button
              className="mr-2 mt-1 py-2"
              variant="primary"
              size="sm"
              loading={isLoading}
              onClick={() => handleSubmit()}
            >
              <Text weight="500">Solicitar alteração</Text>
            </Button>
          )}
        </Row>
      }
    />
  );
}
