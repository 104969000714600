import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import { Button, Text, useSnackbar } from '@cargon/ui';
import Header from '~/components/Header';
import Offset from '~/components/Offset';
import { auctionStore } from '~/services/store/Auction';
import Info from '~/pages/Auction/Single/Details/Info';
import Locations from '~/pages/Shared/Locations';
import useModal from '~/hooks/useModal';
import AcceptAuction from '~/pages/Auction/Single/Details/Modals/AcceptAuction';
import api from '~/services/api';
import { FaExclamationCircle } from 'react-icons/fa';
import * as Styled from '../shared/styles';
import Card from '../../../../components/Card';

export default function Details() {
  const history = useHistory();
  const { auction, loadings } = auctionStore();

  const modal = useModal();
  const [accepted, setAccepted] = React.useState(false);
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [loadingButton, setLoadingButton] = React.useState(false);

  const snackbar = useSnackbar();
  const handleAccept = async shipper_id => {
    try {
      await api.post(`carrier/auction/${auction.id}`, {
        shipping_company: shipper_id,
      });
      setAccepted(true);
      modal.open();
    } catch (e) {
      snackbar.show(
        <Text>
          Não foi possível aceitar a viagem! {e.response.data.message}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoadingButton(false);
      setLoadingModal(false);
    }
  };
  const openAcceptModal = () => {
    if (auction.auctionShippingCompanies.length === 1) {
      setLoadingButton(true);
      handleAccept(auction.auctionShippingCompanies[0].person_id);
    } else modal.open();
  };
  return (
    <>
      <Header
        title={`Oferta de viagem ${
          auction.id !== undefined ? `#${auction.id}` : ''
        }`}
        RightComponent={
          <>
            <Styled.ButtonsContainer className="pt-5">
              <Button variant="secondary" onClick={() => history.goBack()}>
                <Text color="dark" weight={500}>
                  Voltar
                </Text>
              </Button>
              {!(auction.access_denied || auction.auctioned) && (
                <Button
                  variant="primary"
                  onClick={() => openAcceptModal()}
                  loading={loadingButton}
                >
                  <Text weight={500}>Aceitar Viagem</Text>
                </Button>
              )}
            </Styled.ButtonsContainer>
          </>
        }
      />
      <Offset>
        <Container>
          {auction.access_denied || auction.auctioned ? (
            <Card>
              <div className="text-center">
                <FaExclamationCircle
                  size={26}
                  color="#F64E60"
                  className="mr-2 mb-1"
                />
                <Text type="header" color="error">
                  {auction.auctioned
                    ? 'Esta oferta já foi aceita'
                    : 'Você não tem permissão para acessar essa oferta!'}
                </Text>
              </div>
            </Card>
          ) : (
            <>
              <Row>
                <Col xs={12} md={12} className="mb-1">
                  <Info />
                </Col>
                <Col xs={12} md={12}>
                  <Locations
                    travel={auction.travel}
                    loading={loadings.locations}
                  />
                </Col>
              </Row>
              <AcceptAuction
                isOpen={modal.isOpen}
                close={modal.close}
                handleAccept={handleAccept}
                accepted={accepted}
                loading={loadingModal}
                setLoading={setLoadingModal}
              />
            </>
          )}
        </Container>
      </Offset>
    </>
  );
}
