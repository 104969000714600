import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Text, Input, InputAddress, Radio, Select } from '@cargon/ui';

import { driverStore } from '~/services/store/Driver';
import { fetchCities, fetchCountries } from '~/services/fetch';

export default function Address() {
  const { currentDriver, updateCurrentDriver, errors } = driverStore();

  const [countries, setCountries] = useState([]);

  const propertyTypeList = [
    { value: 'casa', label: 'Casa' },
    { value: 'apartamento', label: 'Apartamento' },
    { value: 'zona rural', label: 'Zona rural' },
    { value: 'outros', label: 'Outros' },
  ];

  const propertyStatusList = [
    { value: 'proprio', label: 'Próprio' },
    { value: 'alugado', label: 'Alugado' },
    { value: 'financiado', label: 'Financiado' },
    { value: 'outros', label: 'Outros' },
  ];

  useEffect(() => {
    async function getCountries() {
      const result = await fetchCountries();
      setCountries(result);
    }
    getCountries();
  }, []);

  async function onSelectPlace(place) {
    const cities = await fetchCities(place.city);
    const city = cities.find(c => c.province?.uf === place.province);
    updateCurrentDriver({
      addresses: {
        complement: currentDriver?.addresses?.complement,
        neighborhood: place.neighborhood,
        zip_code: place.zip_code,
        number: place.number ? place.number : null,
        hasNumber: !!place.number,
        city,
        address:
          place.address ||
          place.formatted_address
            .replace(`${place.name} - `, '')
            .split(',')[0]
            .replace(` - ${place.neighborhood}`, ''),
      },
    });
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <Select
            label="País *"
            options={countries}
            value={currentDriver?.addresses?.city?.province?.country}
            onChange={value => {
              updateCurrentDriver({
                addresses: {
                  ...currentDriver?.addresses,
                  city: {
                    ...currentDriver?.addresses?.city,
                    province: {
                      ...currentDriver?.addresses?.city?.province,
                      country: value,
                    },
                  },
                },
              });
            }}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            error={errors?.country ?? errors['addresses.city.province.country']}
            disableClear
          />
        </Col>
        <Col xs={12} className="pt-2">
          <InputAddress
            label="Endereço *"
            value={currentDriver?.addresses?.address}
            onSelectedAddress={onSelectPlace}
            types={[]}
            country={
              currentDriver?.addresses?.city?.province?.country?.abbreviation
            }
            onChange={({ target: { value } }) =>
              updateCurrentDriver({
                addresses: { ...currentDriver.addresses, address: value },
              })
            }
            placeholder="Procurar endereço"
            error={errors.address ?? errors['addresses.address']}
          />
        </Col>
        <Col xs={6} className="pt-2">
          <Input
            label={!currentDriver?.addresses?.hasNumber ? 'Número' : 'Número *'}
            value={currentDriver?.addresses?.number}
            onChange={({ target: { value } }) =>
              updateCurrentDriver({
                addresses: { ...currentDriver.addresses, number: value },
              })
            }
            error={errors?.number ?? errors['addresses.number']}
            disabled={!currentDriver?.addresses?.hasNumber}
          />
        </Col>
        <Col lg={6} md={6} xs={12} className="d-flex align-items-center pt-2">
          <Radio.Group
            onChange={({ target: { value } }) =>
              updateCurrentDriver({
                addresses: { ...currentDriver.addresses, hasNumber: value },
              })
            }
            value={currentDriver?.addresses?.hasNumber}
            horizontal
          >
            <Radio value>
              <Text color="dark" type="label">
                Com Nº
              </Text>
            </Radio>
            <Radio value={false}>
              <Text color="dark" type="label">
                Sem Nº
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
        <Col lg={6} md={6} xs={12} className="pt-2">
          <Input
            label="Complemento"
            value={currentDriver?.addresses?.complement}
            onChange={({ target: { value } }) =>
              updateCurrentDriver({
                addresses: { ...currentDriver.addresses, complement: value },
              })
            }
            error={errors?.complement ?? errors['addresses.complement']}
          />
        </Col>
        <Col lg={6} md={6} xs={12} className="pt-2">
          <Input
            label="Bairro *"
            value={currentDriver?.addresses?.neighborhood}
            onChange={({ target: { value } }) =>
              updateCurrentDriver({
                addresses: { ...currentDriver.addresses, neighborhood: value },
              })
            }
            error={errors?.neighborhood ?? errors['addresses.neighborhood']}
          />
        </Col>
        <Col lg={6} md={6} xs={12} className="pt-2">
          <Input
            label="CEP *"
            value={currentDriver?.addresses?.zip_code}
            onChange={({ target: { value } }) =>
              updateCurrentDriver({
                addresses: { ...currentDriver.addresses, zip_code: value },
              })
            }
            error={errors?.zip_code ?? errors['addresses.zip_code']}
          />
        </Col>
        <Col xs={12} className="pt-2">
          <Select.Async
            label="Cidade"
            onSearch={fetchCities}
            value={currentDriver?.addresses?.city}
            onChange={value => {
              updateCurrentDriver({
                addresses: { ...currentDriver.addresses, city: value },
              });
            }}
            getOptionLabel={option => `${option?.name}, ${option.province?.uf}`}
            getOptionValue={option => `${option?.name}, ${option.province?.uf}`}
            error={errors?.city ?? errors['addresses.city']}
          />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3 pt-2">
          <Select
            label="Tipo de moradia"
            value={currentDriver?.property_type}
            onChange={value => updateCurrentDriver({ property_type: value })}
            options={propertyTypeList}
            error={errors?.property_type}
          />
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3 pt-2">
          <Select
            label="Tipo de endereço"
            value={currentDriver?.property_status}
            onChange={value => updateCurrentDriver({ property_status: value })}
            options={propertyStatusList}
            error={errors?.property_status}
          />
        </Col>
      </Row>
    </>
  );
}
