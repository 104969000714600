import React from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// eslint-disable-next-line import/no-cycle
import { privateRoutes } from '~/routes/map';
import Menu from '~/components/Menu';

import './style.css';

export default function Header({ title, menuOnly, RightComponent }) {
  const location = useLocation();
  const match = useRouteMatch(location.pathname);

  const breadcrumbs = privateRoutes
    .filter(({ path }) => match.path.includes(path))
    .map(({ path, ...rest }) => ({
      path: Object.keys(match.params).length
        ? Object.keys(match.params).reduce(
            (acc, param) => acc.replace(`:${param}`, match.params[param]),
            path
          )
        : path,
      ...rest,
    }))
    .sort((a, b) => a.path.length - b.path.length);

  const { length, [length - 1]: last, 1: second } = breadcrumbs;

  return (
    <Container fluid className="header py-2">
      <Row>
        <Col md={2}>
          <Navbar.Brand href="/">
            <img src="/images/logo.svg" alt="logo cargon" />
          </Navbar.Brand>
        </Col>

        <Col md={10}>
          <Menu path={second} />
        </Col>
      </Row>

      <Container
        className={`mt-5 pb-5 ${menuOnly ? 'd-none' : ''}`}
        id="header-container"
      >
        <Row>
          <Col>
            <h5 className="mt-0 mb-2">{title || last.title}</h5>

            <Breadcrumb listProps={{ className: 'p-0' }}>
              {breadcrumbs.map(bread => (
                <Breadcrumb.Item
                  key={bread.path}
                  linkAs={Link}
                  linkProps={{ to: bread.path }}
                >
                  {bread.title}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </Col>
          <Col className="d-flex justify-content-end">{RightComponent}</Col>
        </Row>
      </Container>
    </Container>
  );
}
