import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Input } from '@cargon/ui';

import { driverStore } from '~/services/store/Driver';
import { useParams } from 'react-router-dom';

export default function EmergencyContact() {
  const params = useParams();
  const { currentDriver, updateCurrentDriver, errors } = driverStore();
  const initEmergencyContact = () => {
    const user = JSON.parse(
      JSON.parse(localStorage.getItem('persist:cargon-carriers'))?.auth
    )?.user?.properties;
    const newEmergencyContact = currentDriver?.driverEmergencyContact?.name
      ? {}
      : {
          name: user?.username,
          email: user?.email,
          phone: user?.phonenumber,
        };
    updateCurrentDriver({
      driverEmergencyContact: {
        ...currentDriver?.driverEmergencyContact,
        ...newEmergencyContact,
      },
    });
  };
  useEffect(() => {
    if (params.id) initEmergencyContact();
    else setTimeout(() => initEmergencyContact(), 1000);
  }, []);
  return (
    <Row>
      <Col md={12}>
        <Input
          label="Nome *"
          value={currentDriver?.driverEmergencyContact?.name}
          onChange={({ target: { value } }) =>
            updateCurrentDriver({
              driverEmergencyContact: {
                ...currentDriver?.driverEmergencyContact,
                name: value,
              },
            })
          }
          error={errors?.['driverEmergencyContact.name']}
        />
      </Col>
      <Col md={6} className="mb-3 pt-2">
        <Input
          value={currentDriver?.driverEmergencyContact?.phone}
          onChange={({ target: { value } }) =>
            updateCurrentDriver({
              driverEmergencyContact: {
                ...currentDriver?.driverEmergencyContact,
                phone: value,
              },
            })
          }
          guide={false}
          masked
          mask={[
            '(',
            /[0-9]/,
            /\d/,
            ')',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          label="Telefone *"
          error={errors?.['driverEmergencyContact.phone']}
        />
      </Col>
      <Col md={6} className="mb-3 pt-2">
        <Input
          label="Email"
          value={currentDriver?.driverEmergencyContact?.email}
          onChange={({ target: { value } }) =>
            updateCurrentDriver({
              driverEmergencyContact: {
                ...currentDriver?.driverEmergencyContact,
                email: value,
              },
            })
          }
          error={errors?.['driverEmergencyContact.email']}
        />
      </Col>
    </Row>
  );
}
