export const RegEx = {
  VEHICLE: {
    PLATE: {
      MERCOSUL:
        /(([A-Z]{3})[-]?(\d{4}))|(([A-Z]{3})(\d{1})([A-Z]{1})(\d{2}))/gm,
    },
  },
};

export const civilStatusList = [
  {
    value: 3,
    label: 'Solteiro',
  },
  {
    value: 2,
    label: 'Casado',
  },
  {
    value: 1003,
    label: 'Divorciado',
  },

  {
    value: 1,
    label: 'Viúvo',
  },
];
