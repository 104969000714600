import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import Card from 'components/Card';
import Text from 'components/Text';
import List from 'components/BulletList';

import { formatDateTime } from 'utils/formatter';
import useModal from 'hooks/useModal';
import { DestinationProductsModal } from 'components/DestinationProductsModal';

export default function Address({ load }) {
  const [list, setList] = useState([]);
  const [activeDestinationProducts, setActiveDestinationProducts] = useState(
    []
  );
  const destinationProductsModal = useModal();

  useEffect(() => {
    const originList = load.originsList.map(item => ({
      header: <div />,
      body: (
        <>
          <Text className="my-2" color="dark" type="regular">
            {item.action === 'delivery' ? 'Entrega' : 'Coleta'}
          </Text>
          <Text className="my-2" color="gray" type="regular">
            {item.city} - {item.province}
          </Text>
          <Text color="gray" type="regular">
            {formatDateTime(item.scheduled_time)}
          </Text>
        </>
      ),
      filledCircle: true,
    }));

    const destinationsList = load.destinationsList.map(item => ({
      header: <div />,
      body: (
        <>
          <Text className="my-2" color="dark" type="regular">
            {item.action === 'delivery' ? 'Entrega' : 'Coleta'}
          </Text>
          <Text className="my-2" color="gray" type="regular">
            {item.city} - {item.province}
          </Text>
          <Text color="gray" type="regular">
            {formatDateTime(item.scheduled_time)}
          </Text>
          {item && item.destinationProducts.length > 0 && (
            <Text
              onClick={() => {
                setActiveDestinationProducts(item.destinationProducts);
                destinationProductsModal.open();
              }}
              clickable
              color="light_blue"
            >
              Visualizar Produtos{' '}
            </Text>
          )}
        </>
      ),
      filledCircle: false,
    }));

    setList([...originList, ...destinationsList]);
  }, []);

  return (
    <>
      <DestinationProductsModal
        handleClose={destinationProductsModal.close}
        destinationProducts={activeDestinationProducts}
        isOpen={destinationProductsModal.isOpen}
      />
      <Card>
        <Row>
          <Col xs={12} className="mb-3">
            <h5 className="card-title">Endereços</h5>
            <hr className="my-3" />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>{list && <List items={list} />}</Col>
          <Col />
        </Row>
      </Card>
    </>
  );
}
