import React from 'react';

export default function Offset({
  translateX = '0',
  translateY = '-50px',
  ...props
}) {
  return (
    <div
      {...props}
      style={{ transform: `translate(${translateX}, ${translateY})` }}
    />
  );
}
