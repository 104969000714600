import React from 'react';
import { Row } from 'react-bootstrap';
import { Button, Modal, Text } from '@cargon/ui';

import { shippingCompanyStore } from '~/services/store/ShippingCompanies';
import { Link } from 'react-router-dom';

function Registered({ isOpen, onClose }) {
  const { person } = shippingCompanyStore();

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      heading="Cadastro de transportadora"
      size="lg"
      body={
        <Row className="mx-0 mt-1">
          <Text weight="400" color="dark">
            Transportadora {person?.social_name} cadastrada com sucesso!
          </Text>
        </Row>
      }
      footer={
        <Row xs={12} className="mr-3 mt-1">
          <Link to="/transportadoras">
            <Button variant="white" className="mr-2 mt-1" outlined size="sm">
              <Text weight="500">Ver listagem</Text>
            </Button>
          </Link>

          <Link to={`/transportadoras/${person?.id}`}>
            <Button className="mr-2 mt-1" variant="primary" size="sm">
              <Text weight="500">Ver transportadora</Text>
            </Button>
          </Link>

          <Button
            variant="success"
            className="mr-2 mt-1"
            size="sm"
            onClick={() => {
              window.location.reload(false);
            }}
          >
            <Text weight="500">Nova transportadora</Text>
          </Button>
        </Row>
      }
    />
  );
}

export default Registered;
