import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  Text,
  Select,
  Input,
  Radio,
  useSnackbar,
  Section,
  Button,
} from '@cargon/ui';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import PaymentCardDetails from './Details';
import PaymentCardModal from './Modals/Form';
import theme from '../../styles/theme';
import useModal from '../../hooks/useModal';
import RemoveCardModal from './Modals/ConfirmDelete';

export default function PaymentCards({
  paymentCards,
  setPaymentCards,
  loading,
}) {
  const modal = useModal();
  const removeModal = useModal();
  const snackbar = useSnackbar();
  const params = useParams();

  const [activeCard, setActiveCard] = useState(undefined);

  function handleAddCard() {
    setActiveCard(undefined);
    modal.open();
  }

  function handleEditCard(card) {
    setActiveCard(card);
    modal.open();
  }

  function handleRemoveCard(card) {
    setActiveCard(card);
    removeModal.open();
  }

  async function submitRemove() {
    try {
      const cardIndex = paymentCards.findIndex(
        card => card.id === activeCard.id
      );
      if (params.id) await api.delete(`v3/payment/cards/${activeCard.id}`);

      setPaymentCards([
        ...paymentCards.slice(0, cardIndex),
        ...paymentCards.slice(cardIndex + 1),
      ]);

      snackbar.show(<Text>Cartão deletado com sucesso</Text>, {
        type: 'success',
      });
    } catch (error) {
      if (error.message === 'Request failed with status code 405') {
        snackbar.show(<Text>Você não pode deletar um cartão já usado</Text>, {
          type: 'error',
        });
      } else {
        snackbar.show(<Text>Não foi possível deletar o cartão</Text>, {
          type: 'error',
        });
      }
    } finally {
      removeModal.close();
    }
  }

  return (
    <Section
      Title="Cartões"
      loading={loading}
      HeaderRightComponent={
        paymentCards.length > 0 ? (
          <Button
            onClick={() => {
              handleAddCard();
            }}
          >
            <Text type="regular" weight="500" color="white">
              Adicionar
            </Text>
          </Button>
        ) : (
          <div />
        )
      }
    >
      <PaymentCardModal
        modal={modal}
        cards={paymentCards}
        setCards={setPaymentCards}
        activePaymentCard={activeCard}
      />
      <RemoveCardModal modal={removeModal} removeCard={() => submitRemove()} />
      {paymentCards.length > 0 ? (
        paymentCards.map(card => (
          <PaymentCardDetails
            paymentCard={card}
            deleteCard={() => handleRemoveCard(card)}
            setActiveCard={() => handleEditCard(card)}
          />
        ))
      ) : (
        <Row>
          <Col className="text-center">
            <Text color={theme.colors.dark} type="regular" weight="400">
              Não há cartão cadastrado!
            </Text>
            <br />
            <Button
              className="mt-3 py-2"
              onClick={() => {
                handleAddCard();
              }}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          </Col>
        </Row>
      )}
    </Section>
  );
}
