import React, { useEffect, useState } from 'react';
import { Input, Select, Text, useSnackbar } from '@cargon/ui';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { useValidation } from '~/hooks';
import api from '~/services/api';
import { fetchTrackers } from '~/services/fetch/Vehicle';
import { riskManagerSchema } from './schema';
import { parseErrors } from '~/utils/formatter';

const RISK_MANAGER_STATUSES = [
  { value: 'WAITING', label: 'Aguardando checklist' },
  { value: 'ON_GOING', label: 'Checklist em andamento' },
  { value: 'REPROVED', label: 'Checklist reprovado' },
  { value: 'APPROVED', label: 'Checklist aprovado' },
  { value: 'WAIT_SCHEDULE', label: 'Checklist aguardando agendamento' },
];

const RISK_MANAGER_STATUSES_OBJECT = Object.values(
  RISK_MANAGER_STATUSES
).reduce((acc, item) => ({ ...acc, [item.value]: item }), {});

export function RiskManagerCard({ travelInfo, refetchTravel }) {
  const [errors, { validate, setErrors }] = useValidation(riskManagerSchema);
  const snackbar = useSnackbar();

  const [isInFlight, setInFlight] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const [data, setData] = useState({
    risk_manager_id: null,
    tracker: null,
    risk_manager_status: null,
    risk_manager_approval_code: '',
    risk_manager_monitoring_request: '',
  });

  useEffect(() => {
    setData({
      risk_manager_id: travelInfo.riskManager?.id,
      tracker: travelInfo.tracker,
      risk_manager_status:
        RISK_MANAGER_STATUSES_OBJECT[travelInfo.risk_manager_status],
      risk_manager_approval_code: travelInfo.risk_manager_approval_code,
      risk_manager_monitoring_request:
        travelInfo.risk_manager_monitoring_request,
    });
  }, [travelInfo]);

  function updateData(key, value) {
    setData(prev => ({ ...prev, [key]: value }));
  }

  async function onSubmit() {
    try {
      setInFlight(true);

      const [isValid] = await validate(data);
      if (!isValid) {
        return;
      }

      await api.put(`travel-data/${travelInfo.attendedId}/risk-manager`, {
        data: {
          risk_manager_id: data.risk_manager_id ?? null,
          tracker: data.tracker ?? null,
          tracker_id: data.tracker?.id ?? null,
          risk_manager_id: data.risk_manager_id,
          risk_manager_approval_code: data.risk_manager_approval_code ?? null,
          risk_manager_monitoring_request:
            data.risk_manager_monitoring_request ?? null,
          risk_manager_status: {
            ...data.risk_manager_status,
          },
        },
      });

      await refetchTravel();

      setEditing(false);
      snackbar.show(<Text>Dados da viagem atualizados com sucesso.</Text>, {
        type: 'success',
      });
    } catch (ex) {
      if (ex.response) {
        const parsedErrors = parseErrors(ex.response.data, {
          getPath: error => error.field,
        });
        setErrors(parsedErrors);
      }
    } finally {
      setInFlight(false);
    }
  }

  async function onToggleEdit() {
    if (isEditing) {
      await onSubmit();
    } else {
      setEditing(true);
    }
  }

  function onCancel() {
    setEditing(false);
  }

  return (
    <Col md={6} xs={12}>
      <Card className="card-list">
        <Card.Body className="p-5">
          <Row>
            <Col xs={isEditing ? 7 : 10} className="mb-3">
              <h5 className="card-title">Gerenciamento de risco</h5>
            </Col>
            <Col xs={isEditing ? 5 : 2} className="form">
              {isEditing && (
                <Button
                  variant="light"
                  loading={isInFlight}
                  className="mr-2 py-2"
                  onClick={onCancel}
                >
                  <Text variant="dark" type="regular" weight="500">
                    Cancelar
                  </Text>
                </Button>
              )}
              <Button
                onClick={onToggleEdit}
                variant={isEditing ? 'primary' : 'light'}
                loading={isInFlight}
                className="py-2"
              >
                <Text
                  color={isEditing ? 'white' : 'dark'}
                  type="regular"
                  weight="500"
                >
                  {isEditing ? 'Salvar' : 'Editar'}
                </Text>
              </Button>
            </Col>
            <hr className="my-3" />
          </Row>
          <Row className="mt-3">
            {isEditing ? (
              <>
                <Col md={6} xs={12} className="form-group">
                  <Select.Async
                    label="Rastreador"
                    value={data.tracker}
                    onSearch={term => fetchTrackers({ search: term })}
                    onChange={value => updateData('tracker', value)}
                  />
                </Col>
                <Col md={6} xs={12} className="form-group">
                  <Select
                    label="Status *"
                    value={data.risk_manager_status}
                    error={errors.risk_manager_status}
                    options={RISK_MANAGER_STATUSES}
                    onChange={value => updateData('risk_manager_status', value)}
                  />
                </Col>
                <Col md={6} xs={12} className="form-group">
                  <Input
                    label="Solicitação de monitoramento"
                    value={data.risk_manager_approval_code}
                    onChange={({ target }) =>
                      updateData('risk_manager_approval_code', target.value)
                    }
                    tooltip="Código de solicitação de rastreamento"
                    errors={errors.risk_manager_approval_code}
                  />
                </Col>
                <Col md={6} xs={12} className="form-group">
                  <Input
                    label="Pesquisa/Consulta"
                    value={data.risk_manager_monitoring_request}
                    onChange={({ target }) =>
                      updateData(
                        'risk_manager_monitoring_request',
                        target.value
                      )
                    }
                    tooltip="O código retornado pela consulta na gerenciadora de risco."
                  />
                </Col>
              </>
            ) : (
              <>
                <Col xs={6} className="form-group">
                  <div className="card-label">Gerenciadora de risco</div>
                  <div className="card-text">
                    {travelInfo.riskManager?.name ?? 'Não informado'}
                  </div>
                </Col>
                <Col xs={6} className="form-group">
                  <div className="card-label">Rastreador</div>
                  <div className="card-text">
                    {travelInfo.tracker?.name ?? 'Não informado'}
                  </div>
                </Col>
                <Col xs={6} className="form-group">
                  <div className="card-label">Rastreado</div>
                  <div className="card-text">
                    {travelInfo.load?.crawled_load ? 'Sim' : 'Não'}
                  </div>
                </Col>
                <Col xs={6} className="form-group">
                  <div className="card-label">Escoltado</div>
                  <div className="card-text">
                    {travelInfo.load?.is_escorted ? 'Sim' : 'Não'}
                  </div>
                </Col>
                <Col xs={6} className="form-group">
                  <div className="card-label">Possui isca</div>
                  <div className="card-text">
                    {travelInfo.load?.with_bait ? 'Sim' : 'Não'}
                  </div>
                </Col>
                {travelInfo.load?.with_bait && (
                  <Col xs={6} className="form-group">
                    <div className="card-label">Número da isca</div>
                    <div className="card-text">
                      {travelInfo.load.bait_number}
                    </div>
                  </Col>
                )}
                <Col xs={6} className="form-group">
                  <div className="card-label">Status *</div>
                  <div className="card-text">
                    {
                      RISK_MANAGER_STATUSES_OBJECT[
                        travelInfo.risk_manager_status
                      ]?.label
                    }
                  </div>
                </Col>
                <Col xs={6} className="form-group">
                  <div className="card-label">Solicitação de monitoramento</div>
                  <div className="card-text">
                    {travelInfo.risk_manager_approval_code ?? 'Não informado'}
                  </div>
                </Col>
                <Col xs={6} className="form-group">
                  <div className="card-label">Pesquisa/Consulta</div>
                  <div className="card-text">
                    {travelInfo.risk_manager_monitoring_request ??
                      'Não informado'}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}
