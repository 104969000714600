import XMLParser from 'react-xml-parser';

class MdfeParser {
  process(xml) {
    try {
      const content = new XMLParser().parseFromString(xml);

      const mdfeElement = content.getElementsByTagName('MDFe')[0];
      const infMDFeElement = mdfeElement.getElementsByTagName('infMDFe')[0];
      const ideElement = infMDFeElement.getElementsByTagName('ide')[0];

      const serie = ideElement.getElementsByTagName('serie')[0].value;
      const number = ideElement.getElementsByTagName('nMDF')[0].value;
      let issued_date = ideElement.getElementsByTagName('dhEmi')[0].value;

      issued_date = issued_date.replace('-03:00', '.000Z');

      const mdfeProtocolElement = content.getElementsByTagName('protMDFe')[0];
      const infProtElement =
        mdfeProtocolElement.getElementsByTagName('infProt')[0];

      const key = infProtElement.getElementsByTagName('chMDFe')[0].value;
      const sefaz_protocol =
        infProtElement.getElementsByTagName('nProt')[0].value;

      const data = {
        serie,
        number,
        key,
        sefaz_protocol,
        issued_date,
      };

      return data;
    } catch (err) {
      throw new Error('Arquivo não compatível com MDFe');
    }
  }
}

export { MdfeParser };
