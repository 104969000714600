import styled, { css } from 'styled-components';

export const StyledSpan = styled.span`
  ${({ clicklable }) =>
    clicklable &&
    css`
      cursor: pointer;
    `}

  ${({ truncate }) =>
    truncate &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    `}
`;
