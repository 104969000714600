import React from 'react';
import { Button, Fallback, Section, Text } from '@cargon/ui';
import { Col, Row } from 'react-bootstrap';
import { shippingCompanyStore } from 'services/store/ShippingCompanies';
import theme from 'styles/theme';
import AddressModal from 'pages/ShippingCompanies/Single/shared/Modals/Address';

export default function Address({ modal }) {
  const { person, errors, loadings } = shippingCompanyStore();

  return (
    <Section
      Title="Endereço"
      loading={loadings.address}
      HeaderRightComponent={
        <>
          {person?.addresses?.[0]?.address && (
            <Button
              className="py-2"
              variant="secondary"
              onClick={() => {
                modal.open();
              }}
            >
              <Text type="regular" weight="500">
                Editar
              </Text>
            </Button>
          )}
        </>
      }
    >
      <AddressModal isOpen={modal.isOpen} onClose={modal.close} />

      <Fallback
        fall={!person?.addresses?.[0]?.address}
        component={
          <Col xs={12} className="align-items-center d-flex flex-column my-2">
            <Text color={theme.colors.dark} type="regular" weight="400">
              Não há endereço cadastrado para essa transportadora
            </Text>
            {Object.keys(errors || {}).some(item =>
              item.includes('address.')
            ) && (
              <div className="mt-2">
                <Text type="regular" color="error">
                  Adicione um endereço
                </Text>
              </div>
            )}
            <Button
              className="mt-3 py-2"
              onClick={() => {
                modal.open();
              }}
            >
              <Text type="regular" weight="500" color="white">
                Adicionar
              </Text>
            </Button>
          </Col>
        }
      >
        <Row>
          <Col xs={12}>
            <Text type="label">País</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.addresses?.[0]?.country?.name || 'Não informado'}
            </Text>
          </Col>

          <Col xs={12}>
            <Text type="label">Endereço</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.addresses?.[0]?.address || 'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label">Número</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.addresses?.[0]?.number || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label">Complemento</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.addresses?.[0]?.complement || 'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label">Bairro</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.addresses?.[0]?.neighborhood || 'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label">CEP</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.addresses?.[0]?.zip_code || 'Não informado'}
            </Text>
          </Col>

          <Col xs={12}>
            <Text type="label">Cidade</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {`${person?.addresses?.[0]?.city_name} -  ${person?.addresses?.[0]?.uf_name} ` ||
                'Não informado'}
            </Text>
          </Col>
        </Row>
      </Fallback>
    </Section>
  );
}
