import styled from 'styled-components';
import theme from 'styles/theme';

export const CalculatorCardDiv = styled.div`
  border: ${({ error }) =>
    error
      ? `1px solid ${theme.colors.error}`
      : `1px solid ${theme.colors.medium_gray}`};
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  border-radius: 12px;
`;

export const ChargePaymentList = styled.ul`
  width: 100%;
  list-style-type: ${props => (props.bulletpoint ? 'circle' : 'none')};
  padding: ${props =>
    `0 ${props.inner ? '0' : '16px'} 0 ${props.bulletpoint ? '32px' : '16px'}`};
  margin-bottom: 0;
`;
