import create from 'zustand';
import entityApi from '../../../../../services/api/entity';
import produce from 'immer';

async function fetchShipperTravelsWithoutRefusedReason(id) {
  try {
    const { data } = await entityApi.get(
      `shipping-company/${id}/refused-travels-no-reason`
    );
    return data.result.travels;
  } catch (e) {
    return [];
  }
}
export const useRefusedReasonStore = create((set, get) => ({
  shipping_company: null,
  travels: [],
  popTravel: () => {
    set(
      produce(draft => {
        const travelsLeft = draft.travels.length;
        if (travelsLeft === 1) draft.modal.isOpen = false;
        draft.travels.pop();
      })
    );
  },
  modal: {
    isOpen: false,
    open: () => set(prev => ({ modal: { ...prev.modal, isOpen: true } })),
    close: () => set(prev => ({ modal: { ...prev.modal, isOpen: false } })),
  },
  getPendingTravels: async id => {
    // TODO Get shipper pending cancellation reason travels
    const travels = await fetchShipperTravelsWithoutRefusedReason(id);
    if (travels && travels.length) {
      set({ travels, shipping_company: { id } });
      get().modal.open();
    }
  },
}));
