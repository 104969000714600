import styled from 'styled-components';
import RawBreadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import theme from '~/styles/theme';

export const Breadcrumb = styled(RawBreadcrumb)`
  > ol {
    background-color: transparent;

    li.breadcrumb-item::before {
      color: #fff;
    }
  }
`;

export const BreadcrumbItem = styled(RawBreadcrumb.Item)``;

export const Header = styled.header`
  background-color: ${theme.colors.dark_blue};
  @media (max-width: 576px) {
    min-height: ${({ expand }) => {
      return expand ? '70vh' : '250px';
    }};
  }
  min-height: ${({ expand }) => {
    return expand ? '40vh' : '250px';
  }};
  padding-bottom: ${({ isOld, expand }) => (isOld || expand ? 0 : 130)}px;
`;

export const Main = styled(Container)`
  @media (max-width: 576px) {
    margin-top: ${({ isOld, expand }) => {
      return isOld ? 0 : expand ? `calc(-70vh + 100px)` : '-130px';
    }};
  }
  margin-top: ${({ isOld, expand }) => {
    return isOld ? 0 : expand ? `calc(-40vh + 100px)` : '-130px';
  }};
`;
