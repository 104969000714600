export default function cteParser(cteElement) {
  const cte = {};

  cte.cUF = cteElement.getElementsByTagName('cUF')[0].value;
  cte.cCt = cteElement.getElementsByTagName('cCT')[0].value;
  cte.cfop = cteElement.getElementsByTagName('CFOP')[0].value;
  cte.serie = cteElement.getElementsByTagName('serie')[0].value;
  cte.cteNumber = cteElement.getElementsByTagName('nCT')[0].value;
  cte.cityCodeTaker = cteElement.getElementsByTagName('cMunEnv')[0].value;
  cte.cityCodeSender = cteElement.getElementsByTagName('cMunIni')[0].value;
  cte.cityCodeReceiver = cteElement.getElementsByTagName('cMunFim')[0].value;
  cte.issuedAt = cteElement.getElementsByTagName('dhEmi')[0].value;

  cte.issuedAt = cte.issuedAt.replace('-03:00', '.000Z');

  return cte;
}
