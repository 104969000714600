export const reshapeTravel = data => {
  function getTravelStatus(logs) {
    const refusedStatus = logs?.filter(log => {
      return log.allocatedTravel?.refused;
    });
    const acceptedStatus = logs?.filter(log => {
      return log.allocatedTravel?.accepted;
    });

    return {
      refused: refusedStatus[refusedStatus.length - 1],
      accepted: acceptedStatus[acceptedStatus.length - 1],
    };
  }

  const { accepted, refused } = getTravelStatus(data?.logs);
  const accept_deadline = data?.accept_deadline;
  const confirmation_deadline = data?.confirmation_deadline;
  const statusColor = data.status_color;
  const taker =
    data.social_name && data.cgccpf
      ? `${data.social_name} - ${data.cgccpf}`
      : '';
  const loadNumber = data.load_id;
  const { status } = data;
  const statusId = data.status_id;
  const statusEDIT = { status: { label: status, value: statusId } };
  const value = data.balance;

  let travelNumber = '';
  let userCreated = 0;

  travelNumber = data.travel_number || '';
  userCreated = data.user_created || 0;

  const [origin] = data.originList || [];
  const destinations = data.destinationList || [];

  return {
    accept_deadline,
    confirmation_deadline,
    attendedId: data.id,
    statusColor,
    statusId,
    taker,
    loadNumber,
    status,
    statusEDIT,
    value,
    travelNumber,
    userCreated,
    origin,
    destinations,
    refused,
    accepted,
    user: data.load.userCreated,
    load: data.load,
  };
};
