import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { FaTrash, FaPencilAlt } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import useModal from 'hooks/useModal';

import theme from '@cargon/ui/dist/theme';
import { Card, Text, Button, Fallback, Table, Badge } from '@cargon/ui';
import { removeVehicleDriverRelation } from '~/services/fetch/Vehicle';
import AddDriverModal from './AddDrivelModal';

import { useVehicle } from '../context';

export default function AttachDriver() {
  const vehicle = useVehicle();
  const addDriverModal = useModal();
  const params = useParams();

  const [modalName, setModalName] = useState('');

  function openModal() {
    setModalName('Driver');
  }

  async function handleRemove(index, item) {
    const deleting = vehicle?.data?.drivers.filter((_, idx) => idx !== index);
    vehicle.setCurrentVehicle({
      drivers: deleting,
    });

    vehicle.setPrevVehicle(old => ({
      ...old,
      drivers: deleting,
    }));

    await removeVehicleDriverRelation({ id: params.id, value: item.value });
  }

  useEffect(() => {
    if (modalName === 'Driver') {
      addDriverModal.open();
    }
  }, [modalName]);

  return (
    <>
      <AddDriverModal
        isOpen={addDriverModal.isOpen}
        onClose={() => {
          addDriverModal.close();
          setModalName('');
        }}
      />
      <Card
        loading={vehicle.isFetching}
        header={
          <Text type="header" color="dark">
            Motoristas
          </Text>
        }
        HeaderRightComponent={
          <>
            {!vehicle.data?.drivers?.length || (
              <Button
                onClick={() => {
                  openModal();
                  vehicle.setCurrentVehicle({
                    currentDriverEditing: null,
                  });
                }}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            )}
          </>
        }
      >
        <Row className="form">
          <Fallback
            fall={!vehicle?.data?.drivers?.length}
            component={
              <Col
                xs={12}
                className="align-items-center d-flex justify-content-center my-2 text-center"
              >
                <Text color={theme.colors.gray}>
                  Não há motoristas cadastrados para esse veículo.
                </Text>
              </Col>
            }
          >
            <Table>
              <thead>
                <tr>
                  <th>
                    <Text weight="500" color="dark">
                      Nome
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text weight="500" color="dark">
                      Ação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {vehicle?.data?.drivers
                  ?.sort((x, y) =>
                    x.current === y.current ? 0 : x.current ? -1 : 1
                  )
                  .map((item, index) => (
                    <tr>
                      <td>
                        <Text type="regular" color="dark">
                          {item.social_name}
                        </Text>
                        {item.current && (
                          <Badge
                            pill
                            light
                            variant="success"
                            className="ml-2"
                            size="sm"
                          >
                            <Text color="success" type="label">
                              Atual
                            </Text>
                          </Badge>
                        )}
                      </td>
                      <td width="120" className="text-center">
                        <Button
                          variant="secondary"
                          size="sm"
                          className="mr-1"
                          onClick={() => {
                            openModal(item);
                            vehicle.setCurrentVehicle({
                              currentDriverEditing: item,
                            });
                          }}
                        >
                          <FaPencilAlt size={16} color="#464E5F" />
                        </Button>
                        <Button
                          variant="error"
                          size="sm"
                          onClick={() => handleRemove(index, item)}
                          disabled={item.current}
                        >
                          <FaTrash size={16} />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Fallback>
          <Text
            label="Vehicle"
            value={vehicle?.data?.driver}
            onChange={event =>
              vehicle.setCurrentVehicle({ driver: event.target.value })
            }
          />
          {!vehicle?.data?.drivers?.length && (
            <Col
              xs={12}
              className="align-items-center d-flex justify-content-center my-2"
            >
              <Button
                onClick={() => {
                  openModal(null);
                  vehicle.setCurrentVehicle({
                    currentDriverEditing: null,
                  });
                }}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    </>
  );
}
