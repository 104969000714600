import { cpf } from 'cpf-cnpj-validator';
import { isValid, isBefore, parse } from 'date-fns';

export function validateDocument(document) {
  if (document) {
    const valid = cpf.isValid(document);

    return valid;
  }
  return true;
}

export function whiteSpaceOrSize({ value, size }) {
  if (value) {
    const withoutUnderline = value?.replaceAll('_', '');
    const onlySpace = withoutUnderline.replace(/\s/g, '');
    return onlySpace.length >= size;
  }

  return true;
}

export function validateDate(value) {
  if (value) {
    const [day, month, year] = value.split('/');

    if (!Number(year) && !Number(month) && !Number(day)) {
      return false;
    }

    const date = parse(
      `${Number(year)}-${Number(month)}-${Number(day)}`,
      'yyyy-MM-dd',
      new Date()
    );

    const valid = isValid(date);

    if (!valid) {
      return false;
    }

    const isGreatter = isBefore(date, new Date());

    return isGreatter;
  }

  return true;
}
