import React from 'react';
import { BsQuestion } from 'react-icons/bs';
import { FaAddressCard } from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap';

import { Text, Radio, Input, Select, Tooltip, Upload } from '@cargon/ui';

import { fetchCities } from '~/services/fetch';
import { driverStore } from '~/services/store/Driver';

const cnhTypes = [
  { value: 'A', label: 'A' },
  { value: 'AB', label: 'AB' },
  { value: 'AC', label: 'AC' },
  { value: 'AD', label: 'AD' },
  { value: 'AE', label: 'AE' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
];

export default function CNH() {
  const { currentDriver, updateCurrentDriver, errors } = driverStore();

  return (
    <>
      <Row className="form">
        <Col xs={12} className="mb-3">
          <Radio.Group
            label="CNH Estrangeira"
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ cnh_is_foreign: value })
            }
            value={currentDriver?.cnh_is_foreign}
            horizontal
          >
            <Radio value>
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio value={false}>
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
        <Col lg={6} md={6} xs={12}>
          <Input
            label="CNH *"
            value={currentDriver?.cnh_number}
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ cnh_number: value })
            }
            error={errors?.cnh_number}
            guide={false}
            masked
            mask={[
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
            ]}
          />
        </Col>
        {!currentDriver?.is_international && !currentDriver?.cnh_is_foreign && (
          <>
            <Col lg={6} md={6} xs={12}>
              <Select
                label="Categoria *"
                getOptionValue={value => value.value}
                getOptionLabel={value => value.value}
                value={cnhTypes.find(
                  type => type.value === currentDriver?.cnh_type
                )}
                options={cnhTypes}
                onChange={value =>
                  updateCurrentDriver({ cnh_type: value?.value })
                }
                error={errors?.cnh_type}
              />
            </Col>
            <Col xs={12}>
              <Input
                label="Chave CNH *"
                value={currentDriver?.cnh_key}
                onChange={({ target: { value } }) =>
                  updateCurrentDriver({ cnh_key: value })
                }
                error={errors?.cnh_key}
              />
            </Col>
            <Col xs={12}>
              <Select.Async
                label="Cidade emissão CNH *"
                onSearch={fetchCities}
                value={currentDriver?.cnhDispatchCity}
                onChange={value =>
                  updateCurrentDriver({
                    cnhDispatchCity: value,
                  })
                }
                getOptionLabel={option =>
                  `${option.name}, ${option.province.uf}`
                }
                getOptionValue={option => option.id}
                error={errors?.cnhDispatchCity}
              />
            </Col>
          </>
        )}
        <Col md={6} xs={12}>
          <Input
            label="1° habilitação *"
            value={currentDriver?.first_cnh_date}
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ first_cnh_date: value })
            }
            masked
            mask={[
              /[0-9]/,
              /[0-9]/,
              '/',
              /[0-9]/,
              /[0-9]/,
              '/',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
            ]}
            error={errors?.first_cnh_date}
          />
        </Col>
        <Col md={6} xs={12}>
          <Input
            label="Data de emissão CNH *"
            value={currentDriver?.cnh_dispatch_date}
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ cnh_dispatch_date: value })
            }
            masked
            mask={[
              /[0-9]/,
              /[0-9]/,
              '/',
              /[0-9]/,
              /[0-9]/,
              '/',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
            ]}
            error={errors?.cnh_dispatch_date}
          />
        </Col>
        <Col md={6} xs={12}>
          <Input
            label="Validade habilitação *"
            value={currentDriver?.cnh_exp_date}
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ cnh_exp_date: value })
            }
            masked
            mask={[
              /[0-9]/,
              /[0-9]/,
              '/',
              /[0-9]/,
              /[0-9]/,
              '/',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
            ]}
            error={errors?.cnh_exp_date}
          />
        </Col>
        <Col xs={12}>
          <Input
            label="Número de formulário"
            value={currentDriver?.cnh_form_number}
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ cnh_form_number: value })
            }
            error={errors?.cnh_form_number}
          />
        </Col>

        <Col xs={12}>
          <Input
            label="Número de renach"
            value={currentDriver?.cnh_renach}
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ cnh_renach: value })
            }
            error={errors?.cnh_renach}
          />
        </Col>
        <Col xs={12} className="mb-3">
          <Radio.Group
            label="Possui MOPP"
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ mopp: value })
            }
            value={currentDriver.mopp}
            horizontal
          >
            <Radio value>
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio value={false}>
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
        {currentDriver.mopp && (
          <>
            <Col xs={12} className="mt-3">
              <Input
                label={
                  <>
                    <>Descrição Mopp</>
                    <Tooltip
                      content={
                        <Text>
                          Se preencher qualquer campo mopp, todos se tornarão
                          obrigatórios
                        </Text>
                      }
                    >
                      <Text>
                        <BsQuestion color="#464E5F" />
                      </Text>
                    </Tooltip>
                  </>
                }
                value={currentDriver.mopp_description}
                onChange={({ target: { value } }) =>
                  updateCurrentDriver({ mopp_description: value })
                }
                error={errors?.mopp_description}
              />
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Input
                label="Primeiro Mopp"
                value={currentDriver.mopp_date_init}
                onChange={({ target: { value } }) =>
                  updateCurrentDriver({ mopp_date_init: value })
                }
                masked
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                error={errors?.mopp_date_init}
              />
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Input
                label="Validade do Mopp"
                value={currentDriver.mopp_expiration}
                onChange={({ target: { value } }) =>
                  updateCurrentDriver({ mopp_expiration: value })
                }
                masked
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  '/',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                error={errors?.mopp_expiration}
              />
            </Col>
          </>
        )}
      </Row>
      <Row className="form">
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Radio.Group
            label="Autônomo"
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ is_autonomous: value })
            }
            value={currentDriver?.is_autonomous}
            horizontal
          >
            <Radio value>
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio value={false}>
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Radio.Group
            label="Proprietário"
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ is_owner: value })
            }
            value={currentDriver?.is_owner}
            horizontal
          >
            <Radio value>
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio value={false}>
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
        <Col xs={12} className="mb-3">
          <Text type="label" color="#464E5F">
            Upload de CNH
          </Text>
          <Row className="form">
            <Col lg={6} md={6} xs={12} className="text-center mt-3">
              <Text type="label" color="#464E5F">
                Frente
              </Text>
              <Upload
                icon={<FaAddressCard size={24} color="#464E5F" />}
                label="Arquivo máximo de 1MB"
                accept={['.png', '.jpg']}
                onChange={({ target: { files } }) =>
                  updateCurrentDriver({ front_cnh_file: files[0] })
                }
                value={currentDriver.front_cnh_file}
                name="front_cnh"
              />
            </Col>
            <Col lg={6} md={6} xs={12} className="text-center mt-3">
              <Text type="label" color="#464E5F">
                Verso
              </Text>
              <Upload
                icon={<FaAddressCard size={24} color="#464E5F" />}
                label="Arquivo máximo de 1MB"
                accept={['.png', '.jpg']}
                onChange={({ target: { files } }) =>
                  updateCurrentDriver({ back_cnh_file: files[0] })
                }
                value={currentDriver.back_cnh_file}
                name="back_cnh"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
