import styled from 'styled-components';

import Row from 'react-bootstrap/Row';
import theme from '~/layouts/Theme';

export const ValuesArea = styled.div`
  background-color: #fff4de;
  width: 100%;
  padding: 35px 25px;
  border-radius: 5px;
  overflow: hidden;
`;

export const TotalValueArea = styled(Row)`
  padding: 0px 30px;
`;

export const BulletsArea = styled.div`
  padding: 0px 30px;
  ul {
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;

      &::before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: ${theme.colors.dark};
        display: inline-flex;
        margin-right: 7px;
      }
    }
  }
`;

export const Line = styled.hr`
  border: 1px solid #b5b5c3;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 35px;
`;

export const DeleteButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background: ${theme.colors.error};

  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none !important;
  }
`;
