import * as Yup from 'yup';
import {
  dataSchema,
  mainSchema,
  ownerSchema,
  tractionSchema,
  wagonSchema,
} from './index';

export const registerTractionVehicleSchema = Yup.object().shape({
  ...dataSchema.fields,
  ...ownerSchema.fields,
  ...mainSchema.fields,
  ...tractionSchema.fields,
});
export const registerWagonVehicleSchema = Yup.object().shape({
  ...dataSchema.fields,
  ...ownerSchema.fields,
  ...mainSchema.fields,
  ...wagonSchema.fields,
});
