import React from 'react';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { DestinationProducts } from './DestinationProducts';

export function DestinationProductsModal({
  destinationProducts = [],
  isOpen = false,
  handleClose,
}) {
  return (
    <Modal
      scrollable
      show={isOpen}
      handleClose={handleClose}
      heading={<Text>Produtos do destino</Text>}
      body={<DestinationProducts items={destinationProducts} />}
      footer={undefined}
    />
  );
}
