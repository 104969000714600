import { Select, Text, useSnackbar } from '@cargon/ui';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useValidation } from '~/hooks';
import api from '~/services/api';
import {
  fetchPaymentCards,
  fetchReceiverOptions,
} from '~/services/fetch/Payment';
import { removeDuplicates } from '~/utils/array';
import { parseErrors } from '~/utils/formatter';
import { paymentSchema } from './schema';

const TOLL_PAYMENT_IDS = {
  2: 'SEM PARAR - TAG',
  3: 'Pago pelo tomador',
  MOEDEIRO_ID: 1,
  SEM_PARAR: 2,
  PAYED_BY_TAKER: 3,
};

const TOLL_PAYMENT_METHODS = [
  { id: 0, label: 'SEM VALE-PEDÁGIO' },
  { id: 1, label: 'PAGO PELO TOMADOR' },
  { id: 2, label: 'SEM PARAR - TAG' },
];

export function PaymentCard({ travelInfo, refetchTravel }) {
  const [errors, { validate, setErrors }] = useValidation(paymentSchema);
  const snackbar = useSnackbar();

  const [isInFlight, setInFlight] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const [data, setData] = useState({
    ciotRecipient: null,
    ciotCard: null,
    tollVoucherRecipient: null,
    tollVoucherCard: null,
    receiving_mode_id: null,
  });
  const [ciotReceivers, setCIOTReceivers] = useState([]);
  const [ciotCards, setCIOTCards] = useState([]);
  const [tollReceivers, setTollReceivers] = useState([]);
  const [tollCards, setTollCards] = useState([]);

  useEffect(() => {
    setData({
      ciotCard: travelInfo.paymentCiot,
      ciotRecipient: travelInfo.paymentCiot?.person,
      tollVoucherCard: travelInfo.paymentToll,
      tollVoucherRecipient: travelInfo.paymentToll?.person,
      receiving_mode_id: travelInfo.receiving_mode_id,
    });
  }, [travelInfo.payment, travelInfo.receiving_mode_id]);

  useEffect(() => {
    async function fetchCIOTOptions() {
      setCIOTReceivers([]);

      if (!travelInfo.tractionVehicle) {
        return;
      }
      const { tractionVehicle, wagonVehicles } = travelInfo;
      const wagonsANTTOwner = wagonVehicles.map(
        wagonAttended => wagonAttended?.antt_owner
      );

      const anttOwnersIds = removeDuplicates(
        [tractionVehicle?.antt_owner, ...wagonsANTTOwner].filter(Boolean),
        item => item
      );
      console.log(anttOwnersIds);
      const options = await Promise.all(
        anttOwnersIds.map(id => fetchReceiverOptions(id))
      );
      setCIOTReceivers(options.flat());
    }

    fetchCIOTOptions();
  }, [travelInfo.tractionVehicle, travelInfo.wagonVehicles]);

  useEffect(() => {
    async function fetchCIOTCards() {
      setCIOTCards([]);
      if (!data.ciotRecipient) {
        return;
      }

      const cards = await fetchPaymentCards(data.ciotRecipient.id);
      setCIOTCards(cards);
    }

    fetchCIOTCards();
  }, [data.ciotRecipient]);

  useEffect(() => {
    async function fetchTollRecipients() {
      setTollReceivers([]);
      if (!travelInfo.driver) {
        return;
      }

      const options = await fetchReceiverOptions(travelInfo.driver.id);
      setTollReceivers([...TOLL_PAYMENT_METHODS, ...options]);
    }

    fetchTollRecipients();
  }, [travelInfo.driver]);

  useEffect(() => {
    async function fetchTollCards() {
      setTollCards([]);
      if (!data.tollVoucherRecipient) {
        return;
      }

      const cards = await fetchPaymentCards(data.tollVoucherRecipient.id);
      setTollCards(cards);
    }

    fetchTollCards();
  }, [data.tollVoucherRecipient]);

  useEffect(() => {
    if (data.tollVoucherRecipient) {
      if (data.tollVoucherRecipient.id === 1) {
        updateData('receiving_mode_id', TOLL_PAYMENT_IDS.PAYED_BY_TAKER);
      } else if (data.tollVoucherRecipient.id === 2) {
        updateData('receiving_mode_id', TOLL_PAYMENT_IDS.SEM_PARAR);
      }
    } else {
      updateData('receiving_mode_id', null);
    }
  }, [data.tollVoucherRecipient]);

  useEffect(() => {
    if (data.tollVoucherCard?.corporation?.slug === 'EXTRATTA') {
      updateData('receiving_mode_id', TOLL_PAYMENT_IDS.MOEDEIRO_ID);
    }
  }, [data.tollVoucherCard]);

  function updateData(key, value) {
    setData(prev => ({ ...prev, [key]: value }));
  }

  async function onSubmit() {
    try {
      setInFlight(true);

      const parsedData = {
        ciot_recipient_card: data.ciotCard?.card_number,
        toll_recipient_card: data.tollVoucherCard?.card_number,
        receiving_mode: data.receiving_mode_id,
      };

      const [isValid] = await validate(parsedData);

      if (!isValid) {
        return;
      }

      await api.put(`travel-data/${travelInfo.attendedId}/payment`, parsedData);

      await refetchTravel();

      setEditing(false);
      snackbar.show(<Text>Dados do pagamento atualizados com sucesso.</Text>, {
        type: 'success',
      });
    } catch (ex) {
      if (ex.response) {
        const parsedErrors = parseErrors(ex.response.data, {
          getPath: error => error.field,
        });

        setErrors(parsedErrors);
      }
    } finally {
      setInFlight(false);
    }
  }

  async function onToggleEdit() {
    if (isEditing) {
      await onSubmit();
    } else {
      setEditing(true);
    }
  }

  function onCancel() {
    setEditing(false);
  }

  const tollTicketReceiver = useMemo(() => {
    if (travelInfo.paymentToll?.person) {
      return travelInfo.paymentToll.person.label;
    }

    const tollPaymentMethod = TOLL_PAYMENT_IDS[travelInfo.receiving_mode_id];
    if (tollPaymentMethod) {
      return tollPaymentMethod;
    }

    return 'Sem vale-pedágio';
  }, [travelInfo.receiving_mode_id, travelInfo.paymentToll]);

  const tollTickerReceiverCard = useMemo(() => {
    if (
      travelInfo.paymentToll &&
      travelInfo.receiving_mode_id === TOLL_PAYMENT_IDS.MOEDEIRO_ID
    ) {
      return travelInfo.paymentToll.card_number;
    }

    const tollPaymentMethod = TOLL_PAYMENT_IDS[travelInfo.receiving_mode_id];
    if (tollPaymentMethod) {
      return tollPaymentMethod;
    }

    return 'Sem vale-pedágio';
  }, [travelInfo]);

  return (
    <Col md={6} xs={12}>
      <Card className="card-list">
        <Card.Body className="p-5">
          <Row>
            <Col xs={isEditing ? 7 : 10} className="mb-3">
              <h5 className="card-title">Pagamento</h5>
            </Col>
            <Col xs={isEditing ? 5 : 2} className="form">
              {isEditing && (
                <Button
                  variant="light"
                  loading={isInFlight}
                  className="mr-2 py-2"
                  onClick={onCancel}
                >
                  <Text variant="dark" type="regular" weight="500">
                    Cancelar
                  </Text>
                </Button>
              )}
              <Button
                onClick={onToggleEdit}
                disabled={travelInfo?.status?.order > 4}
                variant={isEditing ? 'primary' : 'light'}
                loading={isInFlight}
                className="py-2"
              >
                <Text
                  color={isEditing ? 'white' : 'dark'}
                  type="regular"
                  weight="500"
                >
                  {isEditing ? 'Salvar' : 'Editar'}
                </Text>
              </Button>
            </Col>
            <hr className="my-3" />
          </Row>
          <Row className="mt-3">
            {isEditing ? (
              <>
                <Col md={6} xs={12} className="form-group">
                  <Select
                    label="Recebedor do CIOT"
                    value={data.ciotRecipient}
                    onChange={value => updateData('ciotRecipient', value)}
                    options={ciotReceivers}
                    getOptionLabel={option =>
                      `${option.social_name} - ${option.cgccpf}`
                    }
                    getOptionValue={option => option.id}
                  />
                </Col>
                <Col md={6} xs={12} className="form-group">
                  <Select
                    label="Cartão do recebedor do CIOT"
                    value={data.ciotCard}
                    onChange={value => updateData('ciotCard', value)}
                    options={ciotCards}
                    getOptionLabel={option =>
                      `Final ${option.card_number.substr(-4)} (${
                        option.corporation.name
                      })`
                    }
                    getOptionValue={option => option.id}
                    disableClear
                    isDisabled={!data.ciotRecipient}
                    error={errors.ciot_recipient_card}
                  />
                </Col>
                <Col md={6} xs={12} className="form-group">
                  <Select
                    label="Recebedor do vale-pedágio"
                    value={data.tollVoucherRecipient}
                    options={tollReceivers}
                    onChange={value =>
                      updateData('tollVoucherRecipient', value)
                    }
                    getOptionLabel={option => {
                      let label = `${option.social_name} - ${option.cgccpf}`;
                      if (option.id === 0 || option.id === 1 || option.id === 2)
                        label = `${option.label}`;
                      return label;
                    }}
                    getOptionValue={option => option.id}
                    NoOptionsComponent={() => (
                      <Text color="gray" type="regular">
                        Nenhuma pessoa encontrada
                      </Text>
                    )}
                  />
                </Col>
                <Col md={6} xs={12} className="form-group">
                  <Select
                    label="Cartão do vale-pedágio"
                    value={data.tollVoucherCard}
                    onChange={value => updateData('tollVoucherCard', value)}
                    options={tollCards}
                    getOptionLabel={option => {
                      let label;
                      if (option.id !== 1) {
                        label = `Final ${option.card_number.substr(-4)} - ${
                          option.corporation.name
                        }`;
                      } else {
                        label = `${option.label}`;
                      }
                      return label;
                    }}
                    getOptionValue={option => option.id}
                    disableClear
                    isDisabled={
                      !data.tollVoucherRecipient ||
                      [0, 1, 2].includes(data.tollVoucherRecipient.id)
                    }
                  />
                </Col>
              </>
            ) : (
              <>
                <Col xs={6} className="form-group">
                  <div className="card-label">Recebedor do CIOT</div>
                  <div className="card-text">
                    {travelInfo.paymentCiot?.person.label ?? 'Não informado'}
                  </div>
                </Col>
                <Col xs={6} className="form-group">
                  <div className="card-label">Cartão do recebedor do CIOT</div>
                  <div className="card-text">
                    {travelInfo.paymentCiot?.card_number ?? 'Não informado'}
                  </div>
                </Col>
                <Col xs={6} className="form-group">
                  <div className="card-label">Recebedor do vale-pedágio</div>
                  <div className="card-text">{tollTicketReceiver}</div>
                </Col>
                <Col xs={6} className="form-group">
                  <div className="card-label">
                    Cartão do recebedor do vale-pedágio
                  </div>
                  <div className="card-text">{tollTickerReceiverCard}</div>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}
