import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { shippingCompanyStore } from 'services/store/ShippingCompanies';
import Details from './Details';
import Register from './Register';

export default function ShippingCompanySingle() {
  const params = useParams();
  const { setAllLoadings, fetchPerson, clearPerson } = shippingCompanyStore();
  useEffect(() => {
    const getPerson = async () => {
      try {
        setAllLoadings(true);

        await fetchPerson(params.id);
      } finally {
        setAllLoadings(false);
      }
    };
    if (params.id) {
      getPerson();
    } else {
      clearPerson();
    }
  }, [params.id]);

  return <>{params.id ? <Details /> : <Register />}</>;
}
