import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// eslint-disable-next-line import/no-cycle
import Navbar from 'react-bootstrap/Navbar';
import Menu from '~/components/Menu';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { privateRoutes } from '~/routes/map';
import { Text } from '@cargon/ui';
import * as Styled from './styles';

export default function ApplicationLayout({
  menuOnly,
  RightComponent,
  contentFluid,
  children,
  isOld = false,
  title,
  expand,
}) {
  const location = useLocation();
  const match = useRouteMatch(location.pathname);

  const breadcrumbs = privateRoutes
    .filter(({ path }) => match.path.includes(path))
    .map(({ path, ...rest }) => ({
      path: Object.keys(match.params).length
        ? Object.keys(match.params).reduce(
            (acc, param) => acc.replace(`:${param}`, match.params[param]),
            path
          )
        : path,
      ...rest,
    }))
    .sort((a, b) => a.path.length - b.path.length);

  const { length, [length - 1]: last, 1: second } = breadcrumbs;

  return (
    <>
      <Container fluid className="py-2 header">
        <Row>
          <Col md={2}>
            <Navbar.Brand href="/">
              <img src="/images/logo.svg" alt="logo cargon" />
            </Navbar.Brand>
          </Col>

          <Col md={10}>
            <Menu path={second} />
          </Col>
        </Row>
        <Styled.Header
          isOld={isOld}
          className="header-main"
          expand={expand}
          style={{ marginLeft: '-15px', marginRight: '-15px', marginTop: 16 }}
        >
          {!menuOnly && (
            <Container id="header-container">
              <Row>
                <Col lg={6} md={6} xs={4} className=" left-component">
                  <Text type="subtitle" color="white" className="text-title">
                    {title || last.title}
                  </Text>

                  <Styled.Breadcrumb listProps={{ className: 'p-0' }}>
                    {breadcrumbs.map(bread => (
                      <Styled.BreadcrumbItem
                        key={bread.path}
                        linkAs={Link}
                        linkProps={{ to: bread.path }}
                      >
                        <Text type="label" color="white">
                          {bread.title}
                        </Text>
                      </Styled.BreadcrumbItem>
                    ))}
                  </Styled.Breadcrumb>
                </Col>
                <Col lg={6} md={6} xs={8} className="right-component">
                  {RightComponent}
                </Col>
              </Row>
            </Container>
          )}
        </Styled.Header>
      </Container>
      <Container>
        <Styled.Main isOld={isOld} fluid={contentFluid} expand={expand}>
          {children}
        </Styled.Main>
      </Container>
    </>
  );
}
