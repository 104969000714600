export function removeDuplicates(array, keyGetter = item => item.id) {
  return [...new Map(array.map(item => [keyGetter(item), item])).values()];
}

export function groupBy(array, keyGetter) {
  return array.reduce((acc, item) => {
    const key = keyGetter(item);

    const holder = acc[key] || [];
    holder.push(item);

    return { ...acc, [key]: holder };
  }, {});
}

export function removeRepeatedObjects({ array, key }) {
  const uniqueIds = new Set();

  const arr = array || [];
  const unique = arr.filter(element => {
    const isDuplicate = uniqueIds.has(element[key]);

    uniqueIds.add(element.id);

    if (!isDuplicate) {
      return true;
    }
    return false;
  });
  return unique;
}

export function removeElement({ data, index }) {
  const removed = data.splice(index, 1);
  return [data, removed];
}
