import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Modal, Text, Upload, TextArea, Button, useSnackbar } from '@cargon/ui';
import {
  updateDriverAttachments,
  fetchDriverAttachments,
} from '~/services/fetch';
import { driverStore } from '~/services/store/Driver';
import { getNow } from '~/utils/formatter';

export default function AttachmentModal({ isOpen, onClose }) {
  const params = useParams();
  const snackbar = useSnackbar();

  const { currentDriver, updateCurrentDriver, errors, setErrors, clearErrors } =
    driverStore();

  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  function handleClose() {
    setFiles([]);
    setDescription('');
    clearErrors();
    onClose();
  }

  function onFilesChanged({ target }, hasError) {
    setFiles([]);
    clearErrors();

    if (hasError) {
      setErrors({ file: 'Algum arquivo selecionado é maior que 1MB.' });
      return;
    }

    setFiles(
      [...target.files].map(file => {
        return {
          file: {
            blob: file,
            size: file?.size,
            name: file?.name,
            type: file?.type,
            tempPath: URL.createObjectURL(file),
          },
          description: description || file.name,
        };
      })
    );
  }

  function addAttachment() {
    const reshapedFiles = files.map(item => {
      return {
        ...item,
        created_at: getNow(),
      };
    });

    const oldAttachments = currentDriver?.attachments
      ? currentDriver?.attachments
      : [];

    updateCurrentDriver({
      attachments: [...oldAttachments, ...reshapedFiles],
    });

    handleClose();
  }

  async function updateAttachment() {
    async function getUpdatedAttachments() {
      const newAttachments = await fetchDriverAttachments({ id: params.id });
      updateCurrentDriver({ attachments: newAttachments });
    }

    try {
      if (params.id) {
        setLoading(true);

        const attachmentsFiles = new FormData();

        files.forEach((file, index) => {
          const name = `${Date.now()}${index}.${file.file.type.split('/')[1]}`;
          const newFile = new File([file.file.blob], name, {
            type: file.file.type,
          });

          attachmentsFiles.append('file', newFile);
          attachmentsFiles.append(
            `${name}_description`,
            description || file?.file?.name
          );
        });

        await updateDriverAttachments({
          id: params.id,
          data: attachmentsFiles,
        });

        getUpdatedAttachments();

        handleClose();
      } else {
        addAttachment();
      }
    } catch (error) {
      snackbar.show(<Text>Erro ao adicionar arquivo!</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      show={isOpen}
      handleClose={() => handleClose()}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Anexos
        </Text>
      }
      body={
        <div>
          <div className="mb-3">
            <Text>Adicione um arquivo</Text>
            <Upload
              name="file"
              label="Arquivo máximo de 1MB"
              accept=".jpg,.jpeg,.png,.pdf"
              value={files}
              multiple
              onChange={onFilesChanged}
              error={errors.file}
              ignoreValidation
            />
          </div>
          <TextArea
            label="Descrição"
            resizable={false}
            value={description}
            onChange={({ target: { value } }) => setDescription(value)}
          />
          <div className="d-flex flex-column align-items-center mt-3">
            <Button
              disabled={!files.length}
              onClick={params.id ? updateAttachment : addAttachment}
              loading={loading}
            >
              <Text type="regular" weight={500}>
                Adicionar
              </Text>
            </Button>
          </div>
        </div>
      }
    />
  );
}
