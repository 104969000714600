import React from 'react';
import { Button, Text } from '@cargon/ui';
import moment from 'moment';

function formatCityProvince(obj) {
  return `${
    obj.city && obj.province
      ? `${obj.city} - ${obj.province}`
      : obj.city
      ? obj.city
      : obj.province
      ? obj.province
      : 'Sem localização'
  }`;
}

export function locationsObject(
  showAllDestinations = true,
  travel,
  handleShow
) {
  const destinationsToDisplay = showAllDestinations
    ? travel.destinations
    : travel.destinations.slice(-1);

  const items = [
    ...travel.origins.map(item => ({
      header: (
        <div className="d-flex flex-row justify-content-between">
          <Text>Origem</Text>
        </div>
      ),
      body: (
        <>
          <Text className="my-2" color="gray" type="regular">
            {formatCityProvince(item)}
          </Text>
          <Text color="gray" type="regular">
            {moment(item.scheduled_time).format('DD/MM/YYYY HH:mm')}
          </Text>
        </>
      ),
      filledCircle: false,
    })),
  ];

  if (!showAllDestinations && travel.destinations.length > 1)
    items.push({
      header: (
        <Button onClick={handleShow} link>
          <Text type="regular">{travel.destinations.length - 1} destinos</Text>
        </Button>
      ),
      link: true,
    });

  items.push(
    ...destinationsToDisplay.map(item => ({
      header: (
        <div className="d-flex flex-row justify-content-between">
          <Text>Destino</Text>
        </div>
      ),
      body: (
        <>
          <Text className="my-2" color="gray" type="regular">
            {formatCityProvince(item)}
          </Text>
          <Text color="gray" type="regular">
            {moment(item.scheduled_time).format('DD/MM/YYYY HH:mm')}
          </Text>
        </>
      ),
      filledCircle: true,
    }))
  );
  return items;
}
