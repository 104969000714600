import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Text, Button } from '@cargon/ui';

import Container from 'react-bootstrap/Container';

import Header from '~/components/Header';
import Offset from '~/components/Offset';

import InfiniteScroll from 'components/InfiniteScroll';
import Filter from './Filter';
import VehicleCard from './Card';

import * as Styled from '../shared/styles';
import { useVehiclesSWR } from './controller';

export default function VehicleList() {
  const [vehicles, setVehicles] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    lastPage: 1,
    hasMore: false,
  });
  const [params, setParams] = useState({});

  const { data: response, isLoading } = useVehiclesSWR({
    page: pagination.page,
    ...params,
  });

  const [isFilterVisible, setFilterVisible] = useState(false);

  useEffect(() => {
    setVehicles([...vehicles, ...(response?.data || [])]);
    setPagination({
      ...pagination,
      lastPage: response?.lastPage,
      hasMore: response?.page < response?.lastPage,
    });
  }, [response?.data, response?.page, response?.lastPage]);

  return (
    <>
      <Header
        title="Veículos"
        RightComponent={
          <>
            <Styled.ButtonsContainer className="pt-5">
              <Link to="/veiculos/cadastrar">
                <Button variant="secondary">
                  <Text color="black" weight={500}>
                    Novo
                  </Text>
                </Button>
              </Link>
              <Button onClick={() => setFilterVisible(!isFilterVisible)}>
                <Text weight={500} color="white">
                  {isFilterVisible ? 'Ocultar filtro' : 'Mostrar filtro'}
                </Text>
              </Button>
            </Styled.ButtonsContainer>
          </>
        }
      />
      <Offset>
        <Container>
          {isFilterVisible && (
            <Filter
              onChange={filterParams => {
                setVehicles([]);
                setParams(filterParams);
                setPagination({
                  page: 1,
                  lastPage: 1,
                  hasMore: false,
                });
              }}
            />
          )}
          <InfiniteScroll
            loading={isLoading}
            data={vehicles}
            renderItem={vehicle => <VehicleCard vehicle={vehicle} />}
            onEndReach={() => {
              setPagination({
                ...pagination,
                page: pagination.page + 1,
              });
            }}
            hasMore={pagination.page < pagination.lastPage}
            scrollThreshold={0.7}
            isInfinite
          />
        </Container>
      </Offset>
    </>
  );
}
