import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import Text from '~/components/Text';
import Card from '~/components/Card';

import { formatCurrency } from '~/utils/formatter';

import * as Controller from './controller';

import { ValuesArea, TotalValueArea, BulletsArea, Line } from './styles';

function Values({ load, origins, destinations }) {
  const [pricePerKilometer, setPricePerKilometer] = useState([]);
  const [value, setValue] = useState();
  const [total, setTotal] = useState('');

  const typeValueList = [
    { label: 'Por viagem', value: 'CARGA_FECHADA' },
    { label: 'Por tonelada', value: 'POR_TONELADA' },
  ];

  useEffect(() => {
    if (load.pricing_orders.length > 0) {
      const orders = load.pricing_orders.map(order => ({
        price: order.price,
      }));

      const max = orders.reduce(
        (prev, current) => (prev.price > current.price ? prev : current),
        { price: load.fare_company }
      );

      if (max.price > load.fare_company) {
        setValue(max.price);
      } else if (load.fare_company > max.price) {
        setValue(load.fare_company);
      }
    } else {
      setValue(load.fare_company);
    }
  }, [load]);

  useEffect(() => {
    async function calculatePricePerKilometer() {
      if (origins && destinations) {
        const [origin] = origins;
        const coordinates = [origin, ...destinations]
          .filter(place => place.lat && place.lng)
          .map(place => `${place.lat},${place.lng}`);

        const price = await Controller.calculatePricePerKilometer(
          value,
          coordinates
        );

        setPricePerKilometer(price);
      }
    }

    const timeout = setTimeout(calculatePricePerKilometer, 800);

    return () => {
      clearTimeout(timeout);
    };
  }, [load, origins, destinations, value]);

  useEffect(() => {
    let totalValue = value;

    if (load.insurance_included) {
      totalValue += load.insurance_value;
    }

    if (load.tolled_load) {
      totalValue += load.toll_value;
    }

    if (load.unload_included) {
      totalValue += load.discharge_value;
    }

    setTotal(totalValue);
  }, [load, total]);

  return (
    <Col xs={12}>
      <Card
        header={
          <Text color="dark" type="header">
            Valores
          </Text>
        }
      >
        <Row xs={12}>
          <Col lg={6} md={6} xs={12}>
            <div className="container-fluid">
              <Row>
                <Col xs={6}>
                  <Text type="label" color="dark">
                    Pedágio (R$)
                  </Text>

                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {formatCurrency(load.toll_value)}
                  </Text>
                </Col>

                <Col xs={6}>
                  <Text type="label" color="#464E5F">
                    Incluso no valor
                  </Text>

                  <Text type="label" color="gray" as="p" className="mt-2">
                    {load.tolled_load ? 'Sim' : 'Não'}
                  </Text>
                </Col>

                <Col xs={6}>
                  <Text type="label" color="dark">
                    Descarga (R$)
                  </Text>

                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {formatCurrency(load.discharge_value)}
                  </Text>
                </Col>

                <Col xs={6}>
                  <Text type="label" color="dark">
                    Incluso no valor
                  </Text>

                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {load.unload_included ? 'Sim' : 'Não'}
                  </Text>
                </Col>

                <Col xs={6}>
                  <Text type="label" color="dark">
                    Seguro (R$)
                  </Text>

                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {formatCurrency(load.insurance_value)}
                  </Text>
                </Col>

                <Col xs={6}>
                  <Text type="label" color="dark">
                    Incluso no valor
                  </Text>

                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {load.insurance_included ? 'Sim' : 'Não'}
                  </Text>
                </Col>

                <Col xs={6}>
                  <Text type="label" color="dark">
                    Valor do frete (R$)
                  </Text>

                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {value ? formatCurrency(value) : 'A combinar'}
                  </Text>
                </Col>

                <Col xs={6}>
                  <Text type="label" color="dark">
                    Tipo valor carga
                  </Text>
                  {load.type_value === typeValueList[0].value && (
                    <Text type="regular" color="gray" as="p" className="mt-2">
                      Por viagem
                    </Text>
                  )}
                  {load.type_value === typeValueList[1].value && (
                    <Text type="regular" color="gray" as="p" className="mt-2">
                      Por tonelada
                    </Text>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Row>
              <Col xs={12}>
                <ValuesArea>
                  <BulletsArea>
                    <ul>
                      <li className="mb-2">
                        <Col className="pl-0" xs={8}>
                          <Text type="regular" color="dark" weight={500}>
                            Pedágio
                            {load.tolled_load ? (
                              <Text type="label"> (Incluso no frete)</Text>
                            ) : (
                              <Text type="label"> (Não incluso no frete)</Text>
                            )}
                          </Text>
                        </Col>
                        <Col className="text-right p-0">
                          <Text
                            type="regular"
                            color={load.tolled_load ? 'success' : 'error'}
                            weight={500}
                          >
                            {formatCurrency(load.toll_value)}
                          </Text>
                        </Col>
                      </li>
                      <li className="mb-2">
                        <Col className="pl-0" xs={8}>
                          <Text type="regular" color="dark" weight={500}>
                            Descarga
                            {load.unload_included ? (
                              <Text type="label"> (Incluso no frete)</Text>
                            ) : (
                              <Text type="label"> (Não incluso no frete)</Text>
                            )}
                          </Text>
                        </Col>
                        <Col className="text-right p-0">
                          <Text
                            type="regular"
                            color={load.unload_included ? 'success' : 'error'}
                            weight={500}
                          >
                            {formatCurrency(load.discharge_value)}
                          </Text>
                        </Col>
                      </li>
                      <li className="mb-2">
                        <Col className="pl-0" xs={8}>
                          <Text type="regular" color="dark" weight={500}>
                            Seguro
                            {load.insurance_included ? (
                              <Text type="label"> (Incluso no frete)</Text>
                            ) : (
                              <Text type="label"> (Não incluso no frete)</Text>
                            )}
                          </Text>
                        </Col>
                        <Col className="text-right p-0">
                          <Text
                            type="regular"
                            color={
                              load.insurance_included ? 'success' : 'error'
                            }
                            weight={500}
                          >
                            {formatCurrency(load.insurance_value)}
                          </Text>
                        </Col>
                      </li>
                      <li className="mb-2">
                        <Col className="pl-0" xs={8}>
                          <Text type="regular" color="dark" weight={500}>
                            Frete
                          </Text>
                        </Col>
                        <Col className="text-right p-0">
                          <Text type="regular" color="success" weight={500}>
                            {formatCurrency(value)}
                          </Text>
                        </Col>
                      </li>
                    </ul>
                  </BulletsArea>
                  <Line />
                  <TotalValueArea>
                    <Col xs={8}>
                      <Text color="#464E5F" type="header" weight={500}>
                        Total{' '}
                        <Text color="#464E5F" type="regular" weight={500}>
                          (da prestação de serviços)
                        </Text>
                      </Text>
                    </Col>
                    <Col className="text-right">
                      <Text color="success" type="header" weight={500}>
                        {value ? formatCurrency(total) : 'A combinar'}
                      </Text>
                    </Col>
                  </TotalValueArea>
                  {load.type_value === 'CARGA_FECHADA' && pricePerKilometer && (
                    <Text type="regular" color="dark">
                      {pricePerKilometer}
                    </Text>
                  )}
                </ValuesArea>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default Values;
