import { Input, Radio, Text } from '@cargon/ui';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { driverStore } from '~/services/store/Driver';

export default function UserData() {
  const { currentDriver, updateCurrentDriver, errors } = driverStore();

  return (
    <>
      <Row className="form">
        <Col md={6} xs={12}>
          <Input
            label="Senha"
            value={currentDriver?.password}
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ password: value })
            }
            error={errors?.password}
            type="password"
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            label="Confirmar senha"
            value={currentDriver?.verifyPassword}
            type="password"
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ verifyPassword: value })
            }
            error={errors.verifyPassword}
          />
        </Col>
        <Col xs={12}>
          <Radio.Group
            label="Verificado"
            onChange={({ target: { value } }) =>
              updateCurrentDriver({ verified: value })
            }
            value={currentDriver?.verified}
            horizontal
          >
            <Radio value>
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio value={false}>
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
          {errors?.verified && (
            <div>
              <Text color="error" type="label">
                {errors.verified}
              </Text>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}
