import { Badge, Button, DropdownButton, Text, useSnackbar } from '@cargon/ui';
import { Col, Row } from 'react-bootstrap';
import { formatCurrency, formatDateTime } from '~/utils/formatter';
import React, { useMemo, useState } from 'react';
import useModal from '~/hooks/useModal';

import {
  CTE_STATUS_VARIANTS,
  downloadCtePdf,
  downloadCteXml,
  getFirstValueByTagName,
  getImagePath,
  isPDF,
  onClickPreview,
} from '~/pages/Travels/Documents/helpers';
import CteEdit from '~/pages/Travels/Documents/Modals/CteEdit';
import UploadModal from '~/pages/Travels/Documents/Upload';
import Lightbox from 'react-image-lightbox';
import { DocumentPreview } from '~/pages/Travels/Documents/DocumentPreview';

export const Emitted = ({
  emitted,
  lightbox,
  documentsController,
  fetchDocuments,
}) => {
  const modal = useModal();
  const modalCte = useModal();
  const snackbar = useSnackbar();
  const [isLoading, setLoading] = useState(false);

  const [downloading, setDownloading] = useState(false);

  const attachments =
    documentsController?.documents[emitted.id]?.filter(
      document => document.attachments.length > 0
    ) || [];

  const attachmentsWithoutPDF = useMemo(() => {
    return attachments.filter(item => !isPDF(item.doc_filename));
  }, [attachments]);

  const ctes = attachments.filter(document => document.type_obs === 'cte');

  const nfs = attachments.filter(document => document.type_obs === 'canhoto');

  const others = attachments.filter(
    document => document.type_obs === 'outros' || document.type_obs === 'others'
  );

  const dlEmittedXML = async () => {
    setDownloading(true);
    downloadCteXml(emitted.id, emitted.cte_number, snackbar).then(() =>
      setDownloading(false)
    );
  };
  const dlEmittedPDF = async () => {
    setDownloading(true);
    downloadCtePdf(emitted.id, emitted.cte_number, snackbar).then(() =>
      setDownloading(false)
    );
  };

  return (
    <div key={emitted.id} className="mb-3">
      <UploadModal
        isOpen={modal.isOpen}
        onClose={modal.close}
        emittedId={emitted.id}
        fetchDocuments={fetchDocuments}
      />
      <CteEdit
        isOpen={modalCte.isOpen}
        onClose={modalCte.close}
        emittedId={emitted.id}
      />
      <Text as="div" color="dark" type="body" weight={500} className="mb-4">
        CT-e {emitted.cte_number}
        {emitted.emissionStatus && (
          <Badge
            pill
            light
            size="sm"
            className="ml-2"
            variant={CTE_STATUS_VARIANTS[emitted.emission_status_id]}
          >
            {emitted.emissionStatus?.name}
          </Badge>
        )}
        <Button
          variant="secondary"
          disabled={emitted.issuer_id}
          onClick={modalCte.open}
          className="ml-2"
        >
          <Text type="regular" weight="500">
            Editar
          </Text>
        </Button>
      </Text>

      <Row className="d-flex justify-content-between">
        <Col xs={2}>
          <Text as="div" color="dark" type="regular" className="mb-3">
            Data de emissão
          </Text>
          <Text color="gray" type="body">
            {emitted.date_emitted
              ? formatDateTime(emitted.date_emitted)
              : formatDateTime(emitted.created_at)}
          </Text>
        </Col>
        <Col xs={2}>
          <Text as="div" color="dark" type="regular" className="mb-3">
            Nº das notas fiscais
          </Text>
          <Text color="gray" type="body">
            {emitted.nfeEmitted.map(nf => nf.nfe.nf_number).join(', ')}
          </Text>
        </Col>
        <Col xs={2}>
          <Text as="div" color="dark" type="regular" className="mb-3">
            Valor
          </Text>
          <Text color="gray" type="body">
            {formatCurrency(
              emitted.nfeEmitted
                .filter(nf => emitted.id === nf?.nfe?.emitted_id)
                .reduce((acc, curr) => {
                  if (curr.nfe?.nf_Value) {
                    return curr.nfe?.nf_Value + acc;
                  }
                  return acc + 0;
                }, 0) || getFirstValueByTagName(emitted.xml, 'vCarga'),
              'BRL',
              'R$'
            )}
          </Text>
        </Col>
        <Col xs={4} className="d-flex justify-content-end">
          <Button
            style={{ height: '44px' }}
            className=" py-2"
            loading={isLoading}
            onClick={() => fetchDocuments(emitted.id, { setLoading })}
          >
            <Text type="regular" weight="600">
              {documentsController.activeIndex === emitted.id
                ? 'Fechar'
                : 'Anexos'}
            </Text>
          </Button>
          <DropdownButton
            style={{ display: 'inline', marginLeft: 10 }}
            title="Opções"
            variant="secondary"
            className="ml-2 py-2"
            loading={downloading}
            options={[
              {
                title: 'Adicionar',
                onClick: modal.open,
                type: 'item',
              },
              {
                title: 'Baixar XML',
                onClick: () => dlEmittedXML(),
                type: 'item',
              },
            ]}
          />
        </Col>
      </Row>
      {documentsController.activeIndex === emitted.id && (
        <div>
          {lightbox.isOpen && (
            <Lightbox
              mainSrc={getImagePath(lightbox.index, lightbox, true)}
              nextSrc={getImagePath(lightbox.indexes.next, lightbox)}
              prevSrc={getImagePath(lightbox.indexes.previous, lightbox)}
              onCloseRequest={() => lightbox.close()}
              onMovePrevRequest={() =>
                lightbox.setIndex(lightbox.indexes.previous)
              }
              onMoveNextRequest={() => lightbox.setIndex(lightbox.indexes.next)}
              imageTitle={lightbox.title}
            />
          )}
          <DocumentPreview
            title="CT-e"
            documents={ctes}
            onClickPreview={id =>
              onClickPreview(id, attachmentsWithoutPDF, lightbox)
            }
          />
          <DocumentPreview
            title="Canhoto"
            documents={nfs}
            onClickPreview={id =>
              onClickPreview(id, attachmentsWithoutPDF, lightbox)
            }
          />
          <DocumentPreview
            title="Outros"
            documents={others}
            onClickPreview={id =>
              onClickPreview(id, attachmentsWithoutPDF, lightbox)
            }
          />
        </div>
      )}
    </div>
  );
};
