import React from 'react';

import { Link } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Badge as BadgeComponent, Text } from '@cargon/ui';
import styled from 'styled-components';

export const BadgeTag = styled(BadgeComponent)`
  padding: 5px 17px !important;
  background-color: #e3e4e7 !important;
  border-color: #e3e4e7 !important;
`;

export const Badge = styled(BadgeComponent)`
  padding: 5px 17px !important;
`;

const TractionData = ({ vehicle }) => {
  return (
    <>
      <div>
        <Text type="regular" color="dark">
          {vehicle.vehicleType?.name} {vehicle.axisType?.name}
        </Text>
      </div>{' '}
    </>
  );
};
const WagonData = ({ vehicle }) => {
  return (
    <>
      <div>
        <Text type="regular" color="dark">
          {vehicle?.implementType?.name}
        </Text>
      </div>
      <div>
        <Text type="regular" color="dark">
          {vehicle?.vehicleBodyType?.name}
        </Text>
      </div>
    </>
  );
};

function handleVehicleInfo(vehicle) {
  let info = '';
  if (vehicle.vehicleType) info = vehicle.vehicleType.name;
  if (vehicle.axisType) info += ` | ${vehicle.axisType.name}`;
  if (vehicle.implementType) info += ` | ${vehicle.implementType.name}`;

  return info;
}

export default function VehileCard({ vehicle }) {
  return (
    <Col lg={6} md={6} xs={12} className="p-2">
      <Card className="card-list">
        <Card.Body>
          <Row className="p-3">
            <Col xs="8">
              <span className="card-title">
                <i className="fas fa-truck mr-2" />
                {vehicle.plate === null ? 'não encontrada' : vehicle.plate}
              </span>
              <br />
              <Badge pill light type="primary" className="mr-2">
                <Text type="label">
                  {vehicle.is_wagon ? 'Implemento' : 'Tração'}
                </Text>
              </Badge>
            </Col>

            <Col xs="4" className="text-right">
              <Button
                variant="primary"
                as={Link}
                to={`/veiculos/${vehicle.id}`}
                size="sm"
              >
                Ver detalhes
              </Button>
            </Col>
            <Col xs={12}>
              <hr className="mt-2 mb-3" />
              {vehicle.is_wagon ? (
                <WagonData vehicle={vehicle} />
              ) : (
                <TractionData vehicle={vehicle} />
              )}
            </Col>
            {!vehicle.is_wagon && (
              <Col xs={12}>
                <span className="card-label">Motorista:</span>
                <span className="card-text">
                  {vehicle.lastDriver_socialName === null
                    ? 'Sem motorista cadastrado'
                    : vehicle.lastDriver_socialName}
                </span>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}
