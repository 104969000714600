import { Col, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { Button, Modal, Text, useSnackbar } from '@cargon/ui';
import entityApi from '../../../../../../services/api/entity';
import { useVehicle } from '../../../shared/context';

const TractionFields = () => {
  return (
    <ul>
      <li>Tipo do veículo</li>
      <li>Tração</li>
      <li>Cronotacógrafo</li>
      <li>Validade cronotacógrafo</li>
      <li>Combustível</li>
      <li>Motoristas</li>
      <li>Pedágios</li>
    </ul>
  );
};
const WagonFields = () => {
  return (
    <ul>
      <li>Tipo de carroceria</li>
      <li>Tipo de implemento</li>
    </ul>
  );
};
export default function ConvertVehicleModal({ modal }) {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const vehicle = useVehicle();

  async function handleConvertVehicle() {
    try {
      setLoading(true);
      await entityApi.patch(`vehicle/${vehicle.data.id}/convert-vehicle`);
      snackbar.show('Veículo convertido com sucesso', { type: 'success' });
      window.location.reload();
      modal.close();
    } catch (e) {
      snackbar.show('Erro ao converter veículo', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      size="md"
      backdrop="static"
      closeButton={false}
      show={modal.isOpen}
      handleClose={() => modal.close()}
      heading={`Converter para ${
        vehicle.data.is_wagon ? 'tração' : 'implemento'
      }`}
      body={
        <Row>
          <Col xs={12}>
            <Text>
              Caso este veículo seja convertido para{' '}
              {vehicle.data.is_wagon ? 'tração' : 'implemento'} os seguintes
              campos serão resetados:{' '}
            </Text>
            <br />
          </Col>
          {vehicle.data.is_wagon ? <WagonFields /> : <TractionFields />}
        </Row>
      }
      footer={
        <>
          <Button variant="error" onClick={() => modal.close()}>
            <Text type="regular" color="white">
              Cancelar
            </Text>
          </Button>
          <Button
            variant="success"
            onClick={() => handleConvertVehicle()}
            loading={loading}
          >
            <Text type="regular" color="white">
              Prosseguir
            </Text>
          </Button>
        </>
      }
    />
  );
}
