import api from '~/services/api';
import { formatDate } from '~/utils/formatter';

export async function fetchShippingCompany({ id }) {
  const response = await api.get(`carrier/shipping-company/${id}`);
  const person = response.data;
  return {
    ...response.data,
    rntrc_exp_date: formatDate(person?.rntrc_exp_date, {
      isUTC: true,
    }),
    birthdate: formatDate(person?.birthdate, {
      isUTC: true,
    }),
    pis: person.pispasep,
    fathers_name: person?.father_name,
  };
}
