import styled, { css } from 'styled-components';
import {
  Nav as BootstrapNav,
  NavDropdown as BootstrapNavDropdown,
} from 'react-bootstrap';
import theme from '../../styles/theme';

export const NotificationUnreadIcon = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: ${theme.colors.error};
  position: absolute;
  top: 10px;
  left: 25px;
`;

export const NavLink = styled(BootstrapNav.Link)`
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 5px;
  color: ${theme.colors.white} !important;
  padding: 0.5rem 1rem;
  margin-left: 5px;
  margin-right: 5px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    color: ${theme.colors.white};
    text-decoration: none;
  }

  @media (max-width: 768px) {
    padding: 1rem 1rem !important;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: rgba(255, 255, 255, 0.15);
    `}
`;

export const NavDropdown = styled(BootstrapNavDropdown)`
  margin-left: 5px;
  margin-right: 5px;

  > a {
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 5px;
    color: ${theme.colors.white} !important;
    transition: background ease-in 0.3s;

    ${({ active }) =>
      active &&
      css`
        background-color: rgba(255, 255, 255, 0.15);
      `}
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
      color: ${theme.colors.white};
    }

    @media (max-width: 768px) {
      padding: 1rem !important;
    }
  }

  .dropdown-menu {
    padding: 0;
    max-height: calc(100vh - 40px - 40px);
    overflow-y: auto;

    > a {
      text-transform: uppercase;
      font-size: 13px;
      padding: 0.5rem 1rem;

      &:hover {
        border-radius: 5px;
      }
    }
  }
`;
