import { subDays, isBefore } from 'date-fns';
import moment from 'moment';

export function todayIsBeforeDate({ date, subtractDays = 0 }) {
  const subDate = subDays(date, subtractDays);

  return isBefore(new Date(), subDate);
}

export function validateDateField({ date }) {
  const clearDate = date?.replaceAll('_', '');
  return clearDate?.length >= 10;
}

export function formatDateCustom({ date, formatFrom, formatTo }) {
  if (!validateDateField({ date })) {
    return null;
  }
  return date ? moment(date, formatFrom).format(formatTo) : null;
}
