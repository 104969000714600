import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card, DatePicker, Input, Select } from '@cargon/ui';
import produce from 'immer';
import Button from '~/components/Button';
import moment from 'moment';

function Filter({ getTravels }) {
  const [filter, setFilter] = useState({});

  async function handleFilter() {
    getTravels({
      ...filter,
      end_date: filter.end_date
        ? moment(filter.end_date, 'DD/MM/YYYY').format('MM/DD/YYYY')
        : undefined,
      start_date: filter.start_date
        ? moment(filter.start_date, 'DD/MM/YYYY').format('MM/DD/YYYY')
        : undefined,
    });
  }

  const travelCategory = [
    {
      name: 'Todas',
      id: 'ALL',
    },
    {
      name: 'Aceitas',
      id: 'ACCEPTED',
    },
    {
      name: 'Em oferta',
      id: 'AUCTION',
    },
    {
      name: 'Não aceitas',
      id: 'NOT_ACCEPTED',
    },
    {
      name: 'Canceladas',
      id: 'CANCELED',
    },
  ];

  return (
    <Card className=" mb-3">
      <Row>
        <Col xs={12}>
          <h5 className="card-title">Filtro</h5>
          <hr className="my-3" />
        </Col>
        <Col lg={4} md={4} xs={12}>
          <Input
            label="Número da viagem"
            value={filter.travel_number}
            onChange={({ target: { value } }) =>
              setFilter(
                produce(draft => {
                  draft.travel_number = value;
                })
              )
            }
          />
        </Col>

        <Col lg={4} md={4} xs={12}>
          <Input
            value={filter.nfs_number}
            onChange={({ target: { value } }) =>
              setFilter(
                produce(draft => {
                  draft.nfs_number = value;
                })
              )
            }
            label="Nº Nota Fiscal"
            maxLength={44}
          />
        </Col>

        <Col lg={4} md={4} xs={10}>
          <Select
            label="Situação"
            options={travelCategory}
            value={travelCategory.find(opt => opt.id === filter.category)}
            getOptionValue={value => value.id}
            getOptionLabel={value => value.name}
            onChange={value =>
              setFilter(
                produce(draft => {
                  draft.category = value?.id;
                })
              )
            }
          />
        </Col>

        <Col md={6} xs={12}>
          <DatePicker
            label="Período de"
            value={filter.start_date}
            onChange={e =>
              setFilter(
                produce(draft => {
                  draft.start_date = e.target.value;
                })
              )
            }
          />
        </Col>
        <Col md={6} xs={12}>
          <DatePicker
            containerStyle={{ width: 'unset' }}
            label="Até"
            value={filter.end_date}
            onChange={e =>
              setFilter(
                produce(draft => {
                  draft.end_date = e.target.value;
                })
              )
            }
          />
        </Col>

        <Col lg={8} md={8} xs={2} className="text-right py-4 mt-1">
          <Button
            type="submit"
            variant="success"
            onClick={() => handleFilter()}
          >
            Filtrar
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

export default Filter;
