import styled from 'styled-components';

export const ImageRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  > * {
    margin-bottom: 15px;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
`;
