import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PrivateRoute({ component: Component, ...props }) {
  const isAuthenticated = useSelector(store => store.auth.authenticated);

  if (!Component) return null;

  return (
    <Route
      {...props}
      render={routeRenderProps =>
        isAuthenticated ? (
          <Component {...routeRenderProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeRenderProps.location },
            }}
          />
        )
      }
    />
  );
}
