import { useEffect } from 'react';

export default function useWindowResize(fn, deps = []) {
  useEffect(() => {
    function handleResize() {
      fn();
    }

    window.addEventListener('resize', handleResize);

    fn();

    return () => window.removeEventListener('resize', handleResize);
  }, deps);
}
