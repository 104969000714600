import { useCallback, useMemo, useState } from 'react';
import { formatDateTime } from '~/utils/formatter';
import StringBuilder from '~/utils/StringBuilder';

export default function useLightBox() {
  const [isOpen, setIsOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [index, setIndex] = useState(0);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  function getDocumentMetadata() {
    const map = {
      cte: 'CT-e',
      canhoto: 'Canhoto',
    };

    const document = documents[index];
    const type = map[document?.type_obs?.toLowerCase()] || 'Outros';
    const createdAt = formatDateTime(document?.created_at);

    return { type, createdAt };
  }

  const title = useMemo(() => {
    const builder = new StringBuilder(' ');

    const documentMetadata = getDocumentMetadata();

    builder
      .append(`${index + 1}/${documents.length}`)
      .append('|')
      .append(documentMetadata.type)
      .append(`(${documentMetadata.createdAt})`);

    return builder.build();
  }, [index, documents]);
  const indexes = useMemo(() => {
    const next = (index + 1) % documents.length;
    const previous = (index + documents.length - 1) % documents.length;
    return { next, previous };
  }, [index, documents]);
  return {
    isOpen,
    open,
    close,
    documents,
    setDocuments,
    index,
    setIndex,
    title,
    indexes,
  };
}
