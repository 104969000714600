import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaTrash, FaPencilAlt } from 'react-icons/fa';

import { Text, Button } from '@cargon/ui';
import { CteText } from '~/pages/Travels/Documents/Modals/Cte/styles';
import { formatDateTime } from '~/pages/Travels/Documents/Modals/Cte/formatter';

function ListCtes({ cteList, handleDelete, handleEdit }) {
  const hasCtes = useMemo(() => cteList.length > 0, [cteList]);

  return (
    <>
      {hasCtes && (
        <Col xs={12} className="pt-4">
          <Text>Adicionados a viagem</Text>
        </Col>
      )}
      <Col xs={12}>
        {cteList.map(cte => (
          <>
            <Row className="mt-3">
              <Col>
                <CteText weigth={600}>CT-e {cte?.cteNumber}</CteText>
              </Col>
              <Col className="d-flex justify-content-end align-items-center mb-3">
                {handleEdit && (
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => handleEdit(cte)}
                  >
                    <FaPencilAlt />
                  </Button>
                )}
                {handleDelete && (
                  <Button
                    variant="error"
                    size="sm"
                    className="mx-1"
                    onClick={() => handleDelete(cte)}
                  >
                    <FaTrash />
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <CteText type="label" color="gray">
                  Emitido em {formatDateTime(cte.issuedAt)}
                </CteText>
                <CteText type="label" color="gray">
                  Valor à receber{' '}
                  {cte.amountReceivable.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </CteText>
                {cte.invoices?.map(invoice => (
                  <>
                    <CteText color="gray" type="label" className="mt-2">
                      Nota Fiscal {invoice.label}
                    </CteText>
                    {invoice.key && (
                      <CteText color="gray" type="label">
                        Chave {invoice.key}
                      </CteText>
                    )}
                  </>
                ))}
              </Col>
            </Row>
          </>
        ))}
      </Col>
    </>
  );
}

export { ListCtes };
