import React, { useMemo, useState } from 'react';

import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { driverStore } from '~/services/store/Driver';

import { Button, Modal, Text, useSnackbar } from '@cargon/ui';
import { shippingCompaniesReshaper } from '../shared/reshaper';
import { attachShippingCompanies } from '../../../../services/fetch';
import AttachShippingCompany from '../shared/Cards/AttachShippingCompany';
import useCurrentUser from '../../../../hooks/useCurrentUser';

function ActiveDriver({ driver, setDriver, isOpen, onClose }) {
  const history = useHistory();
  const snackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [hasAttachedShippingCompanies, setHasAttachedShippingCompanies] =
    useState(false);
  const { clearCurrentDriver, clearPrevDriver, currentDriver } = driverStore();
  const user = useCurrentUser();

  async function handleSubmit() {
    try {
      setIsLoading(true);
      const data = shippingCompaniesReshaper({
        data: currentDriver?.shippingCompaniesAttachedTo,
      });
      await attachShippingCompanies({ id: driver.id, data });
      setHasAttachedShippingCompanies(true);
    } catch (err) {
      snackbar.show(
        <Text>
          Não foi possível carregar as transportadoras vinculadas ao motorista.
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      closeButton={false}
      backdrop="static"
      show={isOpen}
      onHide={() => {
        onClose();
        setHasAttachedShippingCompanies(false);
      }}
      size="lg"
      heading={
        <Text type="subheader" color="dark">
          Motorista já existe
        </Text>
      }
      body={
        <Row className="mx-0 mt-1">
          {/* eslint-disable-next-line no-nested-ternary */}
          {driver?.shippingCompaniesAttachedTo?.length !== 0 ? (
            <Text weight="400" color="dark">
              Motorista {driver?.social_name} já foi previamente cadastrado na
              plataforma!
            </Text>
          ) : hasAttachedShippingCompanies ? (
            <Text weight="400" color="dark">
              Solicitação realizada com sucesso! Aguarde a confirmação da
              mudança no seu email!
            </Text>
          ) : (
            <>
              <Text weight="400" color="dark" className="pb-2">
                O CPF indicado já possui cadastro na plataforma!
                <br /> Deseja solicitar à <strong>
                  {user?.company?.name}
                </strong>{' '}
                que este usuário seja vinculado às suas transportadoras?
              </Text>

              <AttachShippingCompany />
            </>
          )}
        </Row>
      }
      footer={
        <Row xs={12} className=" mt-1">
          <Button
            variant="success"
            className="mr-2 mt-1"
            size="sm"
            onClick={() => {
              clearCurrentDriver();
              clearPrevDriver();
              window.location.reload(false);
            }}
          >
            <Text weight="500">Cadastrar outro motorista</Text>
          </Button>
          {driver?.shippingCompaniesAttachedTo?.length !== 0 ? (
            <Button
              className="mr-2 mt-1 py-2"
              variant="primary"
              size="sm"
              onClick={() => history.push(`/motoristas/${driver?.id}`)}
            >
              <Text weight="500">Ver motorista</Text>
            </Button>
          ) : (
            !hasAttachedShippingCompanies && (
              <Button
                className="mr-2 mt-1 py-2"
                variant="primary"
                size="sm"
                loading={isLoading}
                onClick={() => handleSubmit()}
              >
                <Text weight="500">Solicitar vínculo</Text>
              </Button>
            )
          )}
        </Row>
      }
    />
  );
}
function BlockedDriver({ driver, setDriver, isOpen, onClose }) {
  const { clearCurrentDriver, clearPrevDriver } = driverStore();

  return (
    <Modal
      closeButton={false}
      backdrop="static"
      show={isOpen}
      onHide={() => {
        onClose();
      }}
      size="lg"
      heading={
        <Text type="subheader" color="dark">
          O CPF indicado pertence a um usuário bloqueado!
        </Text>
      }
      body={
        <Row className="mx-0 mt-1">
          <Text weight="400" color="dark" className="pb-2">
            <Text weight="400" color="dark">
              O Motorista {driver?.social_name} foi bloqueado! Para mais
              informações entre em contato com o suporte.
            </Text>
          </Text>
        </Row>
      }
      footer={
        <Row xs={12} className=" mt-1">
          <Button
            variant="success"
            className="mr-2 mt-1"
            size="sm"
            onClick={() => {
              clearCurrentDriver();
              clearPrevDriver();
              window.location.reload(false);
            }}
          >
            <Text weight="500">Cadastrar outro motorista</Text>
          </Button>
        </Row>
      }
    />
  );
}

export default function DriverAlreadyRegisteredModal(props) {
  const status = useMemo(() => {
    return props.driver?.companyPersonStatus?.[0]?.status;
  }, [props.driver]);
  return status?.slug === 'BLOCKED' ? (
    <BlockedDriver {...props} />
  ) : (
    <ActiveDriver {...props} />
  );
}
