import React from 'react';

import { Card, Text } from '@cargon/ui';

export default function Section({
  Title,
  HeaderRightComponent,
  children,
  loading,
}) {
  return (
    <Card
      loading={loading}
      header={
        <Text type="header" color="dark">
          {Title}
        </Text>
      }
      HeaderRightComponent={HeaderRightComponent}
    >
      {children}
    </Card>
  );
}
