import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { Badge, Input, Radio, Select, Text, useSnackbar } from '@cargon/ui';
import moment from 'moment';
import {
  fetchAnttTypes,
  fetchBodyTypes,
  fetchBrands,
  fetchCities,
  fetchCountries,
  fetchFuels,
  fetchImplementTypes,
  fetchModelsV3,
  fetchOwners,
  fetchSelectOptions,
  fetchTopTrackerTypes,
  fetchTrackersApp,
  fetchTrackerTypes,
  fetchVehicleAxe,
  fetchVehicleTypes,
} from '~/services/fetch';

import api from '~/services/api';
import useModal from '~/hooks/useModal';
import { VehicleAlreadyRegisteredModal } from '~/pages/Vehicle/Single/Register/alreadyRegisteredModal';
import { useVehicle } from '../context';
import { colors, qtyAxis } from '../controller';

export default function MainInputs() {
  const vehicle = useVehicle();
  const [vehicles, setVehicles] = useState({});
  const [axisOptions, setAxisOptions] = useState({});
  const [allAxisOptions, setAllAxisOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [fuels, setFuels] = useState([]);
  const [anttTypes, setAnttTypes] = useState([]);
  const [trackerTypes, setTrackerTypes] = useState({});
  const [bodyTypes, setBodyTypes] = useState({});
  const [implementTypes, setImplementTypes] = useState({});
  const [trackers, setTrackers] = useState([]);
  const [uniqueAxis, setUniqueAxis] = useState(false);
  const [modelOptions, setModelOptions] = useState([]);
  const snackbar = useSnackbar();
  const params = useParams();

  const [rntrcExpDate, setRntrcExpDate] = useState(null);
  const [chronotachographExpDate, setChronotachographExpDate] = useState(null);

  useEffect(() => {
    if (vehicle.data?.rntrc_exp_date) {
      setRntrcExpDate(
        moment(vehicle.data?.rntrc_exp_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      );
    }
    if (vehicle.data?.chronotachograph_exp_date) {
      setChronotachographExpDate(
        moment(vehicle.data?.chronotachograph_exp_date, 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        )
      );
    }
  }, []);

  useEffect(() => {
    vehicle.setCurrentVehicle({ rntrc_exp_date: rntrcExpDate });
  }, [rntrcExpDate]);

  useEffect(() => {
    vehicle.setCurrentVehicle({
      chronotachograph_exp_date: chronotachographExpDate,
    });
  }, [chronotachographExpDate]);

  async function executeFetch() {
    const fetchedTrackers = await fetchTrackersApp();
    setTrackers(fetchedTrackers);
    const fetchedAnttTypes = await fetchAnttTypes();
    setAnttTypes(fetchedAnttTypes);
    const fetchedFuels = await fetchFuels();
    setFuels(fetchedFuels);
    const fetchedVehicleTypes = await fetchVehicleTypes();
    setVehicles(fetchedVehicleTypes);
    const fetchedCountries = await fetchCountries();
    setCountries(fetchedCountries);
    const fetchedImplementTypes = await fetchImplementTypes();
    setImplementTypes(fetchedImplementTypes);
    const fetchedBodyTypes = await fetchBodyTypes();
    setBodyTypes(fetchedBodyTypes);
  }

  useEffect(() => {
    if (!params.id) {
      vehicle.setCurrentVehicle({
        is_wagon: false,
        city: null,
        type: null,
        axis: null,
        plate: null,
        renavam: null,
        antt: null,
        chassi: null,
        brand: null,
        model: null,
        year: null,
        color: null,
        fuel: null,
        tracker: null,
        tags: null,
        province: null,
        anttType: null,
        tracker_type: null,
        isTracked: false,
        crlv: null,
        rntrc_exp_date: null,
        chronotachograph_number: null,
        chronotachograph_exp_date: null,
        owner: null,
        implement: null,
        body: null,
        anttOwner: null,
      });
    }

    executeFetch();
  }, []);

  useEffect(() => {
    if (!vehicle.data.isTracked)
      vehicle.setCurrentVehicle({
        tracker: null,
        tracker_type: null,
        tracker_code: null,
      });
    else if (!vehicle.data.tracker)
      vehicle.setCurrentVehicle({ tracker_type: null });
  }, [vehicle.data.isTracked, vehicle.data.tracker]);

  async function getTrackerTypes() {
    const fetchedTrackerTypes = await fetchTopTrackerTypes();
    setTrackerTypes(fetchedTrackerTypes);
  }

  useEffect(() => {
    if (vehicle.data?.tracker) {
      getTrackerTypes();
    }
  }, [vehicle.data.tracker]);

  useEffect(() => {
    async function reshapeVehicleAxe() {
      try {
        const response = await fetchVehicleAxe();
        setAllAxisOptions(response);
        setAxisOptions(response);
        if (vehicle.data.type?.id === 18 || !vehicle.data.type?.id) {
          setUniqueAxis(false);
        } else if (vehicle.data.type.id === 1) {
          if (vehicle.data.axis !== null && vehicle.data.axis?.id <= 3) {
            setUniqueAxis(false);
            vehicle.setCurrentVehicle({ axis: null });
          }
          setAxisOptions(response.filter(axe => axe.id > 3));
        } else if (vehicle.data.type.id === 4) {
          if (
            vehicle.data.axis !== null &&
            (vehicle.data.axis?.id === 1 || vehicle.data.axis.id > 3)
          ) {
            setUniqueAxis(false);
            vehicle.setCurrentVehicle({ axis: null });
          }
          setAxisOptions(response.filter(axe => axe.id > 1 && axe.id < 4));
        } else if (
          vehicle.data.type.id === 3 ||
          vehicle.data.type.id === 5 ||
          vehicle.data.type.id === 11 ||
          vehicle.data.type.id === 12
        ) {
          setUniqueAxis(true);
          vehicle.setCurrentVehicle({ axis: response[0] });
          setAxisOptions([response[0]]);
        }
      } catch (error) {
        // handle exception
      }
    }

    if (!allAxisOptions[0]) {
      reshapeVehicleAxe();
    } else if (vehicle.data.type?.id === 18 || !vehicle.data.type?.id) {
      setUniqueAxis(false);
      setAxisOptions(allAxisOptions);
    } else if (vehicle.data.type.id === 1) {
      if (vehicle.data.axis !== null && vehicle.data.axis?.id <= 3) {
        setUniqueAxis(false);
        vehicle.setCurrentVehicle({ axis: null });
      }
      setAxisOptions(allAxisOptions?.filter(axe => axe.id > 3));
    } else if (vehicle.data.type.id === 4) {
      if (
        vehicle.data.axis !== null &&
        (vehicle.data.axis?.id === 1 || vehicle.data.axis?.id > 3)
      ) {
        setUniqueAxis(false);
        vehicle.setCurrentVehicle({ axis: null });
      }
      setAxisOptions(allAxisOptions?.filter(axe => axe.id > 1 && axe.id < 4));
    } else if (
      vehicle.data.type.id === 3 ||
      vehicle.data.type.id === 5 ||
      vehicle.data.type.id === 11 ||
      vehicle.data.type.id === 12
    ) {
      setUniqueAxis(true);
      vehicle.setCurrentVehicle({ axis: allAxisOptions[0] });
      setAxisOptions([allAxisOptions[0]]);
    }
  }, [vehicle.data.type]);

  useEffect(() => {
    if (!params.id)
      vehicle.setCurrentVehicle({
        country: countries.find(value => value.id === 1),
      });
  }, [countries]);

  async function handleChangeBrand(value) {
    try {
      if (value?.id) {
        const response = await fetchModelsV3(value.id);

        setModelOptions(response);
      } else {
        setModelOptions([]);
      }
    } catch (error) {
      if (value?.id)
        snackbar.show(<Text>Erro ao buscar modelos</Text>, { type: 'error' });
    }

    vehicle.setCurrentVehicle({ brand: value, model: null });
  }

  const [modalVehicle, setModalVehicle] = useState();
  const modal = useModal();
  const checkPlate = async plate => {
    if (plate.length === 7 && !params.id) {
      try {
        const response = await api.get('carrier/vehicle/byPlateAndOwner', {
          params: {
            plate,
          },
        });
        if (response.data.vehicle) {
          if (response.data.vehicle.blocked?.length) {
            setModalVehicle(response.data.vehicle);
            modal.open();
          }
          const vehicleData = response.data.vehicle;
          setModalVehicle(vehicleData);
          modal.open();
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Row className="form">
      <Col md={6} xs={12}>
        <Select
          label={params.id ? 'País *' : 'País'}
          options={countries}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={vehicle.data.country}
          onChange={value => vehicle.setCurrentVehicle({ country: value })}
          error={vehicle.errors?.country}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label="Cidade *"
          onSearch={fetchCities}
          value={vehicle.data.city}
          getOptionLabel={option => `${option.name}, ${option.province.uf}`}
          getOptionValue={option => option.id}
          onChange={value =>
            vehicle.setCurrentVehicle({
              city: value,
              province: value?.province,
            })
          }
          error={vehicle.errors?.city}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="Placa *"
          value={vehicle.data.plate}
          onChange={event => {
            event.target.value.length > 7
              ? snackbar.show(<Text>Limite de dígitos para placa</Text>, {
                  type: 'error',
                })
              : vehicle.setCurrentVehicle({
                  plate: event.target.value.toUpperCase(),
                });
            if (event.target.value.length === 7) {
              checkPlate(event.target.value);
            }
          }}
          error={vehicle.errors?.plate}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="Renavam *"
          type="number"
          value={vehicle.data.renavam}
          onChange={event =>
            event.target.value.length > 11
              ? snackbar.show(<Text> Limite de dígitos para Renavam </Text>, {
                  type: 'error',
                })
              : vehicle.setCurrentVehicle({ renavam: event.target.value })
          }
          error={vehicle.errors?.renavam}
        />
      </Col>
      <VehicleAlreadyRegisteredModal
        vehicleData={modalVehicle}
        setVehicleData={setModalVehicle}
        onClose={modal.close}
        isOpen={modal.isOpen}
      />
      {vehicle.data.is_wagon ? (
        <>
          <Col md={6} xs={12}>
            <Select
              label={params.id ? 'Tipo de carroceria *' : 'Tipo de carroceria'}
              options={bodyTypes}
              disableClear
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={vehicle.data?.body}
              onChange={option =>
                vehicle.setCurrentVehicle({
                  body: option || undefined,
                })
              }
              error={vehicle.errors?.body}
            />
          </Col>
          <Col md={6} xs={12}>
            <Select
              label="Quantidade de eixos*"
              options={qtyAxis}
              disableClear
              getOptionLabel={option => option.name}
              getOptionValue={option => option.value}
              value={qtyAxis?.find(
                value => value.value === vehicle.data?.qty_axis
              )}
              onChange={option =>
                vehicle.setCurrentVehicle({
                  qty_axis: option.value || undefined,
                })
              }
              multiple={undefined}
              tooltip={undefined}
              onBlur={undefined}
              error={vehicle.errors?.qty_axis}
            />
          </Col>
        </>
      ) : (
        <>
          <Col md={6} xs={12}>
            <Select
              label="Tipo do veículo *"
              options={vehicles}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={vehicle.data.type}
              onChange={value =>
                vehicle.setCurrentVehicle({ type: value, body: null })
              }
              error={vehicle.errors?.type || vehicle.errors?.vehicle_type_id}
            />
          </Col>
          <Col md={6} xs={12}>
            <Select
              label="Tração *"
              isDisabled={uniqueAxis}
              options={axisOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={vehicle.data.axis}
              onChange={value => vehicle.setCurrentVehicle({ axis: value })}
              error={vehicle.errors?.axis}
            />
          </Col>
          {vehicle.data.type?.has_body_type && (
            <Col md={6} xs={12}>
              <Select
                label={
                  params.id ? 'Tipo de carroceria *' : 'Tipo de carroceria'
                }
                options={bodyTypes}
                disableClear
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={vehicle.data?.body}
                onChange={option =>
                  vehicle.setCurrentVehicle({
                    body: option || undefined,
                  })
                }
                error={vehicle.errors?.body}
              />
            </Col>
          )}
          <Col md={6} xs={12}>
            <Select
              label="Combustível"
              options={fuels}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={vehicle.data.fuel}
              onChange={value => vehicle.setCurrentVehicle({ fuel: value })}
              error={vehicle.errors?.fuel}
            />
          </Col>
          {!vehicle.data.type?.has_body_type && <Col md={6} xs={12} />}
          <Col md={6} xs={12}>
            <Input
              label="Cronotacógrafo *"
              type="number"
              value={vehicle.data.chronotachograph_number}
              onChange={event =>
                vehicle.setCurrentVehicle({
                  chronotachograph_number: event.target.value,
                })
              }
              error={vehicle.errors?.chronotachograph_number}
            />
          </Col>
          <Col md={6} xs={12}>
            <Input
              label="Validade cronotacógrafo *"
              value={chronotachographExpDate}
              onChange={event => setChronotachographExpDate(event.target.value)}
              masked
              mask={[
                /[0-9]/,
                /[0-9]/,
                '/',
                /[0-9]/,
                /[0-9]/,
                '/',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
              ]}
              error={vehicle.errors?.chronotachograph_exp_date}
            />
          </Col>
        </>
      )}
      <Col md={6} xs={12}>
        <Select
          label="Tipo de RNTRC *"
          options={anttTypes}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={vehicle.data.anttType}
          onChange={value =>
            vehicle.setCurrentVehicle({
              anttType: value,
              anttOwner: {
                ...vehicle.data.anttOwner,
                anttType: value?.name,
              },
            })
          }
          error={vehicle.errors?.anttType}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="RNTRC *"
          type="number"
          value={vehicle.data.antt}
          onChange={event =>
            vehicle.setCurrentVehicle({ antt: event.target.value })
          }
          error={vehicle.errors?.antt}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="CRLV"
          type="number"
          value={vehicle.data.crlv}
          onChange={event =>
            vehicle.setCurrentVehicle({ crlv: event.target.value })
          }
          error={vehicle.errors?.crlv}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="Chassi *"
          value={vehicle.data.chassi}
          onChange={event =>
            event.target.value.length > 20
              ? snackbar.show(<Text> Limite de dígitos para Chassi </Text>, {
                  type: 'error',
                })
              : vehicle.setCurrentVehicle({ chassi: event.target.value })
          }
          error={vehicle.errors?.chassi}
        />
      </Col>
      <Col md={6} xs={12}>
        <Select.Async
          label="Marca *"
          onSearch={fetchBrands}
          value={vehicle.data.brand}
          onChange={handleChangeBrand}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          error={vehicle.errors?.brand}
          removeInputMin
        />
      </Col>
      <Col md={6} xs={12}>
        <Select
          label="Modelo *"
          value={vehicle.data.model}
          options={modelOptions}
          isDisabled={!vehicle.data.brand}
          onChange={value => vehicle.setCurrentVehicle({ model: value })}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          error={vehicle.errors?.model}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="Ano do modelo"
          type="number"
          value={vehicle.data.year_model}
          onChange={event =>
            event.target.value.length > 4
              ? snackbar.show(<Text> Limite de dígitos para Ano </Text>, {
                  type: 'error',
                })
              : vehicle.setCurrentVehicle({ year_model: event.target.value })
          }
          error={vehicle.errors?.year_model}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="Ano de fabricação"
          type="number"
          value={vehicle.data.year_manufacture}
          onChange={event =>
            event.target.value.length > 4
              ? snackbar.show(<Text> Limite de dígitos para Ano </Text>, {
                  type: 'error',
                })
              : vehicle.setCurrentVehicle({
                  year_manufacture: event.target.value,
                })
          }
          error={vehicle.errors?.year_manufacture}
        />
      </Col>
      <Col md={6} xs={12}>
        <Select
          label="Cor"
          options={colors}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          value={vehicle.data.color}
          onChange={value => vehicle.setCurrentVehicle({ color: value })}
          error={vehicle.errors?.color}
        />
      </Col>{' '}
      <Col md={6} xs={12} />
      <Col md={6} xs={12}>
        <Radio.Group
          label="Rastreado *"
          onChange={({ target }) =>
            vehicle.setCurrentVehicle({ isTracked: target.value })
          }
          value={vehicle.data.isTracked}
          horizontal
          error={vehicle.errors.isTracked}
        >
          <Radio value>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={false}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
        </Radio.Group>
        {vehicle.errors?.isTracked && (
          <Badge className="mt-1" light variant="error">
            Campo obrigatório
          </Badge>
        )}
      </Col>
      <Col md={6} xs={12}>
        <Select
          placeholder={
            vehicle.data.isTracked ? 'Selecione...' : 'Veículo não rastreado'
          }
          isDisabled={!vehicle.data.isTracked}
          label={vehicle.data.isTracked ? 'Rastreador *' : 'Rastreador'}
          options={trackers}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={vehicle.data.isTracked ? vehicle.data.tracker : null}
          onChange={value => vehicle.setCurrentVehicle({ tracker: value })}
          error={vehicle.errors?.tracker}
        />
      </Col>
      <Col>
        <Select.Async
          onSearch={search => fetchTrackerTypes(search, vehicle.data.tracker)}
          value={vehicle.data.tracker ? vehicle.data.tracker_type : null}
          onChange={value => vehicle.setCurrentVehicle({ tracker_type: value })}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          label="Tipo de rastreador"
          placeholder={
            vehicle.data.isTracked
              ? vehicle.data.tracker
                ? 'Selecione...'
                : 'Veículo sem rastreador'
              : 'Veículo não rastreado'
          }
          options={trackerTypes}
          isDisabled={!vehicle.data.isTracked || !vehicle.data.tracker}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          placeholder={
            vehicle.data.isTracked ? 'Digite...' : 'Veículo não rastreado'
          }
          disabled={!vehicle.data.isTracked}
          label={
            vehicle.data.isTracked ? 'ID do rastreador *' : 'ID do rastreador'
          }
          value={vehicle.data.tracker_code}
          onChange={event =>
            vehicle.setCurrentVehicle({ tracker_code: event.target.value })
          }
          error={vehicle.errors?.tracker_code}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label="Tags"
          multiple
          value={vehicle.data.tags}
          horizontal
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          onChange={event => vehicle.setCurrentVehicle({ tags: event })}
          onSearch={search =>
            fetchSelectOptions('tags', {
              search,
              not_paginated: true,
            })
          }
          error={vehicle.errors?.tags}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label="Proprietário do documento *"
          onSearch={fetchOwners}
          onChange={value => vehicle.setCurrentVehicle({ owner: value })}
          value={vehicle.data.owner}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          error={vehicle.errors?.owner || vehicle.errors?.owner_id}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label="Proprietário RNTRC*"
          onSearch={fetchOwners}
          onChange={value =>
            vehicle.setCurrentVehicle({
              anttOwner: {
                ...value,
                anttType: vehicle.data.anttType?.name || '',
              },
            })
          }
          value={vehicle.data.anttOwner}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          error={
            vehicle.errors?.anttOwner ||
            vehicle.errors?.[`anttOwner.cgccpf` || 'anttOwner.anttType']
          }
        />
      </Col>
    </Row>
  );
}
