import React, { useEffect, useRef, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import { Form } from '@unform/web';
import {
  Button,
  Card,
  Text,
  useSnackbar,
  Select as SelectUI,
} from '@cargon/ui';

import api from '~/services/api';

import { formatCurrency, formatDate, formatDateTime } from '~/utils/formatter';
import useModal from 'hooks/useModal';
import { fetchVehicles } from 'services/fetch/v2/Vehicle';
import { useParams } from 'react-router-dom';
import { fetchDrivers } from 'services/fetch/v2/Driver';
import { useRefusedReasonStore } from '../Details/Modal/RefusedReason/store';
import { IncompleteVehicleOrDriverModal } from './incompleteVehicleOrDriverModal';
import { TakerValues } from '../Details/TakerValues';

export default function Info({
  origin,
  destinations,
  travelInfo,
  getTravel,
  travelData,
}) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [wagonsSelected, setWagonsSelected] = useState([{}, {}, {}]);
  const [tractionVehicle, setTractionVehicle] = useState(null);
  const [driver, setDriver] = useState('');
  const snackbar = useSnackbar();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSucceed, setSucceed] = useState(false);
  const [formErrors, setFormErrors] = useState();
  const formRef = useRef(null);
  const modal = useModal();

  const [incompleteDriver, setIncompleteDriver] = useState();
  const [incompleteVehicles, setIncompleteVehicles] = useState([]);

  function forcePutCTe(status_id, emitteds) {
    if (status_id === 3 || status_id === 5 || status_id === 6) {
      // em viagem
      if (!emitteds) return true;
      if (emitteds.length === 0) return true;
    }
    return false;
  }

  function messageByStatus(status_id, status, msg1, msg2, date = false) {
    if (date && status) {
      return formatDateTime(status);
    }
    if (status) {
      return status;
    }
    if (forcePutCTe(status_id)) return msg1;
    return msg2;
  }

  function setDataByPrevTravel() {
    setTractionVehicle(
      travelInfo.tractionVehicle || {
        plate: travelInfo.placa1,
        vehicleType: travelInfo.vehicleType,
      }
    );
    setWagonsSelected([
      { plate: travelInfo.placa2 },
      { plate: travelInfo.placa3 },
      { plate: travelInfo.placa4 },
    ]);
    setDriver(travelInfo.driver || '');
    console.log(travelInfo.driver, 'travelInfo.driver');
    if (formRef?.current) {
      formRef.current.setFieldValue('driver', travelInfo.driver);
    }
  }

  function handleEditButton() {
    if (editing) {
      formRef.current.submitForm();
    } else {
      setEditing(true);
      setDataByPrevTravel();
    }
  }

  const { travels: refusedTravels, modal: refusedTravelModal } =
    useRefusedReasonStore();
  async function updateDriverData(data) {
    if (!tractionVehicle.plate || !driver.id) {
      snackbar.show(<Text>Selecione uma opção para cada campo.</Text>, {
        type: 'error',
      });
      /*  setErrorMessage('Selecione uma opção para cada campo.');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000); */
      return;
    }
    if (tractionVehicle?.vehicleType?.qty_plates === 4) {
      const wagonsData = wagonsSelected.filter(wagon => wagon?.plate);
      if (!wagonsData.length) {
        setFormErrors({
          wagon1: 'Ao menos um implemento deve ser selecionado',
        });
        snackbar.show(<Text>Selecione uma opção para cada campo.</Text>, {
          type: 'error',
        });
        return;
      }
    }
    setFormErrors({});

    /*  let proceed = false;

    if (errorMessage.endsWith('Deseja continuar?')) {
      proceed = true;
    } */
    if (refusedTravels.length) {
      refusedTravelModal.open();
      return;
    }

    try {
      const payload = {
        vehicle: {
          previous: {
            id: travelInfo.vehicleId,
            plate: travelInfo.placa1,
            qty_axis: travelInfo.qty_axis,
          },
          current: {
            id: tractionVehicle.id || travelInfo.vehicleId,
            plate: tractionVehicle.plate || travelInfo.placa1,
            qty_axis:
              tractionVehicle.vehicleType?.qty_axis || travelInfo.qty_axis,
          },
        },
        wagons: [
          wagonsSelected[0]?.id || travelInfo?.wagonId2,
          wagonsSelected[1]?.id || travelInfo?.wagonId3,
          wagonsSelected[2]?.id || travelInfo?.wagonId4,
        ],
        driver: { id: driver.id, social_name: driver.social_name },
        oldDriver: travelInfo.driver
          ? { social_name: travelInfo.driver.social_name }
          : null,
        proceed: false,
      };

      setLoading(true);
      await api.put(
        `travel-data/${travelInfo.attendedId || params.id}/carrier`,
        payload
      );
      await getTravel();

      setEditing(false);
      setLoading(false);
      snackbar.show(<Text>Alterações cadastradas com sucesso</Text>, {
        type: 'success',
      });
    } catch (err) {
      if (err.response.status === 412) {
        setIncompleteDriver(_prev => undefined);
        setIncompleteVehicles(_prev => []);
        const { message } = err.response.data.data;

        const incompleteDriverAndOrVehiclesErrors = JSON.parse(message);

        incompleteDriverAndOrVehiclesErrors.forEach(error => {
          if (Object.keys(error).length === 0) return;
          if (error.isDriver) {
            setIncompleteDriver({
              cgccpf: error.cgccpf,
              social_name: error.social_name,
              id: error.id,
              missingFields: error.missingFields,
            });
          }
          if (error.isVehicle) {
            setIncompleteVehicles(e => [
              ...e,
              {
                plate: error.plate,
                id: error.id,
                missingFields: error.missingFields,
              },
            ]);
          }
        });
      }

      if (err.response.data) {
        if (!err.response.status || err.response.status !== 412) {
          snackbar.show(<Text>{err.response.data.message}</Text>, {
            type: 'error',
          });
        } else if (err.response.status === 412) {
          snackbar.show(<Text>Erro ao confirmar viagem!</Text>, {
            type: 'error',
          });
          setLoading(false);
          modal.open();
        }
      }
    }
    setLoading(false);
  }
  const [displayWagonForm, setDisplayWagonForm] = useState(false);

  useEffect(() => {
    if (editing) {
      formRef.current.setFieldValue('driver', travelInfo.driver);
      formRef.current.setFieldValue('vehicle', {
        plate: travelInfo.placa1,
        vehicleType: travelInfo.tractionVehicle?.vehicleType,
      });
      setDisplayWagonForm(
        travelInfo.tractionVehicle?.vehicleType?.qty_plates === 4
      );
      formRef.current.setFieldValue('wagon1', { plate: travelInfo.placa2 });
      formRef.current.setFieldValue('wagon2', { plate: travelInfo.placa3 });
      formRef.current.setFieldValue('wagon3', { plate: travelInfo.placa4 });
    }
  }, [
    editing,
    travelInfo.driver,
    travelInfo.placa1,
    travelInfo.placa2,
    travelInfo.placa3,
    travelInfo.placa4,
  ]);

  const wagonAlreadySelected = wagonSelected => {
    if (!wagonSelected) return false;
    return wagonsSelected?.some(w => w.id === wagonSelected.id);
  };

  return (
    <>
      <Row className="mb-3 mt-2">
        <Col lg={6} md={6} xs={12}>
          <Card className="card-list" header={<Text>Detalhes da viagem</Text>}>
            <Row>
              <Col xs={12}>
                <Text type="label" color="dark">
                  Nº da viagem:
                </Text>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className="text-uppercase mt-2"
                >
                  {messageByStatus(
                    travelInfo.status_id,
                    travelInfo.travelNumber,
                    'Pendente',
                    'Não Informado'
                  )}
                </Text>
              </Col>
              <Col xs={12}>
                <Text type="label" color="dark">
                  Valor
                </Text>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className="text-uppercase mt-2"
                >
                  {travelInfo.value === null
                    ? 'Não possui valor cadastrado'
                    : formatCurrency(travelInfo.value)}
                </Text>
              </Col>
              <Col xs={12}>
                <Text type="label" color="dark">
                  Cliente
                </Text>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className={travelInfo.taker ? 'text-uppercase mt-2' : 'mt-2'}
                >
                  {travelInfo.taker || 'Não informado'}
                </Text>
              </Col>
              <Col xs={12}>
                <Text type="label" color="dark">
                  Transportadora
                </Text>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className="text-uppercase mt-2"
                >
                  {travelInfo.shipper}
                </Text>
              </Col>
              {travelInfo.cargNumber && (
                <Col xs={12}>
                  <Text type="label" color="dark">
                    Carga
                  </Text>
                  <Text
                    type="regular"
                    color="gray"
                    as="p"
                    className="text-uppercase mt-2"
                  >
                    {travelInfo.cargNumber}
                  </Text>
                </Col>
              )}
              <Col xs={12}>
                <Text type="label" color="dark">
                  Veículos
                </Text>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className="text-uppercase mt-2"
                >
                  {travelInfo.load &&
                    travelInfo.load?.vehicleTypes
                      ?.map(type => type.name)
                      ?.join(', ')}
                </Text>
              </Col>
              {travelInfo.load?.vehicleTypes &&
                travelInfo.load?.vehicleTypes?.some(
                  type => type.id === 18 || type.value === 18
                ) && (
                  <Col xs={12}>
                    <Text type="label" color="dark">
                      Tipo de implemento
                    </Text>
                    <Text
                      type="regular"
                      color="gray"
                      as="p"
                      className="text-uppercase mt-2"
                    >
                      {travelInfo.load && travelInfo.load.implement.length > 0
                        ? travelInfo.load?.implement
                            ?.map(type => type.name)
                            ?.join(', ')
                        : 'Não informado'}
                    </Text>
                  </Col>
                )}
              <Col xs={12}>
                <Text type="label" color="dark">
                  Tipo de carroceria
                </Text>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className="text-uppercase mt-2"
                >
                  {travelInfo.load &&
                    travelInfo.load?.bodyTypes
                      ?.map(type => type.name)
                      ?.join(', ')}
                </Text>
              </Col>
              <Col xs={12}>
                <Text type="label" color="dark">
                  Valor da carga
                </Text>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className="text-uppercase mt-2"
                >
                  {formatCurrency(travelInfo.load.cargo_value)}
                </Text>
              </Col>
              <Col xs={12}>
                <Text type="label" color="dark">
                  Categoria do produto
                </Text>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className="text-uppercase mt-2"
                >
                  {travelInfo.load.productType?.name}
                </Text>
              </Col>

              <Col xs={12}>
                <Text type="label" color="dark">
                  Observações
                </Text>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className="text-uppercase mt-2"
                >
                  {travelInfo.load.comments || 'Não informado'}
                </Text>
              </Col>
              {travelInfo.user && (
                <Col xs={12}>
                  <Text type="label" color="dark">
                    Criado por
                  </Text>
                  <Text
                    type="regular"
                    color="gray"
                    as="p"
                    className="text-uppercase mt-2"
                  >
                    {travelInfo.user.username}
                  </Text>
                </Col>
              )}
            </Row>
          </Card>
        </Col>

        <Col lg={6} md={6} xs={12}>
          <Card
            className="card-list"
            header={
              <>
                <Text>Veículo / Motorista</Text>
                {/* errorMessage && (
                  <Col xs={12}>
                    <Alert variant="danger">{errorMessage}</Alert>
                  </Col>
                ) */}
              </>
            }
            HeaderRightComponent={
              <div style={{ display: 'flex' }}>
                <div className="form">
                  {editing && (
                    <Button
                      variant="secondary"
                      loading={loading}
                      className="mr-2 py-2"
                      onClick={() => {
                        setEditing(false);
                        setErrorMessage('');
                      }}
                    >
                      <Text variant="dark" type="regular" weight="500">
                        Cancelar
                      </Text>
                    </Button>
                  )}
                  <Button
                    onClick={() => handleEditButton()}
                    variant={editing ? 'primary' : 'secondary'}
                    loading={loading}
                    className="py-2"
                  >
                    <Text
                      color={editing ? 'white' : 'dark'}
                      type="regular"
                      weight="500"
                    >
                      {editing ? 'Salvar' : 'Editar'}
                    </Text>
                  </Button>
                </div>
                <IncompleteVehicleOrDriverModal
                  vehiclesData={incompleteVehicles}
                  driverData={incompleteDriver}
                  onClose={modal.close}
                  isOpen={modal.isOpen}
                />
              </div>
            }
          >
            <Col md={12}>
              {editing ? (
                <Form ref={formRef} onSubmit={form => updateDriverData(form)}>
                  <Col md={6}>
                    <SelectUI.Async
                      label="Placa principal *"
                      onSearch={query => {
                        return fetchVehicles(
                          {
                            plate: query,
                          },
                          true
                        );
                      }}
                      name="vehicle"
                      value={tractionVehicle}
                      onChange={value => {
                        if (!value) return;
                        setDisplayWagonForm(
                          value?.vehicleType?.qty_plates === 4
                        );
                        setTractionVehicle(value);
                      }}
                      getOptionLabel={option => option?.plate}
                      getOptionValue={option => option}
                    />
                  </Col>
                  {displayWagonForm && (
                    <>
                      <Col md={6}>
                        <SelectUI.Async
                          onSearch={query => {
                            return fetchVehicles(
                              {
                                plate: query,
                                is_wagon: true,
                              },
                              true
                            );
                          }}
                          label="Placa implemento 1"
                          name="wagon1"
                          value={wagonsSelected[0]}
                          onChange={value => {
                            if (wagonAlreadySelected(value)) {
                              snackbar.show(
                                <Text>Implemento já selecionado</Text>,
                                {
                                  type: 'error',
                                }
                              );
                              return;
                            }
                            if (value)
                              setWagonsSelected([
                                value,
                                wagonsSelected[1],
                                wagonsSelected[2],
                              ]);
                            else {
                              setWagonsSelected([
                                {},
                                wagonsSelected[1],
                                wagonsSelected[2],
                              ]);
                            }
                          }}
                          getOptionLabel={option => option?.plate}
                          getOptionValue={option => option}
                          error={formErrors?.wagon1}
                        />

                        <Text type="label" color="error">
                          {' '}
                          {formErrors?.wagon1}
                        </Text>
                      </Col>
                      <Col md={6}>
                        <SelectUI.Async
                          onSearch={query => {
                            return fetchVehicles(
                              {
                                plate: query,
                                is_wagon: true,
                              },
                              true
                            );
                          }}
                          value={wagonsSelected[1]}
                          label="Placa implemento 2"
                          name="wagon2"
                          onChange={value => {
                            if (wagonAlreadySelected(value)) {
                              snackbar.show(
                                <Text>Implemento já selecionado</Text>,
                                {
                                  type: 'error',
                                }
                              );
                              return;
                            }
                            if (value)
                              setWagonsSelected([
                                wagonsSelected[0],
                                value,
                                wagonsSelected[2],
                              ]);
                            else {
                              setWagonsSelected([
                                wagonsSelected[0],
                                {},
                                wagonsSelected[2],
                              ]);
                            }
                          }}
                          getOptionLabel={option => option?.plate}
                          getOptionValue={option => option}
                        />
                      </Col>
                      <Col md={6}>
                        <SelectUI.Async
                          onSearch={query => {
                            return fetchVehicles(
                              {
                                plate: query,
                                is_wagon: true,
                              },
                              true
                            );
                          }}
                          value={wagonsSelected[2]}
                          label="Placa implemento 3"
                          name="wagon3"
                          onChange={value => {
                            if (wagonAlreadySelected(value)) {
                              snackbar.show(
                                <Text>Implemento já selecionado</Text>,
                                {
                                  type: 'error',
                                }
                              );
                              return;
                            }
                            if (value)
                              setWagonsSelected([
                                wagonsSelected[0],
                                wagonsSelected[1],
                                value,
                              ]);
                            else {
                              setWagonsSelected([
                                wagonsSelected[0],
                                wagonsSelected[1],
                                {},
                              ]);
                            }
                          }}
                          getOptionLabel={option => option?.plate}
                          getOptionValue={option => option}
                        />
                      </Col>
                    </>
                  )}
                  <Col md={6}>
                    <SelectUI.Async
                      onSearch={query => {
                        return fetchDrivers(
                          {
                            driver: query,
                            page: 1,
                          },
                          true
                        );
                      }}
                      value={driver}
                      label="Motorista *"
                      onChange={value => {
                        if (!value) return;
                        setDriver(value);
                      }}
                      getOptionLabel={option =>
                        `${option?.social_name || ''} ${option?.cgccpf || ''}`
                      }
                      getOptionValue={option => option}
                    />
                  </Col>
                </Form>
              ) : (
                <>
                  <Col xs={6} className="form-group">
                    <Text type="label" color="dark">
                      Placa principal
                    </Text>
                    <Text
                      type="regular"
                      color="gray"
                      as="p"
                      className="text-uppercase mt-2"
                    >
                      {travelInfo.placa1 || 'Não informado'}
                    </Text>
                  </Col>
                  {travelInfo.placa2 && (
                    <Col xs={6} className="form-group">
                      <Text type="label" color="dark">
                        Implemento 1
                      </Text>
                      <Text
                        type="regular"
                        color="gray"
                        as="p"
                        className="text-uppercase mt-2"
                      >
                        {travelInfo.placa2}
                      </Text>
                    </Col>
                  )}
                  {travelInfo.placa3 && (
                    <Col xs={6} className="form-group">
                      <Text type="label" color="dark">
                        Implemento 2
                      </Text>
                      <Text
                        type="regular"
                        color="gray"
                        as="p"
                        className="text-uppercase mt-2"
                      >
                        {travelInfo.placa3}
                      </Text>
                    </Col>
                  )}
                  {travelInfo.placa4 && (
                    <Col xs={6} className="form-group">
                      <Text type="label" color="dark">
                        Implemento 3
                      </Text>
                      <Text
                        type="regular"
                        color="gray"
                        as="p"
                        className="text-uppercase mt-2"
                      >
                        {travelInfo.placa4}
                      </Text>
                    </Col>
                  )}
                  <Col xs={12} className="form-group">
                    <Text type="label" color="dark">
                      Motorista
                    </Text>
                    <Text
                      type="regular"
                      color="gray"
                      as="p"
                      className="text-uppercase mt-2"
                    >
                      {(travelInfo.driver?.social_name &&
                        travelInfo.driver?.social_name) ||
                        'Não informado'}
                    </Text>
                  </Col>
                  <Col xs={6} className="form-group">
                    <Text type="label" color="dark">
                      CNH
                    </Text>
                    <Text
                      type="regular"
                      color="gray"
                      as="p"
                      className="text-uppercase mt-2"
                    >
                      {(travelInfo.driverCnh?.cnh &&
                        travelInfo.driverCnh?.cnh) ||
                        'Não informado'}
                    </Text>
                  </Col>
                  <Col xs={6} className="form-group">
                    <Text type="label" color="dark">
                      Data de expiração
                    </Text>
                    <Text
                      type="regular"
                      color="gray"
                      as="p"
                      className="text-uppercase mt-2"
                    >
                      {(travelInfo.driverCnh?.cnhExpire &&
                        formatDate(travelInfo.driverCnh?.cnhExpire)) ||
                        'Não informado'}
                    </Text>
                  </Col>
                  <Col xs={12}>
                    {travelInfo.tags ? (
                      ''
                    ) : (
                      <Text type="label" color="dark">
                        Tags
                      </Text>
                    )}
                    {travelInfo.tags &&
                      travelInfo.tags.map(el => (
                        <Badge className="mr-1" variant="secondary">
                          {el.name}
                        </Badge>
                      ))}
                  </Col>
                </>
              )}
            </Col>
          </Card>
          {travelInfo?.load?.products?.length > 0 && (
            <Card className="card-list" header={<Text>Produtos</Text>}>
              {travelInfo.load.products?.map((product, i) => (
                <>
                  <Col xs={12} md={6} className="mb-3">
                    <Text type="label" color="dark">
                      Produto
                    </Text>
                    <Text
                      type="regular"
                      color="gray"
                      as="p"
                      className="text-uppercase mt-2"
                    >
                      {product?.name ?? 'Não informado'}
                    </Text>
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    <Text type="label" color="dark">
                      Quantidade
                    </Text>
                    <Text
                      type="regular"
                      color="gray"
                      as="p"
                      className="text-uppercase mt-2"
                    >
                      {product?.pivot.quantity
                        ? `${product.pivot.quantity} ${
                            product.commercialUnit?.name ?? product.unit_measure
                          }`
                        : 'Não informado'}
                    </Text>
                  </Col>
                  {i < travelInfo.load.products.length - 1 && (
                    <div
                      style={{
                        width: '100%',
                        height: 0,
                        borderBottom: '1px solid #f2f2f2',
                        marginBottom: 8,
                      }}
                    />
                  )}
                </>
              ))}
            </Card>
          )}
          {travelData && <TakerValues travel={travelData} />}
        </Col>
      </Row>
    </>
  );
}
