import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Text, Section, Editable, useSnackbar } from '@cargon/ui';

import { driverStore } from '~/services/store/Driver';
import { reshaperUserData } from '~/pages/Driver/Single/shared/reshaper';

import { UserData as UserDataRegister } from '~/pages/Driver/Single/shared/Cards';

import { validate } from '~/pages/Driver/Single/shared/validate';
import { updateUserData } from '~/services/fetch';

export default function UserData() {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const {
    currentDriver: driver,
    updateCurrentDriver,
    prevDriver,
    updatePrevDriver,
    setErrors,
    updateErrors,
    clearErrors,
  } = driverStore();

  const [isEditing, setIsEditing] = useState(false);

  function comparePasswords() {
    if (driver.password !== driver.verifyPassword) {
      snackbar.show(<Text> Senhas não conferem </Text>, {
        type: 'error',
      });
      return false;
    }
    return true;
  }

  async function handleSubmit() {
    const isValid = await validate({
      schema: 'userDataSchema',
      data: driver,
      setErrors: updateErrors,
      clearErrors,
    });

    if (!comparePasswords()) return;
    if (!isValid[0]) return;

    try {
      setLoading(true);
      await updateUserData({
        cgccpf: driver.cgccpf,
        data: reshaperUserData({ data: driver }),
      });
      setIsEditing(false);

      const driverCleanFields = {
        ...driver,
        password: '',
        verifyPassword: '',
        verified: true,
      };

      updateCurrentDriver(driverCleanFields);
      updatePrevDriver(driverCleanFields);
    } catch (err) {
      setErrors({});
    } finally {
      setLoading(false);
    }
  }

  async function handleCancelEdit() {
    setIsEditing(false);
    updateCurrentDriver(prevDriver);
    setErrors({});
  }

  return (
    <Section
      Title="Dados do usuário"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setIsEditing}
          isLoading={loading}
          onSave={() => handleSubmit()}
          onCancel={() => handleCancelEdit()}
        />
      }
    >
      {isEditing ? (
        <UserDataRegister />
      ) : (
        <Row>
          <Col xs={12} md={6}>
            <Text type="label">Senha</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              ***********
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label">Token</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver.token || '-'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label">Verificado</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver.verified ? 'Sim' : 'Não'}
            </Text>
          </Col>
        </Row>
      )}
    </Section>
  );
}
