import React, { useState } from 'react';
import { Form } from '@unform/web';
import { useParams, useHistory } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import api from '~/services/api';

import Input from '~/components/Input';
import Button from '~/components/Button';

// import { Container, Card, Logo, Line, Version } from './styles';

export default function ForgotPassword() {
  const params = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [msgError, setMsgError] = useState('');

  async function changePassword(password, confirmedPassword) {
    setLoading(true);
    const data = {
      password,
      confirmedPassword,
    };
    try {
      const response = await api.put(`forgot-password/${params.token}`, data);

      if (response.status === 200) {
        setIsSucceed(true);
      }

      setTimeout(() => {
        history.push('/login');
      }, 3000);

      return;
    } catch (ex) {
      let msg = '';
      try {
        msg = ex.response.data.error.message;
      } catch (e) {
        msg = 'Algo deu errado, tente novamente mais tarde.';
      }
      setIsError(true);
      setMsgError(msg);
      return;
    } finally {
      setLoading(false);
    }
  }

  function validatePassword(password, confirmedPassword) {
    if (!password) {
      return { msgError: 'Por favor, digite uma senha', valid: false };
    }

    if (!confirmedPassword) {
      return { msgError: 'Por favor, confirme sua senha', valid: false };
    }

    if (password !== confirmedPassword) {
      return { msgError: 'Senhas não conferem', valid: false };
    }

    if (password.length < 6) {
      return { msgError: 'Senha precisa ter mais que 6 digitos', valid: false };
    }

    return { valid: true };
  }

  function handleSubmit(data) {
    const { password, confirmedPassword } = data;
    const validObj = validatePassword(password, confirmedPassword);

    if (validObj.valid) {
      changePassword(password, confirmedPassword);
    } else {
      setIsError(true);
      setMsgError(validObj.msgError);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        label="Alteração de senha"
        name="password"
        placeholder="Sua nova senha"
        type="password"
        autoFocus
      />
      <Input
        style={{ marginTop: '10px' }}
        name="confirmedPassword"
        placeholder="Confirme sua nova senha"
        type="password"
        autoFocus
      />

      <Col style={{ paddingTop: '20px' }}>
        {isError && <Alert variant="danger">{msgError}</Alert>}
        {isSucceed && (
          <Alert variant="success">Senha alterada com sucesso !</Alert>
        )}
      </Col>

      <Row className="mt-2 justify-content-center">
        <Button loading={loading} type="submit">
          Alterar Senha
        </Button>
      </Row>
    </Form>
  );
}
