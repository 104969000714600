import React, { useState } from 'react';

import { Section, Text, Editable, useSnackbar } from '@cargon/ui';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Inputs from '../../../shared/Cards/PersonalData';
import { driverStore } from '~/services/store/Driver';
import { validate } from '~/pages/Driver/Single/shared/validate';
import { updateDriverPersonalData } from '~/services/fetch';
import { personalDataReshaper } from '~/pages/Driver/shared/reshaper';

export default function PersonalData() {
  const {
    currentDriver,
    prevDriver,
    updateCurrentDriver,
    updatePrevDriver,
    clearErrors,
    updateErrors,
    loadings,
  } = driverStore();

  const params = useParams();
  const snackbar = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    const data = personalDataReshaper({ data: currentDriver });
    try {
      const isValid = await validate({
        schema: 'personalDataSchema',
        data,
        setErrors: updateErrors,
        clearErrors,
      });

      if (!isValid[0]) return;

      setIsLoading(true);
      await updateDriverPersonalData({ driverId: params.id, data });
      updatePrevDriver(currentDriver);
      snackbar.show(<Text>Dados salvos com sucesso</Text>, {
        type: 'success',
      });
      setIsEditing(false);
    } catch (err) {
      snackbar.show(<Text>Algo deu errado ao alterar os dados pessoais</Text>, {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCancelEdit() {
    setIsEditing(false);
    updateCurrentDriver(prevDriver);
    clearErrors();
  }

  return (
    <Section
      Title="Dados pessoais"
      loading={loadings.address}
      HeaderRightComponent={
        <Editable
          isLoading={isLoading}
          isEditing={isEditing}
          setEditing={setIsEditing}
          onSave={() => handleSubmit()}
          onCancel={() => handleCancelEdit()}
        />
      }
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label">Estrangeiro</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.is_international ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label">Nome</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.social_name}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">
              {currentDriver.is_international ? 'Documento' : 'CPF'}
            </Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.cgccpf}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">Data de nascimento</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.birthdate || '-'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label">Cidade natal</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.bornCity?.name || '-'}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">RG</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.rg || '-'}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">Orgão emissor</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.rg_emissor || '-'}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">Estado emissor</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.rgDispatchProvince?.name || '-'}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">Data de emissão RG</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.rg_dispatch_date || '-'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label">Mãe</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.mothers_name || '-'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label">Pai</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.father_name || '-'}
            </Text>
          </Col>

          <Col lg={6} md={6} xs={12}>
            <Text type="label">Estado civil</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.civilState?.name || '-'}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">PIS</Text>

            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver.pispasep || '-'}
            </Text>
          </Col>
          {currentDriver.tags?.length > 0 && (
            <Col xs={12}>
              <Text type="label">Tags</Text>

              <Text type="regular" color="gray" as="p" className="mt-2">
                {currentDriver.tags.map(tag => `${tag.name}, `)}
              </Text>
            </Col>
          )}
        </Row>
      )}
    </Section>
  );
}
