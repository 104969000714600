import React from 'react';
import BaseReport from '../BaseReport';

export default function RefusedTravels() {
  return (
    <BaseReport
      title={'Relatório de Viagens Recusadas'}
      url={`refused-travels`}
    />
  );
}
