import React, { useEffect, useRef, useState } from 'react';
import { FaFileUpload, FaTrash } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';

import { Button, Select, Text, useSnackbar } from '@cargon/ui';
import { useTravel } from '~/pages/Travels/Details/provider';
import { MdfeParser } from '~/utils/ParserXmlToJsonDocuments/Mdfe';
import api from '~/services/api';
import { DocumentUploader } from '~/utils/DocumentUploader';
import { formatDateTime } from '~/pages/Travels/Documents/Modals/Cte/formatter';
import Upload from '~/components/Upload';

export default function UploadMdfeXml({ destinationList, originList }) {
  const travel = useTravel();
  const uploadRef = useRef();

  const [file, setFile] = useState();
  const [errors, setErrors] = useState({});
  const [mdfes, setMdfes] = useState([]);
  const [destination, setDestination] = useState();
  const [origin, setOrigin] = useState();
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filteredMdfes, setFilteredMdfes] = useState([]);

  const snackbar = useSnackbar();

  function onFilesChanged({ target }, hasError) {
    setFile({});
    setErrors({});

    if (hasError) {
      setErrors({ file: 'Algum arquivo selecionado é maior que 1MB.' });
      return;
    }

    setFile(Object.values(target.files));
  }

  async function getStatus() {
    const status = await api.get('emissionStatus');

    setStatus(status.data);
  }

  async function getFileContent(file) {
    try {
      const documentUploader = new DocumentUploader('xml');

      const { files } = await documentUploader.execute(file);

      const content = files.map(file => {
        const mdfeParser = new MdfeParser();

        const mdfeData = mdfeParser.process(file);

        if (!destination) {
          throw new Error('Selecione um destino');
        }

        if (!origin) {
          throw new Error('Selecione uma origem');
        }

        const xml = file.replace(/\n/g, '').replace(/<\?(xml|XML).*\?>/g, '');

        const data = {
          ...mdfeData,
          destination_id: destination.city_id,
          origin_id: origin.city_id,
          status_id: null,
          xml,
        };

        return data;
      });

      setMdfes(old => [...old, ...content]);
      uploadRef.current.clearFiles();
    } catch (err) {
      snackbar.show(<Text>{err.message}</Text>, {
        type: 'error',
      });
    } finally {
      setFile(null);
    }
  }

  useEffect(() => {
    if (file) {
      getFileContent(file);
    }
  }, [file]);

  function handleDelete(item) {
    setMdfes(mdfes.filter(e => e !== item));
  }

  async function handleCreateMdfe() {
    try {
      await api.put(
        `travel-data/updateStatusWithMDFe/${travel?.travelInfo?.attendedId}`,
        {
          mdfes: filteredMdfes,
        }
      );

      snackbar.show(<Text>Lista de MDF-e adicionada com sucesso.</Text>, {
        type: 'success',
      });

      setMdfes([]);
      setSelectedStatus(null);
      setOrigin(null);
      setDestination(null);
      setFilteredMdfes([]);
      await travel.fetchTravel();
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message;
      snackbar.show(<Text>{errorMessage}</Text>, {
        type: 'error',
      });
    }
  }

  function onSubmit() {
    if (!!mdfes.length) {
      handleCreateMdfe();
    }
  }

  function filterEqualKey() {
    const filtered = mdfes.reduce((acc, current) => {
      if (acc.find(old => old.key === current.key)) return acc;

      acc.push(current);

      return acc;
    }, []);

    setFilteredMdfes(filtered);
  }

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    filterEqualKey();
  }, [mdfes]);

  return (
    <>
      <Col xs={12} className="mb-3">
        <Select
          placeholder={null}
          value={origin}
          label="Origem"
          options={originList}
          getOptionLabel={option => option.formatted}
          getOptionValue={option => option.id}
          onChange={item => setOrigin(item)}
          isDisabled={!!mdfes.length}
        />
      </Col>
      <Col xs={12} className="mb-3">
        <Select
          placeholder={null}
          value={destination}
          label="Destino"
          options={destinationList}
          getOptionLabel={option => option.formatted}
          getOptionValue={option => option.id}
          onChange={item => setDestination(item)}
          isDisabled={!!mdfes.length}
        />
      </Col>
      <Col>
        <Upload
          icon={<FaFileUpload size="40px" />}
          name="file"
          label="Importar XML"
          accept={['.xml', '.zip']}
          onChange={event => onFilesChanged(event)}
          error={errors.file}
          multiple
          isDisabled={!origin || !destination}
          ref={uploadRef}
        />
      </Col>
      <Col>
        {!!filteredMdfes.length &&
          filteredMdfes.map(mdfe => (
            <>
              <Row className="mt-3">
                <Col xs={6} className="d-flex flex-column">
                  <Text>MDF-e {mdfe.number}</Text>
                  <Text fontSize="15px">Série {mdfe.serie}</Text>
                </Col>
                <Col
                  xs={6}
                  className="d-flex justify-content-end align-items-center mb-3"
                >
                  <Button
                    variant="error"
                    size="sm"
                    className="mx-1"
                    onClick={() => handleDelete(mdfe)}
                  >
                    <FaTrash />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Text type="label" color="gray">
                    Emitido em {formatDateTime(mdfe.issued_date)}
                  </Text>
                </Col>
              </Row>
            </>
          ))}
      </Col>
      <Col className="d-flex justify-content-center mt-3">
        <Button variant="success" onClick={() => onSubmit()}>
          Salvar
        </Button>
      </Col>
    </>
  );
}
