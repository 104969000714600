import React, { useEffect, useMemo, useState } from 'react';

import { Input, Radio, Select, Text } from '@cargon/ui';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { fetchCities, fetchProvinces, fetchTags } from '~/services/fetch';
import { driverStore } from '~/services/store/Driver';
import { civilStatusList } from '~/utils';
import { cpfMask, dateMask } from '~/utils/masks';
import api from '~/services/api';
import useModal from '~/hooks/useModal';
import { personalDataSchema } from '../../validate';
import DriverAlreadyRegisteredModal from '../../../Register/alreadyRegisteredModal';

export default function PersonalData() {
  const { updateCurrentDriver, currentDriver, updateErrors, errors } =
    driverStore();

  const [provinces, setProvinces] = useState([]);
  const modal = useModal();
  const [existingDriver, setExistingDriver] = useState();

  async function executeFetch() {
    const fetchedProvinces = await fetchProvinces();
    setProvinces(fetchedProvinces);
  }

  async function validation() {
    try {
      await personalDataSchema.validate(currentDriver, {
        abortEarly: false,
      });
    } catch (error) {
      updateErrors(error.inner);
    }
  }

  useEffect(() => {
    executeFetch();
  }, []);

  const documentInput = useMemo(() => {
    let props = {
      label: 'CPF *',
      mask: cpfMask,
    };

    if (currentDriver.is_international) {
      props = {
        label: 'Documento *',
        mask: () => false,
      };
    }

    return props;
  }, [currentDriver.is_international]);
  const checkDriver = async (cgccpf, blur) => {
    const cpfLength =
      !currentDriver.is_international &&
      cgccpf.replace(/[-_.]/g, '').length === 11 &&
      !blur;
    const internationalOnBlur = currentDriver.is_international && blur;
    if (cpfLength || internationalOnBlur) {
      validation();
      try {
        const response = await api.get(
          `carrier/driver/shippingCompaniesByCpf`,
          {
            params: { cgccpf },
          }
        );
        if (response.data) {
          setExistingDriver(response.data);
          modal.open();
        }
      } catch (e) {}
    }
  };
  return (
    <Row className="form">
      <Col xs={12}>
        <Radio.Group
          label="Estrangeiro *"
          onChange={({ target: { value } }) =>
            updateCurrentDriver({ is_international: value })
          }
          value={currentDriver.is_international}
          horizontal
        >
          <Radio value>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={false}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
        </Radio.Group>
        {errors?.is_international && (
          <div>
            <Text color="error" type="label">
              {errors.is_international}
            </Text>
          </div>
        )}
      </Col>

      <Col xs={12}>
        <Input
          label="Nome *"
          onChange={({ target: { value } }) =>
            updateCurrentDriver({ social_name: value })
          }
          value={currentDriver.social_name}
          error={errors.social_name}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label={documentInput.label}
          onChange={({ target: { value } }) => {
            checkDriver(value);
            updateCurrentDriver({ cgccpf: value });
          }}
          onBlur={({ target: { value } }) => checkDriver(value, true)}
          value={currentDriver.cgccpf}
          masked
          mask={documentInput.mask}
          error={errors.cgccpf}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          masked
          mask={dateMask}
          label="Data de nascimento *"
          onChange={({ target: { value } }) =>
            updateCurrentDriver({ birthdate: value })
          }
          error={errors.birthdate}
          value={currentDriver.birthdate}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label="Cidade natal"
          onSearch={fetchCities}
          onChange={value => updateCurrentDriver({ bornCity: value })}
          value={currentDriver.bornCity}
          getOptionLabel={option => `${option.name}, ${option.province?.uf}`}
          getOptionValue={option => option.id}
        />
      </Col>
      {!currentDriver.is_international && (
        <>
          <Col md={6} xs={12}>
            <Input
              label="RG *"
              onChange={({ target: { value } }) =>
                updateCurrentDriver({ rg: value })
              }
              error={errors.rg}
              value={currentDriver.rg}
            />
          </Col>

          <Col md={6} xs={12}>
            <Input
              label="Orgão emissor *"
              error={errors.rg_emissor}
              onChange={({ target: { value } }) =>
                updateCurrentDriver({ rg_emissor: value })
              }
              value={currentDriver.rg_emissor}
            />
          </Col>
        </>
      )}
      {!currentDriver.is_international && (
        <>
          <Col md={6} xs={12}>
            <Select
              label="UF emissor"
              options={provinces}
              onChange={value =>
                updateCurrentDriver({ rgDispatchProvince: value })
              }
              value={currentDriver.rgDispatchProvince}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => option.id}
            />
          </Col>
        </>
      )}
      {!currentDriver.is_international && (
        <>
          <Col md={6} xs={12}>
            <Input
              masked
              mask={dateMask}
              label="Data de emissão RG *"
              onChange={({ target: { value } }) =>
                updateCurrentDriver({ rg_dispatch_date: value })
              }
              error={errors.rg_dispatch_date}
              value={currentDriver.rg_dispatch_date}
            />
          </Col>
        </>
      )}
      <Col xs={12}>
        <Input
          error={errors.mothers_name}
          label="Mãe *"
          onChange={({ target: { value } }) =>
            updateCurrentDriver({ mothers_name: value })
          }
          value={currentDriver.mothers_name}
        />
      </Col>
      <Col xs={12}>
        <Input
          error={errors.father_name}
          label="Pai"
          onChange={({ target: { value } }) =>
            updateCurrentDriver({ father_name: value })
          }
          value={currentDriver.father_name}
        />
      </Col>
      <Col md={6} xs={12}>
        <Select
          label="Estado civil *"
          onChange={value => updateCurrentDriver({ civilState: value })}
          value={currentDriver.civilState}
          options={civilStatusList}
          error={errors.civilState}
        />
      </Col>
      <Col md={6} xs={12}>
        <Input
          label="PIS"
          onChange={({ target: { value } }) =>
            updateCurrentDriver({ pispasep: value })
          }
          value={currentDriver.pispasep}
        />
      </Col>
      <Col xs={12}>
        <Select.Async
          label="Tags"
          onSearch={fetchTags}
          multiple
          onChange={value => updateCurrentDriver({ tags: value })}
          value={currentDriver.tags}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          // onInputChange={e => {
          //   if (e !== '') {
          //     setInputTag(formatInputTag(e));
          //   }
          // }}
          // inputValue={inputTag}
        />
      </Col>
      {existingDriver && (
        <DriverAlreadyRegisteredModal
          driver={existingDriver}
          setDriver={setExistingDriver}
          isOpen={modal.isOpen}
          onClose={modal.close}
        />
      )}
    </Row>
  );
}
