import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Section, Editable, useSnackbar, Text } from '@cargon/ui';
import PaymentMethodsRegister from '~/pages/Driver/Single/shared/Cards/PaymentMethods';
import { driverStore } from '~/services/store/Driver';
import { updateDriverPaymentMethod } from '~/services/fetch';
import { validate } from '../../../shared/validate';
import { paymentMethodReshaper } from '../../../shared/reshaper';

export default function PaymentMethods() {
  const params = useParams();
  const snackbar = useSnackbar();
  const {
    currentDriver,
    prevDriver,
    updateCurrentDriver,
    updatePrevDriver,
    loadings,
    updateErrors,
    clearErrors,
  } = driverStore();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    const data = paymentMethodReshaper({ data: currentDriver });
    const isValid = await validate({
      schema: 'paymentMethodSchema',
      data,
      setErrors: updateErrors,
      clearErrors,
    });

    if (!isValid[0]) return;

    try {
      setIsLoading(true);
      await updateDriverPaymentMethod({ id: params.id, data });
      updatePrevDriver(currentDriver);
      setIsEditing(false);
    } catch (err) {
      snackbar.show(<Text>Errado ao alterar a Método de pagamento!</Text>, {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCancelEdit() {
    clearErrors();
    setIsEditing(false);
    updateCurrentDriver(prevDriver);
  }

  return (
    <Section
      Title="Método de pagamento"
      loading={loadings.paymentMethods}
      HeaderRightComponent={
        <Editable
          isLoading={isLoading}
          isEditing={isEditing}
          setEditing={setIsEditing}
          onSave={() => handleSubmit()}
          onCancel={() => handleCancelEdit()}
        />
      }
    >
      {isEditing ? (
        <PaymentMethodsRegister />
      ) : (
        <>
          <Text type="regular" color="#494950" as="p" className="mt-2">
            {currentDriver?.driver?.payment?.name
              ? currentDriver?.driver?.payment?.name
              : 'Não informado'}
          </Text>
        </>
      )}
    </Section>
  );
}
