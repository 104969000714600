import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DatePicker, Input, Radio, Select, Text, Tooltip } from '@cargon/ui';
import { shippingCompanyStore } from 'services/store/ShippingCompanies';
import { masks } from 'pages/ShippingCompanies/Single/shared/masks';
import { init_company_fields } from 'pages/ShippingCompanies/Single/shared/constants';
import { FaQuestionCircle } from 'react-icons/all';
import api from 'services/api';
import theme from '../../../../../../styles/theme';

export default function Data() {
  const { person, updatePerson, errors } = shippingCompanyStore();
  const [taxRegimes, setTaxRegimes] = useState([]);

  function onPersonTypeChange(event) {
    const { value } = event.target;
    const personProperties = value === 'Jurídica' ? init_company_fields : {};
    try {
      updatePerson({
        ...personProperties,
        personType: {
          ...person?.personType,
          name: value,
        },
        shippingCompany: {
          is_tax_free: false,
          taxRegime: null,
        },
        cgccpf: '',
      });
    } catch (e) {
      console.log(e);
    }
  }

  const documentInput = useMemo(
    () =>
      person?.personType?.name === 'Jurídica'
        ? {
            label: 'CNPJ *',
            mask: masks.cnpj,
          }
        : {
            label: 'CPF *',
            mask: masks.cpf,
          },
    [person?.personType]
  );
  const isPerson = useMemo(
    () => person?.personType?.name === 'Física',
    [person?.personType?.name]
  );
  const isCompany = useMemo(
    () => person?.personType?.name === 'Jurídica',
    [person?.personType?.name]
  );
  /* const isTaxFree = useMemo(
    () => person?.shippingCompany?.is_tax_free && isCompany,
    [person?.shippingCompany?.is_tax_free, isCompany]
  ); */

  useEffect(() => {
    async function fetchTaxRegimes() {
      try {
        const response = await api.get('/v3/tax-regime');

        setTaxRegimes(response.data);
      } catch (ex) {
        // Suppress exception
      }
    }

    fetchTaxRegimes();
  }, []);

  function onTaxFreeChange(event) {
    const { value } = event.target;

    let { ie } = person;
    if (value) {
      ie = '';
    }

    updatePerson({
      shippingCompany: {
        ...person.shippingCompany,
        is_tax_free: value,
      },
      ie,
    });
  }

  return (
    <Row className="form">
      <Col md={12} className="mb-3">
        <Radio.Group
          label="Tipo de pessoa *"
          onChange={e => onPersonTypeChange(e)}
          value={person?.personType?.name}
          horizontal
        >
          <Radio value="Jurídica">
            <Text color="dark" type="label">
              Jurídica
            </Text>
          </Radio>
          <Radio value="Física">
            <Text color="dark" type="label">
              Física
            </Text>
          </Radio>
        </Radio.Group>
      </Col>
      <Col xs={12}>
        <Input
          label={documentInput.label}
          value={person?.cgccpf}
          onChange={e => updatePerson({ cgccpf: e.target.value })}
          masked
          mask={documentInput.mask}
          error={errors.cgccpf}
          guide={false}
        />
      </Col>
      {isPerson && (
        <Col xs={12} md={6}>
          <Input
            label="PIS"
            value={person?.pis}
            onChange={e => updatePerson({ pis: e.target.value })}
            error={errors.pis}
          />
        </Col>
      )}
      {isCompany && (
        <>
          <Col xs={12} md={6} className="mb-3">
            <Radio.Group
              label="Isento"
              onChange={e => onTaxFreeChange(e)}
              value={!!person.shippingCompany?.is_tax_free}
              horizontal
            >
              <Radio value>
                <Text color="dark" type="label">
                  Sim
                </Text>
              </Radio>
              <Radio value={false}>
                <Text color="dark" type="label">
                  Não
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
          <Col xs={12} md={6}>
            <Input
              label="Inscrição estadual *"
              value={person?.ie}
              onChange={e => updatePerson({ ie: e.target.value })}
              disabled={!!person.shippingCompany?.is_tax_free}
              error={errors.ie}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              label="Regime tributário"
              options={taxRegimes}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={person.shippingCompany?.taxRegime}
              onChange={value =>
                updatePerson({
                  shippingCompany: {
                    ...person.shippingCompany,
                    taxRegime: value,
                  },
                })
              }
              error={errors?.['shippingCompany.taxRegime']}
            />
          </Col>
        </>
      )}
      <Col xs={12}>
        <Input
          label={isCompany ? 'Razão social *' : 'Nome *'}
          value={person?.social_name}
          onChange={e => updatePerson({ social_name: e.target.value })}
          error={errors?.social_name}
        />
      </Col>
      {isCompany && (
        <Col xs={12}>
          <Input
            label="Nome fantasia"
            value={person?.fantasy_name}
            onChange={e => updatePerson({ fantasy_name: e.target.value })}
            error={errors?.fantasy_name}
          />
        </Col>
      )}
      <Col xs={12} md={6}>
        <Input
          label={
            <Text type="label">
              RNTRC *
              <Tooltip
                content={
                  <Text type="label" color="white">
                    Somente 8 dígitos, sem o zero à esquerda
                  </Text>
                }
              >
                <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
              </Tooltip>
            </Text>
          }
          value={person?.rntrc_number}
          onChange={e =>
            updatePerson({ rntrc_number: e.target.value.slice(0, 8) })
          }
          error={errors?.rntrc_number}
        />
      </Col>
      <Col xs={12} md={6} />
      <Col md={12}>
        <Radio.Group
          label="É motorista?"
          value={!!person?.is_driver}
          onChange={({ target }) => {
            updatePerson({
              is_driver: target.value,
            });
          }}
          disabled={person?.personType?.name === 'Jurídica'}
          horizontal
        >
          <Radio value>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={false}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
        </Radio.Group>
      </Col>

      <Col xs={12} md={6} className="mt-3">
        <Input
          label="Telefone *"
          value={person?.phone}
          onChange={e => updatePerson({ phone: e.target.value })}
          masked
          mask={[
            '(',
            /[0-9]/,
            /\d/,
            ')',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          error={errors?.phone}
          guide={false}
        />
      </Col>
      {isPerson && (
        <>
          <Col xs={12} md={6} className="mt-3">
            <Input
              label="Nome do pai *"
              value={person?.fathers_name}
              onChange={e => updatePerson({ fathers_name: e.target.value })}
              error={errors?.fathers_name}
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <Input
              label="Nome da mãe *"
              value={person?.mothers_name}
              onChange={e => updatePerson({ mothers_name: e.target.value })}
              error={errors?.mothers_name}
            />
          </Col>
          <Col xs={12} md={6} className="mt-3">
            <Input
              label="RG *"
              value={person?.rg}
              onChange={e => updatePerson({ rg: e.target.value })}
              error={errors?.rg}
            />
          </Col>
          <Col md={6} xs={12}>
            <Input
              label="Órgão emissor *"
              value={person.rg_emissor}
              onChange={event =>
                updatePerson({ rg_emissor: event.target.value })
              }
              error={errors?.rg_emissor}
            />
          </Col>
          <Col xs={12} md={6}>
            <DatePicker
              guide
              label="Data de nascimento *"
              value={person?.birthdate}
              onChange={e => updatePerson({ birthdate: e.target.value })}
              error={errors?.birthdate}
            />
          </Col>
        </>
      )}
      <Col xs={12}>
        <Input
          label="E-mail"
          value={person?.email}
          onChange={e => updatePerson({ email: e.target.value })}
          error={errors?.email}
        />
      </Col>
    </Row>
  );
}
