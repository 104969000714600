import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Card, Text } from '@cargon/ui';

import { formatCurrency, formatDateTime } from '~/utils/formatter';
import { auctionStore } from '~/services/store/Auction';

export default function Info() {
  const travel = auctionStore(state => state.auction.travel);
  const loading = auctionStore(state => state.loadings.info);

  function forcePutCTe(status_id, emitteds) {
    if (status_id === 3 || status_id === 5 || status_id === 6) {
      // em viagem
      if (!emitteds) return true;
      if (emitteds.length === 0) return true;
    }
    return false;
  }

  function messageByStatus(status_id, status, msg1, msg2, date = false) {
    if (date && status) {
      return formatDateTime(status);
    }
    if (status) {
      return status;
    }
    if (forcePutCTe(status_id)) return msg1;
    return msg2;
  }

  const hasProducts =
    travel?.load?.products && travel?.load?.products.length > 0;

  return (
    <>
      <Row>
        <Col lg={hasProducts ? 6 : 12} md={hasProducts ? 6 : 12} xs={12}>
          <Card
            className="card-list"
            header={<Text>Detalhes da viagem</Text>}
            loading={loading}
          >
            <Col xs={12} className="mb-3">
              <Text type="label" color="dark">
                Nº da viagem:
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {messageByStatus(
                  travel.status_id,
                  travel.travelNumber,
                  'Pendente',
                  'Não Informado'
                )}
              </Text>
            </Col>
            <Col xs={12} className="mb-3">
              <Text type="label" color="dark">
                Valor
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel.value === null
                  ? 'Não possui valor cadastrado'
                  : formatCurrency(travel.value)}
              </Text>
            </Col>
            <Col xs={12} className="mb-3">
              <Text type="label" color="dark">
                Cliente
              </Text>
              <Text
                type="regular"
                color="gray"
                as="p"
                className={travel.taker ? 'text-uppercase mt-2' : 'mt-2'}
              >
                {travel.taker || 'Não informado'}
              </Text>
            </Col>

            {travel.cargNumber !== 0 && (
              <Col xs={12} className="mb-3">
                <Text type="label" color="dark">
                  Carga
                </Text>

                <Text type="regular" color="gray" as="p">
                  {travel.cargNumber || '-'}
                </Text>
              </Col>
            )}

            <Col xs={12} className="mb-3">
              <Text type="label" color="dark">
                Veículos
              </Text>
              <Text
                type="regular"
                color="gray"
                as="p"
                className="text-uppercase mt-2"
              >
                {(travel.load &&
                  travel.load?.vehicleTypes
                    ?.map(type => type.name)
                    ?.join(', ')) ||
                  '-'}
              </Text>
            </Col>
            {travel.load?.vehicleTypes &&
              travel.load?.vehicleTypes?.some(
                type => type.id === 18 || type.value === 18
              ) && (
                <Col xs={12} className="mb-3">
                  <Text type="label" color="dark">
                    Tipo de implemento
                  </Text>
                  <Text
                    type="regular"
                    color="gray"
                    as="p"
                    className="text-uppercase mt-2"
                  >
                    {travel.load && travel.load.implement.length > 0
                      ? travel.load?.implement
                          ?.map(type => type.name)
                          ?.join(', ')
                      : 'Não informado'}
                  </Text>
                </Col>
              )}
            <Col xs={12} className="mb-3">
              <Text type="label" color="dark">
                Tipo de carroceria
              </Text>
              <Text
                type="regular"
                color="gray"
                as="p"
                className="text-uppercase mt-2"
              >
                {(travel.load &&
                  travel.load?.bodyTypes?.map(type => type.name)?.join(', ')) ||
                  '-'}
              </Text>
            </Col>
            <Col xs={12} className="mb-3">
              <Text type="label" color="dark">
                Valor da carga
              </Text>
              <Text
                type="regular"
                color="gray"
                as="p"
                className="text-uppercase mt-2"
              >
                {formatCurrency(travel.load.cargo_value)}
              </Text>
            </Col>
            <Col xs={12} className="mb-3">
              <Text type="label" color="dark">
                Valor do frete
              </Text>
              <Text
                type="regular"
                color="gray"
                as="p"
                className="text-uppercase mt-2"
              >
                {formatCurrency(travel.load.fare_company)}
              </Text>
            </Col>
            <Col xs={12} className="mb-3">
              <Text type="label" color="dark">
                Observações
              </Text>
              <Text
                type="regular"
                color="gray"
                as="p"
                className={
                  travel.load.comments ? 'text-uppercase mt-2' : 'mt-2'
                }
              >
                {travel.load.comments || 'Não informado'}
              </Text>
            </Col>
            {travel.user && (
              <Col xs={12} className="mb-3">
                <Text type="label" color="dark">
                  Criado por
                </Text>
                <Text
                  type="regular"
                  color="gray"
                  as="p"
                  className="text-uppercase mt-2"
                >
                  {travel.user.username}
                </Text>
              </Col>
            )}
          </Card>
        </Col>{' '}
        {hasProducts && (
          <Col lg={6} md={6} xs={12}>
            <Card
              className="card-list"
              header={<Text>Produtos</Text>}
              loading={loading}
            >
              {travel.load.products?.map((product, i) => (
                <>
                  <Col xs={12} md={6} className="mb-3">
                    <Text type="label" color="dark">
                      Produto
                    </Text>
                    <Text
                      type="regular"
                      color="gray"
                      as="p"
                      className="text-uppercase mt-2"
                    >
                      {product?.name ?? 'Não informado'}
                    </Text>
                  </Col>
                  <Col xs={12} md={6} className="mb-3">
                    <Text type="label" color="dark">
                      Quantidade
                    </Text>
                    <Text
                      type="regular"
                      color="gray"
                      as="p"
                      className="text-uppercase mt-2"
                    >
                      {product?.pivot.quantity
                        ? `${product.pivot.quantity} ${
                            product.commercialUnit?.name ?? product.unit_measure
                          }`
                        : 'Não informado'}
                    </Text>
                  </Col>
                  {i < travel.load.products.length - 1 && (
                    <div
                      style={{
                        width: '100%',
                        height: 0,
                        borderBottom: '1px solid #f2f2f2',
                        marginBottom: 8,
                      }}
                    />
                  )}
                </>
              ))}
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
}
