import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function SkeletonResume() {
  return (
    <Row className="d-flex mx-2 mt-2 mb-2 justify-content-between">
      <Col lg={2} md={2} xs={12} className="align-self-center">
        <Skeleton circle width={150} height={150} />
      </Col>
      <Col lg={10} md={10} xs={12} className="align-self-center">
        <SkeletonTheme>
          <Col lg={4} md={4} xs={12}>
            <Skeleton count={1} className="my-3" />
          </Col>
          <Col xs={12}>
            <Skeleton count={3} className="my-3" />
          </Col>
        </SkeletonTheme>
      </Col>
    </Row>
  );
}
