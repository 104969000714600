import api from '../../api';

export async function fetchAttachments(travelId) {
  const response = await api.get(`travel/${travelId}/attachments`);

  return response.data;
}

export async function uploadAttachment({ files, description, travelId }) {
  const attachments = new FormData();

  files.forEach((file, index) => {
    const name = `${Date.now()}${index}.${file.type.split('/')[1]}`;
    const newFile = new File([file.blob], name, { type: file.type });

    attachments.append('file', newFile);
    attachments.append(`${name}_description`, description || file?.name);
  });

  await api.post(`travel/${travelId}/attachments`, attachments);
}
