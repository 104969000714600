import OneSignal from 'react-onesignal';
import api from '../../services/api';

export default async function runOneSignal() {
  await OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL_APPID,
    safari_web_id: 'web.onesignal.auto.2c31ff0c-1624-4aec-8f89-a4f0b1da0ea1',
    notifyButton: {
      enable: true,
      size: 'small' /* One of 'small', 'medium', or 'large' */,
      theme:
        'default' /* One of 'default' (red-white) or 'inverse" (white-red) */,
      position: 'bottom-left' /* Either 'bottom-left' or 'bottom-right' */,
      offset: {
        bottom: '10px',
        left: '10px' /* Only applied if bottom-left */,
        right: '10px' /* Only applied if bottom-right */,
      },
      prenotify: true /* Show an icon with 1 unread message for first-time site visitors */,
      showCredit: false /* Hide the OneSignal logo */,
      text: {
        'tip.state.unsubscribed': 'Clique para receber notificações',
        'tip.state.subscribed': 'Vocês está recebendo notificações',
        'tip.state.blocked': 'Você bloqueou as notificações',
        'message.prenotify': 'Clique para receber notificações',
        'message.action.subscribed':
          'Obrigado por aceitar receber notificações!',
        'message.action.resubscribed': 'Vocês está recebendo notificações',
        'message.action.unsubscribed': 'Você não receberá mais notificações',
        'dialog.main.title': 'Gerenciar Notificações',
        'dialog.main.button.subscribe': 'Aceitar',
        'dialog.main.button.unsubscribe': 'Recusar',
        'dialog.blocked.title': 'Desbloqueie as Notificações',
        'dialog.blocked.message':
          'Siga essas instruções para assinar as notificações:',
      },
    },
    subdomainName: 'transportador',
  });
  await OneSignal.showSlidedownPrompt();
  async function setOnesignalId(id) {
    try {
      await api.put('user/add-user-id', { push_user_id: id });
    } catch (error) {
      console.log('Erro ao salvar preferências de notificação');
    }
  }
  async function setUser() {
    const auth = JSON.parse(
      JSON.parse(window.localStorage.getItem('persist:cargon-carriers') || '{}')
        ?.auth || '{}'
    );
    if (auth?.user) {
      await OneSignal.setExternalUserId(`user_${auth?.user?.properties?.id}`);
    }
  }
  await setUser()
  async function handleSubscription(subscription) {
    const user_push_id = await OneSignal.getUserId();
    const auth = JSON.parse(
      JSON.parse(window.localStorage.getItem('persist:cargon-carriers') || '{}')
        ?.auth || '{}'
    );
    if (auth?.user) {
      await OneSignal.setExternalUserId(`user_${auth?.user?.properties?.id}`);
      //TODO remover lógica antiga
      await setOnesignalId(user_push_id || null);
    }
  }
  await OneSignal.on('subscriptionChange', async subscription => {
    await handleSubscription(subscription);
  });
  OneSignal.addListenerForNotificationOpened(notificationResponse => {
    if (notificationResponse) {
      if (notificationResponse.data.url)
        window.location.href = notificationResponse.data.url;
    }
  });
}
