import React, { createContext, useState } from 'react';

export const NotificationContext = createContext({});

export function NotificationContextProvider({ children }) {
  const [unread, setUnread] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    lastPage: 1,
  });

  const context = {
    unread,
    setUnread,
    loading,
    setLoading,
    notifications,
    setNotifications,
    pagination,
    setPagination,
  };

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
}

export const { Consumer: NotificationContextConsumer } = NotificationContext;
