import { Select, SwitchOption, Text } from '@cargon/ui';
import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { ActiveTabContent, ProgressCard, TabLink, TabPanel } from './styles';
import locationAPI from '~/services/api/location';

const LOCATIONS_RANGE_OPTIONS = [
  { label: 'Criação da viagem', value: 'TRAVEL_CREATED_AT' },
  { label: 'Agendamento da coleta', value: 'LOAD_COLLECT_SCHEDULED' },
];

export default function Options({
  onDisplayMarkersChange,
  onDisplayPathChange,
}) {
  const [displayMarkers, setDisplayMarkers] = useState(true);
  const [displayPath, setDisplayPath] = useState(true);

  useEffect(() => {
    onDisplayMarkersChange(displayMarkers);
  }, [displayMarkers]);

  useEffect(() => {
    onDisplayPathChange(displayPath);
  }, [displayPath]);

  return (
    <ProgressCard>
      <Tab.Container id="options-tab" defaultActiveKey="options">
        <Nav>
          <TabLink eventKey="options">Opções</TabLink>
        </Nav>
        <ActiveTabContent>
          <TabPanel eventKey="options">
            <div style={{ paddingTop: '10px' }}>
              <Text type="label">Exibir marcadores</Text>
              <SwitchOption
                activeText="Sim"
                inactiveText="Não"
                value={displayMarkers}
                onClick={() => setDisplayMarkers(old => !old)}
              />
            </div>
            <div style={{ paddingTop: '10px' }}>
              <Text type="label">Exibir trajetória</Text>
              <SwitchOption
                activeText="Sim"
                inactiveText="Não"
                value={displayPath}
                onClick={() => setDisplayPath(old => !old)}
              />
            </div>
          </TabPanel>
        </ActiveTabContent>
      </Tab.Container>
    </ProgressCard>
  );
}
