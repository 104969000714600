import React from 'react';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { formatDateTime } from '~/utils/formatter';

export default function Logs({ color, onError, logs }) {
  const cardError = (
    <Card className="card-list">
      <Card.Body>
        <Row className="p-3">
          <Col xs={12}>{onError}</Col>
        </Row>
      </Card.Body>
    </Card>
  );

  return onError ? (
    cardError
  ) : (
    <Row>
      <Col xs={12}>
        <Card className="card-list">
          <Card.Body className="p-5">
            <Row>
              <Col xs={12}>
                <h5 className="card-title">Logs de viagem</h5>
                <hr className="my-3" />
              </Col>
              {logs.map(log => (
                <>
                  {log.media === null
                    ? ''
                    : log.media.map((image, indexMedia) => (
                        <Col lg={2} md={2} xs={12} key={image.id}>
                          <img
                            style={{ width: '100%' }}
                            src={image.full_path}
                            alt={indexMedia}
                          />
                        </Col>
                      ))}

                  <Col xs>
                    <div className="card-label mb-0 text-uppercase">
                      {log.user_name}
                    </div>
                    <div className="card-text"> {log.user_email}</div>
                    <div className="card-text">
                      {!!log.allocatedTravel?.refused &&
                        `A viagem foi recusada pela transportadora ${log.allocatedTravel.person.social_name}, o motivo foi: `}
                      {log.obs}
                    </div>
                    {log.deleted_comment && (
                      <div className="card-text">{log.deleted_comment}</div>
                    )}
                  </Col>
                  <Col xs={3} className="text-right">
                    <div className="card-text mb-0 ">
                      {`${formatDateTime(log.log_time)}hrs`}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <hr />
                  </Col>
                </>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
