import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useSelector } from 'react-redux';
import api from '../../services/api';

// eslint-disable-next-line import/no-cycle
import Header from '~/components/Header';
import Offset from '~/components/Offset';

export default function Home() {
  const user = useSelector(store => store.auth.user.properties);

  return (
    <>
      <Header />
      <Offset>
        <Container>
          <Card>
            <Card.Body className="py-5">
              <Card.Title>Olá, {user.username}.</Card.Title>
              <hr />
              <Col className="pt-4">
                <p>
                  Bem vindo ao portal CargOn para transportadoras, aqui você
                  pode cadastrar seus veículos, motoristas, acompanhar suas
                  viagens e negociar novos fretes.
                </p>
                <p>
                  Caso tenha algum dúvida ou questionamento entre contato
                  conosco através do email{' '}
                  <a href="mailto:ola@cargon.com.br">ola@cargon.com.br</a>.
                </p>
              </Col>
            </Card.Body>
          </Card>
        </Container>
      </Offset>
    </>
  );
}
