import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  Text,
  Select,
  Input,
  Radio,
  useSnackbar,
  Modal,
  Button,
} from '@cargon/ui';
import api from 'services/api';
import { useParams } from 'react-router-dom';
import { useValidation } from '../../../../hooks';
import { validatorPaymentsCard } from './validator';

export default function PaymentCardModal({
  modal,
  activePaymentCard,
  cards,
  setCards,
}) {
  const snackbar = useSnackbar();
  const params = useParams();

  const isEditing = !!activePaymentCard;
  const hasSelectDefault = cards?.some(crd => crd.standard_card === true);

  const [card, setCard] = useState({
    id: undefined,
    corporation: '',
    card_number: '',
    active: true,
    standard_card: false,
    due_date: '',
  });

  const [cardCorps, setCardCorps] = useState();

  const [loading, setLoading] = useState(false);
  const [errors, { validate }] = useValidation(validatorPaymentsCard);

  async function fetchPaymentsCard() {
    try {
      const response = await api.get('v3/payment/cards');
      setCardCorps(response.data);
    } catch (ex) {
      snackbar.show(<Text>Erro ao carregar cartões!</Text>, {
        type: 'error',
      });
    }
  }

  useEffect(() => {
    if (activePaymentCard) setCard(activePaymentCard);
    else
      setCard({
        id: undefined,
        corporation: '',
        card_number: '',
        active: true,
        standard_card: !hasSelectDefault,
        due_date: '',
      });
  }, [activePaymentCard, hasSelectDefault, modal.isOpen]);

  useEffect(() => {
    fetchPaymentsCard();
  }, []);

  function handleChangeValue(field, value) {
    setCard(prev => ({ ...prev, [field]: value }));
  }

  function clearModal() {
    setCard({
      id: undefined,
      corporation: '',
      card_number: '',
      active: true,
      standard_card: !hasSelectDefault,
      due_date: '',
    });
    modal.close();
  }

  async function getUpdatedCards() {
    const response = await api.get(`v3/payment/cards/${params.id}`);
    return response.data;
  }

  async function handleSendCards(validCards) {
    try {
      await api.put(`v3/payment/cards/${params.id}`, {
        data: validCards,
      });
      snackbar.show(
        isEditing ? (
          <Text> Dados alterados com sucesso! </Text>
        ) : (
          <Text> Cartão salvo com sucesso! </Text>
        ),
        {
          type: 'success',
        }
      );
      return true;
    } catch (e) {
      snackbar.show(<Text>Houve um erro com a requisição ! </Text>, {
        type: 'error',
      });
      return false;
    }
  }

  async function handleSubmit() {
    setLoading(true);

    const [isValid] = await validate(card);

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }
    const alreadyRegistered =
      !isEditing && cards.some(crd => card.card_number === crd.card_number);

    if (alreadyRegistered) {
      snackbar.show(<Text>Este cartão já foi previamente cadastrado!</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    let newCards = cards;

    if (isEditing) {
      const cardIndex = cards.findIndex(crd => crd.id === card.id);
      newCards = [
        ...newCards.slice(0, cardIndex),
        card,
        ...newCards.slice(cardIndex + 1),
      ];
    } else newCards = [...newCards, card];

    if (params.id) {
      const success = await handleSendCards(newCards);
      setLoading(false);
      if (!success) return;
      newCards = await getUpdatedCards();
    }
    if (setCards) setCards(newCards);
    setLoading(false);
    clearModal();
  }

  return (
    <Modal
      show={modal.isOpen}
      handleClose={() => clearModal()}
      heading={isEditing ? 'Editar Cartão' : 'Adicionar Cartão'}
      body={
        <Row className="form">
          <Col md={12}>
            <Select
              label="Empresa do cartão"
              value={card.corporation}
              options={cardCorps}
              onChange={value => {
                handleChangeValue('corporation', value);
              }}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              error={errors.corporation}
            />
          </Col>

          <Col md={6}>
            <Input
              label="Número do cartão"
              value={card.card_number}
              onChange={event => {
                handleChangeValue('card_number', event.target.value);
              }}
              masked
              mask={[
                /[0-9]/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              error={errors.card_number}
            />
          </Col>

          <Col md={6}>
            <Input
              label="Vencimento"
              value={card.due_date}
              onChange={event => {
                handleChangeValue('due_date', event.target.value);
              }}
              placeholder="MM/AAAA"
              masked
              guide={false}
              mask={[/[0-1]/, /[0-9]/, '/', /[2]/, /[0-0]/, /[2-2]/, /[1-9]/]}
              error={errors.due_date}
            />
          </Col>

          <Col md={6}>
            <Radio.Group
              label="Ativo"
              value={card.active}
              horizontal
              onChange={event => {
                handleChangeValue('active', event.target.value);
              }}
            >
              <Radio value>
                <Text type="label">Sim</Text>
              </Radio>
              <Radio value={false}>
                <Text color="dark" type="label">
                  Não
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
          <Col md={6} className="mt-1">
            <Radio.Group
              label="Padrão"
              onChange={event => {
                handleChangeValue('standard_card', event.target.value);
              }}
              value={card.standard_card}
              horizontal
            >
              <Radio value>
                <Text type="label">Sim</Text>
              </Radio>
              <Radio value={false}>
                <Text color="dark" type="label">
                  Não
                </Text>
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
      }
      footer={
        <Row>
          <Col>
            <Button
              onClick={() => clearModal()}
              className="mr-1"
              variant="error"
            >
              <Text type="regular" weight="500" color="white">
                Cancelar
              </Text>
            </Button>
            <Button
              onClick={() => handleSubmit()}
              loading={loading}
              variant="success"
            >
              <Text type="regular" weight="500" color="white">
                {isEditing ? 'Salvar' : 'Adicionar'}
              </Text>
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
