import { Row, Col } from 'react-bootstrap';

import React, { useState, useEffect, useRef } from 'react';

import Card from 'react-bootstrap/Card';
import { Form as Unform } from '@unform/web';
import Button from '~/components/Button';
import Select from '~/components/Select';
import loadAPI from '~/services/api/load';

export default function Filter({ fetchLoads, filters }) {
  const [optionsDestinations, setOptionsDestinations] = useState([]);
  const [optionsOrigins, setOptionsOrigins] = useState([]);
  const [optionsBody, setOptionsBody] = useState([]);
  const formRef = useRef(null);

  function getCities(locations) {
    return locations.reduce((acc, location) => {
      const cities = location.children.map(city => ({
        ...city,
        province: { name: location.name, uf: location.id },
      }));

      return [...acc, ...cities];
    }, []);
  }

  async function getOptions() {
    try {
      const response = await loadAPI.get('/filters');
      const origins = getCities(response.data.origins);
      const destinations = getCities(response.data.destinations);

      setOptionsOrigins(origins);
      setOptionsDestinations(destinations);
      setOptionsBody(response.data.body_types);
    } catch (ex) {
      setOptionsOrigins([{ text: 'Não encontrado.', value: 'null' }]);
      setOptionsDestinations([{ text: 'Não encontrado.', value: 'null' }]);
      setOptionsBody([{ text: 'Não encontrado.', value: 'null' }]);
    }
  }

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <Unform ref={formRef} onSubmit={data => fetchLoads(data)}>
      <Card className="card-list mb-3">
        <Card.Body className="p-5">
          <Row>
            <Col xs={12}>
              <h5 className="card-title">Filtro</h5>
              <hr className="my-3" />
            </Col>
            <Col xs={3}>
              <Select
                placeholder={filters?.origin}
                label="Origem"
                name="origin"
                options={optionsOrigins}
                getOptionLabel={option =>
                  `${option.name}, ${option.province.uf}`
                }
                getOptionValue={option => option}
              />
            </Col>
            <Col xs={3}>
              <Select
                placeholder={filters?.destination}
                label="Destino"
                name="destination"
                options={optionsDestinations}
                getOptionLabel={option =>
                  `${option.name}, ${option.province.uf}`
                }
                getOptionValue={option => option}
              />
            </Col>
            <Col xs={3}>
              <Select
                placeholder={filters?.body}
                label="Carroceria"
                name="body"
                options={optionsBody}
              />
            </Col>
            <Col xs={3} className="text-right mt-4">
              <Button type="submit" variant="success">
                Filtrar
              </Button>
              {filters.origin && (
                <Button
                  className="ml-2"
                  onClick={() => {
                    localStorage.setItem('filter', '');
                    filters = {};
                    fetchLoads({});
                  }}
                  variant="secondary"
                >
                  Limpar Filtro
                </Button>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Unform>
  );
}
