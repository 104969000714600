import { Badge, DropdownButton, Text, useSnackbar } from '@cargon/ui';
import { Col, Row } from 'react-bootstrap';
import { formatDateTime } from '~/utils/formatter';
import React, { useState } from 'react';
import {
  CTE_STATUS_VARIANTS,
  downloadMdfePdf,
  downloadMdfeXml,
} from '~/pages/Travels/Documents/helpers';

export const Mdfe = ({ mdfe }) => {
  const snackbar = useSnackbar();
  const [downloading, setDownloading] = useState(false);
  const dlMdfeXML = async () => {
    setDownloading(true);
    downloadMdfeXml(mdfe.id, mdfe.number, snackbar).then(() =>
      setDownloading(false)
    );
  };
  const dlMdfePDF = async () => {
    setDownloading(true);
    downloadMdfePdf(mdfe.id, mdfe.number, snackbar).then(() =>
      setDownloading(false)
    );
  };
  return (
    <div key={mdfe.id} className="mb-3">
      <Text as="div" color="dark" type="body" weight={500} className="mb-4">
        MDF-e {mdfe.number}
        {mdfe?.status?.name && (
          <Badge
            pill
            light
            size="sm"
            className="ml-2"
            variant={CTE_STATUS_VARIANTS[mdfe.status.id]}
          >
            {mdfe.status.name}
          </Badge>
        )}
      </Text>

      <Row className="d-flex justify-content-between">
        <Col xs={2}>
          <Text as="div" color="dark" type="regular" className="mb-3">
            Data de emissão
          </Text>
          <Text color="gray" type="body">
            {mdfe.issued_date
              ? formatDateTime(mdfe.issued_date)
              : formatDateTime(mdfe.created_at)}
          </Text>
        </Col>

        <Col className="d-flex justify-content-end">
          <DropdownButton
            style={{ display: 'inline', marginLeft: 10 }}
            title="Opções"
            variant="secondary"
            className="ml-2 py-2"
            loading={downloading}
            options={[
              {
                title: 'Baixar XML',
                onClick: () => dlMdfeXML(),
                type: 'item',
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};
