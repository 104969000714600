import api from 'services/api';

export async function fetchDrivers(params, isByQuery) {
  try {
    const response = await api.get('shipping-companies/drivers', {
      params: {
        perPage: 20,
        ...params,
      },
    });

    if (isByQuery) {
      return response.data.data;
    }

    return {
      data: response.data.data,
      total: response.data.total,
      lastPage: response.data.lastPage,
    };
  } catch (ex) {
    if (isByQuery) {
      return [];
    }
    return {
      data: [],
      total: 0,
      lastPage: 0,
    };
  }
}
