import React from 'react';
import create from 'zustand';
import { fetchShippingCompany } from 'services/fetch/ShippingCompanies';
import produce from 'immer';

const init_shipping_company = {
  personType: { name: 'Jurídica' },
  cgccpf: '',
  ie: '',
  social_name: '',
  fantasy_name: '',
  rg: '',
  rg_emissor: '',
  birthdate: null,
  is_driver: false,
  email: null,
  phone: null,
  rntrc_number: null,
  rntrc_exp_date: null,
  pis: null,
  fathers_name: null,
  mothers_name: null,
  shippingCompany: {
    is_tax_free: false,
    taxRegime: null,
  },
  is_owner: true,
  addresses: [],
  paymentMethods: [],
  shippingCompaniesAttachedTo: [],
  personsPayments: [],
};

export const shippingCompanyStore = create(set => ({
  person: init_shipping_company,

  setPerson: person => set({ person }),

  updatePerson: data =>
    set(prev => ({
      person: { ...prev.person, ...data },
    })),

  clearPerson: () =>
    set(
      prev => ({
        ...prev,
        person: init_shipping_company,
        prevPerson: init_shipping_company,
        errors: {},
      }),
      true
    ),

  prevPerson: init_shipping_company,

  setPrevPerson: prevPerson => set({ prevPerson }),

  updatePrevPerson: data =>
    set(prev => ({
      prevShippingCompany: { ...prev.prevShippingCompany, ...data },
    })),

  errors: {},

  setErrors: errors =>
    set(
      prev => ({
        ...prev,
        errors,
      }),
      true
    ),

  clearErrors: () => set(() => ({ errors: {} })),

  updateErrors: data => set(prev => ({ errors: { ...prev.errors, ...data } })),

  loadings: {
    data: false,
    address: false,
    attachShippingCompany: false,
    paymentMethods: false,
    registering: false,
  },

  setLoadings: data =>
    set(prev => ({ loadings: { ...prev.loadings, ...data } })),

  setAllLoadings: data =>
    set(
      produce(draft => {
        Object.keys(draft.loadings).forEach(key => {
          draft.loadings[key] = data;
        });
      })
    ),

  fetchPerson: async id => {
    try {
      const data = await fetchShippingCompany({ id });
      set(
        produce(draft => {
          draft.person = data;
          draft.prevPerson = data;
        })
      );
    } catch (err) {
      console.log(err);
    }
  },
}));
