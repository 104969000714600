/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Card,
  Text,
  Table,
  Button,
  Badge,
  Fallback,
  useSnackbar,
} from '@cargon/ui';
import theme from '@cargon/ui/dist/theme';
import { Link, useParams } from 'react-router-dom';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import { driverStore } from '~/services/store/Driver';
import { removeAttachedVehicleToDriver } from '~/services/fetch';

import { formatPlate } from '../../../../../../utils/formatter';

import VehicleModal from '../../Modals/Vehicle';

export default function AttachVehicles() {
  const [show, setShow] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState();
  const { currentDriver, updateCurrentDriver, loadings } = driverStore();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const snackbar = useSnackbar();

  async function handleRemoveVehicle(vehicle) {
    setLoading(true);
    try {
      if (params.id) {
        const id = vehicle.id || vehicle.pivot.vehicle_id;
        await removeAttachedVehicleToDriver({
          driverId: params.id,
          vehicleId: id,
        });
      }

      updateCurrentDriver({
        vehicles: currentDriver.vehicles?.filter(item => item !== vehicle),
      });
    } catch (error) {
      snackbar.show('Erro ao deletar veículo', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <VehicleModal
        isOpen={show}
        currentVehicle={currentVehicle}
        setCurrentVehicle={setCurrentVehicle}
        onClose={() => {
          if (currentVehicle) {
            updateCurrentDriver({
              vehicles: [...currentDriver.vehicles, currentVehicle],
            });
          }

          setShow(false);
        }}
        setShow={setShow}
      />
      <Card
        loading={loading}
        header={
          <Text type="header" color="dark">
            Veículos
          </Text>
        }
        HeaderRightComponent={
          <>
            {!!currentDriver.vehicles?.length && (
              <Button
                onClick={() => {
                  setShow(true);
                }}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            )}
          </>
        }
      >
        <Row>
          <Fallback
            fall={!currentDriver.vehicles?.length}
            component={
              <Col xs={12} className="d-flex flex-column align-items-center">
                <Text color={theme.colors.gray}>
                  Não há motoristas cadastrados para esse veículo.
                </Text>
                <Button onClick={() => setShow(true)} className="mt-4">
                  <Text weigth={500} type="regular">
                    Adicionar
                  </Text>
                </Button>
              </Col>
            }
          >
            <Table>
              <thead>
                <tr>
                  <th>
                    <Text weight="500" color="dark">
                      Placa
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Tipo do veículo
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Tipo de carroceria
                    </Text>
                  </th>
                  <th>
                    <Text weight="500" color="dark">
                      Placas Implemento
                    </Text>
                  </th>
                  <th className="text-center">
                    <Text weight="500" color="dark">
                      Ação
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentDriver.vehicles
                  ?.sort((x, y) => {
                    if (x.current === y.current) return 0;
                    return x.current ? -1 : 1;
                  })
                  .map(item => (
                    <tr>
                      <td>
                        <Link to={`/veiculos/${item.pivot?.vehicle_id}`}>
                          <Text type="regular" color="light_blue">
                            {formatPlate(item.plate)}
                          </Text>
                        </Link>
                        {(item.current || item.pivot?.current) && (
                          <Badge
                            pill
                            light
                            variant="success"
                            className="ml-2"
                            size="sm"
                          >
                            <Text color="success" type="label">
                              Atual
                            </Text>
                          </Badge>
                        )}
                      </td>
                      <td>
                        <Text type="regular" color="dark">
                          {item.vehicleType?.name}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark">
                          {item.vehicleBodyType?.name}
                        </Text>
                      </td>
                      <td>
                        <Text type="regular" color="dark">
                          {item.wagons?.length > 0
                            ? item.wagons?.map(
                                (wag, idx) =>
                                  `${wag.plate}${
                                    idx < item.wagons?.length - 1 ? ',' : ''
                                  } `
                              )
                            : 'Não disponível'}
                        </Text>
                      </td>
                      <td className="text-center">
                        <Button
                          variant="secondary"
                          size="sm"
                          className="mr-1"
                          onClick={() => {
                            setCurrentVehicle(item);
                            updateCurrentDriver({
                              vehicles: currentDriver.vehicles?.filter(
                                element => element !== item
                              ),
                            });
                            setShow(true);
                          }}
                        >
                          <FaPencilAlt size={16} color="#464E5F" />
                        </Button>
                        <Button
                          loading={loadings.attachVehicle}
                          disabled={item.pivot?.current || item.current}
                          variant="error"
                          size="sm"
                          onClick={() => handleRemoveVehicle(item)}
                        >
                          <FaTrash size={16} />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Fallback>
        </Row>
      </Card>
    </>
  );
}
