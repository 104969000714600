import React from 'react';
import BaseReport from '../BaseReport';

export default function AcceptedTravelsReport() {
  return (
    <BaseReport
      title={'Relatório de Viagens Aceitas'}
      url={`accepted-travels`}
    />
  );
}
