const colors = {
  primary: '#3C99FF',
  secondary: '#E5EAEE',
  error: '#F64E60',
  success: '#0BB7AF',
  info: '#7337EE',
  warning: '#FFC107',
  light: '#D8EBFF',
  dark: '#464E5F',
  background: '#FFFFFF',
  white: '#fff',
  light_blue: '#3B99FF',
  dark_blue: '#216FB7',
  button_menu: 'rgba(255, 255, 255, 0.15)',
  gray: '#B5B5C3',
  light_gray: '#F9F9F9',
  black: '#212529',
  light_yellow: '#FFF4DE',
  light_red: '#FFE2E5',
  light_green: '#C9F7F5',
  yellow_rating_star: '#FFA800',
};

const button = {
  colors: {
    primary: {
      background: colors.primary,
      text: colors.white,
      outline: {
        background: colors.white,
        text: colors.primary,
      },
      light: {
        background: colors.light,
        text: colors.primary,
      },
    },
    secondary: {
      background: colors.secondary,
      text: colors.dark,
      outline: {
        background: colors.white,
        text: colors.dark,
      },
    },
    error: {
      background: colors.error,
      text: colors.white,
      outline: {
        background: colors.white,
        text: colors.error,
      },
      light: {
        background: colors.light_red,
        text: colors.error,
      },
    },
    success: {
      background: colors.success,
      text: colors.white,
      outline: {
        background: colors.white,
        text: colors.success,
      },
      light: {
        background: colors.light_green,
        text: colors.success,
      },
    },
    info: {
      background: colors.info,
      text: colors.white,
      outline: {
        background: colors.white,
        text: colors.info,
      },
    },
    warning: {
      background: colors.warning,
      text: colors.dark,
      outline: {
        background: colors.white,
        text: colors.warning,
      },
      light: {
        background: colors.light_yellow,
        text: colors.warning,
      },
    },
    light: {
      background: colors.light,
      text: colors.light_blue,
      outline: {
        background: colors.white,
        text: colors.light_blue,
      },
    },
    dark: {
      background: colors.dark,
      text: colors.white,
      outline: {
        background: colors.white,
        text: colors.dark,
      },
    },
    white: {
      background: colors.white,
      text: colors.dark,
      outline: {
        background: colors.white,
        text: colors.dark,
      },
    },
  },
  spacing: {
    sm: '5px',
    md: '10px',
    lg: '15px',
  },
};

const badge = {
  colors: {
    'Ordem de coleta': button.colors.primary,
    'Em viagem': button.colors.info,
    'Aguardando descarregamento': button.colors.warning,
    'Em análise': button.colors.warning,
    Finalizado: button.colors.success,
    Cancelado: button.colors.error,
    'Aguardando carregamento': button.colors.primary,
    'Aguardando emissão': button.colors.primary,
    Transbordado: button.colors.success,
    ...button.colors,
  },
};

const theme = {
  text: {
    sizes: {
      title: '32px',
      subtitle: '24px',
      header: '18px',
      body: '16px',
      regular: '14px',
      label: '12px',
      little: '10px',
    },
  },
  spacing: {
    buttonY: {
      sm: '5px',
      md: '10px',
      lg: '15px',
    },
    buttonX: {
      sm: '10px',
      md: '25px',
      lg: '25px',
    },
  },
  colors: {
    ...colors,
    button: button.colors,
    badge: badge.colors,
  },
};

export default theme;
