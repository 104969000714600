/* eslint-disable import/no-cycle */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
// import moment from 'moment';
import Container from 'react-bootstrap/Container';
import api from '~/services/api';

import Header from '~/components/Header';
import Button from '~/components/Button';
import Values from './Values';
import Offset from '~/components/Offset';

import Vehicle from './Vehicle';
import Data from './Data';
import Address from './Address';

export default function LoadDetail() {
  const params = useParams();

  const [load, setLoad] = useState();

  async function getLoad() {
    try {
      const response = await api.get(`loads/${params.id}`);
      setLoad(response.data);
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    getLoad();
  }, []);

  return (
    <>
      <Header
        header="Detalhes da carga"
        RightComponent={
          <>
            <Button
              style={{ minWidth: '86px', height: '38px' }}
              variant="secondary"
              as={Link}
              to="/cargas"
              className="ml-1"
            >
              Voltar
            </Button>
            <Button
              style={{ minWidth: '86px', height: '38px' }}
              variant="primary"
              className="ml-1"
              as={Link}
              to={load ? `/negociacoes/cadastrar/${load.id}` : ''}
            >
              Tenho Interesse
            </Button>
          </>
        }
      />
      <Offset>
        <Container>
          {load && (
            <>
              <Row>
                <Col xs={6}>
                  <Row>
                    <Col>
                      <Data load={load} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Address load={load} />
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Vehicle load={load} />
                  </Row>
                </Col>
                <Values
                  load={load}
                  destinations={load.destinationsList}
                  origins={load.originsList}
                />
              </Row>
            </>
          )}
        </Container>
      </Offset>
    </>
  );
}
