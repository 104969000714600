import React from 'react';
import { Button, Space, Text } from '@cargon/ui';
import { shippingCompanyStore } from '~/services/store/ShippingCompanies';

export default function Editable({
  isEditing,
  setEditing,
  onSave,
  isLoading,
  add,
  openModal,
}) {
  const { setPerson, prevPerson } = shippingCompanyStore();

  function handleCancel() {
    setEditing(false);
    setPerson(prevPerson);
  }

  if (isEditing) {
    return (
      <>
        <Space x="5px">
          <Button
            variant="secondary"
            onClick={() => handleCancel()}
            className="py-2"
          >
            <Text type="regular" color="dark" weight="500">
              Cancelar
            </Text>
          </Button>
          <Button
            variant="primary"
            onClick={onSave}
            loading={isLoading}
            className="py-2"
          >
            <Text type="regular" color="white" weight="500">
              Salvar
            </Text>
          </Button>
        </Space>
      </>
    );
  }

  return (
    <>
      {add ? (
        <Button variant="primary" onClick={() => openModal()} className="py-2">
          <Text color="white" weight="500" type="regular">
            Adicionar
          </Text>
        </Button>
      ) : (
        <Button
          variant="secondary"
          onClick={() => setEditing(true)}
          className="py-2"
        >
          <Text color="dark" weight="500" type="regular">
            Editar
          </Text>
        </Button>
      )}
    </>
  );
}
