import axios from 'axios';

// eslint-disable-next-line import/no-cycle
import store from '~/store';

const api = axios.create({
  baseURL: process.env.REACT_APP_LOAD_API_URL,
});

api.interceptors.request.use(async config => {
  const outputConfig = config;

  const state = store.getState();
  const { authenticated, user } = state.auth;
  if (authenticated) {
    outputConfig.headers.Authorization = `${user.token.type} ${user.token.token}`;
  }

  return outputConfig;
});

export default api;
