import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import { Button, Text } from '@cargon/ui';

import Header from '~/components/Header';
import Offset from '~/components/Offset';
import Section from '../shared/Section';

import { useVehicle } from '../shared/context';

import DataInputs from '../shared/Data/Inputs';
import AttachDriver from '../shared/AttachDriver';
import MainInputs from '../shared/Main/Inputs';

import * as Styled from '../../shared/styles';

import { handleCreation } from './controller';

import TollTags from './TollTags';
import useModal from '../../../../hooks/useModal';
import IsWagonModal from './Modals/IsWagon';

export default function Register() {
  const history = useHistory();
  const vehicle = useVehicle();

  const [isLoading, setLoading] = useState(false);
  const isWagonModal = useModal();
  useEffect(() => {
    isWagonModal.open();
  }, []);

  return (
    <>
      <Header
        title="Cadastrar Veículo"
        RightComponent={
          <Styled.ButtonsContainer className="pt-5">
            <Button
              variant="secondary"
              onClick={() => history.push('/veiculos')}
            >
              <Text weight={500} color="black">
                Voltar
              </Text>
            </Button>
            <Button loading={isLoading}>
              <Text
                weight={500}
                color="white"
                onClick={() =>
                  handleCreation(
                    vehicle.data,
                    vehicle.setCurrentVehicle,
                    vehicle.setErrors,
                    vehicle.showSnackbar,
                    setLoading
                  )
                }
              >
                Salvar
              </Text>
            </Button>
          </Styled.ButtonsContainer>
        }
      />
      <Offset>
        <Container>
          <Row>
            <IsWagonModal modal={isWagonModal} />
            <Col xs={12} md={6}>
              <Section
                Title={vehicle.data?.is_wagon ? 'Implemento' : 'Veículo tração'}
              >
                <MainInputs />
              </Section>
            </Col>
            <Col xs={12} md={6}>
              <Section Title="Peso e cubagem">
                <DataInputs />
              </Section>
              {!vehicle.data.is_wagon && (
                <>
                  <AttachDriver />
                  <TollTags />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Offset>
    </>
  );
}
