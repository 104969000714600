import React, { useEffect, useState } from 'react';
import { Text, useSnackbar } from '@cargon/ui';
import { fetchVehicle } from '~/services/fetch';
import { useParams } from 'react-router-dom';
import useModal from '~/hooks/useModal';
import { VehicleProvider } from './shared/context';

import Details from './Details';
import Register from './Register';

import { reshapeVehicle } from './shared/reshaper';

import CreatedModal from './shared/CreatedModal';

export default function VehicleSingle() {
  const params = useParams();
  const snackbar = useSnackbar();
  const createdModal = useModal();
  const [isFetching, setFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [prevVehicle, setPrevVehicle] = useState({});
  const [unauthorized, setUnauthorized] = React.useState(false);

  const { company_id } = JSON.parse(
    JSON.parse(window.localStorage.getItem('persist:cargon-carriers') || '{}')
      ?.auth || '{}'
  )?.user?.properties;

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  useEffect(() => {
    async function getVehicle() {
      try {
        const response = await fetchVehicle({ id: params.id });
        const data = reshapeVehicle(response);
        if (
          data?.blocked?.length > 0 &&
          data?.blocked?.find(blocked => blocked?.company_id === company_id)
        ) {
          setUnauthorized(true);
        } else {
          setUnauthorized(false);
        }
        setVehicle(data);
        setPrevVehicle(data);
      } catch (err) {
        snackbar.show(<Text>Não foi possível carregar esse veículo</Text>, {
          type: 'error',
        });
      } finally {
        setFetching(false);
      }
    }
    if (params.id) {
      getVehicle();
    }
  }, [params.id]);

  useEffect(() => {
    if (!params.id && vehicle?.id) {
      createdModal.open();
    }
  }, [vehicle?.id]);

  function setCurrentVehicle(data) {
    setVehicle(prev => ({ ...prev, ...data }));
  }
  function clearCurrentVehicle() {
    setVehicle({});
  }
  function clearPrevVehicle() {
    setPrevVehicle({});
  }

  return (
    <>
      <VehicleProvider
        value={{
          data: vehicle,
          setCurrentVehicle,
          isFetching,
          errors,
          setErrors,
          showSnackbar,
          fetchVehicle,
          prevVehicle,
          setPrevVehicle,
          clearCurrentVehicle,
          clearPrevVehicle,
          unauthorized,
        }}
      >
        <CreatedModal
          isOpen={createdModal.isOpen}
          onClose={createdModal.close}
        />
        {params.id ? <Details /> : <Register />}
      </VehicleProvider>
    </>
  );
}
