/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';
import { Col as BootstrapCol } from 'react-bootstrap';

import theme from '../../../styles/theme';

export const Marker = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${theme.colors.medium_gray};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  left: -9px;

  ${({ completed }) =>
    completed &&
    css`
      background-color: ${theme.colors.success};
    `}
`;

Marker.Tray = styled.div`
  border-left: 2px dashed ${theme.colors.medium_gray};
  height: 100px;
  width: 0;
  margin: 0 auto;

  ${({ completed }) =>
    completed &&
    css`
      border-color: ${theme.colors.success};
    `}
`;

export const Schedules = styled(BootstrapCol)`
  padding: 15px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.secondary};
  margin-bottom: 35px;

  ${({ completed }) =>
    completed &&
    css`
      border-color: ${theme.colors.light_green};
      background-color: ${theme.colors.light_green};
    `}
`;
