export function reshapeVehicle(response) {
  const data = {
    ...response,
    anttOwner: {
      ...response.anttOwner,
      anttType: response.antt_type_name || null,
    },
    implement: response.implementType,
    implements: response.wagons.map(wagon => ({
      ...wagon,
      color: { label: wagon?.color, value: wagon?.color },
      province: wagon?.city?.province,
      anttOwner: { ...wagon?.anttOwner, anttType: wagon?.anttType?.name },
      brand: wagon.modelById?.id
        ? {
            id: wagon?.modelById?.manufacturer?.id,
            name: wagon?.modelById?.manufacturer?.name,
          }
        : null,
      model: wagon.modelById
        ? {
            id: wagon.modelById.id,
            name: wagon.modelById.name,
          }
        : null,
    })),
    body: response.vehicleBodyType || null,
    tollTags: response.tollTagsDeprecated ? response.tollTagsDeprecated : [],
    exclusive: response.exclusive === 1,
    drivers: response.drivers
      ? response.drivers.map(driver => ({
          value: driver.id,
          current: driver.pivot.current,
          social_name: driver.social_name,
          label: driver.label,
          cgccpf: driver.cgccpf,
        }))
      : [],
    tracker_type: response.trackerType
      ? {
          id: response.trackerType.id,
          name: response.trackerType.name,
        }
      : null,
    tracker: response.tracker
      ? {
          id: response.tracker?.id,
          name: response.tracker?.name,
          label: response.tracker?.label,
          value: response.tracker.value,
        }
      : null,
    isTracked: response.tracked,
    fuel: response.fuel_id
      ? { id: response.fuel_id, name: response.fuel_name }
      : null,
    color: response.color
      ? { label: response.color, value: response.color }
      : null,
    owner:
      (response.ownerData?.id &&
        response.ownerData?.cgccpf && {
          id: response.ownerData?.id,
          social_name: response.ownerData?.social_name,
          cgccpf: response.ownerData?.cgccpf,
          label: response.ownerData?.label,
        }) ||
      null,
    brand: response.modelById?.id
      ? {
          id: response?.modelById?.manufacturer?.id,
          name: response?.modelById?.manufacturer?.name,
        }
      : null,
    model: response.modelById
      ? {
          id: response.modelById.id,
          name: response.modelById.name,
        }
      : null,
    axis: response.axisType,
    type: response.vehicleType,
    anttType:
      (response.antt_type &&
        response.antt_type_name && {
          id: response.antt_type,
          name: response.antt_type_name,
        }) ||
      null,
    city: response.city_id &&
      response.city_name && {
        id: response.city_id,
        name: response.city_name,
        province:
          {
            id: response.province_id,
            uf: response.province_uf,
          } || null,
      },
    province: {
      id: response.province_id,
      uf: response.plate_state,
    },
  };
  return data;
}
