import XMLParser from 'react-xml-parser';

import cteParser from './CteParser';

const TOMA_TO_ENTITY = {
  0: 'rem',
  1: 'exped',
  2: 'receb',
  3: 'dest',
  4: 'toma4',
};

class CteParser {
  constructor(xml) {
    this.content = new XMLParser().parseFromString(xml);
  }

  getTaker(entityType) {
    const takerTag = this.content.getElementsByTagName(entityType)[0];
    const documentTag =
      takerTag.getElementsByTagName('CPF')[0] ??
      takerTag.getElementsByTagName('CNPJ')[0];

    return {
      document: documentTag.value,
      name: takerTag.getElementsByTagName('xNome')[0].value,
      ie: takerTag.getElementsByTagName('IE')[0]?.value,
    };
  }

  process() {
    try {
      const cteElement = this.content.getElementsByTagName('CTe')[0];
      const infCteElement = cteElement.getElementsByTagName('infCte')[0];

      const emitElement = infCteElement.getElementsByTagName('emit')[0];
      const emitDocument = emitElement.getElementsByTagName('CNPJ')[0].value;

      const ideElement = infCteElement.getElementsByTagName('ide')[0];
      const cte = cteParser(ideElement);

      const cteProtocolElement =
        this.content.getElementsByTagName('protCTe')[0];
      const infProtocolElement =
        cteProtocolElement.getElementsByTagName('protCTe')[0];
      cte.key = infProtocolElement.getElementsByTagName('chCTe')[0].value;
      cte.sefaz_protocol =
        infProtocolElement.getElementsByTagName('nProt')[0].value;

      const nfElement = infCteElement.getElementsByTagName('infCTeNorm')[0];
      const infDocElement = nfElement?.getElementsByTagName('infDoc')[0];
      const infNfeElement = infDocElement?.getElementsByTagName('infNFe');

      const contemplatedCte = infCteElement.getElementsByTagName('infCteComp')
        ? infCteElement.getElementsByTagName('infCteComp')[0]
        : null;

      const contemplatedCteKey = contemplatedCte?.getElementsByTagName('chCTe')
        ? contemplatedCte?.getElementsByTagName('chCTe')[0]?.value
        : null;

      const invoices = infNfeElement?.map(nfe => {
        const key = nfe.getElementsByTagName('chave')[0].value;

        return {
          key,
          value: Number(key.slice(25, 34)),
          label: Number(key.slice(25, 34)),
        };
      });

      const vPrestElement = infCteElement.getElementsByTagName('vPrest')[0];
      const totalValue = vPrestElement.getElementsByTagName('vTPrest');
      const amountReceivable = vPrestElement.getElementsByTagName('vRec');

      const toma = infCteElement.getElementsByTagName('toma')[0].value;
      const selectedTaker = TOMA_TO_ENTITY[toma];

      cte.totalValue = totalValue[0].value;
      const taker = this.getTaker(selectedTaker);

      const data = {
        cte,
        invoices,
        amountReceivable: amountReceivable[0].value,
        issuerDocument: emitDocument,
        contemplatedCteKey: contemplatedCteKey,
        taker,
      };

      return data;
    } catch (err) {
      console.log('ERR: ', err);
      throw new Error('Arquivo não compatível com CTe');
    }
  }
}

export { CteParser };
