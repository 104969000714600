import React from 'react';

import { Row, Col } from 'react-bootstrap';
import Card from 'components/Card';
import Text from 'components/Text';

import { formatDateTime } from 'utils/formatter';

export default function LoadData({ load }) {
  return (
    <>
      <Row>
        <Col>
          <Card>
            <Row>
              <Col xs={12} className="mb-3">
                <h5 className="card-title">Carga {load.id}</h5>
                <hr className="my-3" />
              </Col>
            </Row>
            <Row>
              {load.productType && (
                <Col xs={6} className="mb-3">
                  <Text type="label" color="#464E5F">
                    Produtos
                  </Text>
                  <Text type="regular" color="gray" as="p">
                    {load.productType ? load.productType.label : '-'}
                  </Text>
                </Col>
              )}
              <Col xs={6} className="mb-3">
                <Text type="label" color="#464E5F">
                  Liberações:
                </Text>
                <Text type="regular" color="gray" as="p">
                  {load.clearances && load.clearances.length > 0
                    ? load.clearances
                        .map(clearance => clearance.label)
                        .join(', ')
                    : '-'}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-3">
                <Text type="label" color="#464E5F">
                  Observações:
                </Text>
                <Text type="regular" color="gray" as="p">
                  {load.comments ? load.comments : '-'}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                <Text type="label" color="#464E5F">
                  Vencimento:
                </Text>
                <Text type="regular" color="gray" as="p">
                  {load.due_date ? formatDateTime(load.due_date) : '-'}
                </Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}
