import React from 'react';
import { useHistory } from 'react-router-dom';
import useWindowResize from 'hooks/useWindowResize';
import * as Styled from 'pages/ShippingCompanies/Single/styles';
import { Button, Text } from '@cargon/ui';
import { Col, Row } from 'react-bootstrap';
import Data from 'pages/ShippingCompanies/Single/Details/Cards/Data';
import AttachShippingCompany from 'pages/ShippingCompanies/Single/Details/Cards/AttachShippingCompany';
import PaymentMethods from 'pages/ShippingCompanies/Single/Details/Cards/PaymentMethods';
import Header from 'components/Header';
import Offset from 'components/Offset';
import Container from 'react-bootstrap/Container';
import Address from 'pages/ShippingCompanies/Single/shared/Cards/Address';
import useModal from 'hooks/useModal';
import PaymentCards from 'components/PaymentCards';
import { shippingCompanyStore } from 'services/store/ShippingCompanies';

export default function Details() {
  const history = useHistory();
  const [saveContainerOffset, setSaveContainerOffset] = React.useState(0);
  const { person, updatePerson, loadings } = shippingCompanyStore();
  const addressModal = useModal();
  useWindowResize(() => {
    const header = document.getElementById('header-container');
    const originDistanceToRight =
      window.pageXOffset + header.getBoundingClientRect().right;
    const headerWidth = header.getBoundingClientRect().width;

    const SAVE_CONTAINER_PADDING = 0;
    const distanceToRight =
      originDistanceToRight - headerWidth + SAVE_CONTAINER_PADDING;
    setSaveContainerOffset(distanceToRight);
  }, []);
  return (
    <div>
      <Header
        title="Transportadora"
        RightComponent={
          <Styled.SaveContainer>
            <Button
              variant="secondary"
              onClick={() => history.push('/transportadoras')}
            >
              <Text weight={500} color="black">
                Voltar
              </Text>
            </Button>
          </Styled.SaveContainer>
        }
      />
      <Offset>
        <Container>
          <Row>
            <Col md={6}>
              <Data addressModal={addressModal} />
              <PaymentMethods />
            </Col>
            <Col md={6}>
              <Address modal={addressModal} />
              <AttachShippingCompany />
              <PaymentCards
                setPaymentCards={cards =>
                  updatePerson({ personsPayments: cards })
                }
                paymentCards={person?.personsPayments}
                loading={loadings.data}
              />
            </Col>
          </Row>
        </Container>
      </Offset>
    </div>
  );
}
