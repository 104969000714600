import moment from 'moment';

export function shippingCompaniesReshaper({ data }) {
  if (!data) {
    return [];
  }
  const idArray = data.map(item => {
    return item?.id;
  });
  return idArray;
}

export function reshapeShippingPerson(person) {
  const { addresses, shippingCompany, shippingCompaniesAttachedTo, ...rest } =
    person;
  const address = addresses[0] || {};
  const country_id = address?.country?.id;
  delete address.country;

  return {
    address: { ...address, country_id },
    shipping_company: {
      payment_method: shippingCompany?.payment?.id,
      is_tax_free: !!shippingCompany?.is_tax_free,
      tax_regime_id: shippingCompany?.taxRegime?.id ?? null,
    },
    shippingCompaniesAttachedTo: shippingCompaniesReshaper({
      data: shippingCompaniesAttachedTo,
    }),
    data: {
      type_id: rest?.personType?.name === 'Jurídica' ? 3 : 2,
      cgccpf: rest?.cgccpf,
      ie: rest?.ie || null,
      social_name: rest?.social_name,
      fantasy_name: rest?.fantasy_name || null,
      phone: rest?.phone,
      phone_unmasked: rest?.phone?.replace(/[^0-9]+/g, ''),
      email: rest?.email,
      is_driver: !!rest?.is_driver,
      rntrc_number: rest?.rntrc_number || null,
      rntrc_exp_date:
        moment(rest?.rntrc_exp_date, 'DD/MM/YYYY').toDate() || null,
      pispasep: rest?.pis || null,
      rg: rest?.rg || null,
      birthdate: moment(rest?.birthdate, 'DD/MM/YYYY').toDate() || null,
      rg_emissor: rest?.rg_emissor || null,
      rg_dispatch_province: rest?.documentIssuerProvince?.ibge_code || null,
      father_name: rest?.fathers_name || null,
      mothers_name: rest?.mothers_name || null,
    },
  };
}

const testData = {
  address: {
    address: 'SHCS Asa Sul',
    uf_name: 'DF',
    city_name: 'Brasília',
    neighborhood: 'Asa Sul',
    number: '122',
    zip_code: '70360-510',
    complement: 'Comércio Local Sul 111',
    country_id: 1,
  },
  shipping_company: {
    payment_method: 2,
    is_tax_free: false,
    tax_regime_id: null,
  },
  shippingCompaniesAttachedTo: [5025, 82456, 3, 58380, 84234],
  data: {
    type_id: 2,
    cgccpf: '723.713.861-89',
    ie: null,
    social_name: 'Gerado com CPF ',
    fantasy_name: null,
    phone: '(64)98761-5151',
    phone_unmasked: '64987615151',
    email: 'gerado@teste.com',
    is_driver: true,
    rntrc_number: '12345678',
    rntrc_exp_date: '2022-12-12T03:00:00.000Z',
    pispasep: '01020304',
    rg: '12121212',
    birthdate: '2000-12-12T02:00:00.000Z',
    rg_emissor: 'SSP',
    rg_dispatch_province: null,
    father_name: 'Pai do Gerado',
    mothers_name: 'Mãe do Gerado',
  },
};
