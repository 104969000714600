import create from 'zustand';
import api from '~/services/api';
import { reshapeTravel } from '~/services/store/Auction/reshaper';

const init_auction = {
  travel: {
    load: {
      products: [],
      vehicleTypes: [],
      implement: [],
      bodyTypes: [],
      cargo_value: 0,
      fare_company: 0,
      productType: {},
      comments: '',
    },
    status_id: 0,
    travelNumber: 0,
    value: 0,
    taker: '',
    shipper: '',
    cargNumber: 0,
    user: {},
    origin: {},
    destinations: [],
    logs: [],
  },
  access_denied: false,
};

export const auctionStore = create(set => ({
  auction: init_auction,
  setAuction: auction => set({ auction }),
  updateAuction: data =>
    set(prev => ({ auction: { ...prev.auction, ...data } })),
  clearAuction: () => set(() => ({ auction: init_auction })),
  prevAuction: {},
  setPrevAuction: prevAuction => set({ prevAuction }),
  updatePrevAuction: data =>
    set(prev => ({ prevAuction: { ...prev.prevAuction, ...data } })),
  clearPrevAuction: () => set(() => ({ prevAuction: init_auction })),
  errors: {},
  setErrors: errors => set(errors),
  clearErrors: () => set(() => ({ errors: {} })),
  updateErrors: data => set(prev => ({ errors: { ...prev.errors, ...data } })),
  loadings: {
    info: false,
    locations: false,
  },
  setLoadings: data =>
    set(prev => ({ loadings: { ...prev.loadings, ...data } })),
  setAllLoadings: data =>
    set(prev => {
      const keysArray = Object.keys(prev.loadings);
      keysArray.forEach(key => {
        prev.loadings[key] = data;
      });
    }),
  fetchAuction: async id => {
    try {
      const response = await api.get(`carrier/auction/${id}`);
      set(prev => {
        const temp = {
          ...response.data,
          travel: reshapeTravel(response.data.travel),
        };
        return { auction: temp, prevAuction: temp };
      });
    } catch (e) {
      if (e.response.status === 401) {
        set(prev => ({
          ...prev,
          auction: {
            ...init_auction,
            access_denied: true,
          },
        }));
      } else if (e.response.status === 406) {
        set(prev => ({
          ...prev,
          auction: {
            ...init_auction,
            auctioned: true,
          },
        }));
      }
    }
  },
}));
