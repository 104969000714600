import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';

import { Input, Text, useSnackbar } from '@cargon/ui';

import { useVehicle } from '../context';
import isDefined from '../../../../../utils/isDefined';

export default function VehicleData() {
  const vehicle = useVehicle();
  const snackbar = useSnackbar();
  const params = useParams();

  useEffect(() => {
    if (!params.id)
      vehicle.setCurrentVehicle({
        total_gross_weight: null,
        capacity: null,
        cubage: null,
        tare_mass: null,
        exclusive: false,
      });
    else if (!isDefined(vehicle.data.exclusive)) {
      vehicle.setCurrentVehicle({
        exclusive: false,
      });
    }
  }, []);
  const hasVolume = vehicle.data?.is_wagon || vehicle.data?.type?.has_body_type;
  return (
    <Row className="form">
      {!vehicle.data.is_wagon && (
        <Col xs={12} md={6} className="mb-3">
          <Input
            label="PBT (Peso bruto total) em kg *"
            type="number"
            value={vehicle.data.total_gross_weight}
            onChange={event =>
              event.target.value < 0
                ? snackbar.show(
                    <Text> Campo não aceita números negativos </Text>,
                    {
                      type: 'error',
                    }
                  )
                : vehicle.setCurrentVehicle({
                    total_gross_weight: event.target.value,
                  })
            }
            error={vehicle.errors.total_gross_weight}
            placeholder="25500"
          />
        </Col>
      )}
      <Col xs={12} md={6} className="mb-3">
        <Input
          label="Tara em kg *"
          type="number"
          value={vehicle.data.tare_mass}
          onChange={event =>
            event.target.value < 0
              ? snackbar.show(
                  <Text> Campo não aceita números negativos </Text>,
                  {
                    type: 'error',
                  }
                )
              : vehicle.setCurrentVehicle({ tare_mass: event.target.value })
          }
          error={vehicle.errors.tare_mass}
          placeholder="17440"
        />
      </Col>
      {hasVolume && (
        <>
          {' '}
          <Col xs={12} md={6} className="mb-3">
            <Input
              label="Pallets"
              type="number"
              value={vehicle.data.capacity}
              onChange={event =>
                event.target.value < 0
                  ? snackbar.show(
                      <Text> Campo não aceita números negativos </Text>,
                      {
                        type: 'error',
                      }
                    )
                  : vehicle.setCurrentVehicle({ capacity: event.target.value })
              }
              error={vehicle.errors?.capacity}
              placeholder="32"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              label="Cubagem em m³"
              type="number"
              value={vehicle.data.cubage}
              onChange={event =>
                event.target.value < 0
                  ? snackbar.show(
                      <Text> Campo não aceita números negativos </Text>,
                      {
                        type: 'error',
                      }
                    )
                  : vehicle.setCurrentVehicle({ cubage: event.target.value })
              }
              error={vehicle.errors?.cubage}
              placeholder="90"
            />
          </Col>
        </>
      )}
    </Row>
  );
}
