import React, { useEffect, useRef, useState } from 'react';
import { FaFileUpload } from 'react-icons/fa';
import { Col } from 'react-bootstrap';

import { Button, Select, Text, useSnackbar } from '@cargon/ui';

import { DocumentUploader } from '~/utils/DocumentUploader';
import { CteParser } from '~/utils/ParserXmlToJsonDocuments/Cte';
import Upload from '~/components/Upload';
import { ListCtes } from '../ListCtes';

export default function UploadCteCsv({
  setCteList,
  destinationList,
  submitCteList,
}) {
  const [file, setFile] = useState();
  const [errors, setErrors] = useState({});
  const [ctes, setCtes] = useState([]);
  const [destination, setDestination] = useState();
  const [filteredCtes, setFilteredCtes] = useState([]);

  const snackbar = useSnackbar();
  const uploadRef = useRef();

  function onFilesChanged({ target }, hasError) {
    setFile({});
    setErrors({});

    if (hasError) {
      setErrors({ file: 'Algum arquivo selecionado é maior que 1MB.' });
      return;
    }

    setFile(Object.values(target.files));
  }

  async function getFileContent(file) {
    try {
      const documentUploader = new DocumentUploader('xml');

      const { files } = await documentUploader.execute(file);

      const content = files.map(file => {
        const cteParser = new CteParser(file);

        const {
          cte,
          invoices,
          amountReceivable,
          issuerDocument,
          contemplatedCteKey,
          taker,
        } = cteParser.process();

        const xml = file;

        if (!destination) {
          throw new Error('Selecione um destino');
        }

        const data = {
          destination,
          invoices,
          issuedAt: cte.issuedAt,
          cteNumber: cte.cteNumber,
          key: cte.key,
          sefaz_protocol: cte.cteNumber,
          xml,
          amountReceivable,
          issuerDocument,
          contemplatedCteKey,
          taker,
        };

        return data;
      });

      setCtes(old => [...old, ...content]);
    } catch (err) {
      snackbar.show(<Text>{err.message}</Text>, {
        type: 'error',
      });
    } finally {
      setFile(null);
      uploadRef.current.clearFiles();
    }
  }

  useEffect(() => {
    if (file) {
      getFileContent(file);
    }
  }, [file]);

  function filterEqualKey() {
    const filtered = ctes.reduce((acc, current) => {
      if (acc.find(old => old.key === current.key)) return acc;

      acc.push(current);

      return acc;
    }, []);

    setFilteredCtes(filtered);
  }

  useEffect(() => {
    filterEqualKey();
  }, [ctes]);

  function handleDelete(item) {
    setCtes(ctes.filter(e => e !== item));
  }

  function onSubmit() {
    submitCteList(filteredCtes);
    setCtes([]);
  }

  useEffect(() => {
    setCteList(filteredCtes);
  }, [filteredCtes]);

  return (
    <>
      <Col xs={12} className="mb-3">
        <Select
          placeholder={null}
          value={destination}
          label="Destino"
          options={destinationList}
          getOptionLabel={option => option.formatted}
          getOptionValue={option => option.id}
          onChange={item => setDestination(item)}
          isDisabled={!!ctes.length}
        />
      </Col>
      <Col>
        <Upload
          icon={<FaFileUpload size="40px" />}
          name="file"
          label="Importar XML"
          accept={['.xml', '.zip']}
          onChange={event => onFilesChanged(event)}
          error={errors.file}
          multiple
          ignoreValidation
          isDisabled={!destination}
          ref={uploadRef}
        />
      </Col>
      <Col>
        <ListCtes cteList={filteredCtes} handleDelete={handleDelete} />
      </Col>
      <Col className="d-flex justify-content-center mt-3">
        <Button variant="success" onClick={() => onSubmit()}>
          Salvar
        </Button>
      </Col>
    </>
  );
}
