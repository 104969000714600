import React, { useState } from 'react';
import { Card, DropdownButton, Text, useSnackbar, Button } from '@cargon/ui';
import { Row } from 'react-bootstrap';

import { Emitted } from '~/pages/Travels/Documents/DocumentItems/emitted';
import { Mdfe } from '~/pages/Travels/Documents/DocumentItems/mdfe';
import useLightBox from '~/hooks/useLightbox';
import { useTravel } from '~/pages/Travels/Details/provider';
import {
  downloadAllCteXmls,
  downloadAllMdfeXmls,
  fetchDocuments,
} from '~/pages/Travels/Documents/helpers';

import useModal from '~/hooks/useModal';
import Cte from '~/pages/Travels/Documents/Modals/Cte';

export default function Documents() {
  const travel = useTravel();
  const snackbar = useSnackbar();
  const lightbox = useLightBox();
  const cteModal = useModal();

  const [documentsController, setDocumentsController] = useState({
    documents: {},
    activeIndex: 0,
  });
  const [downloading, setDownloading] = useState(false);

  const fetchDocs = async (emittedId, options) => {
    const { refetch = false, setLoading } = options;
    if (!refetch && documentsController.activeIndex === emittedId) {
      setDocumentsController(prev => ({
        ...prev,
        activeIndex: null,
      }));
      return;
    }
    if (setLoading) {
      setLoading(true);
    }
    fetchDocuments(emittedId, snackbar).then(documents => {
      setDocumentsController(prev => ({
        ...prev,
        documents: { ...prev.documents, [emittedId]: documents },
        activeIndex: emittedId,
      }));
      if (setLoading) {
        setLoading(false);
      }
    });
  };
  const dlAllMdfeXML = async () => {
    setDownloading(true);
    downloadAllMdfeXmls(travel.travelInfo?.mdfes, snackbar).then(() => {
      setDownloading(false);
    });
  };
  const dlAllEmittedXML = async () => {
    setDownloading(true);
    downloadAllCteXmls(travel.travelInfo?.emitteds, snackbar).then(() => {
      setDownloading(false);
    });
  };

  return (
    <>
      <Card
        loading={travel.isFetching}
        header={
          <>
            <Text type="header" color="dark">
              Documentos da viagem
            </Text>
          </>
        }
        HeaderRightComponent={
          <>
            <Row className="d-flex justify-content-end mr-1">
              <Button
                style={{ marginRight: 7.5 }}
                disabled={
                  !travel.travelInfo?.driver ||
                  !travel.travelInfo?.tractionVehicle
                }
                outlined
                onClick={() => cteModal.open()}
                className="py-2 "
              >
                <Text type="regular" color="primary" font-weight="600">
                  Adicionar CTe/MDFe
                </Text>
              </Button>
              <DropdownButton
                title="Download"
                variant="secondary"
                loading={downloading}
                options={[
                  {
                    title: 'Baixar XML MDFes',
                    onClick: () => dlAllMdfeXML(),
                    disabled: travel.travelInfo?.mdfes?.length === 0,
                    type: 'item',
                  },
                  {
                    title: 'Baixar XML CTes',
                    onClick: () => dlAllEmittedXML(),
                    disabled: travel.travelInfo?.emitteds?.length === 0,
                    type: 'item',
                  },
                ]}
              />
            </Row>
          </>
        }
      >
        <Cte
          status={travel.travelInfo.status}
          handleClose={cteModal.close}
          showModal={cteModal.isOpen}
        />

        {travel.travelInfo?.mdfes?.map(mdfe => (
          <>
            <Mdfe mdfe={mdfe} />
            <hr />
          </>
        ))}
        {travel.travelInfo?.emitteds?.map(emitted => (
          <>
            <Emitted
              emitted={emitted}
              lightbox={lightbox}
              fetchDocuments={fetchDocs}
              documentsController={documentsController}
            />
            <hr />
          </>
        ))}
        {!travel.travelInfo.emitteds?.length &&
          !travel.travelInfo.mdfes?.length && (
            <Text color="gray" type="regular">
              Esta viagem ainda não possui documentação emitida!
            </Text>
          )}
      </Card>
    </>
  );
}
