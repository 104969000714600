import api from '../../../../../services/api';
import Text from '../../../../../components/Text';
import React from 'react';

export async function fetchReasons() {
  try {
    const response = await api.get('v3/log-reason');
    return response.data;
  } catch {
    return null;
  }
}
