import React, { useState, useEffect } from 'react';
import { useSnackbar, Select, Text } from '@cargon/ui';
import { driverStore } from '~/services/store/Driver';
import { fetchPaymentMethods } from '~/services/fetch';

export default function PaymentMethods() {
  const snackbar = useSnackbar();
  const { currentDriver, updateCurrentDriver, errors } = driverStore();
  const [paymentMethods, setPaymentMethods] = useState();

  useEffect(() => {
    async function getPaymentMethods() {
      try {
        const data = await fetchPaymentMethods();
        setPaymentMethods(data);
      } catch (err) {
        snackbar.show(<Text>Erro ao consultar os Métodos de pagamento!</Text>, {
          type: 'error',
        });
      }
    }

    getPaymentMethods();
  }, []);

  return (
    <Select
      label="Método de pagamento *"
      options={paymentMethods}
      value={currentDriver?.driver?.payment}
      onChange={value => {
        updateCurrentDriver({
          driver: { ...currentDriver?.driver, payment: { ...value } },
        });
      }}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      error={errors['payment.id'] || errors['driver.payment.id']}
    />
  );
}
