import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function AuthLayout({ children, ...props }) {
  return (
    <Container fluid className="h-100 login" {...props}>
      <Row className="h-100 justify-content-center align-items-center">
        <Col lg={3} md={3} xs>
          <Card>
            <Card.Body>
              <Row className="p-4">
                <Col xs={12}>{children}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
