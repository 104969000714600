import React from 'react';
import { Text } from 'components';
import { Col } from 'react-bootstrap';
import * as Styled from './styles';

export function DestinationProducts({ items }) {
  return (
    <>
      {items.map(item => (
        <Styled.Wrapper>
          <Col>
            <Text type="label" color="black">
              Produto:
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {item.product.name}
            </Text>
          </Col>
          <Col>
            <Text type="label" color="black">
              Quantidade:
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {item.quantity}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="black">
              Categoria:
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {item.product.productType?.label || 'Não informada'}
            </Text>
          </Col>
        </Styled.Wrapper>
      ))}
    </>
  );
}
