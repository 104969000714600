import { takeLatest, call, put, all, select } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import api from '~/services/api';

import Types from './types';
import Actions from './actions';

export function* fetchVehicleTypes() {
  try {
    const response = yield call(api.get, 'vehicle-types', {
      params: { app: true },
    });

    yield put(Actions.fetchVehicleTypesSuccess(response.data));
  } catch (ex) {
    yield put(Actions.fetchVehicleTypesFailure());
  }
}

export function* fetchVehicleAxis() {
  try {
    const response = yield call(api.get, 'vehicle-axe', {
      params: { app: true },
    });

    yield put(Actions.fetchVehicleAxisSuccess(response.data));
  } catch (ex) {
    yield put(Actions.fetchVehicleAxisFailure());
  }
}

export function* fetchVehicleImplements() {
  try {
    const response = yield call(api.get, 'vehicle-implement-types', {
      params: { app: true },
    });

    yield put(Actions.fetchVehicleImplementsSuccess(response.data));
  } catch (ex) {
    yield put(Actions.fetchVehicleImplementsFailure());
  }
}

export function* fetchVehicleBodies() {
  try {
    const response = yield call(api.get, 'body-types', {
      params: { app: true },
    });

    yield put(Actions.fetchVehicleBodiesSuccess(response.data));
  } catch (ex) {
    yield put(Actions.fetchVehicleBodiesFailure());
  }
}

export function* fetchVehicles({ payload }) {
  try {
    const response = yield call(api.get, 'vehicles', {
      params: {
        not_paginated: true,
        carrier: true,
        ...payload.params,
      },
    });

    yield put(Actions.fetchVehiclesSuccess(response.data));
  } catch (ex) {
    yield put(Actions.fetchVehiclesFailure());
  }
}

export default all([
  takeLatest(Types.FETCH_VEHICLE_TYPES, fetchVehicleTypes),
  takeLatest(Types.FETCH_VEHICLE_AXIS, fetchVehicleAxis),
  takeLatest(Types.FETCH_VEHICLE_IMPLEMENTS, fetchVehicleImplements),
  takeLatest(Types.FETCH_VEHICLE_BODIES, fetchVehicleBodies),
  takeLatest(Types.FETCH_VEHICLES, fetchVehicles),
]);
