import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export default function PageHeader({
  title,
  badge,
  other_details,
  RightComponent,
  ...props
}) {
  return (
    <Card className="card-progress mb-3">
      <Card.Body className="py-3 px-5">
        <Row className="mt-3 mb-3">
          <Col>
            <h5 className="card-title">
              {title} {badge}
            </h5>
            {other_details}
          </Col>

          <Col className="d-flex justify-content-end">{RightComponent}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
