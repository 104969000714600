import * as Yup from 'yup';
import { mainSchema, ownerSchema, tractionSchema, wagonSchema } from './index';

export const mainTractionVehicleSchema = Yup.object().shape({
  ...ownerSchema.fields,
  ...mainSchema.fields,
  ...tractionSchema.fields,
});
export const mainWagonVehicleSchema = Yup.object().shape({
  ...ownerSchema.fields,
  ...mainSchema.fields,
  ...wagonSchema.fields,
});
