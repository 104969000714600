/* eslint-disable import/no-unresolved */
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaArrowRight, FaCheck, FaClock } from 'react-icons/fa';
import { Card, Text } from '@cargon/ui';
// import { useTravel } from 'v3/pages/Travel';
import TextComponent from 'components/Text';
import useModal from 'hooks/useModal';
import { DestinationProductsModal } from 'components/DestinationProductsModal';
import Button from 'components/Button';
import {
  diffInMinutes,
  formatAddress,
  formatDate,
  formatTime,
  pluralize,
} from '../../../utils/formatter';

import theme from '../../../styles/theme';
import InformModal from './Modals/Inform';

import * as Styled from './styles';

function LocationItem({
  id,
  isCompleted,
  isOrigin,
  action,
  address,
  scheduledTime,
  arrivalTime,
  effectiveTime,
  startSchedule,
  hasStartSchedule,
  destinationProducts,
  refetchTravel,
}) {
  const [waitingTime, setWaitingTime] = useState();
  const [informType, setInformType] = useState('');
  const destinationProductsModal = useModal();

  const informModal = useModal();

  useEffect(() => {
    const diff = diffInMinutes({
      laterDate: effectiveTime,
      earlierDate: arrivalTime,
    });
    if (diff < 0) {
      return;
    }
    const hours = parseInt(diff / 60, 10);
    const minutes = diff % 60;

    if (typeof diff === 'number') {
      if (hours < 10) {
        if (minutes < 10) setWaitingTime(`0${hours}:0${minutes}`);
        else setWaitingTime(`0${hours}:${minutes}`);
      } else if (minutes < 10) setWaitingTime(`${hours}:0${minutes}`);
      else setWaitingTime(`${hours}:${minutes}`);
    }
  }, [arrivalTime, effectiveTime]);

  return (
    <>
      <InformModal
        id={id}
        isOpen={informModal.isOpen}
        onClose={informModal.close}
        type={informType}
        isOrigin={isOrigin}
        refetchTravel={refetchTravel}
      />
      <Col md={hasStartSchedule ? 4 : 6} xs={12}>
        <div className="d-flex pl-2">
          <div className="pt-2">
            <Styled.Marker.Tray completed={isCompleted} />
          </div>
          <div className="position-relative pl-4">
            <Styled.Marker completed={isCompleted}>
              {isCompleted && <FaCheck color="#fff" size={9} />}
            </Styled.Marker>
            <Text as="div" color="dark">
              {action}
            </Text>
            <Text as="div" type="regular" color="gray">
              {address}
            </Text>
            {destinationProducts && destinationProducts.length > 0 && (
              <>
                <TextComponent
                  onClick={() => {
                    destinationProductsModal.open();
                  }}
                  clickable
                  color="light_blue"
                >
                  Visualizar Produtos{' '}
                </TextComponent>
                <DestinationProductsModal
                  handleClose={destinationProductsModal.close}
                  destinationProducts={destinationProducts}
                  isOpen={destinationProductsModal.isOpen}
                />
              </>
            )}
          </div>
        </div>
      </Col>
      <Styled.Schedules
        xs={12}
        md={hasStartSchedule ? 8 : 6}
        completed={isCompleted}
      >
        <Row>
          <Col>
            <div className="text-center">
              <Text as="div" color="dark" className="d-block d-sm-none mb-3">
                Agendado
              </Text>
              <div className="d-flex flex-row">
                {!!startSchedule && (
                  <>
                    <div>
                      <Text as="div" type="regular" color="dark">
                        {formatDate(startSchedule)}
                      </Text>

                      <>
                        <FaClock color={theme.colors.dark} className="mr-1" />
                        <Text type="label" color="dark">
                          {formatTime(startSchedule)}
                        </Text>
                      </>
                    </div>
                    <div className="mt-3">
                      <FaArrowRight color={theme.colors.dark} />
                    </div>
                  </>
                )}
                <div className={!hasStartSchedule && 'ml-4'}>
                  <Text as="div" type="regular" color="dark">
                    {scheduledTime
                      ? formatDate(scheduledTime)
                      : 'Não informado.'}
                  </Text>
                  {scheduledTime && (
                    <>
                      <FaClock color={theme.colors.dark} className="mr-1" />
                      <Text type="label" color="dark">
                        {formatTime(scheduledTime)}
                      </Text>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="text-center">
              <>
                <Text as="div" color="dark" className="d-block d-sm-none mb-3">
                  Chegada
                </Text>
                {arrivalTime ? (
                  <Text as="div" type="regular" color="dark">
                    {formatDate(arrivalTime)}
                  </Text>
                ) : (
                  <Button
                    variant="secondary"
                    type="sm"
                    onClick={() => {
                      informModal.open();
                      setInformType('arrival_time');
                    }}
                  >
                    <Text type="regular" color="dark">
                      Informar
                    </Text>
                  </Button>
                )}
                {arrivalTime && (
                  <>
                    <FaClock color={theme.colors.dark} className="mr-1" />
                    <Text type="label" color="dark">
                      {formatTime(arrivalTime)}
                    </Text>
                  </>
                )}
              </>
            </div>
          </Col>
          {arrivalTime && effectiveTime && waitingTime && (
            <>
              <FaClock color={theme.colors.dark} className="mr-1" />
              <Text type="label" color="dark">
                {waitingTime}
              </Text>
            </>
          )}
          <Col>
            <div className="text-center">
              <Text as="div" color="dark" className="d-block d-sm-none mb-3">
                Efetivado
              </Text>
              {effectiveTime ? (
                <Text as="div" type="regular" color="dark">
                  {formatDate(effectiveTime)}
                </Text>
              ) : (
                <Button
                  variant="secondary"
                  type="sm"
                  onClick={() => {
                    informModal.open();
                    setInformType('effective_time');
                  }}
                >
                  <Text type="regular" color="dark">
                    Informar
                  </Text>
                </Button>
              )}
              {effectiveTime && (
                <>
                  <FaClock color={theme.colors.dark} className="mr-1" />
                  <Text type="label" color="dark">
                    {formatTime(effectiveTime)}
                  </Text>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Styled.Schedules>
    </>
  );
}

export default function Locations({ travel, loading, refetchTravel }) {
  const { origin } = travel;
  const [waitingHours, setWaitingHours] = useState();
  const [waitingMinutes, setWaitingMinutes] = useState();

  const datetimeFinishedLoading = travel?.logs?.filter(
    log => log.status_to === 11
  )[0]?.created_at;

  if (datetimeFinishedLoading) origin.effective_time = datetimeFinishedLoading;

  const destinations = useMemo(
    () => travel.destinations,
    [travel.destinations]
  );

  const isOriginCompleted = useMemo(
    () => origin?.arrival_time,
    [travel.status_id, origin]
  );

  useEffect(() => {
    let diff = diffInMinutes({
      laterDate: origin?.effective_time,
      earlierDate: origin?.arrival_time,
    });

    for (let i = 0; i < destinations?.length; i++) {
      diff += diffInMinutes({
        laterDate: destinations[i]?.effective_time,
        earlierDate: destinations[i]?.arrival_time,
      });
    }
    const hours = parseInt(diff / 60, 10);
    const minutes = diff % 60;

    if (typeof diff === 'number') {
      if (hours < 10) {
        setWaitingHours(`0${hours}`);
      } else {
        setWaitingHours(hours);
      }
      setWaitingMinutes(minutes);
    }
  }, [origin, destinations]);

  if (!travel.attendedId && loading === undefined) {
    return null;
  }

  const hasStartSchedule = !!(
    travel.origin?.[0]?.start_schedule ||
    travel.destinations.map(item => item.start_schedule)[0] !== null
  );

  return (
    <Card header={<Text>Rota de viagem</Text>} loading={loading}>
      <Row>
        <Col md={hasStartSchedule ? 4 : 6} xs={12}>
          <div className="mb-3">
            <Text type="body" color="dark">
              Origem
            </Text>
          </div>
        </Col>
        <Col
          md={hasStartSchedule ? 3 : 2}
          className="text-center d-none d-sm-block"
        >
          <Text type="body" color="dark">
            Agendado
          </Text>
        </Col>
        <Col md={2} className="text-center d-none d-sm-block">
          <Text type="body" color="dark">
            Chegada
          </Text>
        </Col>
        <Col
          md={2}
          className={hasStartSchedule ? ' text-right ml-2' : 'text-center'}
        >
          <Text type="body" color="dark">
            Efetivado
          </Text>
        </Col>
      </Row>
      <Row className="mb-3 d-flex align-items-center">
        <LocationItem
          id={origin.id}
          action="Coleta"
          isOrigin
          isCompleted={isOriginCompleted}
          address={formatAddress({
            address: origin.address,
            number: origin.number,
            complement: origin.complement,
            neighborhood: origin.neighborhood,
            city: origin.city,
            province: origin.province,
            zip_code: origin.zip_code,
          })}
          scheduledTime={origin.scheduled_time}
          arrivalTime={origin.arrival_time}
          startSchedule={origin.start_schedule}
          hasStartSchedule={hasStartSchedule}
          effectiveTime={origin.effective_time}
          refetchTravel={refetchTravel}
        />
      </Row>
      <div className="mb-3">
        <Text type="body" color="dark">
          {pluralize('Destino', destinations.length)}
        </Text>
      </div>
      {destinations.map(destination => {
        const isCompleted =
          destination.arrival_time && destination.effective_time;

        const action =
          destination.action.toLowerCase() === 'delivery'
            ? 'Entrega'
            : 'Coleta';

        return (
          <Row>
            <LocationItem
              id={destination.id}
              action={action}
              isCompleted={isCompleted}
              isOrigin={false}
              address={formatAddress({
                address: destination.address,
                number: destination.number,
                complement: destination.complement,
                neighborhood: destination.neighborhood,
                city: destination.city,
                province: destination.province,
                zip_code: destination.zip_code,
              })}
              scheduledTime={destination.scheduled_time}
              arrivalTime={destination.arrival_time}
              effectiveTime={destination.effective_time}
              startSchedule={destination?.start_schedule}
              hasStartSchedule={hasStartSchedule}
              destinationProducts={destination.destinationProducts}
              refetchTravel={refetchTravel}
            />
          </Row>
        );
      })}
      {!!waitingHours && !!waitingMinutes && (
        <Col className="text-center">
          <Text type="regular" color="gray">
            Tempo de espera total -
          </Text>
          <Text
            weight={500}
            type="regular"
          >{` ${waitingHours}:${waitingMinutes}hrs`}</Text>
        </Col>
      )}
    </Card>
  );
}
