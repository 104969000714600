import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { NotificationContextProvider } from './components/Menu/Notifications';
import Snackbar from './components/Snackbar';
import SnackbarUi from '@cargon/ui/dist/Snackbar';

import './styles.css';
import 'react-datepicker/dist/react-datepicker.css';

import theme from '~/styles/theme';
import store, { persistor } from '~/store';
import GlobalStyle from './styles';

import Routes from '~/routes';
import runOneSignal from './utils/OneSignal';

registerLocale('pt-BR', ptBR);

export default function App() {
  useEffect(() => {
    runOneSignal();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div className="App h-100">
          <GlobalStyle />
          <PersistGate persistor={persistor}>
            <Snackbar>
              <SnackbarUi>
                <NotificationContextProvider>
                  <Routes />
                </NotificationContextProvider>
              </SnackbarUi>
            </Snackbar>
          </PersistGate>
        </div>
      </ThemeProvider>
    </Provider>
  );
}
