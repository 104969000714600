/* eslint-disable import/no-cycle */
// Public routes
import Login from '~/pages/Login';
import ForgotPassword from '~/pages/ForgotPassword';

// Private routes
import Home from '~/pages/Home';
import { DriverList, DriverSingle } from '~/pages/Driver';
import Travels from '~/pages/Travels';
import { VehicleList, VehicleSingle } from '~/pages/Vehicle';
import TravelDetails from '~/pages/Travels/Details';
import LoadDetails from '~/pages/Load/Detail';
import LoadList from '~/pages/Load/List';
import TravelsAllocated from '~/pages/TravelsAllocated';
import AuctionIndex from '~/pages/Auction/Single';
import CTesReport from '~/pages/Reports/CTe';
import Deal from '~/pages/Deal';
import List from '~/pages/Deal/List';
import {
  ShippingCompanyList,
  ShippingCompanySingle,
} from '../pages/ShippingCompanies';
import AcceptedTravelsReport from '../pages/Reports/Travels/Accepted';
import RefusedTravels from '../pages/Reports/Travels/Refused';
import ConfirmedTravelsReport from '../pages/Reports/Travels/Confirmed';

export const publicRoutes = [
  {
    key: 'login',
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    key: 'forgot-password',
    path: '/forgot-password/:token',
    component: ForgotPassword,
    exact: true,
  },
];

export const privateRoutes = [
  {
    key: 'home',
    path: '/',
    component: Home,
    title: 'Início',
    exact: true,
  },
  {
    key: 'driver',
    path: '/motoristas',
    title: 'Motoristas',
    component: DriverList,
    exact: true,
  },
  {
    key: 'driver register',
    path: '/motoristas/cadastrar',
    title: 'Cadastrar motorista',
    component: DriverSingle,
    exact: true,
  },
  {
    key: 'driver update',
    path: '/motoristas/:id',
    title: 'Visualizar motorista',
    component: DriverSingle,
    exact: true,
  },
  {
    key: 'shipping_company register',
    path: '/transportadoras',
    title: 'Transportadoras',
    component: ShippingCompanyList,
    exact: true,
  },
  {
    key: 'shipping_company register',
    path: '/transportadoras/cadastrar',
    title: 'Cadastrar transportadoras',
    component: ShippingCompanySingle,
    exact: true,
  },
  {
    key: 'shipping_company update',
    path: '/transportadoras/:id',
    title: 'Visualizar transportadoras',
    component: ShippingCompanySingle,
    exact: true,
  },
  {
    key: 'travels',
    path: '/viagens',
    title: 'Viagens',
    component: Travels,
    exact: true,
  },
  {
    key: 'travel details',
    path: '/viagens/:id',
    title: 'Detalhes da viagem',
    component: TravelDetails,
    exact: true,
  },
  {
    key: 'travel allocated',
    path: '/alocadas',
    title: 'Viagens alocadas',
    component: TravelsAllocated,
    exact: true,
  },
  {
    key: 'vehicles',
    path: '/veiculos',
    title: 'Veículos',
    component: VehicleList,
    exact: true,
  },
  {
    key: 'vehicle register',
    path: '/veiculos/cadastrar',
    title: 'Cadastrar veículo',
    component: VehicleSingle,
    exact: true,
  },
  {
    key: 'vehicle show',
    path: '/veiculos/:id',
    title: 'Visualizar veículo',
    component: VehicleSingle,
    exact: true,
  },
  {
    key: 'deal',
    path: '/negociacoes/cadastrar/:load_id',
    title: 'Novo chat',
    component: Deal,
    exact: true,
  },
  {
    key: 'deals',
    path: '/negociacoes',
    title: 'Chat',
    component: List,
    exact: true,
  },
  {
    key: 'loads',
    path: '/cargas',
    component: LoadList,
    title: 'Listagem de cargas',
    exact: true,
  },
  {
    key: 'load',
    path: '/cargas/:id',
    component: LoadDetails,
    title: 'Detalhes da carga',
    exact: true,
  },
  {
    key: 'auction',
    path: '/oferta/:id',
    component: AuctionIndex,
    title: 'Oferta de viagem',
    exact: true,
  },
  {
    key: 'ctes',
    path: '/relatorios/ctes',
    component: CTesReport,
    title: 'CTes',
    exact: true,
  },
  {
    key: 'accepted_travels',
    path: '/relatorios/viagens-aceitas',
    component: AcceptedTravelsReport,
    title: 'Viagens Aceitas',
    exact: true,
  },
  {
    key: 'refused_travels',
    path: '/relatorios/viagens-recusadas',
    component: RefusedTravels,
    title: 'Viagens Recusadas',
    exact: true,
  },
  {
    key: 'confirmed_travels',
    path: '/relatorios/viagens-confirmadas',
    component: ConfirmedTravelsReport,
    title: 'Viagens Confirmadas',
    exact: true,
  },
];
