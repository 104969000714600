import produce from 'immer';
import React from 'react';
import { Button, Input, Text } from '@cargon/ui';
import { masks } from '~/pages/ShippingCompanies/List/masks';
import * as Styled from './styles';

function Filter({ filter, setFilter, handleSubmit }) {
  function applyMask(rawValue, options) {
    const regex = new RegExp(options.placeholderChar, 'g');
    if (rawValue.replace(regex, '').length > masks.cpf.length) {
      return masks.cnpj;
    }

    return masks.cpf;
  }

  return (
    <Styled.Card Title="Filtro">
      <Styled.FilterDiv>
        <Styled.SelectDiv>
          <Input
            label="Transportadora"
            value={filter.name}
            placeholder="Digite o nome..."
            onChange={({ target }) => {
              setFilter(
                produce(draft => {
                  draft.name = target.value;
                })
              );
            }}
          />
        </Styled.SelectDiv>
        <Styled.ButtonSelectDiv>
          <Styled.SelectDiv>
            <Input
              label="CPF/CNPJ"
              placeholder="Digite o CPF ou CNPJ..."
              value={filter.cgccpf}
              onChange={({ target }) =>
                setFilter(
                  produce(draft => {
                    draft.cgccpf = target.value;
                  })
                )
              }
              masked
              guide={false}
              mask={(rawValue, options) => applyMask(rawValue, options)}
            />
          </Styled.SelectDiv>
          <Styled.ButtonDiv>
            <Button variant="success" onClick={() => handleSubmit()}>
              <Text type="regular" weight={500}>
                Filtrar
              </Text>
            </Button>
          </Styled.ButtonDiv>
        </Styled.ButtonSelectDiv>
      </Styled.FilterDiv>
    </Styled.Card>
  );
}

export default Filter;
