import api from '../../api';

export async function fetchVehicle({ id }) {
  const response = await api.get(`carrier/vehicle/${id}`);
  return response.data;
}

export async function submitVehicle({ id, body }) {
  await api.put(`vehicles/${id}`, body);
}

export async function fetchManufacturers({ search }) {
  const response = await api.get('manufacturers/select', {
    params: { search },
  });

  return response.data;
}

export async function fetchVehiclesByUserShippingCompanies({ search }) {
  const response = await api.get('carrier/vehicle/byShippingCompanies', {
    params: { search },
  });
  return response.data;
}

export async function fetchModels({ manufacturerID, search }) {
  const response = await api.get(`models/${manufacturerID}/select`, {
    params: { search },
  });

  return response.data;
}

export async function fetchTrackers({ search }) {
  try {
    const response = await api.get('select/trackers', { params: { search } });

    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchTrackerModels({ trackerID, search }) {
  const response = await api.get('filter-tracker-types', {
    params: { tracker: trackerID, search },
  });

  return response.data;
}

export async function fetchAnttTypes() {
  try {
    const response = await api.get(`antt-types`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchTrackersApp() {
  try {
    const response = await api.get('tracker/App');
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchFuels() {
  try {
    const response = await api.get('fuel');
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchTrackerTypes(search, tracker) {
  try {
    const response = await api.get(
      `filter-tracker-types?${
        tracker ? `tracker=${tracker.id}&` : ''
      }search=${search}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchOwners(search) {
  try {
    const response = await api.get(
      `v3/persons/owner/shipping-company?search=${search}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchVehicleTypes() {
  try {
    const response = await api.get('vehicle-types', {
      params: { app: true },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchCountries() {
  try {
    const response = await api.get('countries');
    return response.data;
  } catch (err) {
    return [];
  }
}

export async function fetchVehicleAxe() {
  try {
    const response = await api.get('vehicle-axe', {
      params: { app: true },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchTopTrackerTypes(tracker) {
  try {
    const response = await api.get('select/tracker-types', {
      params: {
        tracker: tracker.id,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchBrands(search) {
  try {
    const response = await api.get(`manufacturers/select?search=${search}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchWagonsBrands(search) {
  try {
    const response = await api.get(`manufacturers/wagons?search=${search}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchBodies() {
  try {
    const response = await api.get(`body-types?app=${true}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchImplements() {
  try {
    const response = await api.get(`vehicle-implement-types?app=${true}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchModelsV3(id) {
  try {
    const response = await api.get(`v3/models/${id}`);
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fetchSelectOptions(url, params = {}) {
  try {
    const { data } = await api.get(url, { params });

    return data;
  } catch (err) {
    return [];
  }
}

export async function removeVehicleDriverRelation({ id, value }) {
  await api.delete(`v3/vehicle/driver/${id}/${value}`);
}

export async function addVehicleDriverRelation({ id, payload }) {
  await api.put(`v3/vehicle/driver/${id}`, payload);
}

export async function fetchSearchedDriver({ search }) {
  const response = await api.get('shipping-companies/drivers', {
    params: { driver: search },
  });
  return response.data;
}

export async function fetchTollTags() {
  const response = await api.get('vehicle/toll-tags');
  return response.data.data;
}

export async function updateTollTags({ data }) {
  await api.put('vehicle/toll-tags/update', data);
}
