/* eslint-disable import/no-unresolved */
import api from '~/services/api';
import { formatCurrency } from '~/utils/formatter';

export async function fetchPricingOrders(setPricingOrders) {
  const { company_id } = JSON.parse(localStorage.getItem('usuario'));

  try {
    const { data: orders } = await api.get(
      `company/${company_id}/pricing-orders?active_only=true`
    );

    setPricingOrders(
      orders.map(order => ({
        company_order_id: order.id,
        name: order.name,
        price: '',
        due_date: null,
      }))
    );
  } catch (err) {
    setPricingOrders([]);
  }
}

export function handlePricingOrderChange(
  field,
  index,
  value,
  pricingOrderList,
  setPricingOrderList
) {
  const temp = pricingOrderList.slice();
  temp[index][field] = value;

  setPricingOrderList(temp);
}

export function handlePricingOrderDelete(
  index,
  pricingOrderList,
  setPricingOrderList
) {
  const temp = pricingOrderList.slice();
  temp.splice(index, 1);

  setPricingOrderList(temp);
}

async function fetchDistanceInKilometers(coordinates) {
  try {
    const response = await api.get('load/distance', {
      params: {
        coordinates,
      },
    });

    return response.data.lengthInMeters / 1000;
  } catch (ex) {
    return 0;
  }
}

export async function calculatePricePerKilometer(fullPrice, coordinates) {
  const distanceInKilometers = await fetchDistanceInKilometers(coordinates);
  if (distanceInKilometers) {
    const pricePerKilometer = fullPrice / distanceInKilometers;
    return `${formatCurrency(pricePerKilometer)}/km`;
  }

  return null;
}
