import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

import { Button, Text } from '@cargon/ui';
import Header from '~/components/Header';
import Offset from '~/components/Offset';

import { driverStore } from 'services/store/Driver';

import { Attachments } from '~/pages/Driver/Single/shared/Cards';

import {
  Address,
  PaymentMethods,
  CNH,
  EmergencyContact,
  PersonalData,
  Resume,
  AttachShippingCompany,
  UserData,
  Contacts,
} from './Cards';

import AttachVehicle from '../shared/Cards/AttachVehicle';

import * as Styled from '../../shared/styles';
import PaymentCards from '../../../../components/PaymentCards';

export default function Details() {
  const history = useHistory();
  const {
    clearCurrentDriver,
    clearPrevDriver,
    updateCurrentDriver,
    updatePrevDriver,
    currentDriver,
    loadings,
  } = driverStore();

  return (
    <>
      <Header
        title="Detalhes do Motorista"
        RightComponent={
          <>
            <Styled.ButtonsContainer className="pt-5">
              <Button variant="secondary" onClick={() => history.goBack()}>
                <Text color="dark" weight={500}>
                  Voltar
                </Text>
              </Button>
              <Link to="cadastrar">
                <Button
                  onClick={() => {
                    clearCurrentDriver();
                    clearPrevDriver();
                  }}
                >
                  <Text color="white" weight={500}>
                    Novo Motorista
                  </Text>
                </Button>
              </Link>
            </Styled.ButtonsContainer>
          </>
        }
      />
      <Offset>
        <Container>
          <Row>
            <Col xs={12}>
              <Resume />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <PersonalData />
              <UserData />
              <Contacts />
              <PaymentMethods />
            </Col>
            <Col xs={12} md={6}>
              <CNH />
              <Address />
              <PaymentCards
                setPaymentCards={cards => {
                  updateCurrentDriver({ paymentCards: cards });
                  updatePrevDriver({ paymentCards: cards });
                }}
                paymentCards={currentDriver?.paymentCards}
                loading={loadings.paymentCard}
              />
              <EmergencyContact />
              <AttachShippingCompany />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <AttachVehicle />
              <Attachments />
            </Col>
          </Row>
        </Container>
      </Offset>
    </>
  );
}
