import React from 'react';

import { Col, Row } from 'react-bootstrap';

import { Button, Modal, Text } from '@cargon/ui';

export function IncompleteVehicleOrDriverModal({
  vehiclesData,
  driverData,
  isOpen,
  onClose,
}) {
  return (
    <>
      <Modal
        closeButton={false}
        show={isOpen}
        onHide={onClose}
        size="lg"
        heading={
          <Text type="subheader" color="dark">
            Cadastros incompletos
          </Text>
        }
        body={
          <Row className="mx-0 mt-1">
            <Col xs={12} className="mb-2">
              <Text color="dark">
                Para confirmar essa viagem, é necessário que os seguintes dados
                estejam preenchidos:
              </Text>
            </Col>
            {driverData && (
              <>
                <Col xs={12}>
                  <Text type="subheader" color="dark">
                    Motorista:
                    <br />
                    <a
                      href={`/motoristas/${driverData.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {driverData.social_name}
                    </a>
                  </Text>
                </Col>
                <Col xs={12} className="ml-2 mb-2">
                  <Text>{`- ${driverData.missingFields}`}</Text>
                </Col>
              </>
            )}

            {vehiclesData && vehiclesData.length > 0 && (
              <>
                <Col xs={12} className="mb-2">
                  {' '}
                  <Text type="subheader" color="dark">
                    {' '}
                    Veículos:{' '}
                  </Text>
                </Col>
              </>
            )}

            {vehiclesData.map(vehicleData => (
              <>
                <Col xs={12} className="mb-2">
                  <a
                    href={`/veiculos/${vehicleData.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {vehicleData.plate}
                  </a>
                  <br />

                  <Text className="ml-2 mb-2">{`- ${vehicleData.missingFields}`}</Text>
                </Col>
              </>
            ))}
          </Row>
        }
        footer={
          <Button
            data-testid="count"
            onClick={() => onClose()}
            color="primary"
            autoFocus
          >
            Fechar
          </Button>
        }
      />
    </>
  );
}
