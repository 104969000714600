import { Nav, Tab } from 'react-bootstrap';
import styled from 'styled-components';

export const ProgressCard = styled.div`
  min-width: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  left: 32px;
  top: 25px;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

export const ActiveTabContent = styled(Tab.Content)`
  padding: 20px 20px;
`;

export const TabPanel = styled(Tab.Pane)``;

export const TabLink = styled(Nav.Link)`
  width: 100%;
  padding: 15px 30px;
  color: #464e5f;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  background-color: #ffffff;
  border-bottom: 1px solid #f2f2f2;
  transition: all 0.1s ease-in;

  &:hover {
    background-color: #f2f2f2;
    color: #464e5f;
    border-bottom-color: #f2f2f2;
  }

  &.active {
    background-color: #3b99ff;
    color: #ffffff;
    border-bottom-color: #3b99ff;
  }
`;
