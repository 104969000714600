import styled from 'styled-components';

export const ContainerCard = styled.div`
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

export const CardHeader = styled.div`
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 1rem;
  padding: 3rem 2rem 1rem 2rem;
  border-bottom: 1px solid #f2f2f2;
`;

export const CardBody = styled.div`
  padding: 2rem 3rem 2rem 3rem;
`;
