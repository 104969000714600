import React from 'react';
import { Modal, Text, Button } from '@cargon/ui';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function CheckRTNRCModal({
  isOpen,
  close,
  validateRNTRC,
  vehicle = false,
  implementList = [],
  shippingCompany = false,
}) {
  return (
    <Modal
      show={isOpen}
      handleClose={() => close()}
      heading="RNTRC vencido!"
      body={
        <Row>
          <Col>
            {shippingCompany && (
              <Text>
                A data de vencimento do RNTRC informada anteriormente é
                inválida.
                <br /> <br />{' '}
                <strong>
                  Por favor, verifique se o documento já foi renovado antes de
                  continuar a operação!
                </strong>{' '}
              </Text>
            )}
            {implementList.length > 0 && (
              <Text>
                A data de vencimento do RNTRC{' '}
                {/* eslint-disable-next-line no-nested-ternary */}
                {implementList.length > 1
                  ? `dos implemetos: <br/> <ul> ${implementList.map(
                      implement => <li>{implement}</li>
                    )}</ul>`
                  : `do implemento ${implementList[0]}`}{' '}
                informada anteriormente é inválida.
                <br /> <br />{' '}
                <strong>
                  Por favor, verifique se{' '}
                  {implementList.length > 1
                    ? 'os documentos  já foram renovados'
                    : 'o documento  já foi renovado'}{' '}
                  antes de continuar a operação!
                </strong>{' '}
              </Text>
            )}
            {vehicle && (
              <Text>
                A data de vencimento do RNTRC deste veículo informada
                anteriormente é inválida.
                <br /> <br />{' '}
                <strong>
                  Por favor, verifique se o documento já foi renovado antes de
                  continuar a operação!
                </strong>{' '}
              </Text>
            )}
          </Col>
        </Row>
      }
      footer={
        <>
          <Button onClick={() => close()} variant="error">
            <Text>Cancelar</Text>
          </Button>
          <Button onClick={() => validateRNTRC()} variant="success">
            <Text>Revalidar RNTRC</Text>
          </Button>
        </>
      }
    />
  );
}
