import moment from 'moment';

export function addressReshaper({ data }) {
  const addressData = {
    address: data?.addresses?.address,
    hasNumber: data?.addresses?.hasNumber,
    number: data?.addresses?.hasNumber ? data?.addresses?.number : null,
    complement: data?.addresses?.complement,
    neighborhood: data?.addresses?.neighborhood,
    zip_code: data?.addresses?.zip_code,
    country_id: data?.addresses?.city?.province?.country?.id,
    uf: data?.addresses?.city?.province.uf,
    uf_name: data?.addresses?.city?.province.name,
    city: data?.addresses?.city,
    property_type: data?.property_type,
    property_status: data?.property_status,
  };

  return addressData;
}

export function shippingCompaniesReshaper({ data }) {
  if (!data) {
    return [];
  }
  const idArray = data.map(item => {
    return item?.id;
  });
  return idArray;
}

export function paymentCardReshaper({ data }) {
  const cards = data.paymentCards?.map(card => ({
    ...card,
    card_number: card.cardNumberFormatted,
  }));

  const duplicatedCardsRemoved = cards.reduce((acc, current) => {
    const indexCard = acc.findIndex(
      oldData =>
        oldData.card_number === current.card_number && !oldData.id && current.id
    );

    if (indexCard >= 0) acc.slice(indexCard, indexCard);

    acc.push(current);

    return acc;
  }, []);

  return { paymentCards: duplicatedCardsRemoved };
}

export function paymentMethodReshaper({ data }) {
  const reshapedData = {
    payment: data?.driver?.payment,
  };

  return reshapedData;
}

export function reshaperCNH({ data }) {
  const bodyUpdateCnh = {
    cnh_number: data.cnh_number,
    cnh_type: data.cnh_type,
    cnh_key: data.cnh_key,
    cnhDispatchCity: data?.cnhDispatchCity,
    cnh_dispatch_city: data?.cnhDispatchCity?.id,
    cnh_dispatch_state: data.cnhDispatchCity?.province_id,
    cnh_dispatch_date: moment(data.cnh_dispatch_date, 'DD/MM/YYYY').toDate(),
    cnh_exp_date: moment(data.cnh_exp_date, 'DD/MM/YYYY').toDate(),
    first_cnh_date: moment(data.first_cnh_date, 'DD/MM/YYYY').toDate(),
    mopp: data.mopp,
    mopp_description: data.mopp ? data.mopp_description : null,
    mopp_expiration: data.mopp,
    is_autonomous: data.is_autonomous,
    is_owner: data.is_owner,
    cnh_is_foreign: data.cnh_is_foreign,
    type_id: data.is_owner ? 2 : undefined,
    front_cnh_file: data.front_cnh_file,
    back_cnh_file: data.back_cnh_file,
    cnh_form_number: data.cnh_form_number,
    cnh_renach: data.cnh_renach,
  };

  return bodyUpdateCnh;
}

export function reshaperUserData({ data }) {
  const reshapedData = {
    password: data.password || null,
    verified: data.verified,
    name: data.social_name,
  };

  return reshapedData;
}

export async function emergencyContactReshaper({ data }) {
  const reshapedData = {
    ...data.driverEmergencyContact,
  };
  return reshapedData;
}

export function contactsReshaper(data) {
  return {
    phone: data.phone,
    email: data.email,
    others: data.contacts,
  };
}

export function toRegister(data) {
  return {
    ...data,
    cnh_type: data.cnh_type?.value,
    cnh_dispatch_date: moment(data.cnh_dispatch_date, 'DD/MM/YYYY').toDate(),
    birthdate: moment(data.birthdate, 'DD/MM/YYYY').toDate(),
    rg_dispatch_date: moment(data.rg_dispatch_date, 'DD/MM/YYYY').toDate(),
    cnh_exp_date: moment(data.cnh_exp_date, 'DD/MM/YYYY').toDate(),
    cnh_form_number: data.cnh_form_number,
    cnh_renach: data.cnh_renach,
    first_cnh_date: moment(data.first_cnh_date, 'DD/MM/YYYY').toDate(),
    cnh_dispatch_city: data.cnhDispatchCity?.id,
    cnh_dispatch_state: data.cnhDispatchCity?.province_id,
    property_type: data.property_type?.label,
    property_status: data.property_status?.label,
    rg_dispatch_province: data.rgDispatchProvince?.id,
    civil_state_id: data.civilState?.value,
    born_city_id: data.bornCity?.id,
    born_province_id: data.bornCity?.province_id,
  };
}
