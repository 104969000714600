export function convertToDatabase(value) {
  if (!value) {
    return 0;
  }

  return value.toString().split('.').join('').split(',').join('.');
}

export default {
  convertToDatabase,
};
