import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import theme from 'styles/theme';

const Wrapper = styled(Row)`
  border-radius: 8px;
  border: 1px solid ${theme.colors.medium_gray};
  margin: 8px 0;
`;

export { Wrapper };
