import moment from 'moment';

export const formatData = (shippingCompany, reset_rntrc_exp_date) => {
  return {
    type_id: shippingCompany.personType.name === 'Jurídica' ? 3 : 2,
    cgccpf: shippingCompany.cgccpf,
    ie: shippingCompany.ie || null,
    social_name: shippingCompany.social_name,
    fantasy_name: shippingCompany.fantasy_name || null,
    phone: shippingCompany.phone,
    phone_unmasked: shippingCompany.phone.replace(/[^0-9]+/g, ''),
    email: shippingCompany.email,
    is_driver: shippingCompany.is_driver || null,
    rntrc_number: shippingCompany?.rntrc_number || null,
    rntrc_exp_date: reset_rntrc_exp_date
      ? null
      : moment(shippingCompany?.rntrc_exp_date, 'DD/MM/YYYY').toDate() || null,
    pispasep: shippingCompany?.pis || null,
    rg: shippingCompany?.rg || null,
    father_name: shippingCompany.fathers_name || null,
    mothers_name: shippingCompany.mothers_name || null,
    birthdate:
      moment(shippingCompany?.birthdate, 'DD/MM/YYYY').toDate() || null,
    rg_emissor: shippingCompany?.rg_emissor || null,
  };
};
