import create from 'zustand';

const INITIAL_CURRENT_DRIVER = {
  contacts: [],
  addresses: {
    hasNumber: false,
  },
  is_international: false,
  cnh_is_foreign: false,
  mopp: false,
  is_owner: false,
  is_autonomous: false,
  paymentCards: [],
};

export const driverStore = create(set => ({
  currentDriver: INITIAL_CURRENT_DRIVER,
  setCurrentDriver: currentDriver => set(currentDriver),
  updateCurrentDriver: data =>
    set(prev => ({ currentDriver: { ...prev.currentDriver, ...data } })),
  clearCurrentDriver: () =>
    set(() => ({ currentDriver: INITIAL_CURRENT_DRIVER })),
  prevDriver: {},
  setPrevDriver: prevDriver => set(prevDriver),
  updatePrevDriver: data =>
    set(prev => ({ prevDriver: { ...prev.prevDriver, ...data } })),
  clearPrevDriver: () => set(() => ({ prevDriver: INITIAL_CURRENT_DRIVER })),
  errors: {},
  setErrors: errors => set(errors),
  clearErrors: () => set(() => ({ errors: {} })),
  updateErrors: data => set(prev => ({ errors: { ...prev.errors, ...data } })),
  loadings: {
    resume: false,
    address: false,
    attachments: false,
    attachShippingCompany: false,
    attachVehicle: false,
    cnh: false,
    contacts: false,
    emergencyContact: false,
    lastTravels: false,
    paymentCard: false,
    paymentMethods: false,
    personalData: false,
    riskManager: false,
    userData: false,
    workJourney: false,
    registering: false,
  },
  setLoadings: data =>
    set(prev => ({ loadings: { ...prev.loadings, ...data } })),
  setAllLoadings: data =>
    set(prev => {
      const keysArray = Object.keys(prev.loadings);
      keysArray.forEach(key => {
        // eslint-disable-next-line no-param-reassign
        prev.loadings[key] = data;
      });
    }),
}));
