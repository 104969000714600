import React from 'react';

import { Link } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

export default function DriverCard({ driver }) {
  return (
    <Col lg={6} md={6} xs={12} className="p-2">
      <Card className="card-list">
        <Card.Body>
          <Row className="p-3">
            <Col xs="8">
              <span className="card-title">
                <i className="fas fa-user mr-2" /> {driver.social_name}
              </span>
              <br />
              <span className="card-text">{driver.cgccpf}</span>
            </Col>
            <Col xs="4" className="text-right">
              <Button
                variant="primary"
                as={Link}
                to={`/motoristas/${driver.id}`}
                size="sm"
              >
                Ver detalhes
              </Button>
            </Col>

            {driver.tags && !driver.tags.length ? (
              <Col xs={12}>
                <hr className="mt-2 mb-3" />
                <span className="card-label">Tags</span>
                <br />
                {driver.tags &&
                  driver.tags.map(tag => (
                    <Badge pill variant="secondary" className="my-1 mr-1">
                      {tag.name}
                    </Badge>
                  ))}
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}
