import { Button, Section, Text, useSnackbar } from '@cargon/ui';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Header from 'components/Header';
import Offset from 'components/Offset';
import { useValidation } from 'hooks';
import useModal from 'hooks/useModal';
import { registerDriver } from 'services/fetch';
import { driverStore } from 'services/store/Driver';
import * as Styled from '../../shared/styles';
import {
  Address,
  Attachments,
  AttachShippingCompany,
  AttachVehicle,
  CNH,
  Contacts,
  EmergencyContact,
  PaymentMethods,
  PersonalData,
  UserData,
} from '../shared/Cards';
import { toRegister } from '../shared/reshaper';
import { driverSchema } from '../shared/validate';
import { DriverRegisteredModal } from './RegisteredModal';
import PaymentCards from '../../../../components/PaymentCards';

export default function Register() {
  const history = useHistory();
  const {
    clearCurrentDriver,
    clearPrevDriver,
    currentDriver,
    updateCurrentDriver,
    setErrors,
    setLoadings,
    loadings,
  } = driverStore();

  const snackbar = useSnackbar();
  const driverRegisteredModal = useModal();

  const [errors, { validate }] = useValidation(driverSchema);

  useEffect(() => {
    clearCurrentDriver();
    clearPrevDriver();
  }, []);

  useEffect(() => {
    setErrors({ errors });
  }, [errors]);

  async function onSave() {
    try {
      setLoadings({ registering: true });
      const [isValid] = await validate(currentDriver);
      if (!isValid) {
        snackbar.show(
          <Text>Verifique se os campos obrigatórios foram preenchidos!</Text>,
          {
            type: 'error',
          }
        );
        return;
      }
      const reshapedDriver = toRegister(currentDriver);
      const response = await registerDriver(reshapedDriver);
      updateCurrentDriver({ id: response.personDriver.id });

      driverRegisteredModal.open();
    } catch (ex) {
      snackbar.show(<Text>Algo deu errado ao cadastrar motorista.</Text>, {
        type: 'error',
      });
    } finally {
      setLoadings({ registering: false });
    }
  }

  return (
    <>
      <Header
        title="Cadastrar Motorista"
        RightComponent={
          <>
            <Styled.ButtonsContainer className="pt-5">
              <Button
                variant="secondary"
                onClick={() => history.push('/motoristas')}
              >
                <Text color="dark" weight={500}>
                  Voltar
                </Text>
              </Button>
              <Button onClick={() => onSave()} loading={loadings.registering}>
                <Text color="white" weight={500}>
                  Salvar
                </Text>
              </Button>
            </Styled.ButtonsContainer>
          </>
        }
      />
      <Offset>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <Section Title="Dados pessoais">
                <PersonalData />
              </Section>
              <Section Title="Dados do usuário">
                <UserData />
              </Section>
              <Section Title="Contatos">
                <Contacts />
              </Section>
              <PaymentCards
                setPaymentCards={cards => {
                  updateCurrentDriver({ paymentCards: cards });
                }}
                paymentCards={currentDriver?.paymentCards}
                loading={loadings.paymentCard}
              />
              <Section Title="Método de pagamento">
                <PaymentMethods />
              </Section>
            </Col>
            <Col xs={12} md={6}>
              <Section Title="CNH">
                <CNH />
              </Section>
              <Section Title="Endereço">
                <Address />
              </Section>
              <Section Title="Transportadoras">
                <AttachShippingCompany />
              </Section>
              <Section Title="Contato de emergência">
                <EmergencyContact />
              </Section>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <AttachVehicle />
              <Attachments />
            </Col>
          </Row>
        </Container>
      </Offset>
      <DriverRegisteredModal
        driver={currentDriver}
        isOpen={driverRegisteredModal.isOpen}
        onClose={driverRegisteredModal.close}
      />
    </>
  );
}
