import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { Editable, Section, Text, useSnackbar } from '@cargon/ui';

import { attachShippingCompanies } from '~/services/fetch';
import { shippingCompanyStore } from '~/services/store/ShippingCompanies';
import { shippingCompaniesReshaper } from '~/pages/ShippingCompanies/Single/shared/reshaper';
// eslint-disable-next-line import/no-named-default
import { default as AttachShippingCompanyRegister } from '../../../shared/Cards/AttachShippingCompany';

export default function AttachShippingCompany() {
  const params = useParams();
  const snackbar = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    loadings,
    clearErrors,
    prevPerson,
    updatePerson,
    updatePrevPerson,
    person,
  } = shippingCompanyStore();

  async function handleSubmit() {
    try {
      setIsLoading(true);
      const data = shippingCompaniesReshaper({
        data: person?.shippingCompaniesAttachedTo,
      });
      await attachShippingCompanies({ id: params.id, data });
      updatePrevPerson({
        shippingCompaniesAttachedTo: person.shippingCompaniesAttachedTo,
      });
      setIsEditing(false);
    } catch (err) {
      snackbar.show(
        <Text>
          Não foi possível carregar as transportadoras vinculadas ao motorista.
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  }

  function handleCancelEdit() {
    clearErrors();
    setIsEditing(false);
    updatePerson(prevPerson);
  }

  return (
    <Section
      Title="Transportadoras Vinculadas"
      loading={loadings.attachShippingCompany}
      HeaderRightComponent={
        <Editable
          isLoading={isLoading}
          isEditing={isEditing}
          setEditing={setIsEditing}
          onSave={() => handleSubmit()}
          onCancel={() => handleCancelEdit()}
        />
      }
    >
      {isEditing ? (
        <AttachShippingCompanyRegister />
      ) : (
        <>
          <Row>
            <Col xs={12}>
              <Text>
                {person?.shippingCompaniesAttachedTo?.length > 0
                  ? person?.shippingCompaniesAttachedTo
                      ?.map(shipping => shipping?.social_name)
                      .join(', ')
                  : 'Sem transportadoras vinculadas'}
              </Text>
            </Col>
          </Row>
        </>
      )}
    </Section>
  );
}
