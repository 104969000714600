import { Text } from '@cargon/ui';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Card } from 'components';
import { getTravelTaxes } from './helpers';
import { CalculatorCardDiv, ChargePaymentList } from './styles';
import { ChargePaymentLI } from './ChargePaymentLI';
import { NotValueLI } from './NotValueLI';

export const chargeAndPaymentsStrings = {
  loading_fee: 'Taxa de carregamento',
  gris: 'Gris',
  ad_valorem: 'Ad Valorem',
  unloading_fee: 'Taxa de descarga',
  toll: 'Pedágio',
  insurance: 'Seguro',
  risk_manager_consult: 'Consulta GR',
};

export function TakerValues({ travel }) {
  const [icms, setIcms] = useState(null);

  useEffect(() => {
    if (!travel?.id) return;
    async function fetchTravelTaxes() {
      const response = await getTravelTaxes(travel.id);
      if (!response) return;
      setIcms(response.icms);
    }
    fetchTravelTaxes(travel.id);
  }, [travel.id]);

  const handleValue = ({ freights, loadWeight, kilometers }) => {
    const handleOptions = {
      POR_TONELADA: price => {
        return (parseFloat(price || 0) * (loadWeight || 0)) / 1000;
      },
      POR_QUILOMETRAGEM: price => {
        return parseFloat(price || 0) * (kilometers || 0);
      },
      CARGA_FECHADA: price => price,
    };
    return freights?.reduce((prev, curr) => {
      try {
        const calculatedPrice = handleOptions[curr.slug](curr.value);
        return prev + parseFloat(calculatedPrice);
      } catch {
        return prev;
      }
    }, 0);
  };

  const shippingService = useMemo(() => {
    const freights = travel?.charge_payment_fields?.taker?.freights;
    const loadWeight = travel?.load_weight;
    const kilometers = travel?.charge_payment_fields?.kilometers;
    return handleValue({
      freights,
      loadWeight,
      kilometers,
    });
  }, [
    travel?.charge_payment_fields?.taker?.freights,
    travel?.load_weight,
    travel?.charge_payment_fields?.kilometers,
  ]);

  const receivedValue = useMemo(() => {
    const newValue =
      travel?.charge_payment_fields?.taker?.freight_values?.reduce(
        (acc, curr) => {
          return acc + (curr.is_included ? parseFloat(curr.value) : 0);
        },
        0
      );
    const tempValue = shippingService + newValue;
    return tempValue;
  }, [shippingService, travel?.charge_payment_fields?.taker?.freight_values]);

  const calculateValuePayment = field => {
    if (!field.slug) return 0;
    const options = {
      POR_TONELADA: () => {
        return (
          (parseFloat(field.value || 0) * (travel?.load?.load_weight || 0)) /
          1000
        );
      },
      POR_QUILOMETRAGEM: () => {
        return parseFloat(field.value || 0) * (travel?.load?.kilometers || 0);
      },
      CARGA_FECHADA: () => parseFloat(field.value),
    };
    return options[field.slug]();
  };

  const labelKeyMap = slug => {
    if (!slug) return slug;
    const options = {
      CARGA_FECHADA: 'Por viagem',
      POR_TONELADA: 'Por tonelada',
      POR_QUILOMETRAGEM: 'Por quilometragem',
    };
    if (!options[slug]) return slug;
    return options[slug];
  };

  const hasAtLeastOneValue = () => {
    return (
      shippingService > 0 ||
      travel?.charge_payment_fields?.taker?.freights?.some(
        field => calculateValuePayment(field) > 0
      ) ||
      travel?.charge_payment_fields?.taker?.freight_values?.some(
        field => parseFloat(field.value) > 0
      ) ||
      icms > 0 ||
      receivedValue > 0
    );
  };

  if (!travel || !travel.id || !hasAtLeastOneValue()) {
    return (
      <Card className="card-list" header={<Text>Valores do tomador</Text>}>
        <Row>
          <Col lg={6} md={6} xs={12}>
            <Text>Não há valores para exibir</Text>
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card className="card-list" header={<Text>Valores do tomador</Text>}>
      <CalculatorCardDiv>
        <Text
          weight={600}
          fontSize={20}
          style={{ marginBottom: 20, marginTop: 8 }}
        >
          Tomador
        </Text>
        <ChargePaymentList bulletpoint>
          {parseFloat(shippingService) > 0 && (
            <ChargePaymentLI
              field="Valor do serviço de frete"
              value={shippingService}
              valueColor="success"
              currency={travel?.charge_payment_fields?.currency?.currency}
              currency_symbol={
                travel?.charge_payment_fields?.currency?.currency_symbol
              }
            />
          )}
          <ChargePaymentList inner bulletpoint>
            {travel?.charge_payment_fields?.taker?.freights?.map(field => {
              const value = calculateValuePayment(field);
              return (
                <>
                  {field?.label && value > 0 && field?.value && (
                    <ChargePaymentLI
                      field={labelKeyMap(field.slug)}
                      value={value}
                      valueColor="'gray"
                      appendToValue="+"
                    />
                  )}
                </>
              );
            })}
          </ChargePaymentList>
          {parseFloat(icms) > 0 && (
            <ChargePaymentLI
              field="ICMS"
              helperText="Valor destacado"
              value={icms}
              valueColor="gray"
              appendToValue="+"
            />
          )}
          {(icms === null || icms === undefined) && (
            <NotValueLI field="ICMS" message="Aguardando" />
          )}
          {travel?.charge_payment_fields?.taker?.freight_values.map(field => {
            if (!field.value || !parseFloat(field.value) > 0) return null;
            return (
              <ChargePaymentLI
                field={chargeAndPaymentsStrings[field.name]}
                helperText={
                  field.is_included
                    ? 'Incluso no valor a receber'
                    : 'não incluso no valor a receber'
                }
                value={field.value}
                valueColor={field.is_included ? 'gray' : 'error'}
                appendToValue={field.is_included ? '+' : undefined}
              />
            );
          })}
        </ChargePaymentList>
        <hr style={{ width: '100%' }} />
        {parseFloat(receivedValue) > 0 && (
          <ChargePaymentList bulletpoint>
            <ChargePaymentLI
              field="Valor a receber"
              value={receivedValue}
              valueColor="success"
            />
          </ChargePaymentList>
        )}
      </CalculatorCardDiv>
    </Card>
  );
}
