import React, { useEffect, useState } from 'react';
import { Select, Text, useSnackbar } from '@cargon/ui';
import { removeRepeatedObjects } from '~/utils/array';
import { shippingCompanyStore } from '~/services/store/ShippingCompanies';
import { fetchShippingCompanies } from '~/services/fetch';

export default function AttachShippingCompany() {
  const snackbar = useSnackbar();

  const { person, updatePerson } = shippingCompanyStore();
  const [shippingCompaniesList, setShippingCompaniesList] = useState([]);

  useEffect(() => {
    async function getShippingCompanies() {
      try {
        const list = await fetchShippingCompanies();
        setShippingCompaniesList(list);
        updatePerson({ shippingCompaniesAttachedTo: list });
      } catch (err) {
        snackbar.show(
          <Text>Não foi possível carregar as transportadoras vinculadas.</Text>,
          {
            type: 'error',
          }
        );
      }
    }

    getShippingCompanies();
  }, []);

  function handleChange(value) {
    const newValues = removeRepeatedObjects({ array: value, key: 'id' });
    updatePerson({
      shippingCompaniesAttachedTo: newValues,
    });
  }

  return (
    <>
      <Select
        options={shippingCompaniesList}
        value={person?.shippingCompaniesAttachedTo}
        onChange={value => handleChange(value)}
        getOptionLabel={option => option.social_name}
        getOptionValue={option => option}
        multiple
        disableClear
      />
    </>
  );
}
