import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Text, Section, Editable, useSnackbar } from '@cargon/ui';

import AddressRegister from '~/pages/Driver/Single/shared/Cards/Address';
import { driverStore } from '~/services/store/Driver';
import { addressReshaper } from '~/pages/Driver/Single/shared/reshaper';
import { validate } from '~/pages/Driver/Single/shared/validate';
import { updateDriverAddress } from '~/services/fetch';

export default function Address() {
  const {
    currentDriver,
    prevDriver,
    updateCurrentDriver,
    updatePrevDriver,
    clearErrors,
    updateErrors,
    loadings,
  } = driverStore();

  const params = useParams();
  const snackbar = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    const data = addressReshaper({ data: currentDriver });
    const isValid = await validate({
      schema: 'addressSchema',
      data,
      setErrors: updateErrors,
      clearErrors,
    });

    if (!isValid[0]) return;

    try {
      setIsLoading(true);
      await updateDriverAddress({ driverId: params.id, data });
      updatePrevDriver(currentDriver);
      setIsEditing(false);
    } catch (err) {
      snackbar.show(<Text>Algo deu errado ao alterar o endereço</Text>, {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCancelEdit() {
    clearErrors();
    setIsEditing(false);
    updateCurrentDriver(prevDriver);
  }

  return (
    <Section
      Title="Endereço"
      loading={loadings.address}
      HeaderRightComponent={
        <Editable
          isLoading={isLoading}
          isEditing={isEditing}
          setEditing={setIsEditing}
          onSave={() => handleSubmit()}
          onCancel={() => handleCancelEdit()}
        />
      }
    >
      {isEditing ? (
        <AddressRegister />
      ) : (
        <>
          <Row>
            <Col xs={12}>
              <Text type="label">País</Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {currentDriver?.addresses?.city?.province?.country?.name ||
                  'Sem país'}
              </Text>
            </Col>
            <Col xs={12}>
              <Text type="label">Endereço</Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {currentDriver?.addresses?.address || 'Sem endereço'}
              </Text>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Text type="label">Número</Text>

              <Text type="regular" color="gray" as="p" className="mt-2">
                {currentDriver?.addresses?.number || 'Sem número'}
              </Text>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Text type="label">Complemento</Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {currentDriver?.addresses?.complement || 'Sem complemento'}
              </Text>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Text type="label">Bairro</Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {currentDriver?.addresses?.neighborhood || 'Sem bairro'}
              </Text>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Text type="label">CEP</Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {currentDriver?.addresses?.zip_code || 'Sem CEP'}
              </Text>
            </Col>
            <Col xs={12}>
              <Text type="label">Cidade</Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {currentDriver?.addresses?.city
                  ? `${currentDriver?.addresses?.city?.name} - ${currentDriver?.addresses?.city?.province.uf}`
                  : 'Sem cidade'}
              </Text>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Text type="label">Tipo de moradia</Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {currentDriver?.property_type?.label || 'Sem tipo'}
              </Text>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Text type="label">Tipo de endereço</Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {currentDriver?.property_status?.label || 'Sem tipo'}
              </Text>
            </Col>
          </Row>
        </>
      )}{' '}
    </Section>
  );
}
