import React from 'react';

import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { driverStore } from '~/services/store/Driver';

import { Modal, Button, Text } from '@cargon/ui';

export function DriverRegisteredModal({ driver, isOpen, onClose }) {
  const history = useHistory();
  const { clearCurrentDriver, clearPrevDriver } = driverStore();
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="lg"
      heading={
        <Text type="subheader" color="dark">
          Cadastro de motorista
        </Text>
      }
      body={
        <Row className="mx-0 mt-1">
          <Text weight="400" color="dark">
            Motorista {driver.social_name} cadastrado com sucesso!
          </Text>
        </Row>
      }
      footer={
        <Row xs={12} className=" mt-1">
          <Button
            variant="white"
            className="mr-2 mt-1 py-2"
            outlined
            size="sm"
            onClick={() => history.push('/motoristas')}
          >
            <Text weight="500">Ver listagem</Text>
          </Button>

          <Button
            className="mr-2 mt-1 py-2"
            variant="primary"
            size="sm"
            onClick={() => history.push(`/motoristas/${driver.id}`)}
          >
            <Text weight="500">Ver motorista</Text>
          </Button>
          <Button
            variant="success"
            className="mr-2 mt-1"
            size="sm"
            onClick={() => {
              clearCurrentDriver();
              clearPrevDriver();
              window.location.reload(false);
            }}
          >
            <Text weight="500">Novo motorista</Text>
          </Button>
        </Row>
      }
    />
  );
}
