import unzip from 'unzip-js';

class DocumentUploader {
  constructor(type, currentDocuments = []) {
    this.type = type;
    this.currentDocuments = currentDocuments;
  }

  async execute(files) {
    let documents;

    if (files[0]) {
      if (
        files[0].type === 'application/x-zip-compressed' ||
        files[0].type === 'application/zip'
      ) {
        const unzipedDocuments = await this.unzipDocument(files);

        documents = await this.readDocumentContent(unzipedDocuments);
      } else {
        documents = await this.readDocumentContent(files);
      }
    }

    const alreadyExistsDocuments = documents && documents.some(xml =>
      this.currentDocuments.includes(xml.name)
    );

    if (alreadyExistsDocuments) {
      throw new Error('Este arquivo já foi importado!');
    }

    return { files: documents };
  }

  async unzipDocument(fileList) {
    return new Promise((resolve, reject) => {
      const data = [];
      fileList.forEach(file => {
        unzip(file, (err, zipFile) => {
          zipFile.readEntries(async (err, entries) => {
            try {
              const selected = entries.filter(
                ({ name }) =>
                  !name.includes('/') && name.split('.')[1] === this.type
              );

              if (selected.length === 0) {
                throw new Error(`Nenhum ${this.type} válido selecionado!`);
              }

              const files = await this.readEntry(selected, zipFile);

              const created = this.createFile(files);

              if (created.length > 0) {
                resolve(created);
              }
            } catch (err) {
              reject(err);
            }
          });
        });
      });
    });
  }

  createFile(files) {
    const test = files.map(file => {
      const created = new File(
        [file],
        `ziped_${this.type}_${new Date().getTime()}.${this.type}`,
        {
          type: `text/${this.type}`,
        }
      );

      return created;
    });

    return test;
  }

  async readEntry(entry, zipFile) {
    return new Promise((resolve, reject) => {
      const file = [];

      entry.forEach(document => {
        zipFile.readEntryData(document, false, (err, readStream) => {
          readStream
            .on('data', chunk => {
              const data = Buffer.from(chunk).toString();

              file.push(data);
            })
            .on('end', () => {
              resolve(file);
            })
            .on('error', err => reject(err));
        });
      });
    });
  }

  readDocumentContent(xmlList) {
    return new Promise((resolve, reject) => {
      const files = new Array();

      try {
        xmlList.forEach(document => {
          const reader = new FileReader();
          reader.onload = function () {
            const content = reader.result;
            files.push(content);

            if (files.length === xmlList.length) {
              resolve(files);
            }
          };

          reader.readAsText(document, 'ISO-8859-1');
        });
      } catch (err) {
        reject(err);
      }
    });
  }
}

export { DocumentUploader };
