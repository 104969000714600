import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Text, useSnackbar } from '@cargon/ui';

import { driverStore } from '~/services/store/Driver';
import { fetchDriver, fetchDriverAttachments } from '~/services/fetch/Driver';

import Details from './Details';
import Register from './Register';

import reshapeDriver from '../shared/reshaper';

export default function DriverSingle() {
  const params = useParams();
  const snackbar = useSnackbar();
  const {
    updateCurrentDriver,
    updatePrevDriver,
    setAllLoadings,
    clearCurrentDriver,
    clearPrevDriver,
    clearErrors,
  } = driverStore();
  const history = useHistory();

  useEffect(() => {
    const onUnmount = () => {
      clearCurrentDriver();
      clearPrevDriver();
      clearErrors();
    };
    const unlisten = history.listen(() => {
      onUnmount();
    });
    return () => {
      unlisten();
      onUnmount();
    };
  }, [history]);

  useEffect(() => {
    async function getDriver() {
      try {
        setAllLoadings(true);
        const response = await fetchDriver({ id: params.id });
        const data = reshapeDriver({ data: response });
        updateCurrentDriver(data);
        updatePrevDriver(data);
      } catch (err) {
        snackbar.show(<Text>Não foi possível carregar esse motorista.</Text>, {
          type: 'error',
        });
      } finally {
        try {
          const attachments = await fetchDriverAttachments({ id: params.id });
          updateCurrentDriver({ attachments });
          updatePrevDriver({ attachments });
        } catch (e) {
          snackbar.show(
            <Text>Não foi possível carregar os anexos desse motorista.</Text>,
            {
              type: 'error',
            }
          );
        } finally {
          setAllLoadings(false);
        }
      }
    }

    if (params.id) {
      getDriver();
    }
  }, [params.id]);

  return <>{params.id ? <Details /> : <Register />}</>;
}
