import api from '../../api';
import driverApi from '../../api/driver';

export async function fetchDriver({ id }) {
  const response = await api.get(`carrier/driver/${id}`);
  return response.data;
}

export async function updateDriverContacts({ driverId, phone, email, others }) {
  const response = await api.put(
    `${process.env.REACT_APP_DRIVER_ENDPOINT}/updateContacts/${driverId}`,
    {
      phone,
      email,
      others,
    }
  );
  return response.data;
}

export async function updateDriverAddress({ driverId, data }) {
  await api.put(
    `${process.env.REACT_APP_DRIVER_ENDPOINT}/updateAddress/${driverId}`,
    data
  );
}

export async function fetchShippingCompanies() {
  const response = await api.get(`carrier/driver/shippingCompanies`);
  return response.data;
}

export async function attachShippingCompanies({ id, data }) {
  const response = await api.post(
    `carrier/driver/${id}/requestAttachShippingCompanies`,
    { shippingCompaniesId: data }
  );
  return response.data;
}

export async function fetchDriverAttachments({ id }) {
  const response = await api.get(
    `${process.env.REACT_APP_DRIVER_ENDPOINT}/${id}/attachments`
  );
  return response.data;
}

export async function updateDriverAttachments({ id, data }) {
  await api.post(
    `${process.env.REACT_APP_DRIVER_ENDPOINT}/${id}/attachments`,
    data
  );
}

export async function deleteAttachment({ id }) {
  await api.delete(`travel/attachment/${id}`);
}

export async function updateDriverPersonalData({ driverId, data }) {
  await api.put(
    `${process.env.REACT_APP_DRIVER_ENDPOINT}/updatePersonalData/${driverId}`,
    data
  );
}

export async function updateDriverPaymentCard({ id, data }) {
  await api.put(`v3/payment/cards/${id}`, { data });
}

export async function updateDriverPaymentMethod({ id, data }) {
  await api.put(`${process.env.REACT_APP_DRIVER_ENDPOINT}/payment/${id}`, data);
}

export async function updateCNH({ id, data }) {
  const response = await api.put(
    `${process.env.REACT_APP_DRIVER_ENDPOINT}/updateCnh/${id}`,
    data
  );
  return response.data;
}

export async function updateUserData({ cgccpf, data }) {
  const response = await api.put(
    `${process.env.REACT_APP_DRIVER_ENDPOINT}/updateUserData/${cgccpf}`,
    data
  );
  return response.data;
}

export async function updateDriverEmergencyContact({ id, data }) {
  await api.put(`v3/driver/emergency-contact/${id}`, {
    emergencyContact: data,
  });
}

export async function fetchRating({ id }) {
  const response = await api.get(`driver-rating/${id}`);
  return response.data;
}

export async function uploadFile({ cnhFile, driverId, side }) {
  if (!cnhFile) {
    return;
  }

  const data = new FormData();
  data.append('image_cnh', cnhFile);

  try {
    const response = await driverApi.post(`/driver/${driverId}/${side}`, data);
    return response.data;
  } catch (err) {
    return false;
  }
}

export async function attachVehicleToDriver({ driverId, vehicleId, current }) {
  await api.put(`v3/driver/${driverId}/vehicle/${vehicleId}`, {
    current,
  });
}

export async function removeAttachedVehicleToDriver({ driverId, vehicleId }) {
  await api.delete(`v3/driver/${driverId}/vehicle/${vehicleId}`);
}

export async function uploadAttachments({ attachments, driverId }) {
  if (!attachments.length) {
    return;
  }

  const files = new FormData();

  attachments.forEach((attachment, index) => {
    const nameFile = `${Date.now()}${index}.${
      attachment.file.type.split('/')[1]
    }`;

    const newFile = new File([attachment.file.blob], nameFile, {
      type: attachment.file.type,
    });

    files.append('file', newFile);
    files.append(`${nameFile}_description`, attachment.description);
  });

  try {
    await api.post(
      `${process.env.REACT_APP_DRIVER_ENDPOINT}/${driverId}/attachments`,
      files
    );
  } catch (ex) {
    // Suppress exception
  }
}

export async function registerDriver(driver) {
  const response = await api.post('carrier/driver', driver);
  const driverId = response.data.personDriver.id;

  const attachments = driver.attachments ?? [];

  await Promise.all([
    uploadAttachments({ attachments, driverId }),
    uploadFile({
      cnhFile: driver.front_cnh_file,
      driverId,
      side: 'front_cnh',
    }),
    uploadFile({
      cnhFile: driver.back_cnh_file,
      driverId,
      side: 'back_cnh',
    }),
  ]);

  return response.data;
}
