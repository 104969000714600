import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';

import { Card, Text, Button, Fallback, Select, Radio, Input } from '@cargon/ui';
import theme from '@cargon/ui/dist/theme';

import { useVehicle } from '../../shared/context';
import { fetchTollTags } from '~/services/fetch/Vehicle';

export default function TollTags() {
  const vehicle = useVehicle();
  const [fallback, setFallback] = useState(false);
  const [quantity, setQuantity] = useState([]);
  const [tollTags, setTollTags] = useState();

  useEffect(() => {
    if (vehicle?.data?.tollTags?.length === 0) {
      setFallback(true);
      return;
    }
    setFallback(false);
  }, [vehicle?.data?.tollTags]);

  useEffect(() => {
    async function queryTollTags() {
      try {
        const response = await fetchTollTags();
        setTollTags(response);
      } catch (err) {
        return [];
      }
    }

    queryTollTags();
  }, []);

  function handleChangeValue(index, key, value) {
    setQuantity(old =>
      old.map((item, idx) => {
        if (index === idx) {
          return { ...item, [key]: value };
        }
        if (key === 'current') {
          return { ...item, current: false };
        }
        return item;
      })
    );
  }

  function handleDeleteCard(idx) {
    setQuantity(old => {
      return old.filter((_, index) => index !== idx);
    });
  }

  useEffect(() => {
    vehicle.setCurrentVehicle({ tollTags: quantity });
  }, [quantity]);

  return (
    <>
      <Card
        header={
          <Text color="black" type="header">
            Tags de pedágios
          </Text>
        }
        HeaderRightComponent={
          !fallback && (
            <Button
              className="mt-3 py-2"
              onClick={() => {
                if (quantity.length >= 5) {
                  return;
                }
                setQuantity(old => [
                  ...old,
                  {
                    tollTag: null,
                    number: '',
                    current: false,
                    index: quantity?.length + 1,
                  },
                ]);
              }}
            >
              <Text type="regular" weight={500} color="white">
                Adicionar
              </Text>
            </Button>
          )
        }
      >
        <Fallback
          fall={fallback}
          component={
            <Col
              xs={12}
              className="flex-column align-items-center d-flex justify-content-center my-2"
            >
              <Text color={theme.colors.gray} type="regular">
                Não há tags de pedágios cadastrados para esse motorista.
              </Text>
              <Button
                className="mt-3 py-2"
                onClick={() => {
                  if (quantity.length >= 5) {
                    return;
                  }
                  setQuantity(old => [
                    ...old,
                    {
                      tollTag: null,
                      number: '',
                      current: false,
                      index: quantity?.length + 1,
                    },
                  ]);
                }}
              >
                <Text type="regular" weight="500" color="white">
                  Adicionar
                </Text>
              </Button>
            </Col>
          }
        >
          {quantity.map((item, index) => (
            <>
              <Row className="form">
                <div className="d-flex justify-content-between w-100">
                  <div className="col-md-8">
                    <Select
                      label="Empresa de pedágio"
                      value={quantity[index].tollTag}
                      options={tollTags}
                      onChange={value => {
                        handleChangeValue(index, 'tollTag', value);
                      }}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      error={vehicle.errors[`tollTags[${index}].tollTag`]}
                    />
                  </div>
                  <div className="col-md-4" style={{ textAlign: 'right' }}>
                    <Button
                      variant="error"
                      size="sm"
                      className="mx-1"
                      onClick={() => handleDeleteCard(index)}
                    >
                      <Text>
                        <FaTrash />
                      </Text>
                    </Button>
                  </div>
                </div>
                <Col md={6}>
                  <Input
                    label="Número da tag"
                    value={item.number}
                    onChange={event => {
                      handleChangeValue(index, 'number', event.target.value);
                    }}
                    error={vehicle.errors[`tollTags[${index}].number`]}
                  />
                </Col>

                <Col md={6} className="mt-1">
                  <Radio.Group
                    label="Atual"
                    onChange={event => {
                      handleChangeValue(index, 'current', event.target.value);
                    }}
                    value={quantity[index].current}
                    horizontal
                  >
                    <Radio value>
                      <Text type="label">Sim</Text>
                    </Radio>
                    <Radio value={false}>
                      <Text color="dark" type="label">
                        Não
                      </Text>
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <hr style={{ marginTop: 30, marginBottom: 30 }} />
            </>
          ))}
        </Fallback>
      </Card>
    </>
  );
}
