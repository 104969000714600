import { Col, Row } from 'react-bootstrap';
import { BulletList as List, Select, Text } from '@cargon/ui';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { formatDate } from '../../../../../utils/formatter';
import { TravelCard } from './styled';
import { fetchReasons } from './helpers';
import { useRefusedReasonStore } from './store';

function formatCityProvince(obj) {
  return `${
    obj.city && obj.province
      ? `${obj.city} - ${obj.province}`
      : obj.city
      ? obj.city
      : obj.province
      ? obj.province
      : 'Sem localização'
  }`;
}

export function locationsObject(travel) {
  const destinationsToDisplay = travel.destinations;
  const items = [
    {
      header: (
        <div className="d-flex flex-row justify-content-between mt-2">
          <Text>Origem</Text>
        </div>
      ),
      body: (
        <>
          <Text className="my-2" color="gray" type="regular">
            {formatCityProvince(travel.origin)}
          </Text>
          <Text color="gray" type="regular">
            {moment(travel.origin.scheduled_time).format('DD/MM/YYYY HH:mm')}
          </Text>
        </>
      ),
      filledCircle: false,
    },
  ];

  items.push(
    ...destinationsToDisplay.map(item => ({
      header: (
        <div className="d-flex flex-row justify-content-between">
          <Text>Destino</Text>
        </div>
      ),
      body: (
        <>
          <Text className="my-2" color="gray" type="regular">
            {formatCityProvince(item)}
          </Text>
          <Text color="gray" type="regular">
            {moment(item.scheduled_time).format('DD/MM/YYYY HH:mm')}
          </Text>
        </>
      ),
      filledCircle: true,
    }))
  );
  return items;
}

export function DisplayTravel({ reason, setReason, errors }) {
  const [reasons, setReasons] = useState([]);
  const travel = useRefusedReasonStore(
    state => state.travels[state.travels.length - 1] || undefined
  );
  async function getRefuseReasons() {
    const dbReasons = await fetchReasons();
    if (dbReasons) setReasons(dbReasons);
  }
  useEffect(() => {
    getRefuseReasons();
  }, []);
  if (!travel) return null;
  return (
    <TravelCard>
      <Col xs={12}>
        <a href={`/viagens/${travel.id}`} target="_blank" rel="noreferrer">
          Viagem <Text color="primary">#{travel.id}</Text>
        </a>
      </Col>
      <Col lg={8} md={6} xs={12}>
        <Row>
          <Col xs={12} sm={6}>
            <Text type="label" className="text-uppercase mt-0">
              Lançamento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-0 mb-2">
              {formatDate(travel.created_at)}
            </Text>
          </Col>
          <Col xs={12} sm={6} />

          <Col xs={12} sm={6}>
            <Text type="label" className="text-uppercase mt-0">
              Cliente
            </Text>
            {travel?.load.client?.social_name ? (
              <Text type="medium" color="gray" as="p" className="mt-0 mb-2">
                {travel?.load.client?.social_name} -{' '}
                {travel?.load.client?.cgccpf}
              </Text>
            ) : (
              <Text type="medium" color="gray" as="p" className="mt-0 mb-2">
                Não informado
              </Text>
            )}
          </Col>
          <Col xs={12} sm={12}>
            {' '}
            <Text type="label" className="text-uppercase mt-0">
              Observações
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-0 mb-2">
              {travel.load.comments || 'Não informado'}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col lg={4} md={6} xs={12}>
        <Row>
          <Col xs={12}>
            <List items={locationsObject(travel.load)} />
          </Col>
        </Row>
      </Col>
      <Col xs={12} className="mb-2">
        <Select
          label="Motivo da recusa"
          value={reason}
          options={reasons}
          getOptionLabel={option => option.reason}
          getOptionValue={option => option.id}
          onChange={value => {
            setReason(value);
          }}
          error={errors.reason}
        />
      </Col>
    </TravelCard>
  );
}
