import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { isBefore, parse } from 'date-fns';
import { Text, useSnackbar } from '@cargon/ui';
import api from 'services/api';
import { formatPlate } from 'utils/formatter';
import moment from 'moment';
import { useValidation } from '../../../../../hooks';
import { useVehicle } from '../../shared/context';
import SharedMain from '../../shared/Section';
import Inputs from '../../shared/Main/Inputs';
import Editable from '../../shared/Editable';
import CheckRTNRCModal from '../../../../../components/CheckRTNRCModal';
import useModal from '../../../../../hooks/useModal';
import {
  mainTractionVehicleSchema,
  mainWagonVehicleSchema,
} from '../../shared/validators/mainDetails';
import { qtyAxis } from '../../shared/controller';

function formatDate(date) {
  if (date) return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  return 'Sem data';
}

export default function Main() {
  const vehicle = useVehicle();
  const snackbar = useSnackbar();
  const [errors, { validate }] = useValidation(
    vehicle.data.is_wagon ? mainWagonVehicleSchema : mainTractionVehicleSchema
  );
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const rntrcModal = useModal();

  useEffect(() => {
    vehicle.setErrors(errors);
  }, [errors]);

  async function saveMain(reset_nntrc = false) {
    setLoading(true);

    const [isValid, validData] = await validate(vehicle.data);

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    if (!reset_nntrc && vehicle.data?.rntrc_exp_date) {
      const rntrcExpDate = parse(
        vehicle.data?.rntrc_exp_date,
        'dd/MM/yyyy',
        new Date()
      );
      const september2022 = new Date('09/01/2022');
      const rtcrcIsInvalid = isBefore(rntrcExpDate, september2022);

      if (rtcrcIsInvalid) {
        snackbar.show(<Text>Validade do RNTRC inválida</Text>, {
          type: 'error',
        });
        setLoading(false);
        rntrcModal.open();
        return;
      }
    }
    const rntrc_exp_date = reset_nntrc
      ? null
      : moment(vehicle.data?.rntrc_exp_date, 'DD/MM/YYYY').toDate();

    const chronotachograph_exp_date = moment(
      vehicle.data?.chronotachograph_exp_date,
      'DD/MM/YYYY'
    ).toDate();

    vehicle.setCurrentVehicle({
      rntrc_exp_date,
      chronotachograph_exp_date,
    });

    const payload = {
      plate: validData.plate,
      chassi: validData?.chassi,

      antt: validData.antt,
      renavam: validData?.renavam,
      plate_city: validData.city.name,
      plate_state: validData?.province?.uf,
      year_model: validData?.year_model,
      year_manufacture: validData?.year_manufacture,
      color: validData.color?.label || null,

      tracked: validData?.isTracked,
      tracker_id: validData?.tracker?.id,
      tracker_type_id: validData?.tracker_type?.id,
      tracker_code: validData?.tracker_code,
      antt_type: validData?.anttType?.id,
      country_id: validData.country.id,
      tags: validData.tags ? validData.tags.map(tag => tag.id) : [],
      crlv: vehicle.data?.crlv,
      rntrc_exp_date,

      owner_id: validData?.owner?.id,
      antt_owner: validData?.anttOwner?.id,
      model_id: validData.model?.id,

      qty_axis: validData?.qty_axis || null,
      vehicle_body_type_id: validData.body?.id || null,

      chronotachograph_number: vehicle.data?.chronotachograph_number,
      chronotachograph_exp_date,
      fuel_id: validData?.fuel?.id || null,
      vehicle_type_id: validData.type?.id || null,
      vehicle_axis_id: validData.axis?.id || null,
    };

    try {
      await api.put(`v3/vehicle/main/${validData.id}`, payload);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setEditing(false);
      vehicle.setPrevVehicle(vehicle.data);
    } catch (err) {
      if (err.response && err.response.data.field) {
        vehicle.setErrors({
          [err.response.data.field]: err.response.data.message,
        });
      }

      snackbar.show(
        <Text>{err?.response?.data?.error || 'Erro ao salvar'}</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  const validateRNTRC = () => {
    vehicle.setCurrentVehicle({ rntrc_exp_date: null });
    rntrcModal.close();
    saveMain(true);
  };
  return (
    <SharedMain
      Title={vehicle.data?.is_wagon ? 'Implemento' : 'Veículo tração'}
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={() => saveMain()}
          isLoading={loading}
        />
      }
      loading={vehicle.isFetching}
    >
      <CheckRTNRCModal
        isOpen={rntrcModal.isOpen}
        close={rntrcModal.close}
        validateRNTRC={validateRNTRC}
        vehicle
      />
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              País
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.country?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={12}>
            <Text type="label" color="#464E5F">
              Cidade
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {(vehicle.data?.city?.name &&
                vehicle.data?.province?.uf &&
                `${vehicle.data?.city?.name} - ${vehicle.data.city?.province?.uf}`) ||
                'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Placa
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {formatPlate(vehicle.data?.plate)}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Renavam
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.renavam || 'Não informado'}
            </Text>
          </Col>
          {vehicle.data.is_wagon ? (
            <>
              {' '}
              <Col md={6} xs={12}>
                <Text type="label" color="#464E5F">
                  Tipo de carroceria
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {vehicle.data?.body?.name}
                </Text>
              </Col>
              <Col md={6} xs={12}>
                <Text type="label" color="#464E5F">
                  Quantidade de Eixos
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {qtyAxis.find(axis => axis.value === vehicle?.data?.qty_axis)
                    ?.name || 'Não informado'}
                </Text>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} md={6}>
                <Text type="label" color="#464E5F">
                  Tipo de veículo
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {vehicle.data?.type?.name || 'Não informado'}
                </Text>
              </Col>

              <Col xs={12} md={6}>
                <Text type="label" color="#464E5F">
                  Tração
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {vehicle.data?.axis?.name || 'Não informado'}
                </Text>
              </Col>
              {vehicle.data.type?.has_body_type && (
                <Col md={6} xs={12}>
                  <Text type="label" color="#464E5F">
                    Tipo de carroceria
                  </Text>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {vehicle.data?.body?.name}
                  </Text>
                </Col>
              )}

              <Col md={6} xs={12}>
                <Text type="label" color="#464E5F">
                  Combustível
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {vehicle.data?.fuel?.name || 'Não informado'}
                </Text>
              </Col>
              {!vehicle.data.type?.has_body_type && <Col xs={12} md={6} />}
              <Col xs={12} md={6}>
                <Text type="label" color="#464E5F">
                  Cronotacógrafo
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {vehicle.data?.chronotachograph_number || 'Não informado'}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" color="#464E5F">
                  Validade cronotacógrafo
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {(vehicle &&
                    formatDate(vehicle.data?.chronotachograph_exp_date)) ||
                    'Não informado'}
                </Text>
              </Col>
            </>
          )}
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Tipo de RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.anttType?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.antt || 'Não informado'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              CRLV
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.crlv || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6} />

          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Chassi
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.chassi || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Marca
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.brand?.name || 'Não informada'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Modelo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.model?.name || 'Não infomado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Ano do modelo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.year_model || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Ano de fabricação
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.year_manufacture || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Cor
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.color?.label || 'Não informada'}
            </Text>
          </Col>

          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Rastreado
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.isTracked ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="#464E5F">
              Rastreador
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data.isTracked
                ? vehicle.data?.tracker?.name || 'Não informado'
                : 'Não rastreado'}
            </Text>
          </Col>
          <Col>
            <Text type="label" color="#464E5F">
              Tipo de rastreador
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.tracker_type?.name || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              ID do rastreador
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.tracker_code || 'Não informado'}
            </Text>
          </Col>

          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Tags
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data.tags?.length > 0
                ? vehicle.data.tags.map(item => item.name).join(', ')
                : 'Nenhum'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Proprietário do documento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.owner?.label || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Proprietário RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.anttOwner?.label || 'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedMain>
  );
}
