import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Button, Text } from '@cargon/ui';

import Header from '~/components/Header';
import Offset from '~/components/Offset';
import InfiniteScroll from 'components/InfiniteScroll';
import DriverCard from './Card';
import Filter from './Filter';
import * as Styled from '../shared/styles';
import { useDriversSWR } from './controller';

export default function List() {
  const [drivers, setDrivers] = useState([]);
  const [isFilterVisible, setFilterVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    lastPage: 1,
    hasMore: false,
  });
  const [params, setParams] = useState({});

  const { data: response, isLoading } = useDriversSWR({
    page: pagination.page,
    ...params,
  });

  useEffect(() => {
    setDrivers([...drivers, ...(response?.data || [])]);
    setPagination({
      ...pagination,
      lastPage: response?.lastPage,
      hasMore: response?.page < response?.lastPage,
    });
  }, [response?.data, response?.page, response?.lastPage]);

  return (
    <>
      <Header
        title="Motoristas"
        RightComponent={
          <Styled.ButtonsContainer className="pt-5">
            <Link to="/motoristas/cadastrar">
              <Button variant="secondary">
                <Text color="black" weight={500}>
                  Novo
                </Text>
              </Button>
            </Link>
            <Button onClick={() => setFilterVisible(!isFilterVisible)}>
              <Text weight={500} color="white">
                {isFilterVisible ? 'Ocultar filtro' : 'Mostrar filtro'}
              </Text>
            </Button>
          </Styled.ButtonsContainer>
        }
      />
      <Offset>
        <Container>
          {isFilterVisible && (
            <Filter
              onChange={filterParams => {
                setDrivers([]);
                setParams(filterParams);
                setPagination({
                  page: 1,
                  lastPage: 1,
                  hasMore: false,
                });
              }}
            />
          )}

          <InfiniteScroll
            loading={isLoading}
            data={drivers}
            renderItem={driver => <DriverCard driver={driver} />}
            onEndReach={() => {
              setPagination({
                ...pagination,
                page: pagination.page + 1,
              });
            }}
            hasMore={pagination.page < pagination.lastPage}
            scrollThreshold={0.7}
            isInfinite
          />
        </Container>
      </Offset>
    </>
  );
}
