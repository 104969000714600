import React from 'react';
import BaseReport from '../BaseReport';

export default function ConfirmedTravelsReport() {
  return (
    <BaseReport
      title={'Relatório de Viagens Confirmadas'}
      url={`confirmed-travels`}
    />
  );
}
