import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { Text, Modal, PDFView } from '@cargon/ui';

import AttachmentItem from './Item';
import { driverStore } from '~/services/store/Driver';

export default function ListAttachmentItens() {
  const [isShowingModal, setShowingModal] = useState(false);
  const { currentDriver } = driverStore();

  return (
    <>
      {currentDriver?.attachments?.map(attachment => (
        <Col md={4} className="d-flex flex-column align-items-center">
          <Modal
            show={isShowingModal}
            size="lg"
            onHide={() => setShowingModal(false)}
            heading="Visualizar PDF"
            body={<PDFView file={attachment.full_path} />}
          />
          <AttachmentItem
            attachment={attachment}
            setShowingModal={setShowingModal}
          />
          <Text type="regular" className="mt-2">
            {attachment.description}
          </Text>
        </Col>
      ))}
    </>
  );
}
