import * as Yup from 'yup';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { ieIsValid } from '../../../../utils/ieValidator';

export function validateDocument(documentType, document) {
  if (document) {
    if (documentType === 'cpf') {
      const valid = cpf.isValid(document);

      return valid;
    }
    if (documentType === 'cnpj') {
      const valid = cnpj.isValid(document);

      return valid;
    }
  }
  return true;
}

export const dataSchema = Yup.object().shape({
  personType: Yup.object().shape({
    name: Yup.string().required('Tipo de pessoa obrigatório!'),
  }),
  cgccpf: Yup.string()
    .required('CPF/CNPJ obrigatório')
    .nullable()
    .when('personType', {
      is: value => value.name === 'Jurídica',
      then: Yup.string().test('invalid-document', 'Documento inválido', value =>
        validateDocument('cnpj', value)
      ),
      otherwise: Yup.string().test(
        'invalid-document',
        'Documento inválido',
        value => validateDocument('cpf', value)
      ),
    }),
  ie: Yup.string()
    .nullable()
    .when(
      ['personType', '$isTaxFree', '$ie_locale'],
      (personType, isTaxFree, ie_locale, schema) =>
        personType.name === 'Jurídica' && !isTaxFree
          ? schema.test(
              'invalid-document',
              `Documento inválido para ${ie_locale}`,
              value => ieIsValid(value, ie_locale)
            )
          : schema
    ),
  rg: Yup.string()
    .nullable()
    .when('personType', {
      is: value => value.name !== 'Jurídica',
      then: Yup.string()
        .required('RG é obrigatório')
        .typeError('RG é obrigatório')
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
  rg_emissor: Yup.string()
    .nullable()
    .when('personType', {
      is: value => value.name !== 'Jurídica',
      then: Yup.string()
        .required('Emissor do RG é obrigatório')
        .typeError('Emissor do RG é obrigatório')
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
  birthdate: Yup.string()
    .nullable()
    .when('personType', {
      is: value => value.name !== 'Jurídica',
      then: Yup.string()
        .required('Data de nascimento é obrigatória')
        .typeError('Data de nascimento é obrigatória')
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
  rntrc_number: Yup.string()
    .length(8, 'RNTRC deve ter 8 dígitos')
    .required('RNTRC deve ter 8 dígitos')
    .nullable(),
  fathers_name: Yup.string()
    .nullable()
    .when('personType', {
      is: value => value.name !== 'Jurídica',
      then: Yup.string()
        .required('Nome do pai é obrigatório')
        .typeError('Nome do pai é obrigatório')
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
  mothers_name: Yup.string()
    .nullable()
    .when('personType', {
      is: value => value.name !== 'Jurídica',
      then: Yup.string()
        .required('Nome da mãe é obrigatório')
        .typeError('Nome da mãe é obrigatório')
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
  social_name: Yup.string().nullable().required('Nome obrigatório!'),
  phone: Yup.string()
    .nullable()
    .min(13, 'Telefone deve ter pelo menos 10 dígitos')
    .max(14, 'Telefone não pode ter mais de 11 dígitos')
    .required('Telefone obrigatório'),
});

export const addressSchema = Yup.object().shape({
  address: Yup.string()
    .required('Endereço obrigatório')
    .typeError('Endereço obrigatório'),
  number: Yup.string()
    .required('Endereço obrigatório')
    .typeError('Endereço obrigatório'),

  city_name: Yup.string()
    .required('Cidade obrigatória')
    .typeError('Cidade obrigatória'),

  uf_name: Yup.string()
    .required('Estado obrigatório')
    .typeError('Estado obrigatório'),

  neighborhood: Yup.string()
    .required('Bairro obrigatório')
    .typeError('Bairro obrigatório'),
  zip_code: Yup.string()
    .required('CEP obrigatório')
    .typeError('CEP obrigatório'),
});
export const shippingCompanyRegistrationSchema = dataSchema;
