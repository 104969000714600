import { format, parseISO, differenceInMinutes } from 'date-fns';
import moment from 'moment';

const customCurrencies = [
  'AOA',
  'BOB',
  'ARS',
  'CLP',
  'COU',
  'ECS',
  'PYG',
  'PEN',
  'UYU',
];

export function convertToDatabase(value) {
  if (!value) {
    return 0;
  }

  return value.toString().split('.').join('').split(',').join('.');
}

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export function formatCurrency(value) {
  return currencyFormatter.format(value);
}
export function formatCurrencyBySlug(value, currency = 'BRL', symbol) {
  const formatted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
  }).format(value);

  if (customCurrencies.includes(currency)) {
    return `${symbol} ${formatted.replace(/[^\d,\\.]/g, '')}`;
  }
  return formatted;
}

const numberFormatter = new Intl.NumberFormat('pt-BR');

export function formatNumber(value) {
  return numberFormatter.format(value);
}

export function formatInputTag(input) {
  if (input) {
    const inputFiltered = input.trimStart().replace(/[^a-zA-Z0-9 ]/g, '');
    return inputFiltered;
  }
  throw new Error('Invalid tag to format.');
}

export function formatPlate(plate) {
  if (!plate) {
    return '-';
  }

  if (plate.charAt(4).match(/[0-9]/)) {
    return plate.replace(/^([a-zA-Z]{3})([0-9]{4})$/i, '$1-$2').toUpperCase();
  }

  return plate.toUpperCase();
}

export function formatDistance(distanceInKM = 0) {
  const fixed = distanceInKM.toFixed(0);

  if (distanceInKM < 1) {
    const distanceInMeters = fixed * 1000;
    return String(distanceInMeters).concat('m');
  }

  return String(fixed).concat(' km');
}

export function parse(value) {
  return parseISO(value);
}

export function formatDateTime(value) {
  if (!value) {
    return 'Não informado';
  }

  return format(parse(value), 'dd/MM/yyyy HH:mm');
}

export function getNow() {
  const now = new Date();
  return moment(now).format('YYYY-MM-HH HH:mm:ss');
}

export function formatTime(value) {
  if (!value) {
    return '';
  }

  return format(parse(value), 'HH:mm');
}

export function formatDate(value) {
  if (!value) {
    return 'Não informado';
  }

  return format(parse(value), 'dd/MM/yyyy');
}

export function formatCustomDate({ date, shapeFrom, shapeTo }) {
  if (date) return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  return 'Sem data';
}

export function formatAsDateWithDash(value) {
  if (!value) {
    return 'Não informado';
  }

  return moment(parse(value)).format('YYYY-MM-DD');
}

export function formatDateCustom(value, format) {
  if (!value) {
    return null;
  }

  return moment(value).format(format);
}

export function pluralize(str, quantity, strPluralized) {
  if (quantity !== 1) {
    return strPluralized || str.concat('s');
  }

  return str;
}

export function formatCustom(value, formatter) {
  if (!value) {
    return null;
  }

  return format(parse(value), formatter);
}

export function capitalize(value) {
  if (typeof value !== 'string') return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function lowerCase(string) {
  return string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export function formatAddress({
  address,
  number,
  complement,
  neighborhood,
  city,
  province,
  zip_code,
}) {
  const builder = [];

  if (address) {
    builder.push(address);

    if (number) {
      builder.push(`, ${number}`);
    }

    if (complement) {
      builder.push(` - ${complement}`);
    }

    if (neighborhood) {
      builder.push(` - ${neighborhood},`);
    }
  }

  if (city) {
    builder.push(` ${city}`);

    if (province) {
      builder.push(` - ${province}`);
    }

    if (zip_code) {
      builder.push(`, ${zip_code}`);
    }
  }
  return builder.join('');
}

export function diffInMinutes({ laterDate, earlierDate }) {
  if (!laterDate || !earlierDate) {
    return '';
  }

  return differenceInMinutes(new Date(laterDate), new Date(earlierDate));
}

export function parseErrors(errors, options = {}) {
  const { prefix, getPath = error => error.path } = options;

  return errors.reduce((acc, error) => {
    const output = acc;
    let path = getPath(error);
    if (prefix) {
      path = prefix + path;
    }
    output[path] = error.message;
    return output;
  }, {});
}

export function formatDateFromDatabase(date) {
  if (date) return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
}
