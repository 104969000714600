import React from 'react';
import { Text, Fallback } from '@cargon/ui';
import Attachment from '~/pages/Travels/Documents/Attachment';
import * as Styled from './styles';

export function DocumentPreview({ title, documents, onClickPreview }) {
  return (
    <div className="text-center mt-4">
      <Text as="div" type="regular" color="dark" weight={500}>
        {title}
      </Text>
      <div>
        <Styled.ImageRow>
          <Fallback
            fall={documents.length === 0}
            component={
              <div className="text-center flex-fill">
                <Text color="gray">Nenhuma imagem enviada.</Text>
              </div>
            }
          >
            {documents.map(document => (
              <Attachment
                key={document.id}
                document={document}
                onClickPreview={() => onClickPreview(document.id)}
              />
            ))}
          </Fallback>
        </Styled.ImageRow>
      </div>
    </div>
  );
}
