import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import Form from 'react-bootstrap/Form';

const Select = ({
  name,
  label,
  multiple,
  disabled,
  placeholder = 'Selecione...',
  onChange,
  getOptionLabel = option => option.name,
  getOptionValue = option => option.id,
  EmptyOptionsComponent,
  ...props
}) => {
  const selectRef = useRef(null);

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: ref => {
        const { state } = ref;
        if (multiple) {
          if (!state.value) {
            return [];
          }

          return state.value.map(option => getOptionValue(option));
        }

        if (state.value) {
          return getOptionValue(state.value);
        }

        return '';
      },
      setValue: (ref, value) => {
        // eslint-disable-next-line no-param-reassign
        ref.state.value = value;
      },
    });
  }, [fieldName, registerField, multiple, getOptionValue]);

  function renderNoOptions() {
    if (EmptyOptionsComponent) {
      return EmptyOptionsComponent;
    }

    return <span>Nenhum item encontrado.</span>;
  }

  function handleChange(value) {
    if (onChange) {
      onChange(value);
    }

    clearError();
  }

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <ReactSelect
        className={error ? 'is-invalid' : null}
        classNamePrefix="react-select"
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        defaultValue={defaultValue}
        ref={selectRef}
        isInvalid={!!error}
        isMulti={multiple}
        placeholder={placeholder}
        noOptionsMessage={renderNoOptions}
        isClearable
        onChange={handleChange}
        isDisabled={disabled}
        {...props}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </Form.Group>
  );
};
export default Select;
