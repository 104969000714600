import React, { useEffect, useMemo, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import FileSaver from 'file-saver';
import { Button, DatePicker, Select, Text, useSnackbar } from '@cargon/ui';
import { format, parse, subMonths } from 'date-fns';
import Container from 'react-bootstrap/Container';
import reportApi from '../../../../services/api/report';
import Header from '../../../../components/Header';
import Offset from '../../../../components/Offset';
import entityApi from '../../../../services/api/entity';

const allShipping = {
  label: 'Todos os transportadores vinculados',
  id: 'all',
};

export default function BaseReport({ title, url }) {
  const snackbar = useSnackbar();
  const [exportLoading, setExportLoading] = useState(false);
  const [dates, setDates] = useState({
    from: format(subMonths(new Date(), 1), 'dd/MM/yyyy'), // formatDate(subMonths(new Date(), 1)),
    to: format(new Date(), 'dd/MM/yyyy'), // formatDate(new Date()),
  });

  const [shippingCompanies, setShippingCompanies] = useState([]);
  const [shippingCompaniesOptions, setShippingCompaniesOptions] = useState([]);

  const fetchShippingCompanies = async () => {
    const { data } = await entityApi.get('user/shipping_companies');
    setShippingCompaniesOptions([
      allShipping,
      ...(data.result?.map(shipping => ({
        id: shipping.id,
        label: `${shipping.social_name} - ${shipping.cgccpf}`,
      })) || []),
    ]);
  };

  const availableOptions = useMemo(() => {
    if (shippingCompanies.find(option => option.id === 'all'))
      return [allShipping];
    return shippingCompaniesOptions;
  }, [shippingCompanies, shippingCompaniesOptions]);

  useEffect(() => {
    fetchShippingCompanies();
  }, []);

  function dateFormatter(date, end) {
    // if (end) {
    //   formattedDate.add(1, 'd');
    // }
    const parsed = parse(date, 'dd/MM/yyyy', new Date());
    return format(parsed, 'yyyy-MM-dd');
  }

  function setDate(key, value) {
    setDates(old => {
      return {
        ...old,
        [key]: value,
      };
    });
  }

  async function exportReport() {
    setExportLoading(true);
    try {
      if (!shippingCompanies.length) {
        snackbar.show('Selecione algum transportador', { type: 'error' });
        return;
      }
      const start_date = dateFormatter(dates.from);
      const end_date = dateFormatter(dates.to, true);
      const shipper_id =
        shippingCompanies[0].id === allShipping.id
          ? allShipping.id
          : shippingCompanies.map(shipper => shipper.id);

      const response = await reportApi.get(url, {
        params: {
          start_date,
          end_date,
          shipper_id,
        },
        responseType: 'blob',
      });
      FileSaver.saveAs(response.data, response.headers['x-file-name']);
    } catch (error) {
      if (error.response?.data) {
        const reader = new FileReader();
        reader.readAsText(error.response.data);
        reader.onload = function () {
          const obj = JSON.parse(reader.result);
          if (obj.message) snackbar.show(obj.message, { type: 'error' });
          else snackbar.show('Erro ao buscar relatórios', { type: 'error' });
        };
      } else snackbar.show('Erro ao buscar relatórios', { type: 'error' });
    } finally {
      setExportLoading(false);
    }
  }

  return (
    <>
      <Header title={title}> </Header>
      <Offset>
        <Container>
          <Row className="filter d-flex justify-content-center space-between">
            <Col md={3} xs={12}>
              <DatePicker
                label="Período de *"
                value={dates.from}
                onChange={({ target }) => setDate('from', target.value)}
                labelColor="#fff"
              />
            </Col>
            <Col md={3} xs={12}>
              <DatePicker
                label="Até *"
                value={dates.to}
                onChange={({ target }) => setDate('to', target.value)}
                labelColor="#fff"
              />
            </Col>
            <Col md={4} xs={12}>
              <Select
                label="Transportadores"
                multiple
                value={shippingCompanies}
                options={availableOptions}
                onChange={value => {
                  if (value && value.find(option => option.id === 'all')) {
                    setShippingCompanies([allShipping]);
                  } else setShippingCompanies(value || []);
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.id}
                labelTextColor="#fff"
              />
            </Col>
            <Col lg={2} xs={12} className="vertical bottom">
              <Button
                variant="primary"
                loading={exportLoading}
                onClick={exportReport}
                className="w-100"
              >
                <Text type="regular" weight={500}>
                  Exportar
                </Text>
              </Button>
            </Col>
            <Col md={10} className="mt-3">
              <Text weight={600} color="primary" fontSize={24}>
                Clique em exportar com o filtro preenchido e aguarde o relatório
                ser exportado.
              </Text>
            </Col>
          </Row>
        </Container>
      </Offset>
    </>
  );
}
