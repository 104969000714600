import { shippingCompanyStore } from '~/services/store/ShippingCompanies';
import { Button, Modal, Text, useSnackbar } from '@cargon/ui';
import { default as Inputs } from '../../Cards/PaymentMethods';
import React, { useState } from 'react';
import api from '~/services/api';
import { Col, Row } from 'react-bootstrap';

export default function PaymentModal({
  isOpen,
  onClose,
  saveData,
  setUpperLoading,
}) {
  const { person, prevPerson, updatePrevPerson } = shippingCompanyStore();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('Método de pagamento obrigatório');

  function handleClose() {
    setUpperLoading(false);
    onClose();
  }

  async function handleSubmit() {
    setLoading(true);

    if (!person?.shippingCompany?.payment?.id) {
      setError('Método de pagamento obrigatório');
      snackbar.show(<Text>Adicione um método de pagamento</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      await api.put(`v3/shipping-company/payment/${person?.id}`, {
        paymentMethod: person?.shippingCompany?.payment?.id,
      });
      updatePrevPerson({
        shippingCompany: {
          ...prevPerson.shippingCompany,
          payment: person?.shippingCompany?.payment,
        },
      });
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setLoading(false);
      saveData();

      setError('');
    } catch (err) {
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        show={isOpen}
        onHide={handleClose}
        heading={
          <Text type="header" color="dark">
            Métodos de pagamento
          </Text>
        }
        body={<Inputs error={error} />}
        footer={
          <Row>
            <Col md={6} xs={12}>
              <Button
                className="py-2"
                onClick={() => handleSubmit()}
                loading={loading}
              >
                <Text weight="500" type="regular">
                  Salvar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}
