/* eslint-disable import/no-unresolved */
import { Button, Card, Fallback, Text, useSnackbar } from '@cargon/ui';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useModal from '~/hooks/useModal';
import { fetchAttachments } from '~/services/fetch/Travel/Attachments';
import { AttachmentItem } from './Item';
import { AttachmentModal } from './Modal';

export function Attachments({ travel }) {
  const params = useParams();
  const modal = useModal();
  const snackbar = useSnackbar();

  const [attachments, setAttachments] = useState([]);
  const [isLoading, setLoading] = useState(false);

  async function fetchTravelAttachments() {
    try {
      setLoading(true);

      const data = await fetchAttachments(params.id);
      setAttachments(data);
    } catch (ex) {
      snackbar.show(
        <Text>Não foi possível exibir os anexos dessa viagem.</Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTravelAttachments();
  }, []);

  return (
    <div className="mt-4">
      <AttachmentModal
        isOpen={modal.isOpen}
        onClose={modal.close}
        fetchAttachments={fetchTravelAttachments}
      />
      <Card
        header={
          <Text color="#464E5F" type="header">
            Anexos
          </Text>
        }
        HeaderRightComponent={
          !!attachments?.length && (
            <Button
              onClick={modal.open}
              disabled={!travel?.driver || !travel?.tractionVehicle}
            >
              Adicionar
            </Button>
          )
        }
        loading={isLoading}
      >
        <>
          <Fallback
            component={
              <Col
                xs={12}
                className="align-items-center d-flex flex-column my-2"
              >
                <Text color="gray">
                  Não há anexos cadastrados para essa viagem.
                </Text>
                <Button
                  disabled={
                    (!travel?.driver || !travel?.tractionVehicle) &&
                    travel.status?.code === 'ST2'
                  }
                  onClick={modal.open}
                  className="mt-3"
                >
                  Adicionar
                </Button>
              </Col>
            }
            fall={!attachments?.length}
          >
            <Row>
              {attachments?.map(attachment => {
                return (
                  <Col md={4} className="d-flex flex-column align-items-center">
                    <AttachmentItem
                      attachment={attachment}
                      attachments={attachments}
                      fetchAttachments={fetchAttachments}
                    />
                    <Text type="regular" className="mt-2">
                      {attachment.description}
                    </Text>
                  </Col>
                );
              })}
            </Row>
          </Fallback>
        </>
      </Card>
    </div>
  );
}
