import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Text } from 'components';
import { formatCurrencyBySlug } from 'utils/formatter';

export const ChargePaymentLI = ({
  field,
  value,
  appendToValue,
  suffix,
  valueColor,
  helperText,
  noValue,
  percentage,
  tooltip,
  currency,
  currency_symbol,
}) => {
  return (
    <li style={{ marginBottom: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ paddingRight: 12 }}>
          <Text weight={500}>
            {field}
            <Text className="me-2" weight={300} fontSize={12}>
              {helperText && ` (${helperText})`}
            </Text>
            {tooltip && (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{tooltip}</Tooltip>}
              >
                <span>
                  <FaInfoCircle size={12} color="#3B99FF" />
                </span>
              </OverlayTrigger>
            )}
          </Text>
        </div>
        {!noValue && (
          <div>
            <Text
              weight={500}
              color={valueColor}
              style={{ whiteSpace: 'nowrap' }}
            >
              {percentage
                ? `${value.toFixed(2)}%`
                : `${
                    appendToValue ? `${appendToValue} ` : ''
                  }${formatCurrencyBySlug(
                    value || 0,
                    currency,
                    currency_symbol
                  )}${suffix ? `${suffix} ` : ''}`}
            </Text>
          </div>
        )}
      </div>
    </li>
  );
};
