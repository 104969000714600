import FileSaver from 'file-saver';
import api from '~/services/api';
import XMLParser from 'react-xml-parser';

export async function resolveSequentially(array, fn) {
  await array.reduce(
    (acc, item) => acc.then(async () => fn(item)),
    Promise.resolve()
  );
}

export function downloadPDF(pdf, type, id) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = `PDF ${type} ${id}.pdf`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export async function downloadCteXml(emitted_id, cte_number, snackbar) {
  try {
    const response = await api.post(`get/xml/cte/${emitted_id}`, null, {
      responseType: 'blob',
    });

    FileSaver.saveAs(response.data, `XML CT-e ${cte_number}.xml`);
  } catch (error) {
    snackbar.show('Chave do MDF-e inválida', { type: 'error' });
  }
}

export async function downloadAllCteXmls(emitteds, snackbar) {
  if (emitteds.length > 0) {
    resolveSequentially(emitteds, async emitted => {
      return downloadCteXml(emitted.id, emitted.cte_number, snackbar);
    });
  }
}

export async function downloadCtePdf(id, cte_number, snackbar) {
  try {
    const response = await api.post(`get/pdf/cte/${id}`, null);
    downloadPDF(response.data, 'CT-e', cte_number);
  } catch (error) {
    snackbar.show('Chave do CT-e inválida', { type: 'error' });
  }
}

export async function downloadAllCtePdfs(emitteds, snackbar) {
  if (emitteds.length > 0) {
    resolveSequentially(emitteds, async emitted => {
      return downloadCtePdf(emitted.id, emitted.cte_number, snackbar);
    });
  }
}

export async function downloadMdfeXml(id, number, snackbar) {
  try {
    const response = await api.post(`get/xml/mdfe/${id}`, null, {
      responseType: 'blob',
    });

    FileSaver.saveAs(response.data, `XML MDF-e ${number}.xml`);
  } catch (error) {
    snackbar.show('Chave do MDF-e inválida', { type: 'error' });
  }
}

export async function downloadAllMdfeXmls(mdfes, snackbar) {
  if (mdfes.length > 0) {
    await resolveSequentially(mdfes, async mdfe => {
      return downloadMdfeXml(mdfe.id, mdfe.number, snackbar);
    });
  }
}

export async function downloadMdfePdf(id, number, snackbar) {
  try {
    const response = await api.post(`get/pdf/mdfe/${id}`, null);
    downloadPDF(response.data, 'MDF-e', number);
  } catch (error) {
    snackbar.show('Chave do MDF-e inválida', { type: 'error' });
  }
}

export async function downloadAllMdfePdfs(mdfes, snackbar) {
  if (mdfes.length > 0) {
    await mdfes?.map(mdfe => {
      return downloadMdfePdf(mdfe.id, mdfe.number, snackbar);
    });
  }
}

export const CTE_STATUS_VARIANTS = {
  1: 'success',
  2: 'primary',
  3: 'warning',
  4: 'error',
  5: 'error',
  6: 'warning',
  7: 'success',
  8: 'warning',
  9: 'primary',
  10: 'primary',
};

export function getValueByTagName(xml, tag) {
  if (xml) {
    const parsedXml = new XMLParser().parseFromString(xml);

    return parsedXml.getElementsByTagName(tag).map(element => element.value);
  }
  return null;
}

export function getFirstValueByTagName(xml, tag) {
  if (xml) return getValueByTagName(xml, tag)?.[0];
  return null;
}

export async function fetchDocuments(emittedId, snackbar) {
  try {
    const response = await api.get(`travel-attachment/${emittedId}`);
    const filtered = response.data.filter(data => {
      const [attachment] = data.attachments;
      return !!attachment;
    });
    const mapped = filtered.map(data => {
      const [attachment] = data.attachments;

      let isDocument = true;
      if (attachment.doc_filename) {
        const [, extension] = attachment.doc_filename.split('.');
        if (['jpg', 'png', 'jpeg', 'gif'].includes(extension.toLowerCase())) {
          isDocument = false;
        }
      }

      return {
        ...data,
        ...attachment,
        isDocument,
      };
    });

    return mapped;
  } catch (error) {
    // Handle exception
    snackbar.show('Falha ao carregar os anexos', { type: 'error' });
    return undefined;
  }
}

export function getImagePath(index, lightbox, alwaysDisplay) {
  const isSameAsIndex =
    lightbox.index === lightbox.indexes.next &&
    lightbox.index === lightbox.indexes.previous;

  if (!alwaysDisplay && isSameAsIndex && lightbox.documents.length === 1) {
    return null;
  }

  return lightbox.documents[index]?.attachments[0].path;
}

export function onClickPreview(id, attachmentsWithoutPDF, lightbox) {
  const index = id
    ? attachmentsWithoutPDF.findIndex(attachment => attachment.id === id)
    : 0;
  lightbox.setIndex(index);
  lightbox.setDocuments(attachmentsWithoutPDF);
  lightbox.open();
}

export function isPDF(item) {
  const extensions = item.split('.');
  const lastExtension = extensions[extensions.length - 1];

  return /pdf/gi.test(lastExtension);
}
