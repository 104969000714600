import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import ReactInputMask from 'react-input-mask';
import { useField } from '@unform/core';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { BsCalendar } from 'react-icons/bs';

export default function DatePicker({
  label,
  name,
  onChange,
  placeholder,
  maskProps,
  ...rest
}) {
  const dateRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [date, setDate] = useState(defaultValue || null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: dateRef.current,
      path: 'props.selected',
      clearValue: ref => {
        ref.clear();
      },
      setValue: (_, value) => {
        setDate(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <Form.Group>
      {label && <Form.Label className="d-flex">{label}</Form.Label>}
      <ReactDatePicker
        {...rest}
        ref={dateRef}
        selected={date}
        defaultValue={defaultValue}
        onChange={setDate}
        customInput={
          <ReactInputMask onChange={onChange} {...maskProps}>
            {inputProps => (
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <BsCalendar />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  {...inputProps}
                  defaultValue={defaultValue}
                  placeholder={placeholder}
                  error={error}
                  isInvalid={!!error}
                />
              </InputGroup>
            )}
          </ReactInputMask>
        }
        locale="pt-BR"
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}
