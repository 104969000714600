import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { shippingCompanyStore } from '~/services/store/ShippingCompanies';
import { useParams } from 'react-router-dom';
import api from '~/services/api';
import { Select } from '@cargon/ui';

export default function PaymentMethods({ error }) {
  const { person, updatePerson } = shippingCompanyStore();
  const params = useParams();
  const [paymentMethodList, setPaymentMethodList] = useState();

  useEffect(() => {
    if (!params.id) {
      updatePerson({
        paymentMethod: null,
      });
    } else {
      //
    }
  }, []);

  async function fetchPaymentMethodList() {
    try {
      const response = await api.get('payment/methods?initialFetch=true');
      setPaymentMethodList(response.data);
    } catch (err) {
      // error
    }
  }

  useEffect(() => {
    fetchPaymentMethodList();
  }, []);

  async function fetchPaymentMethods(search) {
    try {
      const response = await api.get('payment/methods', { params: { search } });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  return (
    <Row className="form">
      <Col md={12}>
        <Select.Async
          options={paymentMethodList}
          label="Método de pagamento *"
          onSearch={search => fetchPaymentMethods(search)}
          value={person.shippingCompany?.payment}
          onChange={value => {
            updatePerson({
              shippingCompany: {
                ...person.shippingCompany,
                payment: value,
              },
            });
          }}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          error={error}
        />
      </Col>
    </Row>
  );
}
