import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Text, Section, Editable, useSnackbar } from '@cargon/ui';
import { useParams } from 'react-router-dom';

import { driverStore } from '~/services/store/Driver';
import { contactsReshaper } from '~/pages/Driver/Single/shared/reshaper';
import { validate } from '~/pages/Driver/Single/shared/validate';
import ContactsRegister from '~/pages/Driver/Single/shared/Cards/Contacts';
import { updateDriverContacts } from '~/services/fetch';

export default function Contacts() {
  const {
    currentDriver,
    prevDriver,
    updateCurrentDriver,
    updatePrevDriver,
    clearErrors,
    updateErrors,
    loadings,
  } = driverStore();

  const params = useParams();
  const snackbar = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    try {
      const data = contactsReshaper(currentDriver);
      const isValid = await validate({
        schema: 'contactsSchema',
        data,
        setErrors: updateErrors,
        clearErrors,
      });

      if (!isValid[0]) return;

      setIsLoading(true);
      await updateDriverContacts({ driverId: params.id, ...data });
      updatePrevDriver(currentDriver);
      setIsEditing(false);
    } catch (err) {
      snackbar.show(<Text>Algo deu errado ao atualizar os contatos.</Text>, {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCancelEdit() {
    clearErrors();
    setIsEditing(false);
    updateCurrentDriver(prevDriver);
  }

  return (
    <Section
      Title="Contatos"
      loading={loadings.contacts}
      HeaderRightComponent={
        <Editable
          isLoading={isLoading}
          isEditing={isEditing}
          setEditing={setIsEditing}
          onSave={handleSubmit}
          onCancel={handleCancelEdit}
        />
      }
    >
      {isEditing ? (
        <ContactsRegister />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label">Telefone principal</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver?.phone || 'Sem telefone'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label">E-mail</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver?.email || 'Sem e-mail'}
            </Text>
          </Col>
          <Col xs={12} className="my-3">
            <Text type="body" color="dark">
              Outros contatos
            </Text>
          </Col>
          {!currentDriver?.contacts?.length && (
            <Col xs={12}>
              <Text type="body" color="dark">
                Nenhum contato adicionado.
              </Text>
            </Col>
          )}
          {currentDriver?.contacts?.map(contact => (
            <>
              <Col xs={12} sm={6} className="mb-3">
                <Text type="label">Nome</Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {contact.name}
                </Text>
              </Col>
              <Col xs={12} sm={6}>
                <Text type="label">Telefone</Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {contact.phone}
                </Text>
              </Col>
            </>
          ))}
        </Row>
      )}
    </Section>
  );
}
