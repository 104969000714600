import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FaRegCalendarCheck } from 'react-icons/fa';

import { parseISO, getDay, getMonth, getYear } from 'date-fns';
import DealsActions from '~/store/modules/deals/actions';

import { formatDate } from '~/utils/formatter';

export default function HeaderDeal({ deal }) {
  const [active, setActive] = useState(true);

  const user = useSelector(store => store.auth.user.properties);
  const dispatch = useDispatch();

  function getActiveDeal() {
    if (active) {
      return;
    }
    setActive(true);
    dispatch(
      DealsActions.dealsRequest({
        user_id: user.id,
        only_active: true,
        paginate: true,
      })
    );
  }

  function getClosedDeal() {
    if (!active) {
      return;
    }
    dispatch(
      DealsActions.dealsRequest({
        user_id: user.id,
        only_closed: true,
        paginate: true,
      })
    );
    setActive(false);
  }

  return (
    <Row className="chat-header">
      <Col
        lg={2}
        md={2}
        xs={6}
        className={`chat-tabs text-center ${active ? 'active' : ''}`}
        onClick={() => getActiveDeal()}
      >
        <h6>Em negociação</h6>
      </Col>
      <Col
        lg={2}
        md={2}
        xs={6}
        className={`chat-tabs text-center ${active ? '' : 'active'} `}
        onClick={() => getClosedDeal()}
      >
        <h6>Finalizadas</h6>
      </Col>
      <Col lg={8} md={8} xs={12} className="chat-name">
        <Row>
          <Col>
            <h6>{deal.driver_name} </h6>
            <div className="card-text py-0">
              Início em {formatDate(deal.created_at)}
            </div>
          </Col>
          <Col xs={4} className="text-right">
            <div className="card-text py-0">Carga - {deal.load_id}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
