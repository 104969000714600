/* eslint-disable no-shadow */
import {
  Map as MapRaw,
  Marker,
  MarkerLetter,
  Text,
  useSnackbar,
} from '@cargon/ui';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaBatteryFull, FaBatteryHalf, FaBatteryQuarter } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import api from '~/services/api';
import theme from '~/styles/theme';
import { formatDateTime, formatNumber } from '~/utils/formatter';
import { distanceInMeters } from '~/utils/geolocation';
import useSWR from 'swr';
import Alerts from './Alerts';
import Options from './Options';
import Speed from './Speed';
import StatusColor from './StatusColor';
import locationService from '../../../../services/api/location-service';

export async function fetchTravelLocations(url, config) {
  const response = await locationService.get(url, config);

  return response.data.result?.map(vl => ({
    ...vl,
    lng: vl.location.coordinates[0],
    lat: vl.location.coordinates[1],
    battery: vl.battery_level,
  }));
}

const Image = styled.img`
  height: 45px;
  width: 45px;
  margin: -25px;
  object-fit: contain;
`;

export default function Map({
  travelId,
  isFetching,
  origin,
  destinations,
  firstRender,
}) {
  const params = useParams();
  const snackbar = useSnackbar();
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [coords, setCoords] = useState();
  const [pathEnabled, setPathEnabled] = useState(true);
  const [markersEnabled, setMarkersEnabled] = useState(true);

  const [alerts, setAlerts] = useState([]);
  const { data: locations, error } = useSWR(
    params.id ? `/travel/${params.id}/vehicle-locations` : null,
    url => fetchTravelLocations(url)
  );

  const lastLocation = useMemo(
    () => (locations?.length > 0 ? locations?.slice(-1)[0] : null),
    [locations]
  );
  useEffect(() => {
    if (error && firstRender) {
      snackbar.show(<Text>Erro ao carregar localizações</Text>, {
        type: 'warning',
      });
    }
  }, [error]);

  async function fetchAlert() {
    try {
      const response = await api.get(`travel/${params.id}/alerts`);

      setAlerts(response.data);
    } catch (error) {
      // Handle exception
    }
  }

  useEffect(() => {
    fetchAlert();
  }, []);

  const middleDestinations = useMemo(
    () => destinations?.filter((_, index) => index < destinations?.length - 1),
    [destinations]
  );
  const lastDestination = useMemo(
    () => destinations?.[destinations?.length - 1],
    [destinations]
  );

  useEffect(() => {
    if (maps && map) {
      const bounds = new maps.LatLngBounds();
      const bound = [...(locations || []), origin, ...destinations].filter(
        location => location.lat && location.lng
      );

      bound.forEach(location => {
        bounds.extend(new maps.LatLng(location.lat, location.lng));
      });

      if (bound.length === 0) {
        bounds.extend(new maps.LatLng(1.5939188, -54.6107855));
        bounds.extend(new maps.LatLng(-30.3966149, -55.913429));
      }

      map.fitBounds(bounds);
    }
  }, [locations, map, maps]);

  function onMapLoaded(_map, _maps) {
    setMap(_map);
    setMaps(_maps);
  }

  useEffect(() => {
    if (map && maps) {
      const lineSymbol = {
        path: maps.SymbolPath.FORWARD_CLOSED_ARROW,
        strokeColor: theme.colors.primary,
        fillColor: theme.colors.primary,
        fillOpacity: 1,
        scale: 3,
      };

      const geodesicPolyline = new maps.Polyline({
        path: locations || [],
        geodesic: true,
        strokeColor: theme.colors.success,
        strokeWeight: 6,
        scale: 2,

        icons: [
          {
            icon: lineSymbol,
            repeat: '100px',
            offset: '0%',
          },
        ],
      });

      if (pathEnabled) {
        geodesicPolyline.addListener('click', props => {
          const nearestLocation = (locations || []).reduce((acc, location) => {
            const diff = distanceInMeters(
              location.lat,
              location.lng,
              props.latLng.lat(),
              props.latLng.lng()
            );
            if (!acc || diff < acc.distance) {
              return { ...location, distance: diff };
            }

            return acc;
          }, null);

          setCoords({ ...nearestLocation, velocidade: nearestLocation.speed });
        });

        geodesicPolyline.setMap(map);
      }

      return () => {
        geodesicPolyline.setMap(null);
      };
    }
  }, [locations, maps, map, pathEnabled]);

  if (isFetching) {
    return null;
  }

  function handleClickVelocity(data) {
    if (data) {
      const bounds = new maps.LatLngBounds();

      bounds.extend(new maps.LatLng(data?.lat, data?.lng));
      map.fitBounds(bounds);
      map.setZoom(15);
    }
  }

  return (
    <Row>
      <Col>
        <Col style={{ position: 'relative', height: 730 }}>
          <MapRaw
            zoom={8}
            height={730}
            width="100%"
            position={
              origin.lat
                ? {
                  lat: origin.lat,
                  lng: origin.lng,
                }
                : { lat: -25.453785, lng: -49.2529808 }
            }
            onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}
          >
            {alerts.map(alert => {
              const metadata = JSON.parse(alert?.metadata);
              const alertLat = metadata?.location?.latitude;
              const alertLng = metadata?.location?.longitude;
              return (
                <MarkerLetter
                  background="#FFC107"
                  lat={alertLat}
                  lng={alertLng}
                  color="#fff"
                />
              );
            })}
            {origin?.lat && origin?.lng && markersEnabled && (
              <MarkerLetter
                background="#0bb7af"
                lat={origin.lat}
                lng={origin.lng}
                color="#fff"
              />
            )}
            {middleDestinations &&
              markersEnabled &&
              middleDestinations.map(destinations => (
                <MarkerLetter
                  background="#3B99FF"
                  lat={destinations?.lat}
                  lng={destinations?.lng}
                  color="#fff"
                />
              ))}
            {lastDestination?.lat && lastDestination?.lng && markersEnabled && (
              <MarkerLetter
                background="#F64E60"
                lat={lastDestination.lat}
                lng={lastDestination.lng}
                color="#fff"
              />
            )}
            {lastLocation?.lat && lastLocation?.lng && (
              <Image
                lat={lastLocation.lat}
                lng={lastLocation.lng}
                alt="a"
                src="/images/current.png"
              />
            )}
            {markersEnabled && coords?.lat && coords?.lng && (
              <Marker
                lat={coords?.lat}
                lng={coords?.lng}
                background="#7337EE"
                text={
                  <>
                    <div>
                      <Text color="#fff" type="label">
                        {formatDateTime(coords.captured_at)}
                      </Text>
                    </div>
                    <div>
                      <Text color="#fff" type="label">
                        {formatNumber(coords.velocidade) || 0} km/h
                      </Text>
                    </div>
                    <div>
                      <Text color="#fff" type="label">
                        {coords.battery}%
                        {coords.battery > 75 && (
                          <FaBatteryFull
                            size={16}
                            style={{ marginLeft: 5, marginBottom: 3 }}
                          />
                        )}
                        {coords.battery >= 50 && coords.battery <= 75 && (
                          <FaBatteryHalf
                            size={16}
                            style={{ marginLeft: 5, marginBottom: 3 }}
                          />
                        )}
                        {coords.battery >= 0 && coords.battery < 50 && (
                          <FaBatteryQuarter
                            size={16}
                            style={{ marginLeft: 5, marginBottom: 3 }}
                          />
                        )}
                      </Text>
                    </div>
                  </>
                }
              />
            )}
          </MapRaw>
          <Options
            onDisplayMarkersChange={setMarkersEnabled}
            onDisplayPathChange={setPathEnabled}
          />
          <StatusColor />
        </Col>
        {(locations || [])?.length > 1 ? (
          <Speed
            latLong={locations}
            setCoords={setCoords}
            handleClickVelocity={data => handleClickVelocity(data)}
          />
        ) : (
          <Col
            className="d-flex
              justify-content-center align-items-center
              mt-3"
          >
            <Text color="gray">Não temos dados sobre a velocidade</Text>
          </Col>
        )}

        <Alerts alerts={alerts} />
      </Col>
    </Row>
  );
}
