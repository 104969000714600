import React, { useEffect, useMemo } from 'react';
import { Section, Text, useSnackbar } from '@cargon/ui';
import Editable from 'pages/ShippingCompanies/Single/Details/editable';
import { shippingCompanyStore } from 'services/store/ShippingCompanies';
import { Col, Row } from 'react-bootstrap';
import { useValidation } from 'hooks';
import { dataSchema } from 'pages/ShippingCompanies/Single/shared/validator';
// eslint-disable-next-line import/no-named-default
import api from 'services/api';
import { parseErrors } from 'utils/formatter';
import { formatData } from 'pages/ShippingCompanies/Single/Details/helpers';
import PaymentModal from 'pages/ShippingCompanies/Single/shared/Modals/Payment';
import useModal from 'hooks/useModal';
// eslint-disable-next-line import/no-named-default
import { isBefore, parse } from 'date-fns';
import { default as Inputs } from '../../../shared/Cards/Data';
import CheckRTNRCModal from '../../../../../../components/CheckRTNRCModal';

export default function Data({ addressModal }) {
  const [isEditing, setEditing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const modal = useModal();
  const rntrcModal = useModal();

  const snackbar = useSnackbar();

  const { person, updatePerson, setErrors, setPrevPerson, loadings } =
    shippingCompanyStore();

  const [errors, { validate }] = useValidation(dataSchema);
  const isCompany = useMemo(
    () => person?.personType?.name === 'Jurídica',
    [person?.personType?.name]
  );
  const isTaxFree = useMemo(
    () => person?.shippingCompany?.is_tax_free && isCompany,
    [person?.shippingCompany?.is_tax_free, isCompany]
  );
  const isDriver = useMemo(() => !!person?.is_driver, [!!person?.is_driver]);

  useEffect(() => {
    setErrors({ ...errors });
  }, [errors]);

  const saveData = async (reset_rntrc_exp_date = false) => {
    try {
      const data = formatData(person, reset_rntrc_exp_date);
      const shippingCompanyData = {
        is_tax_free: !!person.shippingCompany?.is_tax_free,
        tax_regime_id: person.shippingCompany?.taxRegime?.id ?? null,
      };

      await api.put(`v3/shipping-company/${person.id}/data`, {
        data,
        shippingCompanyData,
      });

      setPrevPerson(person);

      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setLoading(false);
      setEditing(false);
      setErrors({});
      modal.close();
    } catch (error) {
      if (error.response) {
        const parsedErrors = parseErrors(error.response.data, {
          getPath: e => e.field,
        });
        setErrors({ ...parsedErrors });
      }
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (reset_rntrc_exp_date = false) => {
    if (!reset_rntrc_exp_date && person?.rntrc_exp_date) {
      const rntrcExpDate = parse(
        person?.rntrc_exp_date,
        'dd/MM/yyyy',
        new Date()
      );
      const september2022 = new Date('09/01/2022');
      const rtcrcIsInvalid = isBefore(rntrcExpDate, september2022);

      if (rtcrcIsInvalid) {
        snackbar.show(<Text>Validade do RNTRC inválida</Text>, {
          type: 'error',
        });
        setLoading(false);
        rntrcModal.open();
        return;
      }
    }
    if (person.addresses.length === 0) {
      snackbar.show(<Text>Cadastre um endereço</Text>, {
        type: 'error',
      });
      addressModal.open();
      setLoading(false);
      return;
    }
    const [isValid] = await validate(person, {
      context: {
        isTaxFree,
        ie_locale: person.addresses[0]?.uf_name,
      },
    });
    setLoading(true);
    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }
    if (!person.shippingCompany?.payment?.id) {
      modal.open();
    } else {
      saveData(reset_rntrc_exp_date);
    }
  };
  const validateRNTRC = () => {
    updatePerson({ rntrc_exp_date: null });
    rntrcModal.close();
    handleSubmit(true);
  };
  return (
    <Section
      loading={loadings.data}
      Title="Dados da transportadora"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          isLoading={loading}
          onSave={() => handleSubmit()}
        />
      }
    >
      <CheckRTNRCModal
        isOpen={rntrcModal.isOpen}
        close={rntrcModal.close}
        validateRNTRC={validateRNTRC}
        shippingCompany
      />
      <PaymentModal
        isOpen={modal.isOpen}
        saveData={saveData}
        onClose={modal.close}
        setUpperLoading={setLoading}
      />
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label" color="dark">
              Tipo de Pessoa
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.personType?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="dark">
              {isCompany ? 'CNPJ' : 'CPF'}
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.cgccpf || 'Não informado'}
            </Text>
          </Col>
          {isCompany && (
            <>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Isento
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {isTaxFree ? 'Sim' : 'Não'}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Inscrição Estadual
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {person?.ie || 'Não informado'}
                </Text>
              </Col>
              <Col xs={12}>
                <Text type="label" color="dark">
                  Regime tributário
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {person.shippingCompany?.taxRegime?.name ?? 'Não informado'}
                </Text>
              </Col>
            </>
          )}
          {!isCompany && (
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                PIS
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {person?.pis || 'Não informado'}
              </Text>
            </Col>
          )}
          <Col xs={12}>
            <Text type="label" color="dark">
              {isCompany ? 'Razão Social' : 'Nome'}
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.social_name || 'Não informado'}
            </Text>
          </Col>
          {isCompany ? (
            <Col xs={12} md={6}>
              <Text type="label" color="dark">
                Nome fantasia
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {person?.fantasy_name || 'Não informado'}
              </Text>
            </Col>
          ) : null}
          <Col md={6} xs={12}>
            <Text type="label" color="dark">
              RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.rntrc_number || 'Não informado'}
            </Text>
          </Col>
          <Col md={6} xs={12} />
          <Col md={12}>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {isDriver ? 'É motorista' : 'Não é motorista'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Telefone
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.phone || 'Não informado'}
            </Text>
          </Col>
          {!isCompany && (
            <>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Nome do pai
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {person?.fathers_name || 'Não informado'}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Nome da mãe
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {person?.mothers_name || 'Não informado'}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  RG
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {person?.rg || 'Não informado'}
                </Text>
              </Col>{' '}
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Órgão emissor
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {person?.rg_emissor || 'Não informado'}
                </Text>
              </Col>
              <Col xs={12} md={6}>
                <Text type="label" color="dark">
                  Data de nascimento
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {person?.birthdate || 'Não informado'}
                </Text>
              </Col>
            </>
          )}
          <Col xs={12}>
            <Text type="label" color="dark">
              E-mail
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {person?.email || 'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </Section>
  );
}
