import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Section, Editable, Text, useSnackbar } from '@cargon/ui';

import EmergencyContactRegister from '~/pages/Driver/Single/shared/Cards/EmergencyContact';

import { driverStore } from '~/services/store/Driver';
import { updateDriverEmergencyContact } from '~/services/fetch';
import { emergencyContactReshaper } from '~/pages/Driver/Single/shared/reshaper';
import { validate } from '../../../shared/validate';

export default function EmergencyContact() {
  const params = useParams();
  const snackbar = useSnackbar();
  const {
    currentDriver,
    updateCurrentDriver,
    prevDriver,
    updatePrevDriver,
    clearErrors,
    updateErrors,
    loadings,
  } = driverStore();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    const data = await emergencyContactReshaper({ data: currentDriver });
    const isValid = await validate({
      schema: 'emergencyContactSchema',
      data: { driverEmergencyContact: data },
      setErrors: updateErrors,
      clearErrors,
    });

    if (!isValid[0]) return;

    try {
      setIsLoading(true);
      await updateDriverEmergencyContact({ id: params.id, data });
      updatePrevDriver(currentDriver);
      setIsEditing(false);
    } catch (err) {
      snackbar.show(
        <Text>
          Não foi possível alterar os dados, tente novamente mais tarde!
        </Text>,
        { type: 'error' }
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCancelEdit() {
    clearErrors();
    setIsEditing(false);
    updateCurrentDriver(prevDriver);
  }

  return (
    <Section
      Title="Contato de Emergência"
      loading={loadings.emergencyContact}
      HeaderRightComponent={
        <Editable
          isLoading={isLoading}
          isEditing={isEditing}
          setEditing={setIsEditing}
          onSave={() => handleSubmit()}
          onCancel={() => handleCancelEdit()}
        />
      }
    >
      {isEditing ? (
        <EmergencyContactRegister />
      ) : (
        <Row>
          <Col md={12} xs={12}>
            <Text type="label" color="#464E5F">
              Nome
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver?.driverEmergencyContact?.name || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Telefone
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver?.driverEmergencyContact?.phone || 'Não informado'}
            </Text>
          </Col>

          <Col md={6} xs={12}>
            <Text type="label" color="#464E5F">
              Email
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {currentDriver?.driverEmergencyContact?.email || 'Não informado'}
            </Text>
          </Col>
        </Row>
      )}
    </Section>
  );
}
