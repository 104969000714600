function module11(stringNumber, stringWeight) {
  let soma = 0;
  for (let i = stringNumber.length - 1; i >= 0; i -= 1) {
    soma +=
      parseInt(stringNumber.slice(i, i + 1), 10) *
      parseInt(stringWeight.slice(i, i + 1), 10);
  }
  return parseInt(soma % 11 > 1 ? 11 - (soma % 11) : 0, 10);
}

function moduleAP(stringNumber, stringWeight) {
  let soma = 0;
  let resp = 0;
  let digit11 = 0;
  if (stringNumber >= '03000001' && stringNumber <= '03017000') {
    digit11 = 0;
    soma = 5;
  } else if (stringNumber >= '03017001' && stringNumber <= '03019022') {
    digit11 = 1;
    soma = 9;
  }
  for (let i = stringNumber.length - 1; i >= 0; i -= 1) {
    soma +=
      parseInt(stringNumber.slice(i, i + 1), 10) *
      parseInt(stringWeight.slice(i, i + 1), 10);
  }
  switch (soma % 11) {
    case 11: {
      resp = digit11;
      break;
    }
    case 10: {
      resp = 0;
      break;
    }
    default:
      resp = parseInt(11 - (soma % 11), 10);
  }
  return resp;
}

function moduleRR(stringNumber, stringWeight) {
  let soma = 0;
  for (let i = stringNumber.length - 1; i >= 0; i -= 1) {
    soma +=
      parseInt(stringNumber.slice(i, i + 1), 10) *
      parseInt(stringWeight.slice(i, i + 1), 10);
  }
  return parseInt(soma % 9, 10);
}

function module10(stringNumber, stringWeight) {
  let soma = 0;
  for (let i = stringNumber.length - 1; i >= 0; i -= 1) {
    soma +=
      parseInt(stringNumber.slice(i, i + 1), 10) *
      parseInt(stringWeight.slice(i, i + 1), 10);
  }
  return parseInt(soma % 10 > 0 ? 10 - (soma % 10) : 0, 10);
}

function moduleMGd1(stringNumber, stringWeight) {
  let soma = 0;
  let algarismos = '';
  for (let i = 0; i < stringNumber.length; i++) {
    algarismos += (
      parseInt(stringNumber.slice(i, i + 1), 10) *
      parseInt(stringWeight.slice(i, i + 1), 10)
    ).toString();
  }
  for (let i = algarismos.length - 1; i >= 0; i -= 1) {
    soma += parseInt(algarismos.slice(i, i + 1), 10);
  }
  return parseInt(soma % 10 > 0 ? 10 - (soma % 10) : 0, 10);
}

function moduleMGd2(stringNumber, stringWeight) {
  let soma = 0;
  for (let i = 0; i < stringNumber.length; i++) {
    soma +=
      parseInt(stringNumber.slice(i, i + 1), 10) *
      parseInt(stringWeight.substr((i - 1) * 2 + 2, 2), 10);
  }
  return parseInt(soma % 11 > 1 ? 11 - (soma % 11) : 0, 10);
}

function moduleGO(stringNumber, stringWeight) {
  let soma = 0;
  let resp = 0;
  for (let i = stringNumber.length - 1; i >= 0; i -= 1) {
    soma +=
      parseInt(stringNumber.slice(i, i + 1), 10) *
      parseInt(stringWeight.slice(i, i + 1), 10);
  }
  switch (soma % 11) {
    case 0: {
      break;
    }
    case 1: {
      if (stringNumber >= '10103105' && stringNumber <= '10119997') {
        resp = 1;
        break;
      } else {
        resp = 0;
        break;
      }
    }
    default:
      resp = parseInt(11 - (soma % 11), 10);
  }
  return resp;
}

function moduleRN(stringNumber, stringWeight) {
  let soma = 0;
  for (let i = stringNumber.length - 1; i >= 0; i -= 1) {
    soma +=
      parseInt(stringNumber.slice(i, i + 1), 10) *
      parseInt(stringWeight.substr((i - 1) * 2 + 2, 2), 10);
  }
  return parseInt(soma % 11 === 10 ? 0 : (soma * 10) % 11, 10);
}

function moduleSP(stringNumber, stringWeight) {
  let soma = 0;
  let resp = 0;
  for (let i = stringNumber.length - 1; i >= 0; i -= 1) {
    soma +=
      parseInt(stringNumber.slice(i, i + 1), 10) *
      parseInt(stringWeight.substr((i - 1) * 2 + 2, 2), 10);
  }
  switch (soma % 11) {
    case 11: {
      resp = 1;
      break;
    }
    case 10: {
      resp = 0;
      break;
    }
    default:
      resp = parseInt(soma % 11, 10);
  }
  return resp;
}

export function ieIsValid(ie = '', ie_locale = '') {
  console.log('ie');
  console.log(ie);
  console.log('ie_locale');
  console.log(ie_locale);
  let result = false;

  const n_ie = ie?.replace(/\D/g, '') || '';

  if (n_ie.length >= 8 && n_ie?.length <= 14) {
    switch (ie_locale?.toUpperCase() || '') {
      case 'AC': {
        if (n_ie.length !== 13) {
          break;
        }
        if (!n_ie.startsWith('01')) {
          break;
        }
        const d1 = module11(n_ie.slice(0, 11), '43298765432');
        const d2 = module11(n_ie.slice(0, 11) + d1, '543298765432');
        result = n_ie === n_ie.slice(0, 11) + d1 + d2;
        break;
      }
      case 'AL': {
        // AL e RR começam com 24
        if (n_ie.length !== 9) {
          break;
        }
        if (!n_ie.startsWith('24')) {
          break;
        }
        const d1 = module11(n_ie.slice(0, 8), '98765432');
        result = n_ie === n_ie.slice(0, 8) + d1;
        break;
      }
      case 'AM': // = SC
      case 'CE': // = SC
      case 'ES': // = SC
      case 'PB': // = SC
      case 'PI': // = SC
      case 'SE': // = SC
      case 'SC': {
        if (n_ie.length !== 9) {
          break;
        }
        const d1 = module11(n_ie.slice(0, 8), '98765432');
        result = n_ie === n_ie.slice(0, 8) + d1;
        break;
      }
      case 'AP': {
        if (n_ie.length !== 9) {
          break;
        }
        if (!n_ie.startsWith('03')) {
          break;
        }
        const d1 = moduleAP(n_ie.slice(0, 8), '98765432');
        result = n_ie === n_ie.slice(0, 8) + d1;
        break;
      }
      case 'BA': {
        if (n_ie.length === 8) {
          if ('679'.indexOf(n_ie.charAt(0)) >= 0) {
            const d2 = module11(n_ie.slice(0, 6), '765432');
            const d1 = module11(n_ie.slice(0, 6) + d2, '8765432');
            result = n_ie === n_ie.slice(0, 6) + d1 + d2;
          } else {
            const d2 = module10(n_ie.slice(0, 6), '765432');
            const d1 = module10(n_ie.slice(0, 6) + d2, '8765432');
            result = n_ie === n_ie.slice(0, 6) + d1 + d2;
          }
        } else if (n_ie.length === 9) {
          if ('679'.indexOf(n_ie.charAt(1)) >= 0) {
            const d2 = module11(n_ie.slice(0, 7), '8765432');
            const d1 = module11(n_ie.slice(0, 7) + d2, '98765432');
            result = n_ie === n_ie.slice(0, 7) + d1 + d2;
          } else {
            const d2 = module10(n_ie.slice(0, 7), '8765432');
            const d1 = module10(n_ie.slice(0, 7) + d2, '98765432');
            result = n_ie === n_ie.slice(0, 7) + d1 + d2;
          }
        }
        break;
      }
      case 'DF': {
        if (n_ie.length !== 13) {
          break;
        }
        if (!n_ie.startsWith('07')) {
          break;
        }
        const d1 = module11(n_ie.slice(0, 11), '43298765432');
        const d2 = module11(n_ie.slice(0, 11) + d1, '543298765432');
        result = n_ie === n_ie.slice(0, 11) + d1 + d2;
        break;
      }
      case 'GO': {
        if (n_ie.length !== 9) {
          break;
        }
        if (
          !(
            n_ie.startsWith('10') ||
            n_ie.startsWith('11') ||
            n_ie.startsWith('15')
          )
        ) {
          break;
        }
        const d1 = moduleGO(n_ie.slice(0, 8), '98765432');
        result = n_ie === n_ie.slice(0, 8) + d1;
        break;
      }
      case 'MA': {
        if (n_ie.length !== 9) {
          break;
        }
        if (!n_ie.startsWith('12')) {
          break;
        }
        const d1 = module11(n_ie.slice(0, 8), '98765432');
        result = n_ie === n_ie.slice(0, 8) + d1;
        break;
      }
      case 'MG': {
        if (n_ie.length !== 13) {
          break;
        }
        const d1 = moduleMGd1(
          `${n_ie.slice(0, 3)}0${n_ie.slice(3, 11)}`,
          '121212121212'
        );
        const d2 = moduleMGd2(
          n_ie.slice(0, 3) + n_ie.slice(3, 11) + d1,
          '030211100908070605040302'
        );
        result = n_ie === n_ie.slice(0, 11) + d1 + d2;
        break;
      }
      case 'MS': {
        if (n_ie.length !== 9) {
          break;
        }
        if (!n_ie.startsWith('28')) {
          break;
        }
        const d1 = module11(n_ie.slice(0, 8), '98765432');
        result = n_ie === n_ie.slice(0, 8) + d1;
        break;
      }
      case 'MT': {
        const a_ie = (`0`.repeat(11) + n_ie).slice(-11);
        const d1 = module11(a_ie.slice(0, 10), '3298765432');
        result = a_ie === a_ie.slice(0, 10) + d1;
        break;
      }
      case 'PA': {
        if (n_ie.length !== 9) {
          break;
        }
        if (!n_ie.startsWith('15')) {
          break;
        }
        const d1 = module11(n_ie.slice(0, 8), '98765432');
        result = n_ie === n_ie.slice(0, 8) + d1;
        break;
      }
      case 'PE': {
        if (n_ie.length === 9) {
          const d1 = module11(n_ie.slice(0, 7), '8765432');
          const d2 = module11(n_ie.slice(0, 7) + d1, '98765432');
          result = n_ie === n_ie.slice(0, 7) + d1 + d2;
        } else if (n_ie.length === 14) {
          const d1 = module11(n_ie.slice(0, 13), '5432198765432');
          result = n_ie === n_ie.slice(0, 13) + d1;
        }
        break;
      }
      case 'PR': {
        if (n_ie.length !== 10) {
          break;
        }
        const d1 = module11(n_ie.slice(0, 8), '32765432');
        const d2 = module11(n_ie.slice(0, 8) + d1, '432765432');
        result = n_ie === n_ie.slice(0, 8) + d1 + d2;
        break;
      }
      case 'RJ': {
        if (n_ie.length !== 8) {
          break;
        }
        const d1 = module11(n_ie.slice(0, 7), '2765432');
        result = n_ie === n_ie.slice(0, 7) + d1;
        break;
      }
      case 'RN': {
        if (!n_ie.startsWith('20')) {
          break;
        }
        if (n_ie.length === 9) {
          const d1 = moduleRN(n_ie.slice(0, 8), '0908070605040302');
          result = n_ie === n_ie.slice(0, 8) + d1;
        } else if (n_ie.length === 10) {
          const d1 = moduleRN(n_ie.slice(0, 9), '100908070605040302');
          result = n_ie === n_ie.slice(0, 9) + d1;
        }
        break;
      }
      case 'RO': {
        if (n_ie.length === 9) {
          const d1 = module11(n_ie.slice(3, 8), '65432');
          result = n_ie === n_ie.slice(0, 8) + d1;
        } else if (n_ie.length === 14) {
          const d1 = module11(n_ie.slice(0, 13), '6543298765432');
          result = n_ie === n_ie.slice(0, 13) + d1;
        }
        break;
      }
      case 'RR': {
        // AL e RR começam com 24
        if (n_ie.length !== 9) {
          break;
        }
        if (!n_ie.startsWith('24')) {
          break;
        }
        const d1 = moduleRR(n_ie.slice(0, 8), '12345678');
        result = n_ie === n_ie.slice(0, 8) + d1;
        break;
      }
      case 'RS': {
        if (n_ie.length !== 10) {
          break;
        }
        const d1 = module11(n_ie.slice(0, 9), '298765432');
        result = n_ie === n_ie.slice(0, 9) + d1;
        break;
      }
      case 'SP': {
        if (n_ie.length === 12 && ie?.toUpperCase()?.startsWith('P')) {
          const d1 = moduleSP(n_ie.slice(0, 8), '0103040506070810');
          result = n_ie === n_ie.slice(0, 8) + d1 + n_ie.slice(9, 12);
        } else if (n_ie.length === 12) {
          const d1 = moduleSP(n_ie.slice(0, 8), '0103040506070810');
          const d2 = moduleSP(
            n_ie.slice(0, 8) + d1 + n_ie.slice(9, 11),
            '0302100908070605040302'
          );
          result = n_ie === n_ie.slice(0, 8) + d1 + n_ie.slice(9, 11) + d2;
        }
        break;
      }
      case 'TO': {
        if (n_ie.length === 11) {
          if (
            !(
              n_ie.slice(2, 4) === '01' ||
              n_ie.slice(2, 4) === '02' ||
              n_ie.slice(2, 4) === '03' ||
              n_ie.slice(2, 4) === '99'
            )
          ) {
            break;
          }
          const d1 = module11(n_ie.slice(0, 2) + n_ie.slice(4, 10), '98765432');
          result = n_ie === n_ie.slice(0, 10) + d1;
          break;
        } else if (n_ie.length === 9) {
          const d1 = module11(n_ie.slice(0, 8), '98765432');
          result = n_ie === n_ie.slice(0, 8) + d1;
          break;
        }
        break;
      }
      case '': {
        // UF não informada -> validação apenas pela quantidade de dígitos numéricos
        result = true;
        break;
      }
      default: {
        break;
      }
    }
  }

  return result;
}
