import React, { useState } from 'react';

import { Button, Modal, Text, useSnackbar } from '@cargon/ui';
import { useRefusedReasonStore } from './store';
import { DisplayTravel } from './displayTravel';
import travelApi from '../../../../../services/api/travel';

export default function RefusedReason() {
  const { modal, popTravel, shipping_company } = useRefusedReasonStore();
  const snackbar = useSnackbar();
  const travelsLeft = useRefusedReasonStore(state => state.travels.length);
  const travel = useRefusedReasonStore(
    state => state.travels[state.travels.length - 1] || undefined
  );
  const [reason, setReason] = useState();
  const [loading, setLoading] = useState();
  const [errors, setErrors] = useState({});

  async function handleSubmit() {
    if (!reason) {
      setErrors(prev => ({
        ...prev,
        reason: 'Escolha uma razão para prosseguir',
      }));
      return;
    }

    setErrors({});
    setLoading(true);
    try {
      const { data } = await travelApi.put(
        `${travel?.id}/update-refuse-log-reason`,
        {
          shipping_company: shipping_company.id,
          refused_reason: reason.reason,
        }
      );
      snackbar.show(<Text>Motivo atualizado com sucesso</Text>, {
        type: 'success',
      });
      setReason(null);
      popTravel();
    } catch (e) {
      snackbar.show(<Text>Houve um problema com a requisição</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      size={'lg'}
      show={modal.isOpen}
      handleClose={() => modal.close()}
      heading={<>Viagens com motivo de recusa pendente</>}
      body={
        <>
          <Text>
            Você possui{' '}
            {travelsLeft > 1
              ? `${travelsLeft} viagens recusadas`
              : 'uma viagem recusada'}{' '}
            sem motivo informado! <br />
            Para continuar, preencha o motivo da recusa:
          </Text>
          <br />
          <DisplayTravel
            reason={reason}
            setReason={setReason}
            errors={errors}
          />
        </>
      }
      footer={
        <div className={'d-flex '}>
          <Button
            variant={'success'}
            onClick={() => {
              handleSubmit();
            }}
            loading={loading}
          >
            <Text color={'white'}>
              {travelsLeft > 1 ? 'Próximo' : 'Concluir'}
            </Text>
          </Button>
        </div>
      }
    />
  );
}
