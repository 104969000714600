import React, { useState, useEffect } from 'react';

import { Form } from '@unform/web';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Input from '~/components/Input';
import Select from '~/components/Select';
import DatePicker from '~/components/DatePicker';

import api from '~/services/api';

function Filter({ getTravels }) {
  async function handleFilter(data) {
    getTravels(data);
  }

  function convertLookupToSelectArray(data) {
    try {
      const responseArray = [{ name: 'Todos', id: -1, data: {} }];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < data.length; i++) {
        responseArray.push({
          name: data[i].name,
          id: data[i].id,
          color: data[i].color,
          data: data[i],
        });
      }
      return responseArray;
    } catch (ex) {
      return { text: 'Dados inconsistentes.', value: 0 };
    }
  }

  const [travelStatus, setTravelStatus] = useState([]);
  async function getAvailableStatus() {
    try {
      const response = await api.get(`travel-status`);
      setTravelStatus(convertLookupToSelectArray(response.data));
    } catch (ex) {
      setTravelStatus([{ text: 'Não encontrado.', value: 'null' }]);
    }
  }

  useEffect(() => {
    getAvailableStatus();
  }, []);

  return (
    <Form onSubmit={handleFilter}>
      <Card className="card-list mb-3">
        <Card.Body className="p-5">
          <Row>
            <Col xs={12}>
              <h5 className="card-title">Filtro</h5>
              <hr className="my-3" />
            </Col>
            <Col lg={3} md={3} xs={12}>
              <Input name="travel_number" label="Número da viagem" />
            </Col>
            <Col lg={3} md={3} xs={12}>
              <Input name="license_plate" label="Veículo" maxLength={7} />
            </Col>
            <Col lg={3} md={3} xs={12}>
              <Input name="nfs_number" label="Nº Nota Fiscal" maxLength={44} />
            </Col>
            <Col lg={6} md={6} xs={12}>
              <div className="bd-highlight pb-2">Periodo de/ até</div>
              <div className="d-flex flex-row bd-highlight mb-3">
                <div className="bd-highlight">
                  <DatePicker
                    name="start_date"
                    dateFormat="dd/MM/yyyy"
                    maskProps={{
                      mask: '99/99/9999',
                    }}
                    isClearable
                  />
                </div>
                <div className="bd-highlight">
                  <DatePicker
                    name="end_date"
                    dateFormat="dd/MM/yyyy"
                    maskProps={{
                      mask: '99/99/9999',
                    }}
                    isClearable
                  />
                </div>
              </div>
            </Col>
            <Col lg={6} md={5} xs={12}>
              <Input name="driver_search" label="Motorista" />
            </Col>
            <Col lg={5} md={5} xs={12}>
              <Select label="Status" name="status" options={travelStatus} />
            </Col>
            <Col lg={1} md={1} xs={12} className="text-right py-4">
              <Button type="submit" variant="success">
                Filtrar
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
}

export default Filter;
