import api from '../api';

export * from './Vehicle';
export * from './Driver';

export async function fetchCities(search) {
  try {
    const response = await api.get('cities', { params: { search } });
    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchTags(search) {
  try {
    const response = await api.get('tags', { params: { search } });
    return response.data.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchProvinces() {
  try {
    const response = await api.get('provinces');
    return response.data;
  } catch (ex) {
    return [];
  }
}

export async function fetchPaymentsCard() {
  const response = await api.get('v3/payment/cards');
  return response.data;
}

export async function fetchPaymentMethods() {
  const response = await api.get('payment/methods', {
    params: { initialFetch: true },
  });
  return response.data;
}
export async function fetchBodyTypes() {
  const response = await api.get(`body-types?app=${true}`);
  return response.data || [];
}
export async function fetchImplementTypes() {
  const response = await api.get(`vehicle-implement-types?app=${true}`);
  return response.data || [];
}
