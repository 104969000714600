import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';
import { Text, Section, Editable, useSnackbar } from '@cargon/ui';

import { driverStore } from '~/services/store/Driver';

import { CNH as CHNRegister } from '~/pages/Driver/Single/shared/Cards';

import { validate } from '~/pages/Driver/Single/shared/validate';
import { reshaperCNH } from '~/pages/Driver/Single/shared/reshaper';
import { updateCNH, uploadFile } from '~/services/fetch';

export default function CNH() {
  const params = useParams();
  const snackbar = useSnackbar();

  const {
    currentDriver: driver,
    setCurrentDriver,
    prevDriver,
    updatePrevDriver,
    clearErrors,
    updateErrors,
    loadings,
  } = driverStore();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    const data = reshaperCNH({ data: driver });
    const isValid = await validate({
      schema: 'schemaCNH',
      data,
      setErrors: updateErrors,
      clearErrors,
    });

    if (!isValid[0]) return;

    try {
      setIsLoading(true);
      await updateCNH({ id: params.id, data });

      if (driver.front_cnh_file) {
        await uploadFile({
          cnhFile: driver.front_cnh_file,
          driverId: params.id,
          side: 'front_cnh',
        });
      }

      if (driver.back_cnh_file) {
        await uploadFile({
          cnhFile: driver.back_cnh_file,
          driverId: params.id,
          side: 'back_cnh',
        });
      }
      updatePrevDriver({ driver });
      setIsEditing(false);
    } catch (err) {
      snackbar.show(<Text>Algo deu errado ao alterar a CNH!</Text>, {
        type: 'error',
      });
    } finally {
      clearErrors();
      setIsLoading(false);
    }
  }

  async function handleCancelEdit() {
    setIsEditing(false);
    setCurrentDriver(prevDriver);
    clearErrors();
  }

  return (
    <Section
      Title="CNH"
      loading={loadings.cnh}
      HeaderRightComponent={
        <Editable
          isLoading={isLoading}
          isEditing={isEditing}
          setEditing={setIsEditing}
          onSave={() => handleSubmit()}
          onCancel={() => handleCancelEdit()}
        />
      }
    >
      {isEditing ? (
        <CHNRegister />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label">CNH estrangeira</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver.cnh_is_foreign ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">CNH</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver.cnh_number ? driver.cnh_number : 'Sem número'}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">Categoria</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver.cnh_type ? driver.cnh_type : 'Sem categoria'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label">Cidade emissão CNH</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver.cnhDispatchCity
                ? `${driver.cnhDispatchCity.name} - ${driver.cnhDispatchCity.province.uf}`
                : 'Sem cidade'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label">Chave da CNH</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver && driver.cnh_key
                ? `${driver.cnh_key}`
                : 'Nenhuma chave de CNH cadastrada'}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">1º Habilitação</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver.first_cnh_date}
            </Text>
          </Col>
          <Col md={6} xs={12}>
            <Text type="label">Data de emissão CNH</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver.cnh_dispatch_date}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">Validade da habilitação</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver.cnh_exp_date}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label">Número de formulário</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver.cnh_form_number ?? 'Sem número de formulário'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label">Número Renach</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver.cnh_renach ?? 'Sem número Renach'}
            </Text>
          </Col>
          <Col xs={12}>
            <Row>
              {driver.mopp && (
                <>
                  <Col lg={6} md={6} xs={12}>
                    <Text type="label">Descrição do Mopp</Text>
                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {driver.mopp_description}
                    </Text>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Text type="label">Validade do Mopp</Text>
                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {driver.mopp_expiration}
                    </Text>
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">Autônomo</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver && driver.is_autonomous ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Text type="label">Proprietário</Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {driver && driver.is_owner ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col xs={12} className="mb-3">
            <Text type="label">Upload de CNH</Text>
            <Row>
              <Col lg={6} md={6} xs={12} className="text-center mt-3">
                {driver.frontCnh ? (
                  <Image
                    src={driver.frontCnh.full_path}
                    style={{
                      maxWidth: '100%',
                    }}
                  />
                ) : (
                  <Text type="label" color="gray">
                    Sem foto da frente da CNH
                  </Text>
                )}
              </Col>
              <Col lg={6} md={6} xs={12} className="text-center mt-3">
                {driver.backCnh ? (
                  <Image
                    src={driver.backCnh.full_path}
                    style={{
                      maxWidth: '100%',
                    }}
                  />
                ) : (
                  <Text type="label" color="gray">
                    Sem foto do verso da CNH
                  </Text>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Section>
  );
}
