import travelApi from 'services/api/travel';

export async function getTravelTaxes(travel_id, config) {
  try {
    const url = `/${travel_id}/taxes`;
    const response = await travelApi.get(url, config);
    return response.data.result;
  } catch {
    return null;
  }
}
