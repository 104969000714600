import React, { useEffect } from 'react';

import { BsFillEyeFill } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import theme from 'styles/theme';

import { formatDate, formatDateTime } from '~/utils/formatter';

export default function Progress({
  attended_id,
  color,
  current,
  steps,
  onError,
  destinations,
  ...props
}) {
  let totalCompleted = [];

  useEffect(() => {
    if (destinations && destinations.length > 0) {
      totalCompleted = destinations.filter(
        destination => destination.effective_time
      );
    }
  }, [destinations]);

  function parseDestinationAction(action) {
    if (!action) {
      return '-';
    }

    if (action.toLowerCase() === 'delivery') {
      return 'Entrega';
    }

    return 'Coleta';
  }

  const popover = (
    <Popover id="popover-travel">
      <Popover.Content>
        <div className="flex-row justify-content-around row">
          {destinations.map((destination, index) => (
            <div className="col flex-column">
              <h5 className="d-flex justify-content-center" style={{ color }}>
                {index + 1}
              </h5>
              <div
                className="d-flex justify-content-center"
                style={{ minHeight: '35px' }}
              >
                {parseDestinationAction(destination.action)}
              </div>
              {destination.effective_time && (
                <p className="d-flex justify-content-center">
                  <Badge
                    pill
                    variant="primary"
                    style={{ backgroundColor: color }}
                  >
                    {formatDate(destination.effective_time)}
                  </Badge>
                </p>
              )}
            </div>
          ))}
        </div>
      </Popover.Content>
    </Popover>
  );

  const filteredSteps = steps.filter(
    step => step.name !== 'Aguardando descarregamento'
  );

  return (
    <Card className="card-progress mb-3">
      <Card.Body className="py-3 px-5">
        <Row>
          {filteredSteps.map((step, index) => (
            <>
              <Col lg md xs={6} className="text-center my-3">
                {step.created_at ? (
                  <FaCheckCircle
                    className="h3"
                    style={{
                      color: `${
                        step.completed
                          ? theme.colors.success
                          : theme.colors.gray
                      }`,
                    }}
                  />
                ) : (
                  <div
                    className="progress-circle"
                    style={{ backgroundColor: color }}
                  >
                    {index + 1}
                  </div>
                )}
                <div className="mt-2">
                  {step.name !== 'Em viagem' ? (
                    step.name
                  ) : (
                    <OverlayTrigger
                      trigger="hover"
                      placement="bottom"
                      overlay={popover}
                    >
                      <span>
                        {step.name} ({totalCompleted.length}/{' '}
                        {destinations.length}) <BsFillEyeFill />
                      </span>
                    </OverlayTrigger>
                  )}
                </div>
                {step.completed && (
                  <div className="date-progress">
                    {formatDateTime(step.completed)}
                  </div>
                )}
              </Col>
            </>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
}
