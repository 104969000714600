import * as Yup from 'yup';

export const validatorPaymentsCard = Yup.object().shape({
  corporation: Yup.object().typeError('Campo obrigatório'),
  card_number: Yup.string()
    .required('Campo obrigatório')
    .test('validate-card', 'Cartão inválido', value => {
      const digits = value.replace(/[^0-9]/g, '');

      if (!digits.match(/[0-9]{16}/)) {
        return false;
      }

      return true;
    }),
  due_date: Yup.string().required('Campo obrigatório'),
});
