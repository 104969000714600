import * as Yup from 'yup';

import { plateValidator } from '~/utils/vehicle';

export const ownerSchema = Yup.object().shape({
  owner: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Proprietário obrigatório'),

  anttOwner: Yup.object()
    .shape({
      id: Yup.number().required('Proprietário do RNTRC é obrigatório'),
    })
    .typeError('Proprietário do RNTRC é obrigatório'),
});

export const mainSchema = Yup.object().shape({
  chassi: Yup.string()
    .nullable()
    .required('O chassi é obrigatório!')
    .typeError('O chassi é obrigatório!'),
  renavam: Yup.string()
    .typeError('Renavam Inválido')
    .nullable()
    .required('Renavam Inválido'),
  owner: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Proprietário obrigatório'),

  anttOwner: Yup.object()
    .shape({
      id: Yup.number().required('Proprietário do RNTRC é obrigatório'),
      anttType: Yup.string()
        .required('Proprietário do RNTRC é obrigatório')
        .typeError('Proprietário do RNTRC é obrigatório'),
      cgccpf: Yup.string().when('anttType', {
        is: value => value === 'TAC',
        then: Yup.string()
          .matches(
            /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
            'Tipo de RNTRC é TAC, obrigatório informar um proprietário com CPF'
          )
          .required(
            'Tipo de RNTRC é TAC, obrigatório informar um proprietário'
          ),
        otherwise: Yup.string()
          .matches(
            /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
            'Tipo de RNTRC é ETC, obrigatório informar um proprietário com CNPJ'
          )
          .required(
            'Tipo de RNTRC é ETC, obrigatório informar um proprietário'
          ),
      }),
    })
    .typeError('Proprietário do RNTRC é obrigatório'),
  antt: Yup.string()
    .nullable()
    .required('RNTRC obrigatório')
    .typeError('RNTRC obrigatório'),
  anttType: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Tipo de RNTRC obrigatório'),

  city: Yup.object()
    .shape({
      id: Yup.number().required('Cidade obrigatória'),
    })
    .typeError('Cidade obrigatória'),
  color: Yup.object()
    .shape({
      value: Yup.string().nullable(),
    })
    .nullable(),
  country: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('País obrigatório'),

  isTracked: Yup.boolean().nullable(),
  model: Yup.object()
    .shape({
      id: Yup.number().required('O modelo é obrigatório!'),
    })
    .nullable()
    .required('O modelo é obrigatório!'),
  brand: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .typeError('Marca inválida')
    .nullable()
    .required('A marca é obrigatória!'),
  plate: Yup.string()
    .required('Placa obrigatória')
    .nullable()
    .when('country', {
      is: value => value?.abbreviation === 'br',
      then: Yup.string().test('plateValidation', 'Placa inválida', value =>
        plateValidator(value)
      ),
    })
    .typeError('Placa obrigatória'),
  province: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Estado obrigatório'),
  tags: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
      })
    )
    .nullable()
    .notRequired(),
  tracker: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable()
    .when('isTracked', {
      is: value => value,
      then: Yup.object()
        .shape({
          id: Yup.number(),
        })
        .required('Rastreador obrigatório'),
    }),
});
export const tractionSchema = Yup.object().shape({
  chronotachograph_exp_date: Yup.string()
    .required('Data de validade obrigatória')
    .typeError('Data de validade obrigatória'),
  chronotachograph_number: Yup.string()
    .required('Cronotacógrafo obrigatório')
    .typeError('Cronotacógrafo obrigatório'),
  type: Yup.object()
    .shape({
      id: Yup.number().required(),
      has_body_type: Yup.boolean(),
    })
    .typeError('Tipo de veículo obrigatório'),
  fuel: Yup.object()
    .shape({
      id: Yup.number().nullable(),
    })
    .nullable(),
  axis: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Tração obrigatória'),
  body: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .nullable()
    .when('type', {
      is: value => !!value?.has_body_type,
      then: Yup.object()
        .shape({
          id: Yup.number().required(),
        })
        .typeError('Tipo de carroceria obrigatório')
        .required('Tipo de carroceria obrigatório'),
    }),
});
export const wagonSchema = Yup.object().shape({
  qty_axis: Yup.number()
    .required('Quantidade de eixos é obrigatória')
    .typeError('Quantidade de eixos é obrigatória'),
  body: Yup.object()
    .shape({
      id: Yup.number().required(),
    })
    .typeError('Tipo de carroceria obrigatório'),
});
export const dataSchema = Yup.object().shape({
  tare_mass: Yup.number()
    .positive('Tara precisa ser um valor positivo')
    .required('Tara obrigatório')
    .typeError('Tara obrigatória')
    .integer('Tara precisa ser um valor inteiro')
    .min(1, 'Tara é brigatória'),
  total_gross_weight: Yup.number()
    .positive('Peso precisa ser um valor positivo')
    .typeError('Peso obrigatório')
    .integer('Peso precisa ser um valor inteiro')
    .when('$is_wagon', {
      is: is_wagon => {
        return !!is_wagon;
      },
      then: schema => schema.nullable(),
      otherwise: schema =>
        schema.required('Peso obrigatório').min(1, 'O Peso é obrigatório'),
    }),
  cubage: Yup.number()
    .nullable()
    .integer('Cubagem precisa ser um valor inteiro')
    .transform((currentValue, originalValue) => {
      return originalValue === '' ? null : currentValue;
    }),
  capacity: Yup.number()
    .nullable()
    .integer('Pallets precisa ser um valor inteiro')
    .transform((currentValue, originalValue) => {
      return originalValue === '' ? null : currentValue;
    }),
});
