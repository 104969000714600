import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { Button, Modal, Select, Text, useSnackbar } from '@cargon/ui';
import api from '../../../services/api';

function Refuse({ isOpen, onClose, travel, removeTravel }) {
  const snackbar = useSnackbar();
  const history = useHistory();

  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [reasons, setReasons] = useState();
  const [reason, setReason] = useState();

  useEffect(() => {
    async function fetchReasons() {
      try {
        const response = await api.get('v3/log-reason');
        setReasons(response.data);
      } catch {
        snackbar.show(<Text>Erro ao carregar motivos de recusa</Text>, {
          type: 'error',
        });
      }
    }

    fetchReasons();
  }, []);

  async function handleSubmit(data) {
    setLoading(true);

    try {
      await api.post(`allocated-travels/${travel.id}`, {
        parameters: {
          obs: data.reason?.reason,
          load_id: travel.load_id,
          person_id: travel.shipping_company,
        },
      });
      setErrors();
      history.push('/alocadas');
      snackbar.show(<Text>Viagem recusada com sucesso!</Text>, {
        type: 'success',
      });
      removeTravel();
    } catch (error) {
      snackbar.show(
        <Text>
          {error.response?.data?.error ||
            'Algo deu errado, tente novamente mais tarde!'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
      onClose();
    }
  }

  return (
    <>
      <Modal
        show={isOpen}
        handleClose={onClose}
        heading={
          <Text type="subheader" color="dark">
            Recusa de Viagem
          </Text>
        }
        body={
          <>
            <Row md={12} className="px-5 align-items-center">
              <Col md={12} className="mb-2">
                <Select
                  label="Motivos para recusar"
                  value={reason}
                  options={reasons}
                  getOptionLabel={option => option.reason}
                  getOptionValue={option => option.id}
                  onChange={value => {
                    setReason(value);
                  }}
                />
              </Col>
            </Row>
            <Col md={12} className="mt-1 d-flex justify-content-center mb-2">
              <Button
                disabled={!reason}
                variant="success"
                loading={loading}
                className="py-2"
                onClick={() => handleSubmit({ reason })}
              >
                <Text color="white" weight="600">
                  Confirmar
                </Text>
              </Button>
            </Col>
          </>
        }
      />
    </>
  );
}

export default Refuse;
