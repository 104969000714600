import React from 'react';
import Badge from 'react-bootstrap/Badge';

export default function TravelBadge({ children, pill, ...props }) {
  return (
    <Badge pill {...props}>
      {children}
    </Badge>
  );
}
