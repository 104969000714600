import React, { useState, useMemo } from 'react';
import { FaSearch, FaTrash, FaDownload } from 'react-icons/fa';
import Lightbox from 'react-image-lightbox';
import { Button, Text, useSnackbar } from '@cargon/ui';

import { formatDateTime } from '~/utils/formatter';
import StringBuilder from '~/utils/StringBuilder';

import * as Styled from './styles';

import { deleteAttachment } from '~/services/fetch';
import { driverStore } from '~/services/store/Driver';

export default function AttachmentItem({ attachment, setShowingModal }) {
  const snackbar = useSnackbar();
  const [isHovering, setHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentDriver, updateCurrentDriver } = driverStore();

  function getFullPathArray(item) {
    return item?.full_path
      ? item.full_path?.split('.')
      : item.file.type?.split('/');
  }

  const attachmentsWithoutPDF = useMemo(() => {
    return currentDriver?.attachments?.filter(item => {
      const filterPDF = getFullPathArray(item);
      if (filterPDF[filterPDF?.length - 1] === 'pdf') return null;
      return item;
    });
  }, [currentDriver.attachments]);

  const [lightbox, setLightbox] = useState({
    isOpen: false,
    attachments: attachmentsWithoutPDF,
    index: 0,
  });

  const lightboxIndexes = useMemo(() => {
    const next = (lightbox?.index + 1) % lightbox?.attachments?.length;
    const previous =
      (lightbox?.index + lightbox?.attachments?.length - 1) %
      lightbox?.attachments?.length;

    return { next, previous };
  }, [lightbox?.index, lightbox?.attachments]);

  const lightboxTitle = useMemo(() => {
    function getDocumentMetadata() {
      const document = lightbox?.attachments[lightbox?.index];
      const type = '';
      const createdAt = formatDateTime(document?.created_at);

      return { type, createdAt };
    }

    const builder = new StringBuilder(' ');
    const documentMetadata = getDocumentMetadata();
    builder
      .append(`${lightbox?.index + 1}/${lightbox?.attachments?.length}`)
      .append('|')
      .append(documentMetadata.type)
      .append(`(${documentMetadata.createdAt})`);

    return builder.build();
  }, [lightbox?.index, lightbox?.attachments]);

  function getImagePath(index, alwaysDisplay) {
    const isSameAsIndex =
      lightbox?.index === lightboxIndexes.next &&
      lightbox?.index === lightboxIndexes.previous;

    if (!alwaysDisplay && isSameAsIndex && lightbox?.attachments.length === 1) {
      return null;
    }

    const lightBoxAttachment = lightbox?.attachments[index];

    return lightBoxAttachment?.full_path
      ? lightBoxAttachment?.full_path
      : lightBoxAttachment?.file?.tempPath;
  }

  async function removeAttachment(attachmentToRemove) {
    function removeFromStore() {
      const filteredAttachments = currentDriver?.attachments.filter(
        item => item !== attachmentToRemove
      );
      updateCurrentDriver({
        attachments: filteredAttachments,
      });
    }

    if (attachmentToRemove?.full_path) {
      try {
        setIsLoading(true);
        await deleteAttachment({ id: attachmentToRemove.id });
        removeFromStore();
      } catch (err) {
        snackbar.show('Não foi possível excluir o anexo', { type: 'error' });
      } finally {
        setIsLoading(false);
      }
    } else {
      removeFromStore();
    }
  }

  function handleClick() {
    function onClickPreview(file) {
      const index = file ? attachmentsWithoutPDF.findIndex(a => a === file) : 0;

      setLightbox(prev => ({
        ...prev,
        index,
        attachments: attachmentsWithoutPDF,
        isOpen: true,
      }));
    }

    const fullPathArray = getFullPathArray(attachment);

    if (fullPathArray?.[fullPathArray?.length - 1] === 'pdf') {
      setShowingModal(true);
    } else {
      onClickPreview(attachment);
    }
  }

  function getBackgroundImagePath() {
    if (attachment.full_path) {
      return `url(${
        attachment.full_path?.split('.')?.pop() === 'pdf'
          ? '/images/default-doc.png'
          : attachment.full_path
      }) center center`;
    }
    return `url(${
      attachment.file.type?.split('/')?.pop() === 'pdf'
        ? '/images/default-doc.png'
        : attachment.file.tempPath
    }) center center`;
  }

  return (
    <>
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 240,
          height: 130,
          background: getBackgroundImagePath(),
          backgroundSize: '100%',
        }}
      >
        {lightbox?.isOpen && (
          <Lightbox
            mainSrc={getImagePath(lightbox?.index, true)}
            nextSrc={getImagePath(lightboxIndexes.next)}
            prevSrc={getImagePath(lightboxIndexes.previous)}
            onCloseRequest={() =>
              setLightbox(prev => ({ ...prev, isOpen: false }))
            }
            onMovePrevRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.previous,
              }))
            }
            onMoveNextRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.next,
              }))
            }
            imageTitle={lightboxTitle}
          />
        )}
        {isHovering && (
          <Styled.Backdrop>
            <button type="button" onClick={handleClick}>
              <FaSearch color="#fff" size={28} />
            </button>

            {attachment?.full_path && (
              <a href={attachment.full_path} download>
                <FaDownload color="#fff" size={32} />
              </a>
            )}

            <Button
              variant="transparent"
              loading={isLoading}
              size={0}
              onClick={() => removeAttachment(attachment)}
            >
              <FaTrash size={28} />
            </Button>
          </Styled.Backdrop>
        )}

        <Styled.DateTime>
          <Text type="label" color="white">
            {formatDateTime(attachment.created_at)}
          </Text>
        </Styled.DateTime>
      </div>
    </>
  );
}
