import React, { useEffect, useState } from 'react';
import { Button, Modal, Select, Text } from '@cargon/ui';
import { auctionStore } from '~/services/store/Auction';
import { Col, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

function AcceptAuction({
  isOpen,
  close,
  handleAccept,
  accepted,
  loading,
  setLoading,
}) {
  const history = useHistory();
  const shippingCompanies = auctionStore(state =>
    state.auction.auctionShippingCompanies?.map(shipp => shipp.shippingCompany)
  );
  const travelId = auctionStore(state => state.auction.travel_id);

  const [shipper, setShipper] = React.useState();
  const [error, setError] = useState('');

  const handleSubmit = () => {
    if (!shipper) {
      setError('Selecione uma transportadora');
    } else {
      setError('');
      setLoading(true);
      handleAccept(shipper.id);
    }
  };
  useEffect(() => {
    if (isOpen && shipper === undefined) {
      setShipper(shippingCompanies[0]);
    }
  }, [isOpen]);
  return (
    <Modal
      show={isOpen}
      size="lg"
      handleClose={close}
      closeButton={false}
      heading="Aceitar viagem"
      backdrop="static"
      body={
        <>
          {accepted ? (
            <Row>
              <Col xs={12}>
                <Text weight={500}>Viagem aceita com sucesso!</Text>
                <br />
                <br />
                <Text>
                  A transportadora {shipper?.name} ficou com a oferta!
                </Text>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12} className="mb-1">
                <Text>
                  Escolha qual de suas transportadoras aceitará a viagem:
                </Text>
              </Col>
              <Col xs={12}>
                <Select
                  label="Transportadora"
                  options={shippingCompanies}
                  value={shipper}
                  onChange={value => {
                    setError('');
                    setShipper(value);
                  }}
                  getOptionLabel={option => option.social_name}
                  getOptionValue={option => option.id}
                  error={error}
                />
              </Col>
            </Row>
          )}
        </>
      }
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {accepted ? (
            <>
              <Button variant="secondary" onClick={() => history.goBack()}>
                <Text color="dark" weight={500}>
                  Voltar à listagem
                </Text>
              </Button>
              <Link to={`/viagens/${travelId}`}>
                <Button variant="primary">
                  <Text weight={500}>Ver viagem</Text>
                </Button>
              </Link>
            </>
          ) : (
            <>
              <Button variant="secondary" onClick={() => close()}>
                <Text color="dark" weight={500}>
                  Cancelar
                </Text>
              </Button>
              <Button
                variant="primary"
                onClick={() => handleSubmit()}
                loading={loading}
              >
                <Text weight={500}>Aceitar</Text>
              </Button>
            </>
          )}
        </div>
      }
    />
  );
}

export default AcceptAuction;
