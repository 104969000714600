import React, { useEffect, memo, forwardRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Card, Text } from '@cargon/ui';
import { Grid as GridRaw } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../styles/theme';

import useController from './useController';

function Notifications({ className, 'aria-labelledby': labeledBy }, ref) {
  const {
    loading,
    notifications,
    pagination,
    setNotifications,
    fetchNotifications,
    handleNotificationClick,
  } = useController();

  useEffect(() => {
    fetchNotifications();
  }, []);

  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
      setNotifications([]);
    };
  }, []);

  if (!didMount) {
    return null;
  }
  const Container = styled.div`
    position: absolute;
    top: 41px;
    overflow-y: scroll;
    width: 300px;
    transform: translateX(-200px);

    @media (max-width: 576px) {
      transform: translateX(0px);
    }

    @media (max-width: 767px) {
      transform: translateX(10px);
    }

    @media (max-width: 991px) {
      transform: translateX(0px);
    }

    ::-webkit-scrollbar-track {
      background-color: #fff;
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 4px;
      background: #f6f2f9;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 2rem;
      background: ${theme.colors.light_blue};
    }

    max-height: 80vh;
  `;
  const Notification = styled.div`
    cursor: pointer;
    transition: opacity 0.1s ease-in;
    border-bottom: 1px solid ${theme.colors.gray};
    background-color: ${theme.colors.light};
    padding: 15px;

    &.border-none {
      border: none;
    }

    &:hover {
      opacity: 0.8;
    }

    &.is-read {
      background-color: #fff;

      &:hover {
        h2,
        span {
          color: ${theme.colors.dark} !important;
        }
      }
    }
  `;
  const TextNotification = styled(Text)`
    align-self: center;
  `;
  const Grid = styled(GridRaw)`
    display: flex;
    justify-content: space-between;
    padding: 10px;
  `;
  return (
    <Container
      ref={ref}
      aria-labelledby={labeledBy}
      id="notify-scroll"
      className={className}
    >
      <Grid container>
        <TextNotification as="h2" type="regular">
          Notificações
        </TextNotification>
      </Grid>
      <InfiniteScroll
        dataLength={notifications?.length}
        next={() => fetchNotifications(pagination.page + 1)}
        hasMore={pagination?.page < pagination.lastPage}
        scrollableTarget="notify-scroll"
      >
        {notifications?.map((notification, index) => (
          <Notification
            onClick={() => handleNotificationClick(notification)}
            className={`${notification?.read ? 'is-read' : ' '} ${
              notifications?.length - 1 === index ? 'border-none' : ' '
            }`}
          >
            <Text
              as="h2"
              type="regular"
              color={
                notification.read ? theme.colors.gray : theme.colors.primary
              }
              weight={notification.read ? 400 : 700}
            >
              {notification.title}
            </Text>
            <Text
              type="label"
              color={notification.read ? theme.colors.gray : undefined}
            >
              {notification.text}
            </Text>
          </Notification>
        ))}
      </InfiniteScroll>

      {!loading && notifications?.length === 0 && (
        <Card>Sem notificações não lidas</Card>
      )}
    </Container>
  );
}

export default memo(forwardRef(Notifications));

export { default as useNotificationsController } from './useController';
export { NotificationContextProvider } from './context';
