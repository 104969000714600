import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Text, Input, Button, Table } from '@cargon/ui';
import { FaTrash } from 'react-icons/fa';

import { driverStore } from '~/services/store/Driver';

const phoneNumberMask = [
  '(',
  /[0-9]/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export default function Contacts() {
  const { currentDriver, updateCurrentDriver, errors, updateErrors } =
    driverStore();

  const [contact, setContact] = useState({
    name: '',
    phone: '',
  });

  function validateContact() {
    updateErrors({ contactPhone: null });
    if (contact.phone.length !== 14) {
      updateErrors({ contactPhone: 'Número de telefone inválido' });
      return false;
    }

    return true;
  }

  async function addContact() {
    const isValid = validateContact();
    if (isValid) {
      const currentContacts = currentDriver?.contacts || [];
      updateCurrentDriver({ contacts: [...currentContacts, contact] });
      setContact({ name: '', phone: '' });
    }
  }

  async function deleteContact(index) {
    updateCurrentDriver({
      contacts: currentDriver.contacts.filter((_, i) => i !== index),
    });
  }

  const isContactFilled = contact.name && contact.phone;

  return (
    <Row>
      <Col xs={12} md={6}>
        <Input
          label="Telefone principal *"
          value={currentDriver?.phone}
          onChange={({ target }) =>
            updateCurrentDriver({ phone: target.value })
          }
          masked
          mask={phoneNumberMask}
          guide={false}
          error={errors?.phone}
        />
      </Col>
      <Col xs={12} className="mt-3">
        <Input
          label="E-mail"
          value={currentDriver?.email}
          type="email"
          onChange={({ target }) =>
            updateCurrentDriver({ email: target.value })
          }
          error={errors?.email}
        />
      </Col>
      <Col xs={12} className="my-4">
        <Text type="body" color="dark">
          Outros contatos
        </Text>
      </Col>
      <Col xs={12}>
        <Input
          label="Nome"
          value={contact.name}
          onChange={({ target }) =>
            setContact(prev => ({ ...prev, name: target.value }))
          }
        />
      </Col>
      <Col md={6} xs={12} className="mt-3">
        <Input
          label="Telefone"
          masked
          mask={phoneNumberMask}
          guide={false}
          value={contact.phone}
          error={errors?.contactPhone}
          onChange={({ target }) =>
            setContact(prev => ({ ...prev, phone: target.value }))
          }
        />
        <Button
          variant="success"
          disabled={!isContactFilled}
          onClick={addContact}
          className="mt-3"
        >
          <Text weight={600} type="regular">
            Adicionar
          </Text>
        </Button>
      </Col>
      <Col xs={12}>
        {!currentDriver?.contacts?.length ? (
          <div className="mt-3 text-center">
            <Text type="body" color="dark">
              Nenhum contato adicionado.
            </Text>
          </div>
        ) : (
          <Table>
            <thead>
              <tr>
                <th>
                  <Text type="label" weight="500">
                    Nome
                  </Text>
                </th>
                <th className="text-center">
                  <Text type="label" weight="500">
                    Telefone
                  </Text>
                </th>
                <th className="text-center">
                  <Text type="label" weight="500">
                    Ação
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentDriver?.contacts?.map((contact, index) => (
                <tr>
                  <td>
                    <Text type="regular" weight="400">
                      {contact.name}
                    </Text>
                  </td>
                  <td width="240" className="text-center">
                    <Text type="regular" weight="400">
                      {contact.phone}
                    </Text>
                  </td>
                  <td width="60" className="text-center">
                    <Button
                      variant="error"
                      size="sm"
                      className="mx-1"
                      onClick={() => deleteContact(index)}
                    >
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  );
}
