/* eslint-disable import/no-unresolved */
import { Text } from '@cargon/ui';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaRegClock } from 'react-icons/fa';
import { formatTime } from '~/utils/formatter';

function Modal({ alerts }) {
  return (
    <Row md={12}>
      {alerts &&
        alerts.map(alert => (
          <Col md={12} className="px-5 my-2">
            <FaRegClock color="#464E5F" className="mr-2" />
            <Text color="dark" type="regular">
              {formatTime(alert.captured_at)}
            </Text>
            <Text color="gray" type="regular">
              - Acima de 80km/h por mais de 3 minutos.
            </Text>
          </Col>
        ))}
    </Row>
  );
}

export default Modal;
