import { Button, Modal, Text, TextArea, Upload, useSnackbar } from '@cargon/ui';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { uploadAttachment } from '~/services/fetch/Travel/Attachments';

export function AttachmentModal({ isOpen, onClose, fetchAttachments }) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});

  const params = useParams();
  const snackbar = useSnackbar();

  function handleClose() {
    setFiles([]);
    setDescription('');
    setErrors({});
    onClose();
  }

  async function addAttachment() {
    try {
      setLoading(true);

      await uploadAttachment({ files, description, travelId: params.id });

      handleClose();
      fetchAttachments();
    } catch (ex) {
      snackbar.show(
        <Text>
          Não foi possível adicionar esse anexo, tente novamente mais tarde.
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  function onFilesChanged({ target }, hasError) {
    setFiles([]);
    setErrors({});

    if (hasError) {
      setErrors({ file: 'Algum arquivo selecionado é maior que 1MB.' });
      return;
    }

    setFiles(
      [...target.files].map(file => {
        return {
          blob: file,
          size: file?.size,
          name: file?.name,
          type: file?.type,
          tempPath: URL.createObjectURL(file),
        };
      })
    );
  }

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Anexos
        </Text>
      }
      body={
        <div>
          <div className="mb-3">
            <Text>Adicione um arquivo</Text>
            <Upload
              name="file"
              label="Arquivo máximo de 1MB"
              accept=".jpg,.jpeg,.png,.pdf"
              multiple
              ignoreValidation
              value={files}
              onChange={onFilesChanged}
              error={errors.file}
            />
          </div>

          <TextArea
            label="Descrição"
            resizable={false}
            value={description}
            onChange={event => setDescription(event.target.value)}
          />
          <div className="d-flex flex-column align-items-center mt-3">
            <Button
              loading={loading}
              onClick={addAttachment}
              disabled={!files.length}
            >
              Adicionar
            </Button>
          </div>
        </div>
      }
    />
  );
}
