import {
  lowerCase,
  formatDateFromDatabase,
  formatCustomDate,
} from '~/utils/formatter';
import { formatDateCustom } from '~/utils/date-helpers';

export default function reshapeDriver({ data }) {
  return {
    ...data,
    cnh_is_foreign: data.cnh_is_foreign ? data.cnh_is_foreign : false,
    first_cnh_date: formatCustomDate({
      date: data.first_cnh_date,
      shapeFrom: 'YYYY-MM-DD',
      shapeTo: 'DD/MM/YYYY',
    }),
    cnh_dispatch_date: formatCustomDate({
      date: data.cnh_dispatch_date,
      shapeFrom: 'YYYY-MM-DD',
      shapeTo: 'DD/MM/YYYY',
    }),
    cnh_exp_date: formatCustomDate({
      date: data.cnh_exp_date,
      shapeFrom: 'YYYY-MM-DD',
      shapeTo: 'DD/MM/YYYY',
    }),
    mopp_expiration: formatCustomDate({
      date: data.mopp_expiration,
      shapeFrom: 'YYYY-MM-DD',
      shapeTo: 'DD/MM/YYYY',
    }),
    password: '',
    verifyPassword: '',
    verified: data?.user?.verified || false,
    vehicles: data?.vehicles?.map(item => ({
      ...item,
      current: item.pivot.current,
    })),
    token: data?.user?.token,
    birthdate: formatDateFromDatabase(data.birthdate),
    rg_dispatch_date: formatDateFromDatabase(data.rg_dispatch_date),
    property_type: data.property_type
      ? { value: lowerCase(data.property_type), label: data.property_type }
      : null,
    property_status: data.property_status
      ? { value: lowerCase(data.property_status), label: data.property_status }
      : null,
    addresses: data.addresses
      ? { ...data.addresses, hasNumber: true }
      : { hasNumber: true },
  };
}

export function personalDataReshaper({ data }) {
  let personalData = {
    social_name: data.social_name,
    civilState: data.civilState,
    civil_state_id: data.civilState?.value,
    cgccpf: data.cgccpf,
    birthdate: formatDateCustom({
      date: data.birthdate,
      formatFrom: 'DD/MM/yyyy',
      formatTo: 'yyyy-MM-DD',
    }),
    born_city_id: data.bornCity?.id,
    born_province_id: data.bornCity?.province?.id,
    rg: data.rg,
    rg_emissor: data.rg_emissor,
    rg_dispatch_province:
      data.rgDispatchProvince && `${data.rgDispatchProvince.id}`,
    rg_dispatch_date: formatDateCustom({
      date: data.rg_dispatch_date,
      formatFrom: 'DD/MM/yyyy',
      formatTo: 'yyyy-MM-DD',
    }),
    mothers_name: data.mothers_name,
    father_name: data.father_name,
    pispasep: data.pispasep,
    is_international: data.is_international,
    selected_tags: data.tags ? data.tags.map(x => x.id) : null,
  };

  if (personalData.is_international) {
    personalData = {
      ...personalData,
      rg: null,
      rg_emissor: null,
      rg_dispatch_date: null,
      rg_dispatch_province: null,
    };
  }

  return personalData;
}
