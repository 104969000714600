import axios from 'axios';
import store from '../../store';

const locationService = axios.create({
  baseURL: process.env.REACT_APP_LOCATION_SERVICE_URL,
});

locationService.interceptors.request.use(config => {
  const outputConfig = config;

  const state = store.getState();
  const { authenticated, user } = state.auth;
  if (authenticated) {
    outputConfig.headers.Authorization = `${user.token.type} ${user.token.token}`;
  }
  outputConfig.params = {
    ...outputConfig.params,
  };

  return outputConfig;
});
export default locationService;
