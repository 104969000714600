import { useState, useCallback } from 'react';

export default function useErrors(initialValues = {}) {
  const [errors, setErrors] = useState(initialValues);

  const getErrorsFromYup = useCallback(inner => {
    setErrors(
      inner.reduce((acc, error) => {
        const output = acc;
        output[error.path] = error.message;

        return output;
      }, {})
    );
  }, []);

  const clearErrors = useCallback(() => {
    setErrors(initialValues);
  }, []);

  return [errors, { setErrors, getErrorsFromYup, clearErrors }];
}
