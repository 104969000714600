import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FormControl from 'react-bootstrap/FormControl';

import Header from '~/components/Header';
import Offset from '~/components/Offset';
import Chat from './Chat';
import DealHeader from './HeaderDeal';

import DealsActions from '~/store/modules/deals/actions';

import { formatDate, formatCurrency } from '~/utils/formatter';

export default function List({ heightList = '550px' }) {
  const [deal, setDeal] = useState({});
  const [selectedDeal, setSelectedDeal] = useState('');
  const [filteredDeals, setFilteredDeals] = useState([]);

  const dispatch = useDispatch();
  const deals = useSelector(store => store.deals);

  const user = useSelector(store => store.auth.user.properties);

  function filterDeal(filter) {
    if (!filter) {
      setFilteredDeals(deals.data);
      return;
    }
    const filtered = filteredDeals.filter(deal => {
      const string = `${deal.id} ${deal.origin_city} ${deal.origin_province} ${deal.last_destination_city} ${deal.last_destination_province}`;
      return string.includes(filter);
    });
    setFilteredDeals(filtered);
  }

  useEffect(() => {
    dispatch(
      DealsActions.dealsRequest({
        user_id: user.id,
        paginate: true,
        only_active: true,
      })
    );
  }, []);

  useEffect(() => {
    if (deals.data) {
      setFilteredDeals(deals.data);
    }
  }, [deals]);

  useEffect(() => {
    setSelectedDeal(deal.id);
  }, [deal]);

  useEffect(() => {
    const [firstDeal] = filteredDeals;
    if (firstDeal) {
      setDeal(firstDeal);
      setSelectedDeal(firstDeal.id);
    } else {
      setDeal({});
    }
  }, [filteredDeals]);

  const hasDeals = useMemo(() => deals.fetched && filteredDeals.length > 0, [
    deals,
    filteredDeals,
  ]);

  return (
    <>
      <Header />
      <Offset>
        <Container className="mh-100">
          <Card className="card-list">
            <Card.Body className="p-0">
              <Container>
                <DealHeader deal={deal} />
                <Row>
                  <Col lg={4} md={4} xs={12} className="px-0">
                    <div className="chat-filter p-3">
                      <FormControl
                        placeholder="Filtrar"
                        onChange={event => filterDeal(event.target.value)}
                      />
                    </div>

                    <div className="chat-list p-0">
                      {filteredDeals.map((deal, index) => {
                        const itemColor =
                          selectedDeal === deal.id ? '#007bff' : '';
                        return (
                          <Card
                            key={index}
                            action
                            onClick={() => setDeal({ ...deal })}
                            style={{
                              borderColor: itemColor,
                            }}
                            className="chat-selection"
                          >
                            <Card.Body>
                              <Container>
                                <Row>
                                  <Col className="px-0">
                                    <div className="card-label">
                                      #{deal.load_id}
                                    </div>
                                  </Col>
                                  <Col className="px-0 text-right">
                                    <div className="card-label">
                                      {formatCurrency(deal.price)}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} className="px-0">
                                    <hr />
                                    <div className="card-label">Origem</div>

                                    <div className="card-text pb-0">{`${deal.origin_city} - ${deal.origin_province}`}</div>
                                    <div className="card-text pb-3">
                                      {formatDate(deal.origin_scheduled_time)}
                                    </div>

                                    <div className="card-label">Destino</div>
                                    <div className="card-text pb-0">{`${deal.last_destination_city} - ${deal.last_destination_province}`}</div>
                                    <div className="card-text pb-0">
                                      {formatDate(
                                        deal.last_destination_scheduled_time
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </Card.Body>
                          </Card>
                        );
                      })}

                      <Card className={`d-${hasDeals ? 'none' : 'block'}`}>
                        <Card.Body>
                          <Container>
                            <Row>
                              <Col className="text-center">
                                <div className="card-label mb-0">
                                  Nenhuma negociação encontrada.
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>

                  <Col lg={8} md={8} xs={12} className="px-0">
                    <Chat deal={deal} user={user} />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Container>
      </Offset>
    </>
  );
}
