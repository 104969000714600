import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
 @import url('https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');
  * {
    border: none;
    outline: none;
    box-sizing:border-box;
  }

  /* z-index para funcionar o light box */

  .MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft{
    z-index:4;
  }
  .MuiAppBar-positionFixed{
    z-index:3 !important;
  }
  .MuiGrid-container[hidden]{
    display:none !important;
  }

/* z-index para funcionar o light box */

  body {
    padding: 0;
    margin: 0;
  }

  .MuiPaper-root{
    color:#627182 !important;
    font-family: 'Muli', sans-serif !important;
  }

  .MuiTypography-root{
    font-family: 'Muli', sans-serif !important;
  }

  label {
    margin-bottom: .5rem;
  }
  a{
    text-decoration:none;
  }

  .Mui-selected{

    .bgChip{
      background-color: ${({ theme }) => theme.colors.primary} !important;
      color:#fff;
    }
  }

  .MuiToggleButton-root.Mui-selected{
    color: #fff !important;
    background-color: #2196f3 !important;
    border-color: #2196f3 !important;
  }

  .MuiToggleButtonGroup-root {
    display: flex !important;
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 1;

  }

  .MuiToggleButton-root{
    width:100%;
  }

  .alertDanger{
    background-color: #fbd5dd;
    color: #8c1a32;
    border-color: #8c1a32;
  }

  .alertWarning{
    background-color: #fff7e2;
    color: #988142;
    border-color: #988142;
  }

  .alert{
    text-align: center;
    padding: 7px 15px;
    font-weight: 700;
    border:1px solid;
    border-radius: 20px;
    display: inherit;
  }

  .textTitle{
    margin-bottom:5px;
  }

  .MuiGrid-root h3{
    font-size:1.25rem !important;
    margin:.5rem 0;
  }
  .MuiGrid-root h4{
    font-size:1.25rem !important;
    margin:.5rem 0;
  }
  .MuiGrid-root h2{
    font-size:1.25rem !important;
    margin:.5rem 0;
  }

  .select__single-value{
    color: #7A8994 !important;
  }


  /* a{
    color:${({ theme }) => theme.colors.primary} !important;
  } */

  hr{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid rgba(0,0,0,.1);
  }

  .MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected {
    background-color: ${({ theme }) => theme.colors.primary} !important;
  }
  .MuiPickersToolbarText-toolbarBtnSelected, .MuiPickersToolbarText-toolbarTxt {
    padding: 5px;
  }
  .MuiPickersYear-yearSelected {
    color: ${({ theme }) => theme.colors.primary} !important;
  }


  @media only screen and (max-width: 600px) {

  .toogleExtratoCobrancas{
      display:inline-block !important;

  }

  .toogleExtratoCobrancas{
    .MuiButtonBase-root {
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
      border-radius:0px !important;
    }
  }

}
`;
