import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

// eslint-disable-next-line import/no-cycle
import Header from '~/components/Header';
import Offset from '~/components/Offset';
import { DefaultLoadingComponent } from '~/components/List';

import api from '~/services/api';
import useCurrentUser from '~/hooks/useCurrentUser';

import InfiniteScroll from '~/components/InfiniteScroll';
import TravelCard from './Card/index';
import Filter from './Filter';

export default function List() {
  const user = useCurrentUser();

  const [isLoading, setLoading] = useState(false);
  const [travels, setTravels] = useState([]);
  const [pagination, setPagination] = useState({
    lastPage: 0,
    page: 0,
    total: 0,
  });
  const [isFilterVisible, setFilterVisible] = useState(false);

  async function getTravels(page = 1, filter) {
    if (page === 1) setLoading(true);

    let shippingCompanies = user.shipping_companies.map(({ id }) => id);
    if (!shippingCompanies.length) {
      shippingCompanies = [0];
    }

    try {
      const response = await api.get(`travel-data`, {
        params: {
          page,
          company_id: user.company_id,
          shipping_company: shippingCompanies,
          ...filter,
          allocated: false, // default true
        },
      });
      const { data, ...rest } = response.data;
      setTravels(oldTravels => (page === 1 ? data : [...oldTravels, ...data]));
      setPagination(rest);
    } catch (error) {
      // Handle exception
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTravels();
  }, []);

  function renderItem(travel) {
    return <TravelCard travel={travel} />;
  }

  return (
    <>
      <Header
        RightComponent={
          <Button
            variant="secondary"
            style={{ minWidth: '86px', height: '38px' }}
            onClick={() => setFilterVisible(!isFilterVisible)}
            className="ml-1"
          >
            {isFilterVisible ? 'Ocultar filtro' : 'Mostrar filtro'}
          </Button>
        }
      />
      <Offset>
        <Container>
          {isFilterVisible && (
            <Filter getTravels={filter => getTravels(1, filter)} />
          )}
          <InfiniteScroll
            loading={isLoading}
            data={travels}
            renderItem={travel => renderItem(travel)}
            onEndReach={() => getTravels(pagination.page + 1)}
            hasMore={pagination.page < pagination.lastPage}
            scrollThreshold={0.7}
            loader={<DefaultLoadingComponent />}
          />
        </Container>
      </Offset>
    </>
  );
}
