import React, { useCallback, useEffect, useState } from 'react';
import { Button, Text, useSnackbar } from '@cargon/ui';
import { Link } from 'react-router-dom';
import ApplicationLayout from '~/layouts/ResponsiveApplication';
import InfiniteScroll from 'components/InfiniteScroll';

import Filter from '~/pages/ShippingCompanies/List/Filter';
import api from '~/services/api';
import { DefaultLoadingComponent } from '~/components/List';
import ShippingCompaniesCard from './Card';
import * as Styled from './styles';

export default function List() {
  const [pagination, setPagination] = useState({
    page: 1,
    lastPage: 1,
  });
  const [shippingCompanies, setShippingCompanies] = useState([]);
  const [isFilterVisible, setFilterVisible] = useState(false);
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);
  const snackbar = useSnackbar();

  async function fetchShippingCompanies(searchOptions) {
    if (searchOptions?.page === 1) setFirstFetch(true);
    setLoading(true);
    try {
      const { data } = await api.get('carrier/shipping-company', {
        params: {
          ...searchOptions,
        },
      });
      if (data) {
        const { data: shippers, ...pag } = data;
        setPagination(pag);
        if (Number(pag.page) === 1) {
          setShippingCompanies(shippers);
        } else {
          setShippingCompanies([...shippingCompanies, ...shippers]);
        }
      }
    } catch (ex) {
      snackbar.show(<Text>Não foi possível listar as transportadoras</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
      setFirstFetch(false);
    }
  }

  const handleFilter = useCallback(
    (filterParams = null) => {
      fetchShippingCompanies(filterParams || filter);
    },
    [filter]
  );

  useEffect(() => {
    fetchShippingCompanies();
  }, []);

  return (
    <>
      <ApplicationLayout
        title="Transportadoras"
        expand
        RightComponent={
          <>
            <Styled.ButtonsContainer className="">
              <Button onClick={() => setFilterVisible(!isFilterVisible)}>
                <Text weight={500} color="white">
                  {isFilterVisible ? 'Ocultar filtro' : 'Mostrar filtro'}
                </Text>
              </Button>
              <Link to="/transportadoras/cadastrar">
                <Button variant="secondary">
                  <Text color="black" weight={500}>
                    Novo
                  </Text>
                </Button>
              </Link>
            </Styled.ButtonsContainer>
          </>
        }
      >
        {isFilterVisible && (
          <Filter
            filter={filter}
            setFilter={setFilter}
            handleSubmit={() => handleFilter({ ...filter, page: 1 })}
          />
        )}
        <InfiniteScroll
          className="pt-3"
          data={shippingCompanies}
          loading={firstFetch && loading}
          onEndReach={() =>
            fetchShippingCompanies({
              ...filter,
              page: Number(pagination.page) + 1,
            })
          }
          renderItem={shipping => <ShippingCompaniesCard shipping={shipping} />}
          hasMore={pagination.page < pagination.lastPage}
          scrollThreshold={0.7}
          loader={pagination.page > 1 ? <DefaultLoadingComponent /> : null}
        />
      </ApplicationLayout>
    </>
  );
}
