import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Text, Modal, Button } from '@cargon/ui';

export default function RemoveCardModal({ modal, removeCard }) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!modal.isOpen) {
      setLoading(false);
    }
  }, [modal.isOpen]);
  function handleDeleteCard() {
    setLoading(true);
    removeCard();
  }
  return (
    <Modal
      show={modal.isOpen}
      handleClose={() => modal.close()}
      heading="Remover cartão"
      body={
        <Row>
          <Col>
            <Text>Tem certeza que deseja remover este cartão?</Text>
          </Col>
        </Row>
      }
      footer={
        <Row>
          <Col>
            <Button
              onClick={() => modal.close()}
              className="mr-1"
              variant="success"
            >
              <Text type="regular" weight="500" color="white">
                Cancelar
              </Text>
            </Button>
            <Button
              onClick={() => handleDeleteCard()}
              loading={loading}
              variant="error"
            >
              <Text type="regular" weight="500" color="white">
                Remover
              </Text>
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
