import React, { useEffect, useMemo, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'components/Snackbar';
import Button from 'components/Button';
import Text from 'components/Text';
import Card from 'components/Card';
import { FaExclamationCircle } from 'react-icons/fa';
import Header from '~/components/Header';
import ptBR from 'date-fns/locale/pt-BR';

import api from '~/services/api';

import Documents from '~/pages/Travels/Documents';
import TravelBadge from '~/components/TravelBadge';
import DealAPI from '~/services/api/deal';
import useModal from '~/hooks/useModal';
import Offset from '~/components/Offset';
import { formatDuration, intervalToDuration, isBefore } from 'date-fns';
import { getNow } from '~/utils/formatter';
import { TravelProvider } from '~/pages/Travels/Details/provider';
import travelApi from 'services/api/travel';
import PageHeader from '../PageHeader';
import Progress from '../Progress';
import { TakerValues } from './TakerValues';
import Info from '../Info';
import Logs from '../Logs';
import Map from './Map';
import RefuseModal from './Modal/Refuse';
import Locations from '../../Shared/Locations';
import { RiskManagerCard } from './RiskManager';
import { Attachments } from './Attachments';
import { PaymentCard } from './Payment';
import RefusedReason from './Modal/RefusedReason';
import { useRefusedReasonStore } from './Modal/RefusedReason/store';

export default function TravelDetails() {
  const user = useSelector(store => store.auth.user.properties);
  const params = useParams();
  const history = useHistory();
  const snackbar = useSnackbar();

  const [statuses, setStatuses] = useState([]);
  const [travelId, setTravelId] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const [travelData, setTravelData] = useState({});

  const [isFetching, setFetching] = useState(false);
  const [travelInfo, setTravelInfo] = useState({
    allCanhotosAccepted: false,
    statusEDIT: { label: ' ... ', value: ' ... ' },
    driver: { social_name: '' },
    driverCnh: { cnh: '', cnhExpire: '' },
    destinations: [],
    origin: [],
    emitteds: [],
    tags: [],
    load: {},
    fetch: true,
  });
  const [accessDenied, setAccessDenied] = useState(false);
  const [msgErrorSteps, setMsgErrorSteps] = useState('');
  const [msgErrorLogs, setMsgErrorLogs] = useState('');
  const [current, setCurrent] = useState(true);
  const [steps, setSteps] = useState([]);
  const [negotiator, setNegotiator] = useState([]);
  const [released, setReleased] = useState();
  const [listaLogs, setListaLogs] = useState([]);
  const [shipper, setShipper] = useState({});
  const [loading, setLoading] = useState(false);
  const refuseModal = useModal();
  useEffect(() => {
    setTravelId(params.id);
  }, []);

  async function startChat(params) {
    if (!negotiator) {
      snackbar.show(<Text>A Carga não possui negociador</Text>, {
        type: 'error',
      });
    } else {
      const response = await DealAPI.post('deals', {
        load_id: travelInfo?.loadNumber,
        driver_id: user.id,
        negotiators_ids: [negotiator],
      }).catch(function (error) {
        if (error.response) {
          snackbar.show(<Text>{error.response.data.message}</Text>, {
            type: 'error',
          });
        }
      });

      if (response?.data?.[0]) {
        const messageData = {
          sender_id: user.id,
          receiver_id: negotiator,
          deal_id: response.data[0].id,
          message: `Olá, gostaria de conversar sobre a viagem  #${travelId}.`,
        };

        await DealAPI.post('messages', { ...messageData }).catch(function (
          error
        ) {
          if (error.response) {
            snackbar.show(<Text>{error.response.data.message}</Text>, {
              type: 'error',
            });
          }
        });
      }

      history.push('/negociacoes');
    }
  }

  function getTravelStatus(logs) {
    const refusedStatus = logs?.map(log => {
      return log.allocatedTravel?.refused;
    });
    const acceptedStatus = logs?.filter(log => {
      return log.allocatedTravel?.accepted;
    });

    return {
      refused: refusedStatus[refusedStatus.length - 1],
      accepted: acceptedStatus[acceptedStatus.length - 1],
    };
  }

  const { getPendingTravels } = useRefusedReasonStore();
  async function updateEmittedStatus() {
    const { data: emitteds } = await travelApi.get(`${travelId}/emitteds`, {
      params: {
        limit: 1000,
        check_all_canhotos_accepted: true,
      },
    });

    const allCanhotosAccepted =
      emitteds.data.result?.allCanhotosAccepted ?? false;
    setTravelInfo(prev => ({ ...prev, allCanhotosAccepted }));
  }
  async function getTravel() {
    setFetching(true);

    try {
      const response = await api.get(
        `travel-data/byId/${travelId}?carrier=true`
      );

      if (response?.data && response.data.length) {
        setTravelData(response.data[0]);
      }
      const { data: emitteds } = await travelApi.get(`${travelId}/emitteds`, {
        params: {
          limit: 1000,
          check_all_canhotos_accepted: true,
        },
      });

      const allCanhotosAccepted =
        emitteds.data.result?.allCanhotosAccepted ?? false;

      // if (response.status === 401) {
      //   setAccessDenied(true);
      //   return;
      // }

      setAccessDenied(false);

      const respListaSteps = await api.get(`travel-status/steps/${travelId}`);

      const respListaLogs = await api.get(
        `travel-logs/?attended_id=${travelId}&carrier=true`
      );
      if (response.data[0] === undefined) {
        return;
      }

      if (respListaSteps.status === 200) {
        if (respListaSteps.data.error) {
          setMsgErrorSteps('Não foi possível obter os dados do progresso');
        } else {
          const currentStep = respListaSteps.data
            .map(item => item.current)
            .indexOf(true);
          setCurrent(currentStep);
          setSteps(respListaSteps.data);
        }
      }

      if (respListaLogs.status === 200) {
        if (respListaLogs.data.error) {
          setMsgErrorLogs('Não foi possível obter os dados do Feed.');
        } else if (respListaLogs.data) {
          setListaLogs(respListaLogs.data);
        }
      }

      const [obj] = response.data;
      setNegotiator(obj.load.negotiator_id);
      setReleased(obj.originList[0].is_released);
      setShipper(obj.shipper);

      const { accepted, refused } = getTravelStatus(obj?.logs);
      const accept_deadline = obj?.accept_deadline;
      const confirmation_deadline = obj?.confirmation_deadline;
      const shipper = obj.shipper ? obj.shipper.social_name : '';
      const statusColor = obj.status_color;
      const taker = `${obj.social_name} - ${obj.cgccpf}`;
      const loadNumber = obj.load_id;
      const { status } = obj;
      const statusId = obj.status_id;
      const statusEDIT = { status: { label: status, value: statusId } };
      const value = obj.balance;
      const driver = obj.driverData ? obj.driverData : undefined;
      const driverCnh = driver
        ? { cnh: driver.cnh_number, cnhExpire: driver.cnh_exp_date }
        : undefined;

      const { tags } = obj;

      const { tractionVehicle, wagonVehicles } = obj || {
        wagonVehicles: [],
      };

      const vehicleId = tractionVehicle?.id;
      const qty_axis = tractionVehicle?.qty_axis;
      const placa1 = tractionVehicle?.plate;
      const placa2 = wagonVehicles[0]?.plate || '';
      const placa3 = wagonVehicles[1]?.plate || '';
      const placa4 = wagonVehicles[2]?.plate || '';

      const wagonId2 = wagonVehicles[0]?.id;
      const wagonId3 = wagonVehicles[1]?.id;
      const wagonId4 = wagonVehicles[2]?.id;

      let travelNumber = '';
      let userCreated = 0;

      const emittedId = 0;

      travelNumber = obj.travel_number || '';
      userCreated = obj.user_created || 0;

      const [origin] = obj.originList || [];
      const destinations = obj.destinationList || [];

      getPendingTravels(obj.shipper.id);

      setTravelInfo({
        accept_deadline,
        confirmation_deadline,
        attendedId: travelId,
        vehicleId,
        qty_axis,
        statusColor,
        statusId,
        taker,
        loadNumber,
        status,
        statusEDIT,
        value,
        riskManager: obj.riskManager,
        tracker: obj.tracker,
        driver,
        driverCnh,
        placa1,
        placa2,
        placa3,
        placa4,
        wagonId2,
        wagonId3,
        wagonId4,
        shipper,
        travelNumber,
        userCreated,
        emitteds: emitteds.data.result.emitteds,
        mdfes: obj.mdfes,
        origin,
        destinations,
        emittedId,
        tags,
        refused,
        accepted,
        user: obj.load.userCreated,
        load: obj.load,
        risk_manager_status: obj.risk_manager_status,
        risk_manager_approval_code: obj.risk_manager_approval_code,
        risk_manager_monitoring_request: obj.risk_manager_monitoring_request,
        paymentCiot: obj.paymentCiot,
        paymentToll: obj.paymentToll,
        receiving_mode_id: obj.receiving_mode_id,
        allCanhotosAccepted,
        tractionVehicle,
        wagonVehicles,
      });
    } catch (err) {
      // Handle exception
    } finally {
      setFetching(false);
      const toggleRender = () => setFirstRender(false);
      setTimeout(() => toggleRender(), 5000);
    }
  }

  const { travels: refusedTravels, modal } = useRefusedReasonStore();

  async function acceptTravel() {
    if (refusedTravels.length) {
      modal.open();
      return;
    }
    setLoading(true);
    try {
      await api.post(`allocated-travels/accept/${travelInfo.attendedId}`, {
        parameters: {
          load_id: travelInfo.loadNumber,
          person_id: shipper.id,
        },
      });

      snackbar.show(<Text>Viagem aceita com sucesso!</Text>, {
        type: 'success',
      });
      await getTravel();
    } catch (error) {
      snackbar.show(
        <Text>
          {error.response?.data?.error ||
            'Algo deu errado, tente novamente mais tarde!'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  const deliveryButton = useMemo(() => {
    const hideIndexes = [5, 6, 7];
    const stepIsValid = !(
      steps.filter(step => hideIndexes.some(idx => idx === step.id)) || []
    ).some(step => !!step?.completed);

    const validTravelStatus = ['ST3', 'ST5'];

    const travelStatusIsValid = validTravelStatus.includes(
      travelInfo?.status?.code
    );
    const allCteCanhotosAccepted = travelInfo.allCanhotosAccepted;

    return (
      stepIsValid &&
      allCteCanhotosAccepted &&
      travelStatusIsValid &&
      (!!travelInfo?.mdfes?.length || !!travelInfo?.emitteds?.length)
    );
  }, [
    travelInfo?.mdfes,
    travelInfo?.emitteds,
    steps,
    travelInfo?.allCanhotosAccepted,
  ]);

  const [loadingButton, setLoadingButton] = useState(false);

  const setDelivered = async () => {
    setLoadingButton(true);
    const now = getNow();

    const finishedStep = {
      id: 6,
      code: 'ST6',
      name: 'Em análise',
      completed: now,
      color: '#FF9800',
    };
    if (deliveryButton) {
      try {
        await api.put(`travel-data/updateStatus/${travelInfo.attendedId}`, {
          value: finishedStep.id,
        });

        const inFinishedIdx = steps.findIndex(step => step.id === 6);
        setSteps([
          ...steps.slice(0, inFinishedIdx),
          {
            ...steps[inFinishedIdx],
            completed: finishedStep.completed,
            color: finishedStep.color,
          },
        ]);
        setTravelInfo({
          ...travelInfo,
          status: {
            ...steps[inFinishedIdx],
            completed: finishedStep.completed,
            color: finishedStep.color,
          },
        });
      } catch (e) {
        snackbar.show(<Text>Erro ao mudar status da viagem</Text>, {
          type: 'error',
        });
      } finally {
        setLoadingButton(false);
      }
    }
  };

  useEffect(() => {
    if (travelId) getTravel();
  }, [travelId]);

  useEffect(() => {
    if (travelId) getTravel();
  }, [travelInfo.data?.driver, travelInfo.data?.vehicle]);

  const [acceptDuration, setAcceptDuration] = useState('');
  const [confirmationDuration, setConfirmationDuration] = useState('');

  const hasBeenConfirmed = useMemo(() => {
    return !!travelInfo?.tractionVehicle && !!travelInfo?.driver;
  }, [travelInfo?.tractionVehicle, travelInfo?.driver]);

  const calcDeadlineDuration = () => {
    if (travelInfo?.accept_deadline) {
      if (isBefore(new Date(), new Date(travelInfo?.accept_deadline))) {
        const duration = intervalToDuration({
          start: new Date(),
          end: new Date(travelInfo?.accept_deadline),
        });
        const units = ['days', 'hours', 'minutes'];
        const nonzero = Object.entries(duration)
          .filter(([_, value]) => (value || 0) > 0)
          .map(([unit, _]) => unit);
        const temp = formatDuration(duration, {
          format: units.filter(i => new Set(nonzero).has(i)).slice(0, 3),
          delimiter: ', ',
          locale: ptBR,
        });
        setAcceptDuration(temp);
      }
    }
  };
  const calcConfirmationDeadlineDuration = () => {
    if (travelInfo?.confirmation_deadline) {
      if (isBefore(new Date(), new Date(travelInfo?.confirmation_deadline))) {
        const duration = intervalToDuration({
          start: new Date(),
          end: new Date(travelInfo?.confirmation_deadline),
        });
        const units = ['days', 'hours', 'minutes'];
        const nonzero = Object.entries(duration)
          .filter(([_, value]) => (value || 0) > 0)
          .map(([unit, _]) => unit);
        const temp = formatDuration(duration, {
          format: units.filter(i => new Set(nonzero).has(i)).slice(0, 3),
          delimiter: ', ',
          locale: ptBR,
        });
        setConfirmationDuration(temp);
      }
    }
  };
  useEffect(() => {
    calcDeadlineDuration();
  }, [travelInfo?.accept_deadline]);

  useEffect(() => {
    setTimeout(() => calcDeadlineDuration(), 60000);
  }, [acceptDuration]);

  useEffect(() => {
    calcConfirmationDeadlineDuration();
  }, [travelInfo?.confirmation_deadline]);

  useEffect(() => {
    setTimeout(() => calcConfirmationDeadlineDuration(), 60000);
  }, [confirmationDuration]);

  useEffect(() => {
    async function fetchTravelStatuses() {
      try {
        const response = await api.get('travel-status');
        setStatuses(response.data.filter(item => item.code !== 'ST4'));
      } catch (error) {
        // Handle exception
      }
    }

    fetchTravelStatuses();
  }, []);

  return (
    <>
      <Header
        RightComponent={
          <>
            <Button
              variant="secondary"
              onClick={() => history.goBack()}
              style={{ minWidth: '86px', height: '38px' }}
              className="ml-1"
            >
              <Text weight={500} type="regular">
                Voltar
              </Text>
            </Button>
          </>
        }
      />
      <RefusedReason />
      <RefuseModal
        isOpen={refuseModal.isOpen}
        onClose={refuseModal.close}
        travelId={travelId}
        travelInfo={travelInfo}
        shipper={shipper}
      />
      <Offset>
        <Container>
          {travelInfo.refused || accessDenied ? (
            <Card>
              <div className="text-center">
                <FaExclamationCircle
                  size={26}
                  color="#F64E60"
                  className="mr-2 mb-1"
                />
                <Text type="header" color="error">
                  Você não tem permissão para acessar essa viagem!
                </Text>
              </div>
            </Card>
          ) : (
            <>
              <PageHeader
                title={`Viagem #${travelId}`}
                badge={
                  <>
                    {travelInfo?.status?.code === 'ST6' ||
                      (travelInfo?.status?.code === 'ST5' && (
                        <TravelBadge variant="success" className="ml-2 mt-fix">
                          Entregue
                        </TravelBadge>
                      ))}
                    {travelInfo?.status?.code === 'ST7' ? (
                      <TravelBadge className="ml-2 mt-fix" variant="danger">
                        Viagem cancelada
                      </TravelBadge>
                    ) : (
                      released &&
                      travelInfo?.status?.code === 'ST2' && (
                        <TravelBadge variant="success" className="ml-2 mt-fix">
                          Liberada
                        </TravelBadge>
                      )
                    )}
                  </>
                }
                other_details={
                  <>
                    {acceptDuration && !travelInfo.accepted && (
                      <TravelBadge className="pl-0">
                        {`Tempo restante de aceite: ${acceptDuration}`}
                      </TravelBadge>
                    )}
                    {!hasBeenConfirmed && confirmationDuration && (
                      <TravelBadge className="pl-0">
                        {`Tempo restante para confirmação: ${confirmationDuration}`}
                      </TravelBadge>
                    )}
                  </>
                }
                RightComponent={
                  <>
                    {!travelInfo?.fetch && deliveryButton && (
                      <Button
                        variant="primary"
                        onClick={() => setDelivered()}
                        loading={loadingButton}
                        style={{ minWidth: '86px', height: '38px' }}
                        className="ml-2"
                      >
                        <Text weight={500} type="regular">
                          Relatar entrega
                        </Text>
                      </Button>
                    )}
                    {!travelInfo?.fetch && (
                      <>
                        {travelInfo?.status?.code === 'ST2' && (
                          <Button
                            variant="error"
                            onClick={refuseModal.open}
                            style={{ minWidth: '86px', height: '38px' }}
                            className="ml-2"
                          >
                            <Text weight={500} type="regular">
                              Recusar viagem
                            </Text>
                          </Button>
                        )}
                        {(!travelInfo?.driver?.id || !travelInfo?.placa1) && (
                          <>
                            {!travelInfo.accepted && (
                              <Button
                                variant="primary"
                                onClick={() => acceptTravel()}
                                style={{ minWidth: '86px', height: '38px' }}
                                className="ml-2"
                                loading={loading}
                              >
                                <Text weight={500} type="regular">
                                  Aceitar viagem
                                </Text>
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {!travelInfo?.fetch && (
                      <Button
                        variant="secondary"
                        onClick={() => startChat()}
                        style={{ minWidth: '86px', height: '38px' }}
                        className="ml-2"
                      >
                        <Text weight={500} type="regular">
                          Contatar Cliente
                        </Text>
                      </Button>
                    )}
                  </>
                }
              />
              <Progress
                attended_id={travelId}
                color={travelInfo.statusColor}
                current={current}
                steps={steps}
                onError={msgErrorSteps}
                destinations={travelInfo.destinations}
              />
              <Map
                travelId={travelInfo.attendedId}
                isFetching={isFetching}
                origin={travelInfo.origin}
                destinations={travelInfo.destinations}
                firstRender={firstRender}
              />
              <Info
                origin={travelInfo.origin}
                destinations={travelInfo.destinations}
                travelInfo={travelInfo}
                getTravel={getTravel}
                travelData={travelData}
                setTravelInfo={setTravelInfo}
              />

              <Locations travel={travelInfo} refetchTravel={getTravel} />
              <Row>
                <RiskManagerCard
                  travelInfo={travelInfo}
                  refetchTravel={getTravel}
                />
                <PaymentCard
                  travelInfo={travelInfo}
                  refetchTravel={getTravel}
                />
              </Row>
              <Attachments travel={travelInfo} />
              <TravelProvider
                value={{
                  travelInfo,
                  setTravelInfo,
                  getTravel,
                  isFetching,
                  updateEmittedStatus,
                  statuses,
                }}
              >
                <Documents />
              </TravelProvider>
              <Logs
                attended_id={travelId}
                color={travelInfo.status_color}
                onError={msgErrorLogs}
                logs={listaLogs}
              />
            </>
          )}
        </Container>
      </Offset>
    </>
  );
}
