import { takeLatest, call, put, all } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle
import { persistor } from '~/store';
// eslint-disable-next-line import/no-cycle
import api from '~/services/api';

import Types from './types';
import Actions from './actions';
import User from '../../models/User';
import OneSignal from 'react-onesignal';

export function* validateEmail({ payload }) {
  const { email, formRef } = payload;

  try {
    if (!email) {
      throw new Error();
    }

    yield call(api.post, 'sessions/email', { email });

    yield put(Actions.validateEmailSuccess());
  } catch (ex) {
    let message = 'Você informou um e-mail inválido.';
    if (ex.response) {
      message = ex.response.data.message;
    }

    yield put(Actions.validateEmailFailure(message, formRef));
  }
}

export function validateEmailFailure({ payload }) {
  const { message, formRef } = payload;

  formRef.setErrors({ email: message });
}

export function* signIn({ payload }) {
  const { email, password, formRef, history, from } = payload;

  try {
    const response = yield call(api.post, 'sessions', {
      email,
      password,
    });

    const { user: userProperties, token } = response.data;
    const user = new User(userProperties, token);
    OneSignal.setExternalUserId(`user_${userProperties.id}`);

    yield put(Actions.signInSuccess(user, { history, from }));
  } catch (ex) {
    let message = 'Senha incorreta.';
    if (ex.response) {
      message = ex.response.data.message;
    }

    yield put(Actions.signInFailure(message, formRef));
  }
}

function signInSuccess({ payload }) {
  const { history, from } = payload;

  history.push(from);
}

function signInFailure({ payload }) {
  const { message, formRef } = payload;

  formRef.setErrors({ password: message });
}

function signOut({ payload }) {
  const { history } = payload;
  OneSignal.removeExternalUserId();

  history.push('/login');
  persistor.purge();
}

export default all([
  takeLatest(Types.VALIDATE_EMAIL, validateEmail),
  takeLatest(Types.VALIDATE_EMAIL_FAILURE, validateEmailFailure),
  takeLatest(Types.SIGN_IN, signIn),
  takeLatest(Types.SIGN_IN_SUCCESS, signInSuccess),
  takeLatest(Types.SIGN_IN_FAILURE, signInFailure),
  takeLatest(Types.SIGN_OUT, signOut),
]);
