import React, { useState, useEffect, useCallback } from 'react';
import { SnackbarContext } from './controller';
import * as Styled from './styles';

const [SnackbarProvider, useSnackbar] = SnackbarContext;

export default function Snackbar({ children }) {
  const [isVisible, setVisible] = useState(false);
  const [content, setContent] = useState(null);
  const [duration, setDuration] = useState(5000);
  const [type, setType] = useState('primary');

  const show = useCallback(
    (_content, options = {}) => {
      if (options.duration) setDuration(options.duration);
      if (options.type) setType(options.type);
      setContent(_content);
      setVisible(true);
    },
    [content, type, duration, isVisible]
  );

  useEffect(() => {
    if (isVisible) {
      const timeout = setTimeout(() => {
        setVisible(false);
      }, duration);
      return () => clearTimeout(timeout);
    }
    return () => false;
  }, [isVisible]);

  return (
    <SnackbarProvider value={{ isVisible, content, show, type }}>
      <Styled.Div type={type} isVisible={isVisible}>
        <Styled.Content>{content}</Styled.Content>
      </Styled.Div>
      {children}
    </SnackbarProvider>
  );
}

export { useSnackbar };
