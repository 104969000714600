import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Text, useSnackbar } from '@cargon/ui';

import { auctionStore } from '~/services/store/Auction';
import Details from './Details';

export default function DriverSingle() {
  const params = useParams();
  const snackbar = useSnackbar();
  const { fetchAuction, setAllLoadings } = auctionStore();

  useEffect(() => {
    async function getAuction() {
      try {
        setAllLoadings(true);
        await fetchAuction(params.id);
      } catch (err) {
        console.log(err);
        snackbar.show(<Text>Não foi possível carregar essa oferta.</Text>, {
          type: 'error',
        });
      } finally {
        setAllLoadings(false);
      }
    }
    if (params.id) {
      getAuction();
    }
  }, [params.id]);

  return <>{params.id ? <Details /> : <div />}</>;
}
