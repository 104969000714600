import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Fallback, Text, Button } from '@cargon/ui';
import theme from '@cargon/ui/dist/theme';

import { driverStore } from '~/services/store/Driver';
import AttachmentItens from './Itens';

export default function Attachments({ setShowModal }) {
  const { currentDriver } = driverStore();

  return (
    <>
      <Fallback
        component={
          <Col xs={12} className="align-items-center d-flex flex-column my-2">
            <Text color={theme.colors.gray}>
              Não há anexos cadastrados para esse motorista.
            </Text>
            <Button onClick={() => setShowModal(true)} className="mt-3">
              Adicionar
            </Button>
          </Col>
        }
        fall={!currentDriver?.attachments?.length}
      >
        <Row>
          <AttachmentItens />
        </Row>
      </Fallback>
    </>
  );
}
