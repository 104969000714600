import { Col, Row } from 'react-bootstrap';
import React from 'react';
import { Button, Modal, Select, Text } from '@cargon/ui';
import { useVehicle } from '../../../shared/context';

const traction = { label: 'Veículo tração', value: false };
const wagon = { label: 'Implemento', value: true };

const isWagonOptions = [traction, wagon];
export default function IsWagonModal({ modal }) {
  const vehicle = useVehicle();
  return (
    <Modal
      size="md"
      backdrop="static"
      closeButton={false}
      show={modal.isOpen}
      handleClose={() => modal.close()}
      heading="Tipo de veículo"
      body={
        <Row>
          <Col xs={12}>
            <Text>Selecione o tipo de veículo a ser cadastrado: </Text>
          </Col>
          <Col xs={12}>
            <Select
              label="Tipo de veículo"
              options={isWagonOptions}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              value={vehicle.data.is_wagon ? wagon : traction}
              onChange={option =>
                vehicle.setCurrentVehicle({
                  is_wagon: option?.value,
                })
              }
            />
          </Col>
        </Row>
      }
      footer={
        <>
          <Button variant="success" onClick={() => modal.close()}>
            <Text type="regular" color="white">
              Salvar
            </Text>
          </Button>
        </>
      }
    />
  );
}
