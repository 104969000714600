/* eslint-disable import/no-unresolved */
import React, { useState, useMemo } from 'react';
import moment from 'moment';

import api from 'services/api';
import { useSnackbar } from 'components/Snackbar';
import Modal from 'components/Modal';
import Text from 'components/Text';
import Button from 'components/Button';
import { DatePicker } from '@cargon/ui';
import { Col, Row } from 'react-bootstrap';

const TYPES = {
  arrival_time: 'chegada',
  effective_time: 'efetivação',
};

export default function Inform({
  id,
  isOpen,
  onClose,
  isOrigin,
  type,
  refetchTravel,
}) {
  const [datetime, setDatetime] = useState('');

  const [errors, setErrors] = useState({ datetime: '' });

  const snackbar = useSnackbar();

  function setError(key, message) {
    setErrors(prev => ({ ...prev, [key]: message }));
  }

  function clearError(key) {
    setError(key, '');
  }

  async function handleInsertTime() {
    const endpoint = isOrigin ? 'origin' : 'destination';

    const momentDate = moment(datetime, 'DD/MM/YYYY HH:mm');

    if (!momentDate.isValid() || datetime.match(/\d+/g).join('').length < 12) {
      setError('datetime', 'Data informada é inválida');

      return;
    }

    try {
      const data = {
        title: TYPES[type],
        [type]: momentDate.toDate(),
      };

      await api.put(`/travel-data/${endpoint}/${id}`, data);

      snackbar.show(<Text>Horário informado com sucesso</Text>, {
        type: 'success',
      });

      if (refetchTravel) refetchTravel();
    } catch (ex) {
      snackbar.show(<Text>Não foi possível informar o horário</Text>, {
        type: 'error',
      });
    } finally {
      setDatetime('');
      onClose();
    }
  }

  const informModalTitle = useMemo(
    () => (
      <Text color="dark" weight="500" type="header">
        Informar data de {TYPES[type]}
      </Text>
    ),
    [type]
  );

  return (
    <Modal
      show={isOpen}
      heading={informModalTitle}
      handleClose={onClose}
      body={
        <Row>
          <Col xs={12}>
            <DatePicker
              guide
              withTime
              label={<Text> Data de {TYPES[type]} </Text>}
              value={datetime}
              onChange={({ target }) => setDatetime(target.value)}
              onBlur={() => clearError('datetime')}
              error={errors.datetime}
            />
          </Col>
          <Col xs={12} className="text-center ">
            <Button onClick={handleInsertTime} className="py-2 me-3 mt-3">
              <Text type="regular" weight="500">
                Confirmar
              </Text>
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
