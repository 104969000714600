import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { conformToMask } from 'react-text-mask';
import moment from 'moment';
import 'moment/locale/pt-br';

const numberFormatter = new Intl.NumberFormat('pt-BR');

const currencyMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const currencyMask = createNumberMask({ ...currencyMaskOptions });

const customCurrencies = [
  'AOA',
  'BOB',
  'ARS',
  'CLP',
  'COU',
  'ECS',
  'PYG',
  'PEN',
  'UYU',
];

export function conformCurrency(value) {
  const { conformedValue } = conformToMask(value, currencyMask, {
    guide: false,
  });
  return conformedValue;
}

export function formatCurrency(value, currency = 'BRL', symbol) {
  const formatted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
  }).format(value);

  if (customCurrencies.includes(currency)) {
    return `${symbol} ${formatted.replace(/[^\d,\\.]/g, '')}`;
  }
  return formatted;
}

export function formatNumber(value) {
  return numberFormatter.format(value);
}

export function convertToDatabase(value) {
  if (!value) {
    return 0;
  }

  return value.toString().split('.').join('').split(',').join('.');
}

export function convertFromDatabase(value) {
  if (!value) {
    return null;
  }

  return value.toString().split('.').join(',');
}

export function pluralize(str, quantity, strPluralized) {
  if (quantity !== 1) {
    return strPluralized || str.concat('s');
  }

  return str;
}

export function calculateDeadline(limit) {
  if (!limit) {
    return 'Não informado';
  }

  const diff = moment(limit).diff(moment().fromNow(), 'hours');

  return moment.utc(diff).format('HH:mm:ss.SSS');
}

export function replaceNonDigits(string) {
  return string.replace(/\D/g, '');
}

export function addLimit(value) {
  const minutes = moment.duration(value, 'hours').asMinutes();
  if (minutes) {
    return moment().add(minutes, 'minutes').toDate();
  }

  return false;
}

export function formatDateTime(value = new Date()) {
  if (!value) {
    return 'Não informado';
  }

  return moment(value).format('DD/MM/YYYY [às] HH:mm');
}

export function formatDate(value = new Date(), options = {}) {
  if (!value) {
    return 'Não informado';
  }

  const { isUTC = false } = options;

  if (isUTC) {
    return moment(value).utc().format('DD/MM/YYYY');
  }

  return moment(value).format('DD/MM/YYYY');
}

export function formatDateTimeLong(value) {
  if (!value) {
    return 'Data não informada';
  }

  return moment(value).format('DD [de] MMMM [às] HH:mm');
}

export function formatDateShort(value) {
  if (!value) {
    return 'Não informado';
  }

  const date = moment(value);
  const year = new Date().getFullYear();
  if (year !== date.year()) {
    return date.format('DD MMM YY').toUpperCase();
  }

  return moment(value).format('DD MMM').toUpperCase();
}

export function formatTime(value) {
  if (!value) {
    return 'Não informado';
  }

  return moment(value).format('HH:mm');
}

export function formatDateCustom(value, format) {
  if (!value) {
    return null;
  }

  return moment(value).format(format);
}

export function formatDateFromNow(value) {
  return moment(value).fromNow();
}

export function formatDateToNow(value) {
  return moment(value).toNow(true);
}

export function capitalize(value) {
  if (typeof value !== 'string') return value;

  const output = value.toLowerCase();
  return output.charAt(0).toUpperCase() + output.slice(1);
}

export function capitalizeFullname(value) {
  if (!value) {
    return '';
  }

  return value
    .toLowerCase()
    .split(' ')
    .map(word =>
      word.length > 2
        ? word.replace(/(^\w{1})/g, match => match.toUpperCase())
        : word
    )
    .join(' ');
}

export function formatPlate(plate) {
  if (!plate) {
    return '-';
  }

  if (plate.charAt(4).match(/[0-9]/)) {
    return plate.replace(/^([a-zA-Z]{3})([0-9]{4})$/i, '$1-$2').toUpperCase();
  }

  return plate.toUpperCase();
}

export function formatBoolean(value) {
  return value ? 'Sim' : 'Não';
}

export function formatAddress({
  address,
  number,
  complement,
  neighborhood,
  city,
  province,
  zip_code,
}) {
  const builder = [];

  if (address) {
    builder.push(address);

    if (number) {
      builder.push(`, ${number}`);
    }

    if (complement) {
      builder.push(` - ${complement}`);
    }

    if (neighborhood) {
      builder.push(` - ${neighborhood},`);
    }
  }

  if (city) {
    builder.push(` ${city}`);

    if (province) {
      builder.push(` - ${province}`);
    }

    if (zip_code) {
      builder.push(`, ${zip_code}`);
    }
  }
  return builder.join('');
}

export function parseErrors(errors, options = {}) {
  const { prefix, getPath = error => error.path } = options;

  return errors.reduce((acc, error) => {
    const output = acc;
    let path = getPath(error);
    if (prefix) {
      path = prefix + path;
    }
    output[path] = error.message;
    return output;
  }, {});
}

export function parseDate(date, format = 'DD/MM/YYYY') {
  return moment(date, format).toDate();
}
