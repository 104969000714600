/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Button, Col, Collapse, Dropdown, Nav, Row } from 'react-bootstrap';
import { FaBell } from 'react-icons/fa';

import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthActions from '~/store/modules/auth/actions';
import Notifications, { useNotificationsController } from './Notifications';
import * as Styled from './styles';
import { NavDropdown, NavLink } from './styles';

import './style.css';

const links = [
  { name: 'Cargas', path: '/cargas' },
  { name: 'Viagens', path: '/viagens' },
  { name: 'Viagens Alocadas', path: '/alocadas' },
  { name: 'Chat', path: '/negociacoes' },

  {
    name: 'Cadastros',
    children: [
      { name: 'Motoristas', path: '/motoristas' },
      { name: 'Veículos', path: '/veiculos' },
      { name: 'Transportadoras', path: '/transportadoras' },
    ],
  },
  {
    name: 'Relatórios',
    children: [
      { name: 'Relatório de CTes', path: '/relatorios/ctes' },
      {
        name: 'Relatório de Viagens Aceitas',
        path: '/relatorios/viagens-aceitas',
      },
      {
        name: 'Relatório de Viagens Recusadas',
        path: '/relatorios/viagens-recusadas',
      },
      {
        name: 'Relatório de Viagens Confirmadas',
        path: '/relatorios/viagens-confirmadas',
      },
    ],
  },
];

export default function Menu({ path }) {
  const { unread, fetchUnreadNotifications } = useNotificationsController();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchUnreadNotifications();
  }, []);

  const [open, setOpen] = useState(true);

  function signOut() {
    dispatch(AuthActions.signOut(history));
  }

  return (
    <Row>
      <Col className="d-block d-lg-none text-right">
        <Button onClick={() => setOpen(!open)} aria-expanded={open}>
          <i className="fas fa-bars" />
        </Button>
      </Col>

      <Col xs={12}>
        <Collapse in={open} className="nav-header">
          <Nav variant="pills" className="mt-5 mt-md-0 d-lg-block">
            <Row>
              <Col md={10} className=" d-lg-flex">
                {links.map((link, index) => {
                  if (link.children) {
                    const active = link.children.find(item => {
                      return item.path === path;
                    });

                    return (
                      <Nav.Item>
                        <NavDropdown
                          active={active}
                          title={link.name}
                          id={link.path}
                          className="text-uppercase"
                        >
                          {link.children
                            .sort((a, b) => {
                              let order;
                              a.name > b.name
                                ? (order = 1)
                                : a.name < b.name
                                ? (order = -1)
                                : (order = 0);
                              return order;
                            })
                            .map(item => (
                              <NavDropdown.Item
                                as={Link}
                                to={item.path}
                                active={active}
                                className="text-uppercase"
                              >
                                {item.name}
                              </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                      </Nav.Item>
                    );
                  }
                  const active = path && path.path === link.path;

                  return (
                    <Nav.Item>
                      <NavLink
                        as={Link}
                        to={link.path}
                        key={index}
                        active={active}
                        className="text-uppercase d-inline-block"
                      >
                        {link.name}
                      </NavLink>
                    </Nav.Item>
                  );
                })}
              </Col>
              <Col
                md={12}
                lg={2}
                className="d-block d-lg-flex justify-content-end"
              >
                <Dropdown>
                  <Dropdown.Toggle
                    as={React.forwardRef(({ onClick }, ref) => (
                      <NavLink ref={ref} onClick={onClick}>
                        <FaBell color="#fff" />
                        {unread && <Styled.NotificationUnreadIcon />}
                      </NavLink>
                    ))}
                  />
                  <Dropdown.Menu as={Notifications} />
                </Dropdown>

                <Nav.Item>
                  <NavLink
                    as={Link}
                    onClick={signOut}
                    to="#"
                    className="text-uppercase d-inline-block"
                  >
                    Sair
                  </NavLink>
                </Nav.Item>
              </Col>
            </Row>
          </Nav>
        </Collapse>
      </Col>
    </Row>
  );
}
