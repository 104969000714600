import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaRegStar, FaStarHalfAlt, FaStar, FaCheck } from 'react-icons/fa';

import { Text, Card, Map, Marker, Tooltip } from '@cargon/ui';

import moment from 'moment';

import { fetchRating } from '~/services/fetch/Driver';
import { pluralize } from '~/utils/formatter';
import SkeletonResume from './Skeleton';
import { ContainerStar, Image } from './styles';

import { driverStore } from '~/services/store/Driver';

function Resume() {
  const { currentDriver, loadings, prevDriver } = driverStore();

  const [travelsCount, setTravelsCount] = useState(0);
  const [ratings, setRatings] = useState({
    general: 0,
    schedule: 0,
    app: 0,
  });
  const [selfie, setSelfie] = useState();
  const [verified, setVerified] = useState(false);

  function renderStars(rating) {
    const starsQtt = [1, 2, 3, 4, 5];

    return (
      <Tooltip
        placement="bottom"
        content={<Text>{Number(rating).toFixed(2)}</Text>}
      >
        <div>
          {starsQtt.map(star => {
            if (rating >= star) {
              return <FaStar color="#FFA800" />;
            }

            if (rating < star && rating > star - 1) {
              return <FaStarHalfAlt color="#FFA800" />;
            }

            return <FaRegStar color="#FFA800" />;
          })}
        </div>
      </Tooltip>
    );
  }

  useEffect(() => {
    async function getRating() {
      try {
        const response = await fetchRating({ id: currentDriver?.id });

        // eslint-disable-next-line no-underscore-dangle
        setTravelsCount(response.__meta__.travels_count);
        setRatings({
          general: response.rating_general,
          app: response.rating_app,
          schedule: response.rating_schedules,
        });
      } catch (err) {}
    }

    if (prevDriver?.id) {
      getRating();

      if (currentDriver?.driver) {
        const {
          driver: { selfie: driverSelfie },
        } = currentDriver;

        const selfiePath = driverSelfie ? driverSelfie.full_path : null;

        setSelfie(selfiePath);
      }
    }

    if (currentDriver?.driver) {
      setVerified(currentDriver?.driver?.verified_person);
    }
  }, [prevDriver]);

  return (
    <Row>
      <Col lgs={12} xs={12}>
        <Card
          loading={loadings.resume}
          LoadingBodyComponent={() => <SkeletonResume />}
        >
          <Row>
            <Col lg={2} md={2} xs={12}>
              <Image
                url={selfie || '/images/user-padrao.png'}
                className="mx-auto mb-4"
                alt="Imagem de perfil"
              />
            </Col>
            <Col lg={10} md={10} xs={12}>
              <Row>
                <Col xs={12} className="text-md-left text-center">
                  <Text
                    type="subtitle"
                    transform="uppercase"
                    line-height="24px"
                  >
                    {currentDriver?.social_name}
                  </Text>
                  {verified && (
                    <Tooltip
                      placement="right"
                      content={<Text>Motorista verificado</Text>}
                    >
                      <FaCheck className="mx-3 mb-2" color="#0BB7AF" />
                    </Tooltip>
                  )}
                </Col>
                <Col lg={3} md={3} xs={4} className="my-3">
                  <Text type="body" weight="500">
                    {currentDriver?.cgccpf}
                  </Text>
                </Col>
                <Col lg={3} md={3} xs={4} className="my-3">
                  <Text type="body" weight="500">
                    {currentDriver?.phone}
                  </Text>
                </Col>
                <Col lg={6} md={6} xs={4} className="my-3 text-center">
                  <Text type="body" weight="500">
                    {travelsCount
                      ? `${travelsCount} ${pluralize(
                          'viagem',
                          travelsCount,
                          'viagens'
                        )}`
                      : 'Nenhuma viagem'}
                  </Text>
                </Col>
                <Col xs={12} className="my-3">
                  <hr />
                </Col>
                <Col lg={3} md={3} xs={6} className="d-sm-none d-md-block">
                  <Text type="label" weight="500">
                    CNH
                  </Text>
                  <Text type="body" as="p" weight="500" className="mt-3">
                    {currentDriver?.cnh_number}
                  </Text>
                </Col>
                <Col lg={3} md={3} xs={6} className="d-sm-none d-md-block">
                  <Text type="label" weight="500">
                    Tipo
                  </Text>
                  <Text type="body" as="p" weight="500" className="mt-3">
                    {currentDriver?.cnh_type}
                  </Text>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  xs={12}
                  className="d-flex justify-content-between "
                >
                  <ContainerStar>
                    <Text type="label" weight="500" className="d-flex mb-2">
                      Geral
                    </Text>

                    <div>{renderStars(ratings.general)}</div>
                  </ContainerStar>
                  <ContainerStar>
                    <Text type="label" weight="500" className="d-flex mb-2">
                      Horário
                    </Text>

                    <div>{renderStars(ratings.schedule)}</div>
                  </ContainerStar>
                  <ContainerStar>
                    <Text
                      type="label"
                      weight="500"
                      transform="uppercase"
                      className="d-flex mb-2"
                    >
                      App
                    </Text>

                    <div>{renderStars(ratings.app)}</div>
                  </ContainerStar>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col fluid>
            <Row>
              <Col className="mb-2">
                {currentDriver?.user?.latitude && (
                  <Map
                    height={200}
                    position={{
                      lat: currentDriver?.user?.latitude,
                      lng: currentDriver?.user?.longitude,
                    }}
                  >
                    <Marker
                      background="#0bb7af"
                      lat={currentDriver?.user?.latitude}
                      lng={currentDriver?.user?.longitude}
                      color="#fff"
                      text={moment(currentDriver?.user?.captured_at).format(
                        'LLL'
                      )}
                    />
                  </Map>
                )}
              </Col>
            </Row>
          </Col>
        </Card>
      </Col>
    </Row>
  );
}

export default Resume;
