import React from 'react';
import styled from 'styled-components';
import { Section as CardRaw } from '@cargon/ui';

export const Card = styled(CardRaw)``;
export const FilterDiv = styled.div`
  margin: -16px;
  display: flex;
  flex-wrap: wrap;
`;
export const ToggleDiv = styled.div`
  width: 180px;
  margin: 8px;
`;
export const ButtonSelectDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: flex-end;
`;
export const SelectDiv = styled.div`
  flex-grow: 1;
  width: 250px;
  margin: 8px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 8px;
  margin-bottom: 8px;
  @media (max-width: 576px) {
    margin-right: 8px;
  }
`;
