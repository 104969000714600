import React from 'react';

import { Link } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import { formatCurrency, formatDate } from '~/utils/formatter';
import TravelBadge from '~/components/TravelBadge';

export default function TravelCard({ travel, ...props }) {
  function checkPreEmissao(status) {
    switch (status) {
      case 2:
      case 8:
      case 9:
        return true;
      default:
        return false;
    }
  }

  function messageByStatus(status, col, msg1, msg2, date = false) {
    if (date && col) {
      return formatDate(col);
    }
    if (col) {
      return col;
    }

    if (checkPreEmissao(status)) return msg1;
    return msg2;
  }

  return (
    <Col lg={6} md={6} xs={12} className="p-2">
      <Card className="card-list">
        <Card.Body>
          <Row className="p-3">
            <Col xs="8">
              <span className="card-title">
                <i className="fas fa-industry mr-2" />
                {travel.load &&
                  travel.load.client &&
                  travel.load.client.social_name}
              </span>
              {travel?.status?.code === 'ST7' ? (
                <TravelBadge className="ml-2 mt-fix" variant="danger">
                  Viagem cancelada
                </TravelBadge>
              ) : (
                travel?.status?.code === 'ST2' &&
                travel?.is_released === true && (
                  <TravelBadge pill variant="success" className="mt-1 ml-2">
                    Liberada
                  </TravelBadge>
                )
              )}
              <br />
              <span className="card-text">
                {travel.load && travel.load.client && travel.load.client.cgccpf}
              </span>
            </Col>
            <Col xs={4} className="text-right">
              <Button
                variant="primary"
                as={Link}
                to={`/viagens/${travel.id}`}
                size="sm"
              >
                Ver Detalhes
              </Button>
            </Col>

            <Col xs={12}>
              <hr className="mt-2 mb-3" />
              <span className="card-label">Origem:</span>
              <span className="card-text">
                {travel.origin_city === null
                  ? 'Não cadastrada'
                  : travel.origin_city +
                    (travel.origin_province
                      ? ` - ${travel.origin_province.toUpperCase()}`
                      : '')}
              </span>
            </Col>

            <Col xs={8}>
              <span className="card-label">Destino:</span>
              <span className="card-text">
                {travel.destination_city === null
                  ? 'Não cadastrada'
                  : travel.destination_city +
                    (travel.destination_province
                      ? ` - ${travel.destination_province.toUpperCase()}`
                      : '')}
              </span>
            </Col>

            <Col xs={12}>
              <span className="card-label">Valor:</span>
              <span className="card-text">
                {travel.balance === null
                  ? 'Não possui valor cadastrado'
                  : formatCurrency(travel.balance)}
              </span>
            </Col>
            <Col xs={12} className="mb-3">
              <span className="card-label">Placa:</span>
              <span className="card-text"> {travel.plate}</span>
            </Col>
            <Col xs={12} className="mb-3">
              <span className="card-label">Status da viagem:</span>
              <span className="card-text">{travel?.status?.name}</span>
            </Col>
            <Col xs={6}>
              <span className="card-label">Lançamento:</span>
              <span className="card-text">{formatDate(travel.created_at)}</span>
            </Col>
            <Col xs={6}>
              <span className="card-label">1º Emissão:</span>
              <span className="card-text">
                {messageByStatus(
                  travel.status_id,
                  travel.firstEmitted_data_emissao,
                  'Pendente',
                  'Não Informado',
                  true
                )}
              </span>
            </Col>
            <Col xs={6}>
              <span className="card-label">Nº:</span>
              <span className="card-text">
                {messageByStatus(
                  travel.status_id,
                  travel.travel_number,
                  'Pendente',
                  'Não Informado'
                )}
              </span>
            </Col>

            <Col xs={6} className="mb-3">
              <span className="card-label">REF:</span>
              <span className="card-text"> {travel.attended_id}</span>
            </Col>

            <Col xs={12} className="mb-3">
              <span className="card-label">
                {travel.cte_is_complementary ? ' CT-e COMPLEMENTAR' : ' CT-e'}:{' '}
              </span>
              <span className="card-text">
                {messageByStatus(
                  travel.status_id,
                  travel.cte,
                  'Aguardando emissão de CTe',
                  'Não Cadastrado'
                )}
              </span>
            </Col>

            {travel.tags && !travel.tags.length ? (
              ''
            ) : (
              <Col xs={12}>
                <span className="card-label">Tags</span>
                <br />
                {travel.tags &&
                  travel.tags.map(tag => (
                    <Badge
                      pill
                      variant="secondary"
                      className="my-1 mr-1"
                    >{`#${tag.name}`}</Badge>
                  ))}
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}
